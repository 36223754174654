import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import FormFieldContainer from '../FormFieldContainer';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

const CustomTextField = (props) => {
  const {
    label,
    name,
    containerProps: defaultContainerProps = {},
    helperText,
    variant = 'standard',
    children,
    required = false,
    ...restProps
  } = props;

  let fieldProps = {
    name: name,
    variant: variant,
    fullWidth: true,
    size: 'small',
    ...restProps,
  };

  return (
    <Field name={name}>
      {(formikFieldProps) => {
        const { field, meta } = formikFieldProps;
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            required={required}
            {...defaultContainerProps}
          >
            {variant == 'outlined' ? (
              <StyledTextField {...field} error={error} {...fieldProps} />
            ) : (
              <TextField {...field} error={error} {...fieldProps} />
            )}
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomTextField;

// const TextField2 = (props) => {
//   const {
//     label,
//     name,
//     containerProps: defaultContainerProps = {},
//     helperText,
//     variant = 'standard',
//     children,
//     required = false,
//     ...restProps
//   } = props;

//   let fieldProps = {
//     name: name,
//     variant: variant,
//     fullWidth: true,
//     size: 'small',
//     ...restProps,
//   };

//   return (
//     <FormFieldContainer
//       label={label}
//       error={error}
//       helperText={helperText}
//       required={required}
//       {...defaultContainerProps}
//     >
//       {variant == 'outlined' ? (
//         <StyledTextField {...field} error={error} {...fieldProps} />
//       ) : (
//         <TextField {...field} error={error} {...fieldProps} />
//       )}
//       {children}
//     </FormFieldContainer>
//   );
// };
