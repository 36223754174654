import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  InputBase,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from '../../styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// {
// 	"user_id": 5,
// 	"share_capital_id":2,
// 	"user_allotment": 500
// }

const userTypeId_individual = '1';
const userTypeId_individual_passport = '1';
const userTypeId_company = '2';

const ShareholderDialog = ({
  props,
  open,
  handleClose,
  handleSubmit,
  userData,
  shareData,
  existShareholder,
  allShareholders = [],
  companyData,
  handleRemoveShareholder,
}) => {
  var classes = useStyles();
  const errorSelectMsg = 'Please select.';
  const errorMsg = 'Please fill up.';

  const [entity, setEntity] = useState('Individual');
  const [numberShare, setNumbershare] = useState();
  const [errorMsgNumberShare, setErrorMsgNumberShare] = useState('');

  const [shareType, setSharetype] = useState();
  const [sharetypeerror, setSharetypeerror] = useState(false);
  const [userType, setUsertype] = useState(1);
  const [shareCapitalId, setSharecapitalid] = useState();
  const [shareCapital, setSharecapital] = useState();
  const [shareholderId, setShareholderid] = useState();

  const [userValue, setUservalue] = useState();
  const [errorMsgUser, setErrorMsgUser] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  function handleReset() {
    setEntity('Individual');
    setUsertype(1);
    setUservalue();
    setNumbershare();
    setSharetype();
    setSharetypeerror('');
    setErrorMsgUser('');
  }

  useEffect(() => {
    if (open) {
      if (existShareholder) {
        setIsEdit(true);
        if (existShareholder.user_idtype === 3) {
          setEntity('Company');
          setUsertype(existShareholder.user_idtype);
        } else {
          setUsertype(1);
          setEntity('Individual');
        }
        setUservalue(existShareholder.user_id);
        setSharetype(existShareholder.user_sharetype);
        setNumbershare(existShareholder.user_sharenumber);
        setSharecapitalid(existShareholder.share_capital_id);
        setShareholderid(existShareholder.shareholder_id);
      } else {
        setIsEdit(false);
        handleReset();
      }
    } else {
      handleReset();
    }
  }, [isEdit, existShareholder, open]);

  function handleChangeUser(event, value) {
    if (value === undefined) {
      setErrorMsgUser(errorSelectMsg);
    } else {
      if (value.user_id !== undefined && value.user_id !== null) {
        setUservalue(value.user_id);
      } else if (value.company_id !== undefined && value.company_id !== null) {
        setUservalue(value.company_id);
      }
      setErrorMsgUser('');
    }
  }

  const handleChangeEntity = (event) => {
    setEntity(event.target.value);
    if (event.target.value === 'Individual') {
      setUsertype(userTypeId_individual);
    } else {
      setUsertype(userTypeId_company);
    }
  };

  const handleChangeNumberShare = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgNumberShare(errorMsg);
    } else {
      setNumbershare(event.target.value);
      setErrorMsgNumberShare('');
    }
  };

  function handleChangeShareType(event) {
    if (event.target.value === undefined) {
      setSharetypeerror(true);
    } else {
      setSharetype(event.target.value);
      let foundShareCapital = shareData.find((v) => v.share_type === event.target.value);
      setSharecapital(foundShareCapital);
      setSharecapitalid(foundShareCapital.share_capital_id);
      setSharetypeerror(false);
    }
  }

  const defaultProps = {
    options: entity === 'Individual' ? userData : companyData,
    getOptionLabel: (option) => {
      if (entity === 'Individual') {
        if (option.nric !== null) {
          return (
            option.first_name +
            (option.last_name != null ? ' ' + option.last_name : '') +
            ' (NRIC - ' +
            option.nric +
            ')'
          );
        } else {
          return (
            option.first_name +
            (option.last_name != null ? ' ' + option.last_name : '') +
            ' (Passport - ' +
            option.passport +
            ')'
          );
        }
      } else {
        return option.company_name + ' (' + option.company_registration_no + ')';
      }
    },
  };

  let closeImg = {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
  };

  return (
    <Dialog onBackdropClick="false" open={open} onClose={handleClose} padding={2}>
      <DialogTitle id="simple-dialog-title">
        {existShareholder === undefined ? 'Add Shareholder' : 'Edit Shareholder'}
        <img
          src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
          style={closeImg}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Entity</FormLabel>
              <RadioGroup
                aria-label="entity"
                name="entity"
                onChange={handleChangeEntity}
                defaultValue={entity}
                row
              >
                <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                <FormControlLabel value="Company" control={<Radio />} label="Company" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              // value={existShareholder.user_id}
              onChange={handleChangeUser}
              // value={props.user}
              defaultValue={
                existShareholder === undefined
                  ? null
                  : existShareholder.user_idtype === 3
                  ? companyData.find((v) => v.company_id === existShareholder.user_id)
                  : userData.find((v) => v.user_id === existShareholder.user_id)
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={entity}
                  margin="normal"
                  error={errorMsgUser !== '' ? true : false}
                  helperText={errorMsgUser}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl error={sharetypeerror} required fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Share Types</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={currencyvalue}
                onChange={handleChangeShareType}
                error={sharetypeerror}
                // defaultValue={1}
                // value={existShareholder.user_sharetype}
                defaultValue={
                  shareType
                  // "Ordinary"
                  // existShareholder === undefined
                  //   ? null
                  //   : console.log(
                  //       "sharehodler",
                  //       shareData.find(
                  //         (v) => v.share_type === userType
                  //         // (v) => v.share_type === existShareholder.user_sharetype
                  //       )
                  //     )
                }
              >
                {shareData.map((element) => (
                  <MenuItem value={element.share_type}>{element.share_name}</MenuItem>
                ))}
              </Select>
              <FormHelperText hidden={!sharetypeerror} error>
                Please select.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="numbershare"
              name="numbershare"
              label="Number of Share"
              fullWidth
              value={numberShare}
              // value={existShareholder.user_sharenumber}
              type="number"
              onChange={handleChangeNumberShare}
              //   onInput={(e) => setNumbershare(e.target.value)}
              error={errorMsgNumberShare !== '' ? true : false}
              helperText={errorMsgNumberShare}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isEdit && (
          <Button
            variant="contained"
            onClick={() => {
              if (handleRemoveShareholder) {
                handleRemoveShareholder(existShareholder);
              }
            }}
          >
            Remove
          </Button>
        )}
        <Button
          onClick={() => {
            let passShareNumber = false;
            if (numberShare === undefined) {
              setErrorMsgNumberShare(errorSelectMsg);
            } else {
              let totalHoldingShares = 0;
              if (shareType != undefined) {
                allShareholders.forEach((aShareholder) => {
                  if (shareType == aShareholder.user_sharetype) {
                    if (existShareholder) {
                      if (aShareholder.shareholder_id != existShareholder.shareholder_id) {
                        totalHoldingShares += aShareholder.user_sharenumber;
                      }
                    } else {
                      totalHoldingShares += aShareholder.user_sharenumber;
                    }
                  }
                });
              }
              let newTotalShare = parseFloat(totalHoldingShares) + parseFloat(numberShare);

              if (shareCapital && newTotalShare > shareCapital.number) {
                setErrorMsgNumberShare(
                  "Current shareholder's number of share exceeds share limit."
                );
              } else {
                passShareNumber = true;
                setErrorMsgNumberShare('');
              }
            }

            if (userValue === undefined) {
              setErrorMsgUser(errorMsg);
            } else {
              setErrorMsgUser('');
            }

            if (shareType === undefined) {
              setSharetypeerror(true);
            } else {
              setSharetypeerror(false);
            }
            console.log('userType', userType);
            if (
              userType !== undefined &&
              userValue !== undefined &&
              shareType !== undefined &&
              numberShare !== undefined &&
              passShareNumber
            ) {
              handleSubmit({
                is_edit: isEdit,
                user_id: userValue,
                // user_firstname: userValue.first_name,
                // user_idtype: userValue.identification_name,
                shareholder_id: shareholderId,
                share_capital_id: shareCapitalId,
                user_idtype: userType,
                // user_idvalue: userValue.identification_value,
                user_sharetype: shareType,
                user_sharenumber: numberShare,
              });
              handleReset();
            }
          }}
          //   onClick={handleClose}
          fullWidth
          variant="contained"
          size="medium"
          color="secondary"
        >
          SUBMIT
        </Button>
      </DialogActions>
      {/* <div>Shareh
      olderDialog</div> */}
      {/* <Form handleClose={handleClose} /> */}
    </Dialog>
  );
};

export default ShareholderDialog;
