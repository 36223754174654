import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Paper,
  Backdrop,
  TextField,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSnackbar } from 'notistack';

import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import FormFieldContainer from '../../components/CustomFormFields/FormFieldContainer';

import { useUserState } from '../../context/UserContext';
import orderSvc from '../../services/order.service';
import requestsApi from '../../services/requests.service';
import useQueryParams from '../../hooks/useQueryParams';

export const validateValue = (
  value,
  additionalChecker = [],
  defaultChecker = [undefined, null, '']
) => {
  let result = true;
  let checker = [...defaultChecker, ...additionalChecker];
  for (let i = 0; i < checker.length; i++) {
    if (value === checker[i]) {
      result = false;
      break;
    }
  }
  return result;
};
const getAddressString = (data = {}, direction = 'vertical') => {
  // const {
  //   contactPerson = '',
  //   contactNumber = '',
  //   address1 = '',
  //   address2 = '',
  //   address3 = '',
  //   postcode = '',
  //   stateId = '',
  //   state = '',
  //   countryId = '',
  //   country = '',
  // } = data;

  let addressKeys = ['address1', 'address2', 'address3', ['postcode', 'state'], 'country'];
  let result = [];
  addressKeys.forEach((aKey) => {
    if (Array.isArray(aKey)) {
      let subKeysValue = '';
      aKey.forEach((aSubKey) => {
        if (validateValue(data[aSubKey])) {
          if (subKeysValue == '') {
            subKeysValue += data[aSubKey];
          } else {
            subKeysValue += ` ${data[aSubKey]}`;
          }
        }
      });
      if (validateValue(subKeysValue)) {
        result.push(subKeysValue);
      }
    } else {
      if (validateValue(data[aKey])) {
        result.push(data[aKey]);
      }
    }
  });

  if (direction == 'horizontal') {
    let resultString = '';
    result.map((aData, index) => {
      resultString += `${aData}${index != result.length - 1 ? ', ' : ''}`;
    });
    return <div>{resultString}</div>;
  }
  return (
    <div>
      {result.map((aData, index) => {
        return <div key={index}>{`${aData}${index != result.length - 1 ? ',' : ''}`}</div>;
      })}
    </div>
  );
};

const TypographyStatus = ({ status = '' }) => {
  let statusColor, textLabel;
  switch (status.toLowerCase()) {
    case 'completed':
      statusColor = '#24FF00';
      textLabel = 'Completed';
      break;
    case 'pending':
      statusColor = '#FF7F09';
      textLabel = 'Pending';
      break;
    case 'paid':
      statusColor = '#041DFF';
      textLabel = 'Paid';
      break;
    case 'processing':
      statusColor = '#FF7F09';
      textLabel = 'Processing';
      break;
    case 'failed':
      statusColor = '#FF4D00';
      textLabel = 'Failed';
      break;
  }
  return (
    <Typography variant="body1" align="right" style={{ fontWeight: 600, color: statusColor }}>
      {textLabel}
    </Typography>
  );
};

const defaultRefundModal = {
  show: false,
  data: null,
};
const refundStatus = 'Request For Refund';
const order_status_paid = 'PAID';
const order_status_refunded = 'REFUNDED';
// company_application_status_id: 16
// status_name: "Refunded"
// company_application_status_id: 17
// status_name: "Request For Refund"

export default function OrderDetail() {
  const { enqueueSnackbar } = useSnackbar();

  let history = useHistory();
  let query = useQueryParams();
  const orderId = query.get('orderid');

  const [order, setOrder] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [refundModal, setRefundModal] = useState(defaultRefundModal);
  const [refundRemark, setRefundRemark] = useState('');
  const user = useUserState();

  let userId = user?.userId;
  let orderItems = order?.items || [];

  const fetchOrder = async () => {
    setLoadingSubmit(true);
    await orderSvc
      .getOrder(orderId)
      .then((ord) => {
        setOrder(ord || {});
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  useEffect(() => {
    (async () => {
      fetchOrder();
    })();
  }, [orderId]);

  let isPaid = order?.status == order_status_paid;

  const handleRefundModalClose = () => {
    setRefundModal(defaultRefundModal);
    setRefundRemark('');
  };

  const handleRequestOrderRefund = async () => {
    if (orderId && userId) {
      if (window.confirm('Confirm request for refund for all item(s)?')) {
        setLoadingSubmit(true);
        try {
          // await orderSvc.updateOrderStatus(orderId, 'Request For Refund', userId);
          for (let i = 0; i < orderItems.length; i++) {
            let anItem = orderItems[i];
            if (anItem?.status != refundStatus) {
              await orderSvc.updateOrderItemStatus(anItem?.id, refundStatus, userId);
              if (anItem?.company_application_id) {
                await requestsApi.updateRequestStatus(anItem.company_application_id, 17);
              }
            }
          }
          fetchOrder();
          enqueueSnackbar(`Successfully requested for refund`, { variant: 'success' });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to request for refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleRequestOrderItemRefund = async (value) => {
    if (value?.id && userId) {
      if (window.confirm('Confirm request for refund for this item?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, refundStatus, userId);
          if (value.company_application_id) {
            await requestsApi.updateRequestStatus(value.company_application_id, 17);
          }
          fetchOrder();
          enqueueSnackbar(`Successfully requested for refund`, { variant: 'success' });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to request for refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleCancelRequestRefund = async (value) => {
    let remark = 'Cancel Request for Refund' + (refundRemark ? ` - ${refundRemark}` : '');
    if (value?.id && userId) {
      if (window.confirm('Confirm to cancel this request?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_paid, userId, remark);
          if (value.company_application_id) {
            await requestsApi.updateRequestStatus(value.company_application_id, 10);
          }
          fetchOrder();
          enqueueSnackbar(`Request cancelled`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to cancel`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleRejectRequestRefund = async (value) => {
    let remark = 'Reject Request for Refund' + (refundRemark ? ` - ${refundRemark}` : '');

    if (value?.id && userId) {
      if (window.confirm('Confirm to reject this request?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_paid, userId, remark);
          if (value.company_application_id) {
            await requestsApi.updateRequestStatus(value.company_application_id, 10);
          }
          fetchOrder();
          enqueueSnackbar(`Request rejected`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to reject`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleRefunded = async (value) => {
    let remark = 'Refunded' + (refundRemark ? ` - ${refundRemark}` : '');

    if (value?.id && userId) {
      if (window.confirm('Confirm this item is refunded?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_refunded, userId, remark);
          if (value.company_application_id) {
            await requestsApi.updateRequestStatus(value.company_application_id, 16);
          }
          fetchOrder();
          enqueueSnackbar(`Item Refunded`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const orderTableColumns = [
    {
      label: 'No.',
      id: 'index',
      render: (_, __, index) => {
        return `${index + 1}.`;
      },
    },
    {
      label: 'Date',
      id: 'created_at',
      render: (text) => {
        return text ? moment(order.created_at).format(`DD/MM/yyyy`) : '-';
      },
    },
    {
      label: 'Item ID',
      id: 'id',
    },
    {
      label: 'Item Name',
      id: 'product',
      render: (product, data) => {
        return (
          <Box>
            <Box>
              <small>Req No: {data?.company_application_id}</small>
            </Box>
            <Box>{product?.name || '-'}</Box>
          </Box>
        );
      },
    },
    {
      label: 'Price (S$)',
      id: 'price',
      render: (text) => {
        return `${text}`;
      },
    },
    {
      label: 'Quantity',
      id: 'quantity',
    },
    {
      label: 'Status',
      id: 'status',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: '',
      id: 'action',
      render: (_, data) => {
        let isRequestedForRefund = data?.status == refundStatus;
        let isOrderItemRefunded = data?.status == order_status_refunded;

        if (isRequestedForRefund) {
          return (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setRefundModal({
                  show: true,
                  data: data,
                });
              }}
            >
              Refund
            </Button>
          );
        } else {
          if (!isOrderItemRefunded) {
            return (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleRequestOrderItemRefund(data);
                }}
              >
                Request for Refund
              </Button>
            );
          }
        }
        return null;
      },
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/orders' })}
      >
        BACK TO ORDERS
      </Button>
      <PageTitle title={`Order Details (ID: ${orderId})`} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Paper component={Box} mb={3} p={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box display={'flex'}>
                        <Typography variant="body1" align="left">
                          Date: {moment(order.created_at).format(`DD/MM/yyyy`)}
                        </Typography>
                      </Box>
                      <Box>
                        <Box display="flex">
                          <Box mr={2}>User:</Box>
                          {user?.user?.email} (ID: {user?.user?.user_id})
                        </Box>
                        {/* <Box display="flex">
                          <Box mr={2}>Email:</Box>
                          {order.email || '-'}
                        </Box> */}
                        <Box display="flex">
                          <Box mr={2}>Company:</Box>
                          {!order.company_name
                            ? '-'
                            : `${order.company_name} ${
                                order.company_registration_no
                                  ? `(${order.company_registration_no})`
                                  : ''
                              }`}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" flexDirection={'column'} textAlign="right">
                      <Typography variant="caption">Status</Typography>
                      <TypographyStatus status={order.status} />
                      <Typography variant="caption">Total</Typography>
                      <Box>
                        <Typography variant="body2">
                          <b>S$ {order.totalPrice}</b>
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={handleRequestOrderRefund}
                          disabled={!isPaid}
                        >
                          Request for Refund
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box py={2}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Box>
                      <b>Billing Information</b>
                    </Box>
                    {order?.billing_address_id ? (
                      <Box>
                        <Box>
                          {!order.first_name && !order.last_name
                            ? '-'
                            : `${order.first_name} ${order.last_name}`}
                        </Box>
                        <Box>{order?.email}</Box>
                        <Box>{order?.phone}</Box>
                        <Box>
                          {getAddressString({
                            address1: order?.billing_address?.address_line_1,
                            address2: order?.billing_address?.address_line_2,
                            address3: order?.billing_address?.address_line_3,
                            postcode: order?.billing_address?.postal_code,
                            state: order?.billing_address?.state,
                            country: order?.billing_address?.country,
                          })}
                        </Box>
                      </Box>
                    ) : (
                      <Box>-</Box>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <b>Shipping Information</b>
                    </Box>
                    {order?.shipping_address_id ? (
                      <Box>
                        <Box>
                          {!order.shipping_first_name && !order.shipping_last_name
                            ? '-'
                            : `${order.shipping_first_name} ${order.shipping_last_name}`}
                        </Box>
                        <Box>{order?.shipping_email}</Box>
                        <Box>{order?.shipping_phone}</Box>
                        <Box>
                          {getAddressString({
                            address1: order?.shipping_address?.address_line_1,
                            address2: order?.shipping_address?.address_line_2,
                            address3: order?.shipping_address?.address_line_3,
                            postcode: order?.shipping_address?.postal_code,
                            state: order?.shipping_address?.state,
                            country: order?.shipping_address?.country,
                          })}
                        </Box>
                      </Box>
                    ) : (
                      <Box>-</Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box py={2}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <b>Remark</b>
                    </Box>
                    <Box>{order?.remark || '-'}</Box>
                  </Grid>
                </Grid>
              </Paper>

              <Box mb={1}>
                <Typography
                  variant="h6"
                  align="left"
                  color="secondary"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {orderItems?.length || 0} Item(s)
                </Typography>
              </Box>
              <CustomTable data={orderItems || []} columns={orderTableColumns} />
              {/* <Paper>
                <Box p={2}>
                  {orderItems?.map((item, index) => (
                    <>
                      <Box display={xsBelow ? 'initial' : 'flex'} justifyContent="space-between">
                        <Box display={xsBelow ? 'initial' : 'flex'}>
                          <Typography variant="body1" align="left" style={{ fontWeight: 'bold' }}>
                            {index + 1}. {item.product?.name}
                          </Typography>
                          <Box pr={4} />
                          <Typography variant="body1" align="left">
                            Date: {moment(item.created_at).format(`DD/MM/yyyy`)}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          align={'right'}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          S$ {item.price}
                        </Typography>
                      </Box>

                      <Box display={xsBelow ? 'initial' : 'flex'} justifyContent="space-between">
                        <Typography variant="body1" align="left" style={{ fontWeight: 'bold' }}>
                          # {item.id}
                        </Typography>
                        <Typography variant="body1" align="left">
                          Quantity: {item.quantity}
                        </Typography>
                      </Box>
                    </>
                  ))}
                </Box>
              </Paper> */}
            </>
          )}
        </Grid>
      </Grid>
      <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomModal
        show={refundModal.show}
        onHide={handleRefundModalClose}
        title={
          <Typography variant="h5">
            {refundModal?.data?.product?.name} (Req No: {refundModal?.data?.company_application_id})
          </Typography>
        }
        scrollable={false}
      >
        <Box>
          <Box mb={2}>
            <FormFieldContainer label="Remark">
              <TextField
                placeholder="Optional"
                multiline
                rows={4}
                variant="outlined"
                fullWidth={true}
                value={refundRemark}
                onChange={(e) => {
                  setRefundRemark(e.target.value);
                }}
              />
            </FormFieldContainer>
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => {
                  handleCancelRequestRefund(refundModal?.data);
                }}
              >
                Cancel Request
              </Button>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleRejectRequestRefund(refundModal?.data);
              }}
            >
              Reject
            </Button>
            <Box ml={'auto'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleRefunded(refundModal?.data);
                }}
              >
                Refunded
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
