import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import {
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  Divider,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomRadioGroupField from '../../components/CustomFormFields/CustomRadioGroupField';
import CustomUploadButton from '../../components/CustomFormFields/CustomUploadButton';
import FormikDummyField from '../../components/CustomFormFields/FormikDummyField';

import commonApi from '../../services/common.service';
import userApi from '../../services/user.service';
import companyApi from '../../services/company.service';
import businessApi from '../../services/business.service';
import shareApi from '../../services/share.service';
import addressApi from '../../services/address.service';
import shareholderApi from '../../services/shareholder.service';
import documentApi from '../../services/document.service';

import useCommonOptions from '../../hooks/useCommonOptions';
import fileUploader from '../../utility/fileuploader';
import { useUserState } from '../../context/UserContext';
import useQueryParams from '../../hooks/useQueryParams';

import {
  CompanyOfficerTable,
  CompanyOfficerModalForm,
} from '../companyform/components/forms/CompanyOfficer';
import {
  ShareholderTable,
  ShareholderModalForm,
} from '../companyform/components/forms/CompanyShareholder';
import {
  SuperAdminTable,
  SuperAdminModalForm,
} from '../companyform/components/forms/CompanySuperAdmin';
import {
  AuditorCompanyTable,
  AuditorCompanyModalForm,
} from '../companyform/components/forms/CompanyAuditor';
import {
  CompanyAddressTable,
  CompanyAddressModalForm,
} from '../companyform/components/forms/CompanyAddress';

import ShareCapital, {
  // validationSchema as shareCapitalValidationSchema,
  validationSchemaObj as shareCapitalValidationSchemaObj,
  ordinarySchema_notRequired,
  fieldNames as shareCapitalFieldNames,
} from '../companyform/components/forms/ShareCapitalFields';
import CompanyDocuments from '../companyform/components/forms/CompanyDocuments';
import { CompanyTerminationModalForm } from './components/CompanyTermination';

const fields_companyDetail = {
  // Company Details
  company_client_type_id: {
    name: 'company_client_type_id',
    label: 'Company Category',
  },
  company_registration_no: {
    name: 'company_registration_no',
    label: 'Registered Number',
  },
  company_name: {
    name: 'company_name',
    label: 'Company Name',
  },
  former_name: {
    name: 'former_name',
    label: 'Former Name (if any)',
  },
  name_effective_from: {
    name: 'name_effective_from',
    label: 'wef',
  },
  incorporation_date: {
    name: 'incorporation_date',
    label: 'Incorporation Date',
  },
  company_type_id: {
    name: 'company_type_id',
    label: 'Company Type',
  },
  company_status_id: {
    name: 'company_status_id',
    label: 'Status',
  },
  status_date: {
    name: 'status_date',
    label: 'Status Date',
  },
  country_of_incorporation_id: {
    name: 'country_of_incorporation_id',
    label: 'Country of Incorporation',
  },
  fye_date: {
    name: 'fye_date',
    label: 'Next Financial Year End Date',
  },
  first_fye_date: {
    name: 'first_fye_date',
    label: 'First Financial Year End Date',
  },
  // country_of_operation_id: country_of_incorporation_id,
};

const fields_principalActivities = {
  // Principal Activities
  primary_ssic_id: {
    name: 'primary_ssic_id',
    label: 'Activity 1',
  },
  business_description: {
    name: 'business_description',
    label: 'Activity 1 Description',
  },
  secondary_ssic_id: {
    name: 'secondary_ssic_id',
    label: 'Activity 2',
  },
  secondary_business_description: {
    name: 'secondary_business_description',
    label: 'Activity 2 Description',
  },
};

// Capital
const fields_treasuryShares = {
  // Treasury Shares
  number_of_shares: {
    name: 'number_of_shares',
    label: 'Issued Number of Shares',
  },
};

const fields_registeredAddress = {
  // Registered Address
  address_type_id: {
    name: 'address_type_id',
    label: 'Address Type',
  },
  floor_unit_no: {
    name: 'floor_unit_no',
    label: 'Floor No. / Unit No.',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Street / Road',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Address Line 2',
  },
  postal_code: {
    name: 'postal_code',
    label: 'Postal Code',
  },
  country_id: {
    name: 'country_id',
    label: 'Country',
  },
  // address_line_3: null,
  // is_default: 1,
  // is_applying: 0,
  // is_registered: 1,
  // registration_datetime: "2021-03-31 00:00:00",
};
export const fields = {
  ...fields_companyDetail,
  ...fields_principalActivities,
  ...fields_treasuryShares,
  ...fields_registeredAddress,
};

const client_company_id = '1';
const client_shareholder_company_id = '2';
const client_auditor_company_id = '3';
let minDate_today = moment().format('YYYY-MM-DD');

export const validationSchemaObj = {
  [fields.company_client_type_id.name]: Yup.string()
    .required(`${fields.company_client_type_id.label} is required`)
    .default(client_company_id),
  // Company Details
  [fields.company_registration_no.name]: Yup.string()
    .required(`${fields.company_registration_no.label} is required`)
    .default(''),
  [fields.company_name.name]: Yup.string()
    .required(`${fields.company_name.label} is required`)
    .default(''),
  [fields.former_name.name]: Yup.string().default(''),
  [fields.name_effective_from.name]: Yup.string(),
  [fields.incorporation_date.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.incorporation_date.label} is required`),
    })
    .default(''),
  [fields.company_type_id.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.company_type_id.label} is required`),
    })
    .default(''),
  [fields.company_status_id.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.company_status_id.label} is required`),
    })
    .default(''),
  [fields.status_date.name]: Yup.string()
    .when(fields.company_client_type_id.name, {
      is: client_company_id,
      then: Yup.string().required(`${fields.status_date.label} is required`),
    })
    .default(''),
  [fields.country_of_incorporation_id.name]: Yup.string()
    .required(`${fields.country_of_incorporation_id.label} is required`)
    .default(''),
  [fields.first_fye_date.name]: Yup.string()
    .default('')
    .when(fields.fye_date.name, {
      is: '',
      then: Yup.string().required(`${fields.first_fye_date.label} is required`),
    }),
  [fields.fye_date.name]: Yup.date()
    // .default('')
    .min(new Date(minDate_today), `${fields.fye_date.label} must be future date`)
    .when(fields.first_fye_date.name, {
      is: '',
      then: Yup.date()
        .required(`${fields.fye_date.label} is required`)
        .min(new Date(minDate_today), `${fields.fye_date.label} must be future date`),
    }),

  // Principal Activities
  [fields.primary_ssic_id.name]: Yup.string().when(fields.company_client_type_id.name, {
    is: client_company_id,
    then: Yup.string().required(`${fields.primary_ssic_id.label} is required`),
  }),
  [fields.business_description.name]: Yup.string(),
  [fields.secondary_ssic_id.name]: Yup.string(),
  [fields.secondary_business_description.name]: Yup.string(),
  // Capital
  // Treasury Shares
  [fields.number_of_shares.name]: Yup.string(),
  // Registered Address
  // [fields.address_type_id.name]: Yup.string().required(
  //   `${fields.address_type_id.label} is required`
  // ),
  // [fields.floor_unit_no.name]: Yup.string(),
  // [fields.address_line_1.name]: Yup.string().required(`${fields.address_line_1.label} is required`),
  // [fields.address_line_2.name]: Yup.string(),
  // [fields.postal_code.name]: Yup.string().required(`${fields.postal_code.label} is required`),
  // [fields.country_id.name]: Yup.string().required(`${fields.country_id.label} is required`),

  // Upload Documents
  // Company Officers
  // Shareholders
  // Super Admins
};

function isRequired(fieldName, schema) {
  return schema?.fields?.[fieldName]?.exclusiveTests?.required || false;
}

const defaultCommonData = {
  companyCategories: [
    {
      label: 'Client Company',
      value: client_company_id,
    },
    {
      label: "Client's Shareholder Company",
      value: client_shareholder_company_id,
    },
    {
      label: 'Auditor Company',
      value: client_shareholder_company_id,
    },
  ],
  companyTypes: [],
  companyStatuses: [],
  countries: [],
  ssic: [],
  addressTypes: [],
};

const companyCategoryOptions = [
  {
    label: 'Client Company',
    value: client_company_id,
  },
  {
    label: "Client's Shareholder Company",
    value: client_shareholder_company_id,
  },
  {
    label: "Client's Auditor Company",
    value: client_auditor_company_id,
  },
];

const CompanyDetailForm = ({ refetchCompany }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userId: authUserId } = useUserState();
  let query = useQueryParams();
  const selectedCompanyId = parseInt(query.get('company'));
  let history = useHistory();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoadingData, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState();

  const [dynamicRequire, setIsDynamicRequired] = useState(true);
  const [dynamicRequireCompanyType, setIsDynamicRequiredCompanyType] = useState(true);

  const validationSchema = useMemo(() => {
    if (dynamicRequire && dynamicRequireCompanyType) {
      return Yup.object().shape(
        {
          ...validationSchemaObj,
          ...shareCapitalValidationSchemaObj,
        },
        [[fields.first_fye_date.name, fields.fye_date.name]]
      );
    }
    return Yup.object().shape({
      ...validationSchemaObj,
      ...shareCapitalValidationSchemaObj,
      ...ordinarySchema_notRequired,
      [fields.first_fye_date.name]: Yup.string().default(''),
      [fields.fye_date.name]: Yup.string().default(''),
    });
  }, [dynamicRequire, dynamicRequireCompanyType]);

  const changeDynamicRequired = useCallback(
    (value, formik) => {
      let isShareholderCompany =
        value == client_shareholder_company_id || value == client_auditor_company_id;
      let dynamicRequire = isShareholderCompany ? false : true;
      setIsDynamicRequired(dynamicRequire);
      if (isShareholderCompany) {
        if (!formik.values[fields.company_type_id.name]) {
          formik.setFieldValue(fields.company_type_id.name, 13);
        }
        if (!formik.values[fields.company_status_id.name]) {
          formik.setFieldValue(fields.company_status_id.name, 68);
        }
      }
    },
    [setIsDynamicRequired]
  );

  const changeDynamicRequiredCompanyType = useCallback(
    (value, formik) => {
      // PUBLIC COMPANY LIMITED BY GUARANTEE
      let dynamicRequire = value != 7;
      setIsDynamicRequiredCompanyType(dynamicRequire);
    },
    [setIsDynamicRequiredCompanyType]
  );

  const {
    options: identificationTypeOptions,
    loading: loadingIdentificationTypes,
    rawData: identificationTypes = [],
    refetch: fetchIdentificationTypes,
  } = useCommonOptions(commonApi.getIdentificationType, {
    respondKey: 'identificationtypes',
    labelKey: 'identification_name',
    valueKey: 'identification_type_id',
  });

  const {
    options: companyOptions = [],
    loading: loadingCompanies,
    rawData: companies = [],
  } = useCommonOptions(async () => companyApi.getCompanies('', 1, 10000), {
    respondKey: 'companies',
    labelKey: 'company_name',
    valueKey: 'company_id',
    render: (_, anItem) => {
      return `${anItem.company_name} (${anItem.company_registration_no})`;
    },
  });

  const {
    options: shareTypeOptions,
    loading: loadingShareTypes,
    rawData: shareTypes = [],
  } = useCommonOptions(commonApi.getShareType, {
    respondKey: 'share_types',
    labelKey: 'share_type_name',
    valueKey: 'share_type_id',
  });

  const {
    options: companyRoleOptions,
    loading: loadingCompanyRoles,
    rawData: companyRoles = [],
  } = useCommonOptions(commonApi.getCompanyRole, {
    respondKey: 'companyroles',
    labelKey: 'role_name',
    valueKey: 'company_role_id',
  });
  const { options: userOptions, loading: loadingUsers, rawData: users = [] } = useCommonOptions(
    async () => await userApi.getUsers('', 1, 100000),
    {
      respondKey: 'users',
      labelKey: 'email',
      valueKey: 'user_id',
    }
  );
  const { options: companyTypeOptions, loading: loadingCompanyTypes } = useCommonOptions(
    commonApi.getCompanyType,
    {
      respondKey: 'company_types',
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
    }
  );

  const { options: companyStatusOptions, loading: loadingCompanyStatuses } = useCommonOptions(
    commonApi.getCompanyStatus,
    {
      respondKey: 'company_statuses',
      labelKey: 'company_status_name',
      valueKey: 'company_status_id',
      render: (value, data) => {
        return `${data?.status_category} - ${data?.company_status_name}`;
      },
    }
  );

  const {
    options: countryOptions,
    loading: loadingCountries,
    rawData: countries,
  } = useCommonOptions(commonApi.getCountry, {
    respondKey: 'countries',
    labelKey: 'country_name',
    valueKey: 'country_id',
  });

  const { options: ssicOptions, loading: loadingSsic } = useCommonOptions(commonApi.getSsicCode, {
    respondKey: 'document',
    labelKey: 'ssic_name',
    valueKey: 'ssic_id',
  });

  const { options: addressTypeOptions, loading: loadingAddressTypes } = useCommonOptions(
    commonApi.getAddressType,
    {
      respondKey: 'addresstypes',
      labelKey: 'address_type_name',
      valueKey: 'address_type_id',
    }
  );

  // company address
  const [companyAddressForm, setCompanyAddressForm] = useState({
    show: false,
    data: null,
  });
  const [selectedCompanyAddresses, setSelectedCompanyAddresses] = useState([]);

  const handleSubmitCompanyAddressForm = (values) => {
    let is_default = values?.is_default ? 1 : 0;

    if (companyAddressForm?.data) {
      setSelectedCompanyAddresses((prev) => {
        let newResult = prev.map((anItem) => {
          if (anItem.company_address_id == values.company_address_id) {
            let newItemValue = {
              ...anItem,
              ...values,
              is_default,
              isEdited: true,
            };
            return newItemValue;
          } else {
            let newItemValue = anItem;
            if (is_default) {
              if (newItemValue['is_default'] != 0 && !newItemValue?.isNew) {
                newItemValue['isEdited'] = true;
              }
              newItemValue['is_default'] = 0;
            }
            return newItemValue;
          }
        });

        return newResult;
      });
    } else {
      setSelectedCompanyAddresses((prev) => {
        let newResult = prev.map((anItem) => {
          let newItemValue = anItem;
          if (is_default) {
            if (newItemValue['is_default'] != 0 && !newItemValue?.isNew) {
              newItemValue['isEdited'] = true;
            }
            newItemValue['is_default'] = 0;
          }
          return newItemValue;
        });
        return [...newResult, { ...values, isNew: true, is_default }];
      });
    }

    setCompanyAddressForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveCompanyAddress = (value) => {
    setSelectedCompanyAddresses((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem.company_address_id == value.company_address_id) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      }
      return prev.filter((anItem) => {
        return anItem.company_address_id != value.company_address_id;
      });
    });
    setCompanyAddressForm({
      show: false,
      data: null,
    });
  };

  const handleRestoreCompanyAddress = (value, index) => {
    setSelectedCompanyAddresses((prev) => {
      let newResult = prev.map((anItem) => {
        if (anItem.company_address_id == value.company_address_id) {
          return {
            ...anItem,
            isDeleted: false,
          };
        }
        return anItem;
      });

      return newResult;
    });
  };

  // company auditors
  const [auditorCompanyForm, setAuditorCompanyForm] = useState({
    show: false,
    data: null,
  });
  const [selectedAuditorCompanies, setSelectedAuditorCompanies] = useState([]);

  const handleSubmitAuditorCompanyForm = (values) => {
    if (auditorCompanyForm?.data) {
      setSelectedAuditorCompanies((prev) => {
        let newValue = selectedAuditorCompanies.map((anItem) => {
          if (auditorCompanyForm?.data?.company_id == anItem.company_id) {
            return {
              ...anItem,
              ...values,
              isEdited: true,
            };
          }
          return anItem;
        });
        return newValue;
      });
    } else {
      setSelectedAuditorCompanies((prev) => {
        return [...prev, { ...values, isNew: true }];
      });
    }

    setAuditorCompanyForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveAuditorCompany = (value) => {
    setSelectedAuditorCompanies((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem.company_id == value?.company_id) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      }
      return prev.filter((anItem) => {
        return anItem.company_id != value?.company_id;
      });
    });
    setAuditorCompanyForm({
      show: false,
      data: null,
    });
  };

  const handleRestoreCompanyAuditor = (value, index) => {
    setSelectedAuditorCompanies((prev) => {
      let newValue = selectedAuditorCompanies.map((anItem) => {
        if (value?.company_id == anItem.company_id) {
          return {
            ...anItem,
            isDeleted: false,
          };
        }
        return anItem;
      });
      return newValue;
    });
  };

  // company super admins
  const [superAdminForm, setSuperAdminForm] = useState({
    show: false,
    data: null,
  });
  const [selectedSuperAdmins, setSelectedSuperAdmins] = useState([]);

  const handleSubmitSuperAdminForm = (values) => {
    if (superAdminForm?.data) {
      setSelectedSuperAdmins((prev) => {
        let newResult = prev.map((anItem) => {
          if (anItem.email == values.email) {
            return {
              ...anItem,
              ...values,
              isEdited: true,
            };
          }
          return anItem;
        });

        return newResult;
      });
    } else {
      setSelectedSuperAdmins((prev) => {
        return [...prev, { ...values, isNew: true }];
      });
    }

    setSuperAdminForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveSuperAdmin = (value) => {
    setSelectedSuperAdmins((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem.email == value.email) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      }
      return prev.filter((anItem) => {
        return anItem.email != value.email;
      });
    });
    setSuperAdminForm({
      show: false,
      data: null,
    });
  };

  const handleRestoreSuperAdmin = (value, index) => {
    setSelectedSuperAdmins((prev) => {
      let newResult = prev.map((anItem) => {
        if (anItem.email == value.email) {
          return {
            ...anItem,
            isDeleted: false,
          };
        }
        return anItem;
      });

      return newResult;
    });
  };

  // shareholders
  const [shareholderForm, setShareholderForm] = useState({
    show: false,
    data: null,
  });
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const tempShareholderId = 'row_id';

  const getTotalShareholderShares = (shareType) => {
    let totalHoldingShares = 0;
    selectedShareholders.forEach((aShareholder) => {
      if (!aShareholder?.isDeleted) {
        if (aShareholder.user_sharetype == shareType) {
          let aShareNum = parseInt(aShareholder['user_sharenumber']);
          totalHoldingShares += aShareNum;
        }
      }
    });
    return totalHoldingShares;
  };

  const handleSubmitShareholder = (values) => {
    let isNew = shareholderForm?.data ? false : true;
    if (isNew) {
      setSelectedShareholders((prev) => [
        ...prev,
        { ...values, [tempShareholderId]: prev.length, isNew: true },
      ]);
    } else {
      setSelectedShareholders((prev) => {
        return prev.map((anItem) => {
          if (anItem[tempShareholderId] == values[tempShareholderId]) {
            return {
              ...anItem,
              ...values,
              isEdited: true,
            };
          }
          return anItem;
        });
      });
    }
    setShareholderForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveShareholder = (value) => {
    setSelectedShareholders((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem?.[tempShareholderId] == value?.[tempShareholderId]) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      }
      return prev.filter((anItem) => {
        return anItem?.[tempShareholderId] != shareholderForm?.data?.[tempShareholderId];
      });
    });
    setShareholderForm({
      show: false,
      data: null,
    });
  };

  const handleRestoreShareholder = (value, index) => {
    setSelectedShareholders((prev) => {
      return prev.map((anItem) => {
        if (anItem[tempShareholderId] == value[tempShareholderId]) {
          return {
            ...anItem,
            isDeleted: false,
          };
        }
        return anItem;
      });
    });
  };

  // officers
  const [officerForm, setOfficerForm] = useState({
    show: false,
    data: null,
  });
  const [selectedOfficers, setSelectedOfficers] = useState([]);

  const handleSubmitOfficer = (values) => {
    if (officerForm?.data) {
      setSelectedOfficers((prev) => {
        let newValue = prev.map((anItem) => {
          if (officerForm?.data?.user_company_role_id == anItem.user_company_role_id) {
            return {
              ...anItem,
              ...values,
              isEdited: true,
            };
          }
          return anItem;
        });
        return newValue;
      });
    } else {
      setSelectedOfficers((prev) => {
        return [...prev, { ...values, user_company_role_id: prev.length, isNew: true }];
      });
    }

    setOfficerForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveOfficer = (value) => {
    setSelectedOfficers((prev) => {
      if (!value?.isNew) {
        return prev.map((anItem) => {
          if (anItem.user_company_role_id == value?.user_company_role_id) {
            return {
              ...anItem,
              isDeleted: true,
            };
          }
          return anItem;
        });
      } else {
        let newResult = prev.filter((anItem) => {
          return anItem.user_company_role_id != value?.user_company_role_id;
        });
        newResult = newResult.map((anItem, index) => {
          return {
            ...anItem,
            user_company_role_id: index,
          };
        });
        return newResult;
      }
    });
    setOfficerForm({
      show: false,
      data: null,
    });
  };
  const handleRestoreOfficer = (value, index) => {
    setSelectedOfficers((prev) => {
      let newValue = prev.map((anItem) => {
        if (value?.user_company_role_id == anItem.user_company_role_id) {
          return {
            ...anItem,
            isDeleted: false,
          };
        }
        return anItem;
      });
      return newValue;
    });
  };

  const ref_certificateOfIncorporation = useRef();
  const ref_bizFile = useRef();
  const ref_annualReport = useRef();
  const ref_auditedReport = useRef();
  const ref_constitutionalDocument = useRef();
  const ref_managementAccounts = useRef();

  const [bizFileDocView, setBizFileDocView] = useState();
  const [certificateIncorporationDocView, setCertificateIncorporationDocView] = useState();
  const [annualReportDocView, setAnnualReportDocView] = useState();
  const [auditedReportDocView, setAuditedReportDocView] = useState();
  const [constitutionalDocumentView, setConstitutionalDocumentView] = useState();
  const [managementAccountsView, setManagementAccountsView] = useState();

  let isLoading =
    loadingCompanyTypes ||
    loadingCompanyStatuses ||
    loadingCountries ||
    loadingSsic ||
    loadingAddressTypes ||
    isLoadingData;

  const fetchCompanyDetails = async () => {
    let result = {};
    try {
      const companyDetailsRes = await companyApi.getCompanyDetails(selectedCompanyId);
      let companyData = companyDetailsRes?.data?.company;
      result = {
        ...companyData,
        [fields.company_client_type_id.name]: `${companyData[fields.company_client_type_id.name]}`,
        [fields.name_effective_from.name]: companyData[fields.name_effective_from.name]
          ? moment(companyData[fields.name_effective_from.name]).format('YYYY-MM-DD')
          : '',
        [fields.incorporation_date.name]: moment(
          companyData[fields.incorporation_date.name]
        ).format('YYYY-MM-DD'),
        [fields.status_date.name]: moment(companyData[fields.status_date.name]).format(
          'YYYY-MM-DD'
        ),
        [fields.first_fye_date.name]: companyData[fields.first_fye_date.name]
          ? moment(companyData[fields.first_fye_date.name]).format('YYYY-MM-DD')
          : '',
        [fields.fye_date.name]: companyData[fields.fye_date.name]
          ? moment(companyData[fields.fye_date.name]).format('YYYY-MM-DD')
          : '',
      };
    } catch (error) {}
    return result;
  };

  const fetchBusinessActivity = async () => {
    let result = {};
    try {
      const respBiz = await businessApi.getBusinessActivity(selectedCompanyId);
      let businessActivityData = respBiz?.data?.business_activities;
      result = businessActivityData;
    } catch (error) {}
    return result;
  };

  const fetchShareCapital = async () => {
    let result = {};
    try {
      const respShares = await shareApi.getShareCapital(selectedCompanyId);
      let shareCapitalData = respShares?.data?.shares;

      var sharecapitalidData;
      if (shareCapitalData.length > 0) {
        // setIssuedShareCapitals(shareCapitalData);
        for (var i = 0; i < shareCapitalData.length; i++) {
          let aShareCapital = shareCapitalData[i];

          // ordinary
          if (aShareCapital.share_type_id == 1) {
            result['shareType_ordinary'] = aShareCapital;
            sharecapitalidData = shareCapitalData[i].share_capital_id;
          }

          // preference
          if (aShareCapital.share_type_id == 2) {
            result['shareType_preference'] = aShareCapital;
          }

          // others
          if (aShareCapital.share_type_id == 3) {
            result['shareType_others'] = aShareCapital;
          }

          // ??? value always be set to last item
          if (shareCapitalData.length === i + 1 && sharecapitalidData) {
            const respTreasuryShares = await shareApi.getTreasuryShare(
              selectedCompanyId,
              sharecapitalidData
            );
            if (respTreasuryShares.data.shares !== undefined) {
              result = {
                ...result,
                ...respTreasuryShares.data.shares,
              };
              // setTreasurysharecapitalid(respTreasuryShares.data.shares.share_capital_id);
              // setTreasuryshareid(respTreasuryShares.data.shares.treasury_share_id);
              // setTreasurysharevalue(respTreasuryShares.data.shares.number_of_shares);
            }
          }
        }
      }
    } catch (error) {}
    return result;
  };

  const fetchCompanyAddresses = async () => {
    let result = [];
    try {
      const respAddress = await addressApi.getCompanyAddress(selectedCompanyId);

      let companyAddressData = respAddress?.data?.companyAddresses || [];
      if (companyAddressData?.length > 0) {
        result = companyAddressData;
      }
    } catch (error) {}

    return result;
  };

  const fetchCompanyAddress = async () => {
    let result = {};
    try {
      const respAddress = await addressApi.getCompanyAddress(selectedCompanyId);

      let companyAddressData = respAddress?.data?.companyAddresses;
      if (companyAddressData?.length > 0) {
        result = companyAddressData[0];
      }
      for (var i = 0; i < companyAddressData.length; i++) {
        if (companyAddressData[i].is_default === 1) {
          result = companyAddressData[i];
        }
      }
    } catch (error) {}

    return result;
  };

  const fetchDocument = async (docType, dataType, dataId) => {
    const respDocument = await documentApi.getDocumentsSTS(docType, dataType, dataId);

    let documentData = respDocument?.data?.document;

    if (docType === 15) {
      setBizFileDocView(documentData[documentData.length - 1]);
    } else if (docType === 18) {
      setCertificateIncorporationDocView(documentData[documentData.length - 1]);
    } else if (docType === 5) {
      setAnnualReportDocView(documentData[documentData.length - 1]);
    } else if (docType === 12) {
      setAuditedReportDocView(documentData[documentData.length - 1]);
    } else if (docType === 28) {
      setConstitutionalDocumentView(documentData[documentData.length - 1]);
    } else if (docType === 57) {
      setManagementAccountsView(documentData[documentData.length - 1]);
    }
  };

  const fetchCompanyOfficers = async () => {
    let result = [];
    try {
      const respOfficers = await companyApi.getCompanyOfficers(selectedCompanyId);

      var arrExistOfficer = [];
      let officers = respOfficers?.data?.userCompanyRoles || [];
      // for (var i = 0; i < officers.length; i++) {
      //   let anOfficer = officers[i];
      //   var existOfficer = {};
      //   existOfficer.row_id = i;
      //   existOfficer.user_id = anOfficer.user_id;
      //   existOfficer.user_firstname = anOfficer.first_name;
      //   existOfficer.user_idtype = '-';
      //   existOfficer.user_idvalue = '-';
      //   if (anOfficer.nric !== null) {
      //     existOfficer.user_nric = anOfficer.nric;
      //   }
      //   if (anOfficer.passport !== null) {
      //     existOfficer.user_passport = anOfficer.passport;
      //   }
      //   existOfficer.company_role = anOfficer.company_role_id;
      //   existOfficer.date_appointment = anOfficer.date_of_appointment;
      //   existOfficer.nominee_id = anOfficer.nominee_id;
      //   existOfficer.nominee_name = anOfficer.nominee_first_name;
      //   existOfficer.nominator_id = anOfficer.nominator_id;
      //   existOfficer.nominator_name = anOfficer.nominator_first_name;
      //   existOfficer.controller_id = anOfficer.controller_id;
      //   existOfficer.controller_name = anOfficer.controller_first_name;
      //   existOfficer.named_secretary_id = anOfficer.named_secretary_id;
      //   existOfficer.named_secretary_name = anOfficer.named_secretary_first_name;
      //   existOfficer.cessation_type = anOfficer.cessation_type_id;
      //   existOfficer.cessation_type_name = anOfficer.cessation_type_name;
      //   existOfficer.date_cessation = anOfficer.cessation_date;
      //   existOfficer.user_company_role_id = anOfficer.user_company_role_id;
      //   arrExistOfficer.push(existOfficer);
      // }

      let valueKeys = Object.keys(officers);
      valueKeys.forEach((aKey) => {
        let value = officers?.[aKey];
        if (value == null) {
          officers[aKey] = '';
        }
      });
      result = officers;
      // setSelectedOfficers(officers);
    } catch (error) {}

    return result;
  };

  const fetchShareholders = async () => {
    let result = [];
    try {
      const respShareholders = await shareholderApi.getShareholder(selectedCompanyId);
      let shareholdersData = respShareholders?.data?.shares;

      var arrExistShareholders = [];
      for (var i = 0; i < shareholdersData.length; i++) {
        let aShareholder = shareholdersData[i];

        var existShareholder = {
          ...aShareholder,
        };
        existShareholder['row_id'] = i;
        existShareholder['shareholder_id'] = aShareholder.shareholder_id;

        // is individual
        if (aShareholder.user_id !== undefined) {
          existShareholder['user_id'] = aShareholder.user_id;
        }

        // is company
        if (aShareholder.company_id !== undefined) {
          aShareholder.identifications[0].identification_type_id = 3;
          existShareholder['user_idtype'] = aShareholder.identifications[0].identification_type_id;
          existShareholder['user_id'] = aShareholder.company_id;
        }

        existShareholder['share_capital_id'] = aShareholder.share_capital_id;
        existShareholder['Name'] = aShareholder.Name;
        existShareholder['user_firstname'] = aShareholder.Name;

        if (aShareholder.identifications.length >= 1) {
          existShareholder['user_idvalue'] = aShareholder.identifications[0].identification_value;
          existShareholder['user_idtype'] = aShareholder.identifications[0].identification_type_id;
        }

        existShareholder['user_sharetype'] = aShareholder.share_type_id;
        existShareholder['user_sharenumber'] = aShareholder.user_allotment;
        arrExistShareholders.push(existShareholder);
      }
      let valueKeys = Object.keys(arrExistShareholders);
      valueKeys.forEach((aKey) => {
        let value = arrExistShareholders?.[aKey];
        if (value == null) {
          arrExistShareholders[aKey] = '';
        }
      });
      result = arrExistShareholders;
      // setSelectedShareholders(arrExistShareholders);
    } catch (error) {}

    return result;
  };

  const fetchSuperAdmins = async () => {
    let result = [];
    try {
      const resSysInfoUserRoles = await userApi.getSysInfoUserRoleByCompanyId(selectedCompanyId);
      if (resSysInfoUserRoles?.data?.is_success) {
        result = resSysInfoUserRoles?.data?.users || [];
        result = result.map((anItem) => {
          let newValue = anItem;
          let valueKeys = Object.keys(newValue);
          valueKeys.forEach((aKey) => {
            let value = newValue?.[aKey];
            if (value == null) {
              newValue[aKey] = '';
            }
          });
          return { ...newValue, is_existing_user: '1' };
        });
        // setSelectedSuperAdmins(result);
      } else {
        result = [];
        // setSelectedSuperAdmins([]);
      }
    } catch (error) {}
    return result;
  };

  const fetchAuditorCompanies = async () => {
    let result = [];
    try {
      const res = await companyApi.getCompanyAuditorsByCompanyId(selectedCompanyId);
      let data = res?.data?.is_success ? res?.data?.company_auditors : [];
      result = data;
      result = result.map((anItem) => {
        let newValue = anItem;
        let valueKeys = Object.keys(newValue);
        valueKeys.forEach((aKey) => {
          let value = newValue?.[aKey];
          if (value == null) {
            newValue[aKey] = '';
          }
        });
        return newValue;
      });
      // setSelectedAuditorCompanies(result);
    } catch (error) {}
    return result;
  };

  const fetchInitialValues = async () => {
    console.log('fetchInitialValues');
    let newInitialValues = validationSchema.cast();
    setLoading(true);

    let companyDetailsRes = await fetchCompanyDetails();
    let businessActivityRes = await fetchBusinessActivity();
    let shareCapitalRes = await fetchShareCapital();
    let companyAddressRes = await fetchCompanyAddress();

    // documents
    try {
      // console.log('cretificationDocView');
      await fetchDocument(18, 2, selectedCompanyId);
      // console.log('bizDocView');
      await fetchDocument(15, 2, selectedCompanyId);

      // console.log('annualDocView');
      // await fetchDocument(5, 2, selectedCompanyId);
      // console.log('auditedDocView');
      // await fetchDocument(12, 2, selectedCompanyId);
      // console.log('accountsDocView');
      // await fetchDocument(57, 2, selectedCompanyId);
      // console.log('constitutionalDocView');
      // await fetchDocument(28, 2, selectedCompanyId);
    } catch (error) {}

    // tables
    let defaultCompanyAddresses = await fetchCompanyAddresses();
    let defaultOfficers = await fetchCompanyOfficers();
    let defaultShareholders = await fetchShareholders();
    let defaultSuperAdmins = await fetchSuperAdmins();
    let defaultCompanyAuditors = await fetchAuditorCompanies();
    if (defaultSuperAdmins?.length > 0) {
      defaultSuperAdmins = defaultSuperAdmins.map((anItem) => {
        return {
          ...anItem,
          is_existing_user: '1',
        };
      });
    }

    newInitialValues = {
      ...newInitialValues,
      ...companyDetailsRes,
      ...businessActivityRes,
      ...shareCapitalRes,
      ...companyAddressRes,
    };

    let editingText = companyDetailsRes?.editing_text;
    if (editingText) {
      if (typeof editingText == 'string') {
        try {
          let editingTextObj = JSON.parse(editingText);

          if (editingTextObj) {
            const {
              publish_status_id,
              publish_status_name,
              is_applying,
              is_default,
              is_registered,
              editing_text,
              company_id,
              tables,
              ...restEditingText
            } = editingTextObj;

            const { officers, shareholders, superAdmins, companyAuditors } = tables;
            if (officers?.length > 0) {
              defaultOfficers = officers;
            }
            if (shareholders?.length > 0) {
              defaultShareholders = shareholders;
            }
            if (superAdmins?.length > 0) {
              defaultSuperAdmins = superAdmins;
            }
            if (companyAuditors?.length > 0) {
              defaultCompanyAuditors = companyAuditors;
            }

            newInitialValues = {
              ...newInitialValues,
              ...restEditingText,
            };
          }
        } catch (error) {}
      }
    }
    let valueKeys = Object.keys(newInitialValues);
    valueKeys.forEach((aKey) => {
      let value = newInitialValues?.[aKey];
      if (value == null) {
        newInitialValues[aKey] = '';
      }
    });
    setSelectedCompanyAddresses(defaultCompanyAddresses);
    setSelectedOfficers(defaultOfficers);
    setSelectedShareholders(defaultShareholders);
    setSelectedSuperAdmins(defaultSuperAdmins);
    setSelectedAuditorCompanies(defaultCompanyAuditors);

    setInitialValues(newInitialValues);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCompanyId) {
      fetchInitialValues();
    }
  }, [selectedCompanyId]);

  const bizFileDocViewRef = useRef();
  bizFileDocViewRef.current = bizFileDocView;
  const certificateIncorporationDocViewRef = useRef();
  certificateIncorporationDocViewRef.current = certificateIncorporationDocView;
  const selectedOfficersRef = useRef();
  selectedOfficersRef.current = selectedOfficers;
  const selectedSuperAdminsRef = useRef();
  selectedSuperAdminsRef.current = selectedSuperAdmins;

  const checkCanPublish = async (values) => {
    const {
      [fields.first_fye_date.name]: firstFyeDateValue,
      [fields.fye_date.name]: fyeDateValue,
      [shareCapitalFieldNames.shareType_ordinary.name]: shareType_ordinaryValue,
      [fields.country_of_incorporation_id.name]: country_of_incorporation_idValue,
      [shareCapitalFieldNames.shareType_ordinary.name]: shareCapital_ordinary,
      [shareCapitalFieldNames.shareType_preference.name]: shareCapital_preference,
      [shareCapitalFieldNames.shareType_others.name]: shareCapital_others,
    } = values;

    let bizFileDocView = bizFileDocViewRef?.current;
    let certificateIncorporationDocView = certificateIncorporationDocViewRef?.current;
    let selectedOfficers = selectedOfficersRef?.current;
    let selectedSuperAdmins = selectedSuperAdminsRef?.current;

    let passedOfficers = selectedOfficers?.length > 0 ? true : false;
    let passedSuperAdmin = selectedSuperAdmins?.length > 0 ? true : false;
    let passedShares = true;
    let passedDocUpload = true;
    let passedFye = true;

    // fye
    if (moment(firstFyeDateValue).isValid() || moment(fyeDateValue).isValid()) {
      passedFye = true;
    } else {
      passedFye = false;
    }
    // share number
    let totalDistributedShares = {};
    selectedShareholders.forEach((anItem) => {
      if (!anItem?.isDeleted) {
        let shareType = anItem.user_sharetype;
        let shareNum = parseInt(anItem?.user_sharenumber || 0);
        if (totalDistributedShares[shareType] != undefined) {
          totalDistributedShares[shareType] =
            parseInt(totalDistributedShares?.[shareType] || 0) + shareNum;
        } else {
          totalDistributedShares[shareType] = shareNum;
        }
      }
    });

    let totalAvailableShares = {};
    [shareCapital_ordinary, shareCapital_preference, shareCapital_others].forEach((anItem) => {
      let shareType = anItem.share_type_id;
      let shareNum = anItem.number_of_shares;
      if (totalAvailableShares[shareType] != undefined) {
        totalAvailableShares[shareType] = totalAvailableShares[shareType] + shareNum;
      } else {
        totalAvailableShares[shareType] = shareNum;
      }
    });

    let shareTypes = Object.keys(totalAvailableShares);
    shareTypes.forEach((aType) => {
      if (totalAvailableShares[aType] != undefined) {
        if (
          totalAvailableShares[aType] != undefined &&
          totalDistributedShares[aType] != undefined
        ) {
          if (parseInt(totalAvailableShares[aType]) != parseInt(totalDistributedShares[aType])) {
            passedShares = false;
          }
        } else {
          passedShares = false;
        }
      }
    });

    // console.log('countriescountries', countries);
    // doc
    let countryId_sgp = 190;
    // let isSGPSelected = incorporationcountryvalue == countryId_sgp;
    let isSGPSelected = false;
    let foundCountry = countries?.find((v) => v.country_id == country_of_incorporation_idValue);
    if (foundCountry) {
      if (foundCountry?.country_name?.toLocaleLowerCase().indexOf('singapore') >= 0) {
        isSGPSelected = true;
      }
    }
    if (isSGPSelected) {
      if (bizFileDocView?.signed_document_url) {
      } else {
        passedDocUpload = false;
      }
    } else {
      if (certificateIncorporationDocView?.signed_document_url) {
      } else {
        passedDocUpload = false;
      }
    }

    if (!passedFye) {
      enqueueSnackbar(
        `Please fill up either First Financial Year End Date or Financial Year End Date`,
        { variant: 'error' }
      );
    }

    if (!passedOfficers) {
      enqueueSnackbar(`Please add at least one Officer`, { variant: 'error' });
    }
    if (!passedSuperAdmin) {
      enqueueSnackbar(`Please add at least one Super Admin`, {
        variant: 'error',
      });
    }
    if (!passedShares) {
      enqueueSnackbar(
        // `All shares issued must be distributed completely among shareholders`,
        `The number of ordinary or preference or other share owned by shareholder doesnt add up total issued ordinary or preference or other share`,
        { variant: 'error' }
      );
    }
    if (!passedDocUpload) {
      enqueueSnackbar(
        `${
          isSGPSelected
            ? 'Biz File in Principal Activities'
            : 'Certificate of incorporation in Company Details'
        } is required.`,
        { variant: 'error' }
      );
    }

    if (passedFye && passedOfficers && passedSuperAdmin && passedShares && passedDocUpload) {
      return true;
    }
    return false;
  };

  const handleOnPublishCompany = async (values) => {
    // check conditions before submit
    // publish_status_id 1 (Draft), 2 (Action), 3 (Publish)

    let canPublish = await checkCanPublish(values);

    if (canPublish) {
      let newSuperAdmins = await fetchSuperAdmins();
      let userList = [];
      if (newSuperAdmins?.length > 0) {
        userList = newSuperAdmins.filter((anItem) => {
          if (!anItem?.isDeleted) {
            if (anItem.invitation_accepted == undefined) {
              return true;
            }
            return anItem.invitation_accepted == 0;
          }
          return false;
        });
      }
      console.log('userListuserListuserList', { userList, newSuperAdmins });
      let publishBody = {
        company_id: selectedCompanyId,
        publish_status_id: 3,
        user_list: userList,
        // user_list: sysInfoUserRolesByCompany.map((anItem) => {
        //   return {
        //     ...anItem,
        //     is_new_user: anItem.invitation_accepted == 0 ? 0 : 1,
        //   };
        // }),
        created_by: parseInt(authUserId),
      };

      try {
        setLoadingSubmit(true);
        await companyApi.updateCompanyStatus(selectedCompanyId, 3);
        enqueueSnackbar(`Published`, { variant: 'success' });
        await fetchInitialValues();
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Publish`, {
          variant: 'error',
        });
        setLoadingSubmit(false);

        return;
      }

      if (userList?.length > 0) {
        try {
          let updateSuperAdminRes = await userApi.updateCompanySuperAdminOnPublish(publishBody);
          let isAllSent = true;

          updateSuperAdminRes?.data?.users?.forEach((anItem) => {
            if (!anItem?.email_sent) {
              isAllSent = false;
              enqueueSnackbar(`Failed to send email (${anItem?.email})`, {
                variant: 'error',
              });
            }
          });
          if (isAllSent) {
            enqueueSnackbar(`Email(s) sent succesfully`, { variant: 'success' });
          }
        } catch (error) {
          console.log('updateCompanySuperAdminOnPublish error', error);
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Publish`, {
            variant: 'error',
          });
        }
        try {
          let newSuperAdmins = await fetchSuperAdmins();
          setSelectedSuperAdmins(newSuperAdmins);
        } catch (error) {}
      }
      setLoadingSubmit(false);
    }
  };

  const handleOnDraftCompany = async () => {
    setLoadingSubmit(true);
    try {
      await companyApi.updateCompanyStatus(selectedCompanyId, 5);
      await fetchInitialValues();
      await refetchCompany();
      enqueueSnackbar(`Company is set to Editing`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to set to Draft`, {
        variant: 'error',
      });
    }
    setLoadingSubmit(false);
  };

  const handleSaveEditing = async (values) => {
    setLoadingSubmit(true);
    try {
      const {
        publish_status_id,
        publish_status_name,
        is_applying,
        is_default,
        is_registered,
        editing_text,
        tables,
        ...restValues
      } = values;

      let newEditingText = {
        ...restValues,
        tables: {
          officers: selectedOfficers || [],
          shareholders: selectedShareholders || [],
          superAdmins: selectedSuperAdmins || [],
          companyAuditors: selectedAuditorCompanies || [],
        },
      };
      let companyRes = await companyApi.updateCompanyDetails(selectedCompanyId, {
        ...initialValues,
        [fields_companyDetail.fye_date.name]: values?.[fields_companyDetail.fye_date.name],
        editing_text: JSON.stringify(newEditingText),
      });
      await fetchInitialValues();
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message || `Failed to Save Editing Company Details`,
        {
          variant: 'error',
        }
      );
      setLoadingSubmit(false);
      return;
    }
    setLoadingSubmit(false);
  };

  const handleDiscardEditing = async () => {
    setLoadingSubmit(true);
    try {
      let companyRes = await companyApi.updateCompanyDetails(selectedCompanyId, {
        ...initialValues,
        editing_text: '',
      });
      await fetchInitialValues();
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message || `Failed to Discard Editing Company Details`,
        {
          variant: 'error',
        }
      );
      setLoadingSubmit(false);
      return;
    }
    setLoadingSubmit(false);
  };

  const [terminationForm, setTerminationForm] = useState({
    show: false,
    data: null,
  });

  const onSubmit = async (values, actions) => {
    return new Promise(async (resolve, reject) => {
      await (async () => {
        const dynamicRequireFields = {
          [fields.incorporation_date.name]: '', // 0000-00-00
          // [fields.company_type_id.name]: null,
          // [fields.company_status_id.name]: null,
          [fields.status_date.name]: '',
          [fields.primary_ssic_id.name]: '',
        };
        console.log('onSubmit values: ', values);
        setLoadingSubmit(true);
        const startSubmit = async () => {
          // update company
          // let companyId = companyRes?.data?.company?.company_id;
          let companyId = selectedCompanyId;

          let companyBody = {};
          let companyDetailKeys = Object.keys(fields_companyDetail);
          companyDetailKeys.forEach((aKey) => {
            let fieldName = fields_companyDetail?.[aKey]?.name;
            if (fieldName) {
              if (values?.[fieldName]) {
                companyBody[fieldName] = values?.[fieldName];
                if (fieldName == 'country_of_incorporation_id') {
                  companyBody['country_of_operation_id'] = values?.[fieldName];
                }
              } else {
                let foundDynamicRequireField = dynamicRequireFields?.[fieldName] != undefined;
                if (foundDynamicRequireField) {
                  companyBody[fieldName] = dynamicRequireFields?.[fieldName];
                }
              }
            }
          });
          if (values?.[fields.fye_date.name]) {
            companyBody[fields.fye_date.name] = values?.[fields.fye_date.name];
          } else {
            companyBody[fields.fye_date.name] = null;
          }

          let companyRes;
          try {
            companyRes = await companyApi.updateCompanyDetails(companyId, companyBody);
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message || `Failed to Update Company Details`,
              { variant: 'error' }
            );
            // reject(error);
            // return;
          }
          // try {
          //   companyRes = await companyApi.createCompany(companyBody);
          // } catch (error) {
          //   enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Create Company`, {
          //     variant: 'error',
          //   });
          //   return;
          // }

          // if (companyRes?.data?.is_success) {
          if (
            ref_certificateOfIncorporation?.current?.value !== undefined ||
            ref_certificateOfIncorporation?.current?.value !== null ||
            ref_certificateOfIncorporation?.current?.value !== ''
          ) {
            try {
              await fileUploader(ref_certificateOfIncorporation, 18, 2, companyId);
            } catch (error) {
              enqueueSnackbar(
                error?.response?.data?.error?.message ||
                  `Failed to Upload Certificate Of Incorporation`,
                { variant: 'error' }
              );
              // return;
            }
          }

          // principal activity
          let principalActivitiesBody = {};
          let principalActivityKeys = Object.keys(fields_principalActivities);
          principalActivityKeys.forEach((aKey) => {
            let fieldName = fields_principalActivities?.[aKey]?.name;
            if (fieldName && values?.[fieldName]) {
              principalActivitiesBody[fieldName] = values?.[fieldName];
            }
          });
          let businessActivityId = values?.['business_activity_id'];

          if (Object.keys(principalActivitiesBody).length > 0) {
            try {
              if (businessActivityId) {
                const respBusiness = await businessApi.updateBusinessActivity(
                  companyId,
                  businessActivityId,
                  principalActivitiesBody
                );
              } else {
                let businessRes = await businessApi.createBusinessActivity(companyId, {
                  ...principalActivitiesBody,
                  created_by: parseInt(authUserId),
                });
              }
            } catch (error) {
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Create Principal Activity`,
                { variant: 'error' }
              );
              // return;
            }
          }

          if (
            ref_bizFile?.current?.value !== undefined ||
            ref_bizFile?.current?.value !== null ||
            ref_bizFile?.current?.value !== ''
          ) {
            try {
              await fileUploader(ref_bizFile, 15, 2, companyId);
            } catch (error) {
              enqueueSnackbar(
                error?.response?.data?.error?.message || `Failed to Upload Biz File`,
                {
                  variant: 'error',
                }
              );
              // return;
            }
          }

          // capital
          let shareData = values?.[shareCapitalFieldNames.shareType_ordinary.name];
          let preferenceShareData = values?.[shareCapitalFieldNames.shareType_preference.name];
          let otherShareData = values?.[shareCapitalFieldNames.shareType_others.name];

          let respShare, respPreferenceShare, respOtherShare;

          let ordinaryShareCapitalId =
            values?.[shareCapitalFieldNames.shareType_ordinary.name]?.share_capital_id;
          try {
            if (
              shareData &&
              shareData.issued_share_capital !== undefined &&
              shareData.number_of_shares !== undefined &&
              shareData.paid_up_capital !== undefined &&
              shareData.currency_id !== undefined
            ) {
              if (ordinaryShareCapitalId) {
                respShare = await shareApi.updateShareCapital(
                  companyId,
                  ordinaryShareCapitalId,
                  shareData
                );
              } else {
                shareData = {
                  ...shareData,
                  share_type_id: 1,
                  created_by: parseInt(authUserId),
                };
                respShare = await shareApi.createShareCapital(companyId, shareData);
              }
            }
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message || `Failed to Create Share Capital (Ordinary)`,
              { variant: 'error' }
            );
            // return;
          }

          try {
            if (
              preferenceShareData &&
              preferenceShareData.issued_share_capital !== undefined &&
              preferenceShareData.number_of_shares !== undefined &&
              preferenceShareData.paid_up_capital !== undefined &&
              preferenceShareData.currency_id !== undefined
            ) {
              let preferenceShareCapitalId =
                values?.[shareCapitalFieldNames.shareType_preference.name]?.share_capital_id;

              if (preferenceShareCapitalId !== null && preferenceShareCapitalId !== undefined) {
                respPreferenceShare = await shareApi.updateShareCapital(
                  companyId,
                  preferenceShareCapitalId,
                  preferenceShareData
                );
              } else {
                preferenceShareData = {
                  ...preferenceShareData,
                  share_type_id: 2,
                  created_by: parseInt(authUserId),
                };
                respPreferenceShare = await shareApi.createShareCapital(
                  companyId,
                  preferenceShareData
                );
              }
            }
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message ||
                `Failed to Create Share Capital (Preference)`,
              { variant: 'error' }
            );
            // return;
          }

          try {
            if (
              otherShareData &&
              otherShareData.issued_share_capital !== undefined &&
              otherShareData.number_of_shares !== undefined &&
              otherShareData.paid_up_capital !== undefined &&
              otherShareData.currency_id !== undefined
            ) {
              let othersShareCapitalId =
                values?.[shareCapitalFieldNames.shareType_others.name]?.share_capital_id;

              if (othersShareCapitalId !== null && othersShareCapitalId !== undefined) {
                respOtherShare = await shareApi.updateShareCapital(
                  companyId,
                  othersShareCapitalId,
                  otherShareData
                );
              } else {
                otherShareData = {
                  ...otherShareData,
                  share_type_id: 3,
                  created_by: parseInt(authUserId),
                };
                respOtherShare = await shareApi.createShareCapital(companyId, otherShareData);
              }
            }
          } catch (error) {
            enqueueSnackbar(
              error?.response?.data?.error?.message || `Failed to Create Share Capital (Others)`,
              { variant: 'error' }
            );
            // return;
          }

          // treasury share
          // let ordinaryShareCapitalId = respShare?.data?.shares?.share_capital_id;
          if (respShare?.data?.shares?.share_capital_id) {
            let treasuryShareBody = {
              created_by: parseInt(authUserId),
            };
            let treasuryShareKeys = Object.keys(fields_treasuryShares);
            treasuryShareKeys.forEach((aKey) => {
              let fieldName = fields_treasuryShares?.[aKey]?.name;
              if (fieldName && values?.[fieldName]) {
                treasuryShareBody[fieldName] = values?.[fieldName];
              }
            });
            if (
              Object.keys(treasuryShareBody).length > 0 &&
              treasuryShareBody?.[fields_treasuryShares.number_of_shares.name]
            ) {
              try {
                let treasuryShareCapitalId = values?.share_capital_id;
                let treasuryShareId = values?.treasury_share_id;

                if (
                  ordinaryShareCapitalId !== undefined &&
                  ordinaryShareCapitalId !== null &&
                  treasuryShareCapitalId !== undefined &&
                  treasuryShareCapitalId !== null &&
                  treasuryShareId !== undefined &&
                  treasuryShareId !== null
                ) {
                  const respTreasury = await shareApi.updateTreasuryShare(
                    companyId,
                    treasuryShareCapitalId,
                    treasuryShareId,
                    treasuryShareBody
                  );
                  console.log('respTreasuryUpdate', respTreasury);
                } else {
                  const respTreasury = await shareApi.createTreasuryShare(
                    companyId,
                    ordinaryShareCapitalId,
                    { ...treasuryShareBody, created_by: parseInt(authUserId) }
                  );
                  console.log('respTreasuryAdd', respTreasury);
                }
              } catch (error) {
                enqueueSnackbar(
                  error?.response?.data?.error?.message || `Failed to Create Treasury Share`,
                  { variant: 'error' }
                );
                // return;
              }
            }
          }

          // address
          // let companyAddressBody = {
          //   address_line_3: '',
          //   is_default: 1,
          //   is_applying: 0,
          //   is_registered: 1,
          //   registration_datetime: '2021-03-31 00:00:00',
          //   created_by: parseInt(authUserId),
          // };
          // let companyAddressBody_update = {};
          // let companyAddressKeys = Object.keys(fields_registeredAddress);
          // companyAddressKeys.forEach((aKey) => {
          //   let fieldName = fields_registeredAddress?.[aKey]?.name;
          //   if (fieldName) {
          //     companyAddressBody[fieldName] = values?.[fieldName];
          //     companyAddressBody_update[fieldName] = values?.[fieldName];
          //   }
          // });

          // try {
          //   let addressId = values?.['address_id'];
          //   let company_address_id = values?.['company_address_id'];
          //   if (addressId) {
          //     const respCompanyAddress = await addressApi.updateCompanyAddressByAddressId(
          //       selectedCompanyId,
          //       company_address_id,
          //       { ...companyAddressBody_update, address_id: addressId }
          //     );
          //   } else {
          //     const respCompanyAddress = await addressApi.createCompanyAddress(selectedCompanyId, {
          //       companyAddresses: [companyAddressBody],
          //     });
          //   }
          // } catch (error) {
          //   enqueueSnackbar(
          //     error?.response?.data?.error?.message || `Failed to Create Company Address`,
          //     { variant: 'error' }
          //   );
          //   // return;
          // }

          if (selectedCompanyAddresses?.length > 0) {
            let newAddresses = [];
            for (var i = 0; i < selectedCompanyAddresses.length; i++) {
              let addressData = selectedCompanyAddresses[i];
              let addressId = addressData?.['address_id'];
              let company_address_id = addressData?.['company_address_id'];

              let companyAddressData = {
                ...addressData,
              };

              if (addressData?.isDeleted) {
                try {
                  const respCompanyAddress = await addressApi.removeCompanyAddress(
                    selectedCompanyId,
                    company_address_id
                  );
                } catch (error) {
                  enqueueSnackbar(
                    error?.response?.data?.error?.message || `Failed to Remove Address`,
                    {
                      variant: 'error',
                    }
                  );
                }
              } else {
                if (addressData?.isNew) {
                  newAddresses.push({
                    address_line_3: '',
                    is_default: 1,
                    is_applying: 0,
                    is_registered: 1,
                    registration_datetime: '2021-03-31 00:00:00',
                    ...companyAddressData,
                  });
                } else {
                  if (addressData?.isEdited) {
                    try {
                      const respCompanyAddress = await addressApi.updateCompanyAddressByAddressId(
                        selectedCompanyId,
                        company_address_id,
                        { ...companyAddressData, address_id: addressId }
                      );
                    } catch (error) {
                      enqueueSnackbar(
                        error?.response?.data?.error?.message || `Failed to Update Address`,
                        {
                          variant: 'error',
                        }
                      );
                    }
                  }
                }
              }
            }

            if (newAddresses?.length > 0) {
              try {
                const respCompanyAddress = await addressApi.createCompanyAddress(
                  selectedCompanyId,
                  {
                    companyAddresses: newAddresses,
                  }
                );
              } catch (error) {
                enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Address`, {
                  variant: 'error',
                });
                // return;
              }
            }
          }
          // Documents
          // if (
          //   ref_annualReport?.current?.value !== undefined ||
          //   ref_annualReport?.current?.value !== null ||
          //   ref_annualReport?.current?.value !== ''
          // ) {
          //   try {
          //     await fileUploader(ref_annualReport, 5, 2, companyId);
          //   } catch (error) {
          //     enqueueSnackbar(
          //       error?.response?.data?.error?.message || `Failed to Upload Annual Report`,
          //       { variant: 'error' }
          //     );
          //     // return;
          //   }
          // }

          // if (
          //   ref_auditedReport?.current?.value !== undefined ||
          //   ref_auditedReport?.current?.value !== null ||
          //   ref_auditedReport?.current?.value !== ''
          // ) {
          //   try {
          //     await fileUploader(ref_auditedReport, 12, 2, companyId);
          //   } catch (error) {
          //     enqueueSnackbar(
          //       error?.response?.data?.error?.message || `Failed to Upload Audited Report`,
          //       { variant: 'error' }
          //     );
          //     // return;
          //   }
          // }

          // if (
          //   ref_constitutionalDocument?.current?.value !== undefined ||
          //   ref_constitutionalDocument?.current?.value !== null ||
          //   ref_constitutionalDocument?.current?.value !== ''
          // ) {
          //   try {
          //     await fileUploader(ref_constitutionalDocument, 28, 2, companyId);
          //   } catch (error) {
          //     enqueueSnackbar(
          //       error?.response?.data?.error?.message || `Failed to Upload Constitutional Document`,
          //       { variant: 'error' }
          //     );
          //     // return;
          //   }
          // }

          // if (
          //   ref_managementAccounts?.current?.value !== undefined ||
          //   ref_managementAccounts?.current?.value !== null ||
          //   ref_managementAccounts?.current?.value !== ''
          // ) {
          //   try {
          //     await fileUploader(ref_managementAccounts, 57, 2, companyId);
          //   } catch (error) {
          //     enqueueSnackbar(
          //       error?.response?.data?.error?.message || `Failed to Upload Management Accounts`,
          //       { variant: 'error' }
          //     );
          //     // return;
          //   }
          // }

          // officer
          if (selectedOfficers?.length > 0) {
            for (var i = 0; i < selectedOfficers.length; i++) {
              let officerData = selectedOfficers[i];
              let companyofficerData = {
                ...officerData,
                cessation_type_id: officerData.cessation_type_id || null,
                company_id: companyId,
                nominee_id: officerData.nominee_id === 'null' ? null : officerData.nominee_id,
                nominator_id: officerData.nominator_id === 'null' ? null : officerData.nominator_id,
                controller_id:
                  officerData.controller_id === 'null' ? null : officerData.controller_id,
                named_secretary_id:
                  officerData.named_secretary_id === 'null' ? null : officerData.named_secretary_id,
              };

              if (officerData?.isDeleted) {
                try {
                  const respCompanyOfficer = await companyApi.removeCompanyOfficer(
                    companyId,
                    officerData?.user_company_role_id
                  );
                } catch (error) {
                  enqueueSnackbar(
                    error?.response?.data?.error?.message || `Failed to Remove Officer`,
                    {
                      variant: 'error',
                    }
                  );
                }
              } else {
                if (officerData?.isNew) {
                  try {
                    const respCompanyOfficer = await companyApi.addCompanyOfficer(companyId, {
                      ...companyofficerData,
                      created_by: parseInt(authUserId),
                    });
                  } catch (error) {
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Add Officer`,
                      {
                        variant: 'error',
                      }
                    );
                    // return;
                  }
                } else {
                  if (officerData?.isEdited) {
                    try {
                      const respCompanyOfficer = await companyApi.updateCompanyOfficer(
                        companyId,
                        officerData.user_company_role_id,
                        companyofficerData
                      );
                      console.log('edit respCompanyOfficer', respCompanyOfficer);
                    } catch (error) {
                      enqueueSnackbar(
                        error?.response?.data?.error?.message || `Failed to Update Officer`,
                        {
                          variant: 'error',
                        }
                      );
                    }
                  }
                }
              }
            }
          }

          //   user_firstname: "Dolan";
          //   user_id: 5;
          //   user_idtype: "NRIC";
          //   user_idvalue: "123456789";
          //   user_sharenumber: "11";
          //   user_sharetype: 2;

          // shareholders

          for (var i = 0; i < selectedShareholders.length; i++) {
            let shareholderData = selectedShareholders[i];

            let share_capital_id;
            if (shareholderData.user_sharetype == 1) {
              share_capital_id = respShare?.data.shares.share_capital_id;
            } else if (shareholderData.user_sharetype == 2) {
              share_capital_id = respPreferenceShare?.data.shares.share_capital_id;
            } else if (shareholderData.user_sharetype == 3) {
              share_capital_id = respOtherShare?.data.shares.share_capital_id;
            }
            // const shareholderBody = {
            //   shareholder_type_id:
            //     shareholderData.user_idtype === 3
            //       ? 2
            //       : shareholderData.user_idtype === 2
            //       ? 1
            //       : parseInt(shareholderData.user_idtype),
            //   table_primary_id: shareholderData.user_id,
            //   share_capital_id: share_capital_id,
            //   user_allotment: parseInt(shareholderData.user_sharenumber),
            //   created_by: parseInt(authUserId),
            // };
            const shareholderBody = {
              shareholder_type_id: parseInt(shareholderData.user_idtype),
              table_primary_id: shareholderData.user_id,
              share_capital_id: share_capital_id,
              user_allotment: parseInt(shareholderData.user_sharenumber),
              created_by: parseInt(authUserId),
            };

            if (shareholderData?.isDeleted) {
              try {
                const respShareholder = await shareholderApi.removeShareholder(
                  companyId,
                  shareholderData?.shareholder_id
                );
              } catch (error) {
                enqueueSnackbar(
                  error?.response?.data?.error?.message || `Failed to Remove Shareholder`,
                  {
                    variant: 'error',
                  }
                );
              }
            } else {
              if (shareholderData?.isNew) {
                try {
                  const respShareholder = await shareholderApi.addShareholder(
                    companyId,
                    shareholderBody
                  );
                  console.log('respShareholder' + i, respShareholder);
                } catch (error) {
                  enqueueSnackbar(
                    error?.response?.data?.error?.message || `Failed to Add Shareholder`,
                    {
                      variant: 'error',
                    }
                  );
                  // return;
                }
              } else {
                if (shareholderData?.isEdited) {
                  try {
                    const respShareholder = await shareholderApi.updateShareholder(
                      companyId,
                      shareholderData.shareholder_id,
                      shareholderBody
                    );
                    console.log('respShareholder' + i, respShareholder);
                  } catch (error) {
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Add Shareholder`,
                      {
                        variant: 'error',
                      }
                    );
                    // return;
                  }
                }
              }
            }
          }

          // super admin
          for (let i = 0; i < selectedSuperAdmins.length; i++) {
            let anAdmin = selectedSuperAdmins[i];
            // if (anAdmin?.user_id) {
            //   // create sysinfo user role only
            //   try {
            //     await userApi.createSysInfoUserRole({
            //       username: anAdmin?.email,
            //       role_id: anAdmin.role_id,
            //       company_id: companyId,
            //       sysinfo_user_id: anAdmin?.user_id,
            //       user_id: parseInt(authUserId),
            //       created_by: parseInt(authUserId),
            //     });
            //   } catch (error) {
            //     console.log('createSysInfoUser error', error?.response?.data?.error?.message);
            //     enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Super Admin`, {
            //       variant: 'error',
            //     });
            //     // return;
            //   }
            // } else {
            //   // create sysinfo user & sysinfo user role

            //   let newSysInfoUserBody = {
            //     ...anAdmin,
            //     created_by: parseInt(authUserId),
            //     username: anAdmin?.email,
            //     password: null,
            //     is_new_user: true,
            //   };

            //   let createSysInfoUserRes;
            //   try {
            //     createSysInfoUserRes = await userApi.createSysInfoUser(newSysInfoUserBody);
            //   } catch (error) {
            //     console.log('createSysInfoUser error', error?.response?.data?.error?.message);
            //     enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Super Admin`, {
            //       variant: 'error',
            //     });
            //     // return;
            //   }

            //   if (createSysInfoUserRes?.data?.is_success) {
            //     try {
            //       await userApi.createSysInfoUserRole({
            //         username: createSysInfoUserRes?.data?.users?.email,
            //         role_id: anAdmin.role_id,
            //         company_id: companyId,
            //         sysinfo_user_id: createSysInfoUserRes?.data?.users?.user_id,
            //         user_id: parseInt(authUserId),
            //         created_by: parseInt(authUserId),
            //       });
            //     } catch (error) {
            //       enqueueSnackbar(
            //         error?.response?.data?.error?.message || `Failed to Add Super Admin`,
            //         { variant: 'error' }
            //       );
            //       // return;
            //     }
            //   }
            // }

            if (anAdmin?.isDeleted && anAdmin?.company_id && anAdmin?.sysinfo_role_username) {
              try {
                await userApi.removeSysInfoUserRoleByUsername(
                  anAdmin?.sysinfo_role_username,
                  anAdmin?.company_id,
                  authUserId
                );
              } catch (error) {
                enqueueSnackbar(
                  error?.response?.data?.error?.message || `Failed to Remove Super Admin`,
                  { variant: 'error' }
                );
                return;
              }
            } else {
              if (anAdmin?.isNew) {
                if (anAdmin?.user_id) {
                  // let foundSysInfoUser = sysInfoUsers?.find((anItem) => {
                  //   return anItem[fields.user_id.name] == values[fields.user_id.name];
                  // });
                  // create sysinfo user role only
                  try {
                    await userApi.createSysInfoUserRole({
                      username: anAdmin?.email,
                      role_id: anAdmin.role_id,
                      company_id: companyId,
                      sysinfo_user_id: anAdmin?.user_id,
                      user_id: parseInt(authUserId),
                      created_by: parseInt(authUserId),
                    });
                  } catch (error) {
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                      { variant: 'error' }
                    );
                    return;
                  }
                } else {
                  // create sysinfo user & sysinfo user role
                  let newSysInfoUserBody = {
                    ...anAdmin,
                    created_by: parseInt(authUserId),
                    username: anAdmin?.email,
                    password: null,
                    is_new_user: true,
                  };
                  let createSysInfoUserRes;
                  try {
                    createSysInfoUserRes = await userApi.createSysInfoUser(newSysInfoUserBody);
                  } catch (error) {
                    console.log('createSysInfoUser error', error?.response?.data?.error?.message);
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                      { variant: 'error' }
                    );
                    return;
                  }

                  if (createSysInfoUserRes?.data?.is_success) {
                    try {
                      await userApi.createSysInfoUserRole({
                        username: createSysInfoUserRes?.data?.users?.email,
                        role_id: anAdmin.role_id,
                        company_id: companyId,
                        sysinfo_user_id: createSysInfoUserRes?.data?.users?.user_id,
                        user_id: parseInt(authUserId),
                        created_by: parseInt(authUserId),
                      });
                    } catch (error) {
                      enqueueSnackbar(
                        error?.response?.data?.error?.message || `Failed to Add Super Admin`,
                        { variant: 'error' }
                      );
                      return;
                    }
                  }
                }
              } else {
                // update role_id
                if (anAdmin?.isEdited && anAdmin?.company_id && anAdmin?.sysinfo_role_username) {
                  try {
                    await userApi.updateSysInfoUserRole(
                      anAdmin?.sysinfo_role_username,
                      anAdmin?.company_id,
                      anAdmin.role_id
                    );
                    enqueueSnackbar(`Role Updated`, { variant: 'success' });
                  } catch (error) {
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Update Super Admin`,
                      { variant: 'error' }
                    );
                    return;
                  }
                }
              }
            }
          }

          // auditor company
          for (let i = 0; i < selectedAuditorCompanies.length; i++) {
            let anAuditorCompany = selectedAuditorCompanies[i];
            if (anAuditorCompany?.isDeleted && anAuditorCompany?.company_auditor_id) {
              try {
                await companyApi.removeCompanyAuditor(
                  anAuditorCompany?.company_auditor_id,
                  parseInt(authUserId)
                );
              } catch (error) {
                enqueueSnackbar(
                  error?.response?.data?.error?.message || `Failed to remove Company Auditor`,
                  { variant: 'error' }
                );
              }
            } else {
              if (anAuditorCompany?.isNew || !anAuditorCompany?.auditor_company_id) {
                try {
                  await companyApi.createCompanyAuditor({
                    ...anAuditorCompany,
                    client_company_id: companyId,
                    // company_type_id: 14,
                    // company_status_id: 2,
                    created_by: parseInt(authUserId),
                  });
                } catch (error) {
                  console.log('Add Auditor Company error', error?.response?.data?.error?.message);
                  enqueueSnackbar(
                    error?.response?.data?.error?.message || `Failed to Add Auditor Company`,
                    { variant: 'error' }
                  );
                  // return;
                }
              } else {
                if (anAuditorCompany?.isEdited) {
                  try {
                    await companyApi.updateCompanyAuditor(anAuditorCompany?.company_auditor_id, {
                      client_company_id: anAuditorCompany?.client_company_id || companyId,
                      auditor_company_id: anAuditorCompany?.auditor_company_id,
                      appointment_date: anAuditorCompany?.appointment_date,
                      cessation_type_id: anAuditorCompany?.cessation_type_id || null,
                      cessation_date: anAuditorCompany?.cessation_date || null,
                    });
                  } catch (error) {
                    console.log(
                      'Update Company Auditor error',
                      error?.response?.data?.error?.message
                    );
                    enqueueSnackbar(
                      error?.response?.data?.error?.message || `Failed to Add Auditor Company`,
                      {
                        variant: 'error',
                      }
                    );
                    // return;
                  }
                }
              }
            }
          }

          enqueueSnackbar(`Company Updated`, { variant: 'success' });
          await fetchInitialValues();
          // }
        };

        await startSubmit()
          .then(() => {
            resolve();
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      })();
    });
  };

  const customValidateForm = async (callback, formikProps) => {
    let canSubmit = false;
    let result = {};

    const { validateForm, values, setTouched, touched } = formikProps;
    // try {
    //   result = await validateForm();
    //   if (result && Object.keys(result).length == 0) {
    //     canSubmit = true;
    //   }
    // } catch (errors) {
    //   console.log('validateForm error', errors);
    //   enqueueSnackbar(`Please fill up all required fields`, {
    //     variant: 'error',
    //   });
    //   // setLoadingSubmit(false);
    //   setTouched({ ...touched, ...errors });
    // }

    // if (canSubmit) {
    //   return await callback(values);
    // } else {
    //   // console.log('result', result);
    //   // setLoadingSubmit(false);
    //   enqueueSnackbar(`Please fill up all required fields`, {
    //     variant: 'error',
    //   });
    //   setTouched({ ...touched, ...result });
    // }
    // await validateForm()
    //   .then((result) => {
    //     console.log('validateForm result', result);
    //     let canSubmit = false;
    //     if (result && Object.keys(result).length == 0) {
    //       canSubmit = true;
    //     }
    //     if (canSubmit) {
    //       callback(values);
    //     } else {
    //       // setLoadingSubmit(false);
    //       enqueueSnackbar(`Please fill up all required fields`, {
    //         variant: 'error',
    //       });
    //       setTouched({ ...touched, ...result });
    //     }
    //   })
    //   .catch((errors) => {
    //     console.log('validateForm error', errors);
    //     enqueueSnackbar(`Please fill up all required fields`, {
    //       variant: 'error',
    //     });
    //     // setLoadingSubmit(false);
    //     setTouched({ ...touched, ...errors });
    //   });

    return new Promise(async (resolve, reject) => {
      await (async () => {
        try {
          result = await validateForm();
          if (result && Object.keys(result).length == 0) {
            canSubmit = true;
          }
        } catch (errors) {
          console.log('validateForm error', errors);
          enqueueSnackbar(`Please fill up all required fields`, {
            variant: 'error',
          });
          // setLoadingSubmit(false);
          setTouched({ ...touched, ...errors });
        }

        if (canSubmit) {
          try {
            let callBackResult = await callback(values);
            // return callBackResult;
            resolve(callBackResult);
          } catch (error) {
            reject(error);
          }
        } else {
          // console.log('result', result);
          // setLoadingSubmit(false);
          enqueueSnackbar(`Please fill up all required fields`, {
            variant: 'error',
          });
          setTouched({ ...touched, ...result });
        }
        // await validateForm()
        //   .then((result) => {
        //     console.log('validateForm result', result);
        //     let canSubmit = false;
        //     if (result && Object.keys(result).length == 0) {
        //       canSubmit = true;
        //     }
        //     if (canSubmit) {
        //       callback(values);
        //     } else {
        //       // setLoadingSubmit(false);
        //       enqueueSnackbar(`Please fill up all required fields`, {
        //         variant: 'error',
        //       });
        //       setTouched({ ...touched, ...result });
        //     }
        //   })
        //   .catch((errors) => {
        //     console.log('validateForm error', errors);
        //     enqueueSnackbar(`Please fill up all required fields`, {
        //       variant: 'error',
        //     });
        //     // setLoadingSubmit(false);
        //     setTouched({ ...touched, ...errors });
        //   });
      })();
    });
  };

  const applyAndPublish = async (formikProps) => {
    try {
      await customValidateForm(onSubmit, formikProps);
      let canPublish = await customValidateForm(checkCanPublish, formikProps);
      if (canPublish) {
        await customValidateForm(handleOnPublishCompany, formikProps);
      }
    } catch (error) {}
  };

  const handleSendSuperAdminEmail = async (value) => {
    setLoadingSubmit(true);
    try {
      let sendEmailRes = await userApi.sendSuperAdminEmail({
        username: value?.sysinfo_username,
        role_id: value?.role_id,
        company_id: value?.company_id,
        created_by: parseInt(authUserId),
      });
      console.log('sendEmailRes', sendEmailRes);
      enqueueSnackbar(`Email is sent.`, { variant: 'success' });
    } catch (error) {
      console.log('handleSendSuperAdminEmail errorerror', error);
      enqueueSnackbar(`Failed to send email..`, { variant: 'error' });
    }
    setLoadingSubmit(false);
  };
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Button
          size="medium"
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          // onClick={() => history.goBack()}
          onClick={() => history.push({ pathname: '/company' })}
        >
          BACK TO COMPANY DATABASE
        </Button>
        <Button
          size="medium"
          color="primary"
          endIcon={<ArrowForwardIosIcon />}
          // onClick={() => history.goBack()}
          onClick={() =>
            history.push({
              pathname: '/companydocuments',
              search: '?company=' + selectedCompanyId,
            })
          }
        >
          GO TO COMPANY DOCUMENTS
        </Button>
      </Box>
      <PageTitle title={`Company Details (ID: ${selectedCompanyId})`} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            // onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formikProps) => {
              const {
                values,
                handleSubmit,
                validateForm,
                setTouched,
                touched,
                setFieldValue,
              } = formikProps;

              let isPublished = values?.publish_status_id == 3;
              let isEditing = values?.publish_status_id == 5;
              let isTerminated = values?.publish_status_id == 6;

              let generalDisabled = isPublished == true || isEditing == false;
              let canDiscard =
                values?.editing_text != undefined &&
                values?.editing_text != null &&
                values?.editing_text != '';

              let disableForm_companyDetail = generalDisabled;
              let disableForm_businessActivities = generalDisabled;
              let disableForm_shareCapital = generalDisabled;
              let disableForm_treasuryShare = generalDisabled;
              let disableForm_address = generalDisabled;
              let disableForm_documents = generalDisabled;
              let disableForm_officers = generalDisabled;
              let disableForm_shareholders = generalDisabled;
              let disableForm_superAdmins = generalDisabled;
              let disableForm_companyAuditors = generalDisabled;

              let incorporation_date = values?.[fields.incorporation_date.name];
              let minDate_fye;
              let maxDate_first_fye;
              let minDate_today = moment().add(1, 'days').format('YYYY-MM-DD');

              if (incorporation_date) {
                minDate_fye = moment(incorporation_date).format('YYYY-MM-DD');
                maxDate_first_fye = moment(incorporation_date)
                  .add(18, 'months')
                  .format('YYYY-MM-DD');
              }

              let defaultFirstFyeDate = values?.[fields.first_fye_date.name];
              let defaultFyeDate = values?.[fields.fye_date.name];
              if (defaultFirstFyeDate && !defaultFyeDate) {
                if (defaultFirstFyeDate && moment(defaultFirstFyeDate).isValid()) {
                  let newFye = moment(defaultFirstFyeDate).add(1, 'years');
                  if (newFye.isAfter(moment().format('YYYY-MM-DD'))) {
                    defaultFyeDate = newFye.format('YYYY-MM-DD');
                    setFieldValue(fields.fye_date.name, defaultFyeDate);
                  } else {
                    setFieldValue(
                      fields.fye_date.name,
                      moment().add(1, 'years').format('YYYY-MM-DD')
                    );
                  }
                }
              }

              let shareDatas = [];
              if (
                values?.[shareCapitalFieldNames.shareType_ordinary.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_ordinary.name],
                  share_type_name: shareCapitalFieldNames.shareType_ordinary.label,
                });
              }
              if (
                values?.[shareCapitalFieldNames.shareType_preference.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_preference.name],
                  share_type_name: shareCapitalFieldNames.shareType_preference.label,
                });
              }
              if (
                values?.[shareCapitalFieldNames.shareType_others.name]?.[
                  shareCapitalFieldNames.number_of_shares.name
                ]
              ) {
                shareDatas.push({
                  ...values[shareCapitalFieldNames.shareType_others.name],
                  share_type_name: shareCapitalFieldNames.shareType_others.label,
                });
              }

              const customValidateForm2 = async (callback) => {
                let canSubmit = false;
                let result = {};

                try {
                  result = await validateForm();
                  if (result && Object.keys(result).length == 0) {
                    canSubmit = true;
                  }
                } catch (errors) {
                  console.log('validateForm error', errors);
                  enqueueSnackbar(`Please fill up all required fields`, {
                    variant: 'error',
                  });
                  // setLoadingSubmit(false);
                  setTouched({ ...touched, ...errors });
                }

                if (canSubmit) {
                  return await callback(values);
                } else {
                  // console.log('result', result);
                  // setLoadingSubmit(false);
                  enqueueSnackbar(`Please fill up all required fields`, {
                    variant: 'error',
                  });
                  setTouched({ ...touched, ...result });
                }
                // await validateForm()
                //   .then((result) => {
                //     console.log('validateForm result', result);
                //     let canSubmit = false;
                //     if (result && Object.keys(result).length == 0) {
                //       canSubmit = true;
                //     }
                //     if (canSubmit) {
                //       callback(values);
                //     } else {
                //       // setLoadingSubmit(false);
                //       enqueueSnackbar(`Please fill up all required fields`, {
                //         variant: 'error',
                //       });
                //       setTouched({ ...touched, ...result });
                //     }
                //   })
                //   .catch((errors) => {
                //     console.log('validateForm error', errors);
                //     enqueueSnackbar(`Please fill up all required fields`, {
                //       variant: 'error',
                //     });
                //     // setLoadingSubmit(false);
                //     setTouched({ ...touched, ...errors });
                //   });

                // return new Promise(async (resolve, reject) => {
                //   (async () => {
                //     try {
                //       result = await validateForm();
                //       if (result && Object.keys(result).length == 0) {
                //         canSubmit = true;
                //       }
                //     } catch (errors) {
                //       console.log('validateForm error', errors);
                //       enqueueSnackbar(`Please fill up all required fields`, {
                //         variant: 'error',
                //       });
                //       // setLoadingSubmit(false);
                //       setTouched({ ...touched, ...errors });
                //     }

                //     if (canSubmit) {
                //       try {
                //         let callBackResult = await callback(values);
                //         // return callBackResult;
                //         resolve(callBackResult);
                //       } catch (error) {
                //         reject(error);
                //       }
                //     } else {
                //       // console.log('result', result);
                //       // setLoadingSubmit(false);
                //       enqueueSnackbar(`Please fill up all required fields`, {
                //         variant: 'error',
                //       });
                //       setTouched({ ...touched, ...result });
                //     }
                //     // await validateForm()
                //     //   .then((result) => {
                //     //     console.log('validateForm result', result);
                //     //     let canSubmit = false;
                //     //     if (result && Object.keys(result).length == 0) {
                //     //       canSubmit = true;
                //     //     }
                //     //     if (canSubmit) {
                //     //       callback(values);
                //     //     } else {
                //     //       // setLoadingSubmit(false);
                //     //       enqueueSnackbar(`Please fill up all required fields`, {
                //     //         variant: 'error',
                //     //       });
                //     //       setTouched({ ...touched, ...result });
                //     //     }
                //     //   })
                //     //   .catch((errors) => {
                //     //     console.log('validateForm error', errors);
                //     //     enqueueSnackbar(`Please fill up all required fields`, {
                //     //       variant: 'error',
                //     //     });
                //     //     // setLoadingSubmit(false);
                //     //     setTouched({ ...touched, ...errors });
                //     //   });
                //   })();
                // });
              };

              const applyAndPublish2 = async () => {
                try {
                  await customValidateForm(onSubmit);
                  let canPublish = await customValidateForm(checkCanPublish);
                  if (canPublish) {
                    await customValidateForm(handleOnPublishCompany);
                  }
                } catch (error) {}
              };

              return (
                <form onSubmit={handleSubmit}>
                  <FormikDummyField
                    field={fields.company_client_type_id.name}
                    onChange={changeDynamicRequired}
                  />
                  <FormikDummyField
                    field={fields.company_type_id.name}
                    onChange={changeDynamicRequiredCompanyType}
                  />
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box mr={2}>
                          <b>Status: {values?.publish_status_name || '-'}</b>
                        </Box>
                        {values?.termination_date && (
                          <Box mt={1}>Pending Termination on: {values?.termination_date}</Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" alignItems="center">
                            <Box>
                              {isPublished ? (
                                <Button
                                  variant={'contained'}
                                  color={'primary'}
                                  onClick={handleOnDraftCompany}
                                >
                                  Set To Editing
                                </Button>
                              ) : null}

                              {isEditing ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    applyAndPublish(formikProps);
                                  }}
                                  disabled={generalDisabled}
                                >
                                  {'Apply & Publish'}
                                </Button>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" alignItems={'center'}>
                            <Button
                              variant={'contained'}
                              color={'secondary'}
                              onClick={() => {
                                setTerminationForm((prev) => ({
                                  ...prev,
                                  show: true,
                                }));
                              }}
                            >
                              {values?.publish_status_id == 6 ? 'Undo Termination' : 'Terminate'}
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                          <Box>
                            {isPublished ? (
                              <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                  handleOnDraftCompany();
                                  // setIsEditing((prev) => !prev);
                                }}
                              >
                                Set To Editing
                              </Button>
                            ) : (
                              <Button
                                // type="submit"
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                  (async () => {
                                    let canPublish = await customValidateForm(checkCanPublish);
                                    if (canPublish) {
                                      try {
                                        await customValidateForm(onSubmit);
                                        console.log('selectedSuperAdmins', selectedSuperAdmins);
                                      } catch (error) {}
                                      await customValidateForm(handleOnPublishCompany);
                                    }
                                  })();
                                }}
                                disabled={generalDisabled}
                              >
                                {'Apply & Publish'}
                              </Button>
                            )}
                          </Box>
                          <Box ml={2}>
                            <h3>Status: {values?.publish_status_name || '-'}</h3>
                          </Box>
                        </Box>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Company Details
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomRadioGroupField
                          name={fields.company_client_type_id.name}
                          label={fields.company_client_type_id.label}
                          options={companyCategoryOptions}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.company_registration_no.name}
                          label={fields.company_registration_no.label}
                          required={true}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.company_name.name}
                          label={fields.company_name.label}
                          required={true}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.former_name.name}
                          label={fields.former_name.label}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.name_effective_from.name}
                          label={fields.name_effective_from.label}
                          type="date"
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.incorporation_date.name}
                          label={fields.incorporation_date.label}
                          type="date"
                          placeholder="YYYY_MM_DD"
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.company_type_id.name}
                          label={fields.company_type_id.label}
                          options={companyTypeOptions}
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.company_status_id.name}
                          label={fields.company_status_id.label}
                          options={companyStatusOptions}
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.status_date.name}
                          label={fields.status_date.label}
                          type="date"
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.country_of_incorporation_id.name}
                          label={fields.country_of_incorporation_id.label}
                          options={countryOptions}
                          required={true}
                          disabled={disableForm_companyDetail}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <small>For New Company</small>
                        </Box>
                        <CustomTextField
                          name={fields.first_fye_date.name}
                          label={fields.first_fye_date.label}
                          type="date"
                          inputProps={{
                            min: minDate_fye,
                            max: maxDate_first_fye,
                          }}
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <small>For Existing Company</small>
                        </Box>
                        <CustomTextField
                          name={fields.fye_date.name}
                          label={fields.fye_date.label}
                          type="date"
                          inputProps={{
                            min: minDate_today,
                          }}
                          required={dynamicRequire}
                          disabled={disableForm_companyDetail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_certificateOfIncorporation}
                          placeholder="Upload Certificate of Incorporation"
                          previewLink={certificateIncorporationDocView?.signed_document_url}
                          previewLabel={'View Certificate of Incorporation'}
                          disabled={disableForm_companyDetail}
                          document={certificateIncorporationDocView}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Principal Activities
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.primary_ssic_id.name}
                          label={fields.primary_ssic_id.label}
                          options={ssicOptions}
                          required={dynamicRequire}
                          disabled={disableForm_businessActivities}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.business_description.name}
                          label={fields.business_description.label}
                          disabled={disableForm_businessActivities}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.secondary_ssic_id.name}
                          label={fields.secondary_ssic_id.label}
                          options={ssicOptions}
                          disabled={disableForm_businessActivities}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.secondary_business_description.name}
                          label={fields.secondary_business_description.label}
                          disabled={disableForm_businessActivities}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_bizFile}
                          placeholder="Upload Biz File"
                          previewLink={bizFileDocView?.signed_document_url}
                          previewLabel={'View Biz File'}
                          disabled={disableForm_businessActivities}
                          document={bizFileDocView}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Capital
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">Issued Share Capital</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ShareCapital
                          shareTypeOptions={shareTypeOptions}
                          totalAssignedShare={{
                            [shareCapitalFieldNames.shareType_ordinary
                              .name]: getTotalShareholderShares(1),
                            [shareCapitalFieldNames.shareType_preference
                              .name]: getTotalShareholderShares(2),
                            [shareCapitalFieldNames.shareType_others
                              .name]: getTotalShareholderShares(3),
                          }}
                          required={dynamicRequire && dynamicRequireCompanyType}
                          hideHeader={true}
                          disabled={disableForm_shareCapital}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">Treasury Shares</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.number_of_shares.name}
                          label={fields.number_of_shares.label}
                          type="number"
                          disabled={disableForm_treasuryShare}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Registered Address
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setCompanyAddressForm({
                                show: true,
                                data: null,
                              });
                            }}
                            disabled={disableForm_address}
                          >
                            Add Company Address
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyAddressTable
                          data={selectedCompanyAddresses}
                          onEdit={(data) => {
                            setCompanyAddressForm({
                              show: true,
                              data: data,
                            });
                          }}
                          disabled={disableForm_address}
                          customRowOverlay={(rowData, index) => {
                            if (rowData.isDeleted && !rowData?.isNew) {
                              return (
                                <Box
                                  // width="100%"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  p={2}
                                >
                                  <Box mr={2}>
                                    <b>Pending Remove</b>
                                  </Box>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      handleRestoreCompanyAddress(rowData, index);
                                    }}
                                  >
                                    Restore
                                  </Button>
                                </Box>
                              );
                            }
                            return null;
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <CustomSelectField
                          name={fields.address_type_id.name}
                          label={fields.address_type_id.label}
                          options={addressTypeOptions}
                          required={true}
                          disabled={disableForm_address}
                          disablePortal={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.floor_unit_no.name}
                          label={fields.floor_unit_no.label}
                          disabled={disableForm_address}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.address_line_1.name}
                          label={fields.address_line_1.label}
                          required={true}
                          disabled={disableForm_address}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTextField
                          name={fields.address_line_2.name}
                          label={fields.address_line_2.label}
                          disabled={disableForm_address}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTextField
                          name={fields.postal_code.name}
                          label={fields.postal_code.label}
                          required={true}
                          disabled={disableForm_address}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomSelectField
                          name={fields.country_id.name}
                          label={fields.country_id.label}
                          options={countryOptions}
                          required={true}
                          disabled={disableForm_address}
                        />
                      </Grid> */}
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h3" size="sm">
                          Upload Documents
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyDocuments
                          dataId={selectedCompanyId}
                          disabled={disableForm_documents}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_annualReport}
                          placeholder="Upload Annual Report"
                          previewLink={annualReportDocView?.signed_document_url}
                          previewLabel={'View Annual Report'}
                          disabled={disableForm_documents}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_auditedReport}
                          placeholder="Upload Audited Report"
                          previewLink={auditedReportDocView?.signed_document_url}
                          previewLabel={'View Audited Report'}
                          disabled={disableForm_documents}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_constitutionalDocument}
                          placeholder="Upload Constitutional Document"
                          previewLink={constitutionalDocumentView?.signed_document_url}
                          previewLabel={'View Constitutional Document'}
                          disabled={disableForm_documents}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomUploadButton
                          ref={ref_managementAccounts}
                          placeholder="Upload Management Accounts"
                          previewLink={managementAccountsView?.signed_document_url}
                          previewLabel={'View Management Accounts'}
                          disabled={disableForm_documents}
                        />
                      </Grid> */}
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Company Officers
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setOfficerForm({
                                show: true,
                                data: null,
                              });
                            }}
                            disabled={disableForm_officers}
                          >
                            Add Company Officer
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyOfficerTable
                          data={selectedOfficers}
                          onEdit={(data) => {
                            setOfficerForm({
                              show: true,
                              data: data,
                            });
                          }}
                          companyRoleOptions={companyRoleOptions}
                          disabled={disableForm_officers}
                          customRowOverlay={(rowData, index) => {
                            if (rowData) {
                              if (rowData?.isNew) {
                                return null;
                              } else {
                                if (rowData.isDeleted) {
                                  return (
                                    <Box
                                      // width="100%"
                                      height="100%"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      p={2}
                                    >
                                      <Box mr={2}>
                                        <b>Pending Remove</b>
                                      </Box>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                          handleRestoreOfficer(rowData, index);
                                        }}
                                      >
                                        Restore
                                      </Button>
                                    </Box>
                                  );
                                }
                              }
                            }
                            return null;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Shareholders
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setShareholderForm({
                                show: true,
                                data: null,
                              });
                            }}
                            disabled={disableForm_shareholders}
                          >
                            Add Shareholder
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <ShareholderTable
                          data={selectedShareholders}
                          onEdit={(data) => {
                            setShareholderForm({
                              show: true,
                              data: data,
                            });
                          }}
                          shareTypeOptions={shareTypeOptions}
                          identificationTypeOptions={identificationTypeOptions}
                          disabled={disableForm_shareholders}
                          customRowOverlay={(rowData, index) => {
                            if (rowData.isDeleted && !rowData?.isNew) {
                              return (
                                <Box
                                  // width="100%"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  p={2}
                                >
                                  <Box mr={2}>
                                    <b>Pending Remove</b>
                                  </Box>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      handleRestoreShareholder(rowData, index);
                                    }}
                                  >
                                    Restore
                                  </Button>
                                </Box>
                              );
                            }
                            return null;
                          }}
                        />
                        <ShareholderModalForm
                          {...shareholderForm}
                          onHide={() => {
                            setShareholderForm({
                              show: false,
                              data: null,
                            });
                          }}
                          onSubmit={handleSubmitShareholder}
                          onRemove={handleRemoveShareholder}
                          userData={users}
                          shareTypes={shareDatas}
                          shareholders={selectedShareholders}
                          disabled={disableForm_shareholders}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Meyzer360 Company Account Users
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setSuperAdminForm({
                                show: true,
                                data: null,
                              });
                            }}
                            disabled={disableForm_superAdmins}
                          >
                            Add User
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <SuperAdminTable
                          canResendEmail={isPublished}
                          onSendEmail={handleSendSuperAdminEmail}
                          data={selectedSuperAdmins}
                          onEdit={(data) => {
                            setSuperAdminForm({
                              show: true,
                              data: data,
                            });
                          }}
                          disabled={disableForm_superAdmins}
                          customRowOverlay={(rowData, index) => {
                            if (rowData.isDeleted && !rowData?.isNew) {
                              return (
                                <Box
                                  // width="100%"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  p={2}
                                >
                                  <Box mr={2}>
                                    <b>Pending Remove</b>
                                  </Box>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      handleRestoreSuperAdmin(rowData, index);
                                    }}
                                  >
                                    Restore
                                  </Button>
                                </Box>
                              );
                            }
                            return null;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="h3" size="sm">
                            Company Auditors
                          </Typography>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => {
                              setAuditorCompanyForm({
                                show: true,
                                data: null,
                              });
                            }}
                            disabled={disableForm_companyAuditors}
                          >
                            Add Company Auditor
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <AuditorCompanyTable
                          data={selectedAuditorCompanies}
                          onEdit={(data) => {
                            setAuditorCompanyForm({
                              show: true,
                              data: data,
                            });
                          }}
                          disabled={disableForm_companyAuditors}
                          customRowOverlay={(rowData, index) => {
                            if (rowData.isDeleted && !rowData?.isNew) {
                              return (
                                <Box
                                  // width="100%"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  p={2}
                                >
                                  <Box mr={2}>
                                    <b>Pending Remove</b>
                                  </Box>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      handleRestoreCompanyAuditor(rowData, index);
                                    }}
                                  >
                                    Restore
                                  </Button>
                                </Box>
                              );
                            }
                            return null;
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    {isEditing && (
                      <>
                        <Box>
                          <Button
                            // type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              // update editing_text only
                              customValidateForm(handleSaveEditing, formikProps);
                            }}
                            disabled={!isEditing}
                          >
                            Save as Draft
                          </Button>
                        </Box>
                        <Button
                          // type="submit"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            // update editing_text only
                            // customValidateForm(handleSaveEditing);
                            handleDiscardEditing();
                          }}
                          disabled={!canDiscard}
                        >
                          Discard Draft
                        </Button>
                      </>
                    )}
                  </Box>
                </form>
              );
            }}
          </Formik>
          <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <AuditorCompanyModalForm
            {...auditorCompanyForm}
            onHide={() => {
              setAuditorCompanyForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitAuditorCompanyForm}
            onRemove={handleRemoveAuditorCompany}
          />
          <SuperAdminModalForm
            {...superAdminForm}
            onHide={() => {
              setSuperAdminForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitSuperAdminForm}
            onRemove={handleRemoveSuperAdmin}
            superAdmins={selectedSuperAdmins}
          />
          <CompanyOfficerModalForm
            {...officerForm}
            onHide={() => {
              setOfficerForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitOfficer}
            onRemove={handleRemoveOfficer}
            userData={users}
          />
          <CompanyTerminationModalForm
            show={terminationForm?.show}
            data={initialValues}
            onHide={() => {
              setTerminationForm({
                show: false,
                data: null,
              });
            }}
            fetchInitialValues={fetchInitialValues}
            refetchCompany={refetchCompany}
          />
          <CompanyAddressModalForm
            {...companyAddressForm}
            onHide={() => {
              setCompanyAddressForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitCompanyAddressForm}
            onRemove={handleRemoveCompanyAddress}
          />

          {/* <<<<<<< HEAD
          <CompanyTerminationModalForm
            show={terminationForm?.show}
            data={initialValues}
            onHide={() => {
              setTerminationForm({
=======
          <CompanyAddressModalForm
            {...companyAddressForm}
            onHide={() => {
              setCompanyAddressForm({
>>>>>>> da7be8be8869fa39d9220fb33d40b400eef87d88
                show: false,
                data: null,
              });
            }}
            fetchInitialValues={fetchInitialValues}
            refetchCompany={refetchCompany}
            onSubmit={handleSubmitCompanyAddressForm}
            onRemove={handleRemoveCompanyAddress}
          /> */}
        </>
      )}
    </>
  );
};

export default CompanyDetailForm;
