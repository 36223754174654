import React, { useState, useEffect } from 'react';
import { Button, Box, Chip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import CustomTable from '../../../../components/CustomTable';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import companyApi from '../../../../services/company.service';
import commonApi from '../../../../services/common.service';

let defaultAuditorCompanyTableColumns = [
  {
    label: 'NAME',
    id: 'company_name',
  },
  {
    label: 'REG NO.',
    id: 'company_registration_no',
  },
  {
    label: 'APPOINTMENT DATE',
    id: 'appointment_date',
    render: (text) => {
      return text ? moment(text).format('YYYY-MM-DD') || '-' : '-';
    },
  },
  {
    id: 'cessation_type_name',
    label: 'Cessation Types',
    render: (text) => {
      return text || '-';
    },
  },
  {
    id: 'cessation_date',
    label: 'Date of Cessation',
    render: (text) => {
      return text ? moment(text).format('YYYY-MM-DD') || '-' : '-';
    },
  },
  // {
  //   label: "COUNTRY OF INCORPORATION",
  //   id: "country_of_incorporation",
  // },
];

export const AuditorCompanyTable = ({ data = [], onEdit, disabled = false, ...restProps }) => {
  const handleOnEdit = (data) => {
    if (onEdit) {
      onEdit(data);
    }
  };
  let auditorCompanyTableColumns = defaultAuditorCompanyTableColumns;
  if (onEdit) {
    auditorCompanyTableColumns = [
      {
        label: '',
        id: 'auditor_company_id',
        render: (text, data) => {
          return (
            <Chip
              color="secondary"
              onClick={() => handleOnEdit({ ...data, appointment_date: '2022-04-08' })}
              clickable
              label={'EDIT'}
              disabled={disabled}
            />
          );
        },
      },
      ...defaultAuditorCompanyTableColumns,
    ];
  }

  return (
    <CustomTable
      data={data}
      columns={auditorCompanyTableColumns}
      rowKey={'auditor_company_id'}
      {...restProps}
    />
  );
};

export const fields = {
  auditor_company_id: {
    name: 'auditor_company_id',
    label: 'Company',
  },
  appointment_date: {
    name: 'appointment_date',
    label: 'Date of Appointment',
  },
  cessation_type: {
    name: 'cessation_type_id',
    label: 'Cessation Types',
  },
  cessation_date: {
    name: 'cessation_date',
    label: 'Date of Cessation',
  },
};

export const validationSchemaObj = {
  auditor_company_id: Yup.string()
    .required(`${fields.auditor_company_id.label} is required`)
    .default(''),
  appointment_date: Yup.string().default(''),
  cessation_type: Yup.string().default(''),
  cessation_date: Yup.string().default(''),
};
let validationSchema = Yup.object(validationSchemaObj);

export const AuditorCompanyModalForm = ({
  data: defaultData,
  show = false,
  onHide,
  disabled = false,
  title,
  onSubmit,
  onRemove,
}) => {
  let data = {
    ...defaultData,
  };
  let appointmentDateValue = defaultData?.[fields.appointment_date.name];
  let dateCessationValue = defaultData?.[fields.cessation_date.name];
  if (appointmentDateValue) {
    data[fields.appointment_date.name] = moment(appointmentDateValue).format('YYYY-MM-DD') || null;
  }
  if (dateCessationValue) {
    data[fields.cessation_date.name] = moment(dateCessationValue).format('YYYY-MM-DD') || null;
  }

  let isNew = !Boolean(defaultData);
  let defaultValues = isNew ? validationSchema.cast() : data;

  const [loading, setLoading] = useState(false);
  const {
    options: companyOptions,
    loading: loadingCompanies,
    rawData: companies = [],
    refetch: fetchCompanies,
  } = useCommonOptions(
    async () => {
      let res = await companyApi.getCompanies('', 1, 10000);
      let result = res?.data?.companies || [];
      let filtered = result.filter((anItem) => {
        return anItem.company_client_type_id == 3;
      });

      return {
        ...res,
        data: {
          ...res.data,
          companies: filtered,
        },
      };
    },
    {
      respondKey: 'companies',
      labelKey: 'company_name',
      valueKey: 'company_id',
      render: (text, data) => {
        return `${text} (${data.company_registration_no})`;
      },
      lazyLoad: true,
    }
  );
  const {
    options: cessationTypeOptions,
    loading: loadingCessationTypes,
    rawData: cessationTypes = [],
    refetch: fetchCessationTypes,
  } = useCommonOptions(commonApi.getCessationType, {
    respondKey: 'cessationtypes',
    labelKey: 'cessation_type_name',
    valueKey: 'cessation_type_id',
    lazyLoad: true,
  });

  useEffect(() => {
    (async () => {
      if (show) {
        try {
          setLoading(true);
          if (fetchCompanies) {
            await fetchCompanies();
          }
          if (fetchCessationTypes) {
            await fetchCessationTypes();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [show]);

  const handleOnSubmit = (values) => {
    let foundCompany =
      companies.find((anItem) => anItem.company_id == values[fields.auditor_company_id.name]) || {};
    let foundCessationType =
      cessationTypes.find((anItem) => anItem.cessation_type_id == values.cessation_type_id) || {};

    let newBody = {
      ...foundCompany,
      ...values,
      ...foundCessationType,
    };

    if (onSubmit) {
      onSubmit(newBody);
    }
  };

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(data);
    }
  };
  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={title ? title : `${isNew ? 'Add' : 'Edit'} Company Auditor`}
    >
      {loading ? (
        'Loading'
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(props) => {
            const { handleSubmit } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.auditor_company_id.name}
                    label={fields.auditor_company_id.label}
                    options={companyOptions}
                    required={true}
                    disabled={!isNew}
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    name={fields.appointment_date.name}
                    label={fields.appointment_date.label}
                    type="date"
                    // required={true}
                  />
                </Box>
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.cessation_type.name}
                    label={fields.cessation_type.label}
                    options={cessationTypeOptions}
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    name={fields.cessation_date.name}
                    label={fields.cessation_date.label}
                    type="date"
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && onRemove && (
                      <Box ml={2}>
                        <Button variant="contained" onClick={handleOnRemove}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );
};
