import requestsApi from './requestsapi';
import axios from './customAxios';
const getRequests = () => {
  return requestsApi.get(`/companyapplications?filter=&page=1&page_limit=10000000`);
};

const getRequestById = (id) => {
  return requestsApi.get(`/companyapplications/${id}`);
};

const getRequestDocuments = (companyApplicationId) => {
  // https://nztftlfooj.execute-api.ap-southeast-1.amazonaws.com/Prod/companyapplications/{companyApplicationID}/documents
  return requestsApi.get(`/companyapplications/${companyApplicationId}/documents`);
};
const updateRequestStatus = (id, statusId) => {
  return requestsApi.put(`/companyapplicationstatus/${id}`, {
    company_application_status_id: statusId,
  });
};

const createRequest_other = (body = {}) => {
  return requestsApi.post(`/otherrequestapplications`, body);
};

const getApplication = (id, type) => {
  let req = { endpoint: '', response: 'applications' };
  switch (type) {
    case '9':
      req = { endpoint: 'application', response: 'financial_year_ends' };
      break;
    case '12':
      req = { endpoint: 'bizactivity', response: 'business_activities' };
      break;
    case '13':
      req = { endpoint: 'application', response: 'dividend_declarations' };
      break;
    case '14':
      req = { endpoint: 'application', response: 'other_requests' };
      break;

    default:
      req = { endpoint: 'application', response: 'applications' };
  }

  let appType = getAppType(type);
  return axios
    .get(`/${req.endpoint}${appType ? `/${appType}` : ''}/${id}`)
    .then((response) => response.data[req.response]);
};

const getAppType = (type) => {
  switch (type) {
    case '1':
      return APPLICATION_TYPES.SHARE_APPLICATION;
    case '2':
      return APPLICATION_TYPES.SHARE_TRANSFER_APPLICATION;
    case '3':
      return APPLICATION_TYPES.COMPANY_ADDRESS_CHANGE_APPLICATION;
    case '4':
      return APPLICATION_TYPES.COMPANY_NAME_CHANGE_APPLICATION;
    case '5':
      return APPLICATION_TYPES.OFFICER_APPLICATION;
    case '6':
      return APPLICATION_TYPES.SHARE_HOLDER_APPLICATION;
    case '7':
      return APPLICATION_TYPES.CESSATION_APPLICATION;
    case '8':
      return APPLICATION_TYPES.INDIVIDUAL_PARTICULAR_APPLICATION;
    case '9':
      return APPLICATION_TYPES.FINANCIAL_YEAR_END_APPLICATION;
    case '10':
      return APPLICATION_TYPES.OPEN_BANK_ACCOUNT_APPLICATION;
    case '11':
      return APPLICATION_TYPES.CLOSE_BANK_ACCOUNT_APPLICATION;
    case '12':
      return APPLICATION_TYPES.BUSINESS_ACTIVITY_APPLICATION;
    case '13':
      return APPLICATION_TYPES.DIVIDEND_APPLICATION;
    case '14':
      return APPLICATION_TYPES.OTHER_REQUEST_APPLICATION;
    case '15':
      return APPLICATION_TYPES.SHARE_ALLOTMENT_APPLICATION;
    case '16':
      return APPLICATION_TYPES.COMPANY_AUDITOR_APPLICATION;
    case '18':
      return APPLICATION_TYPES.AUDITOR_CESSATION_APPLICATION;
    default:
      return '';
  }
};
// const createShareCapital = (companyId, data) => {
//   // console.log("common", commonApi.get(`/identificationtype`));
//   return shareApi.post(`/companies/` + companyId + `/shares`, data);
// };
// https://n3xztlk00e.execute-api.ap-southeast-1.amazonaws.com/Prod/companyapplications?filter=&page=1&page_limit=10
// const createTreasuryShare = (companyId, shareId, data) => {
//   // console.log("common", commonApi.get(`/identificationtype`));
//   return shareApi.post(
//     `/companies/` + companyId + `/shares/` + shareId + `/treasuryshares`,
//     data
//   );
// };

// const getShareCapital = (companyId) => {
//   return shareApi.get(`/companies/` + companyId + `/shares`);
// };

// const getTreasuryShare = (companyId, shareId) => {
//   return shareApi.get(
//     `/companies/` + companyId + `/shares/` + shareId + `/treasuryshares`
//   );
// };

// const updateShareCapital = (companyId, shareId, data) => {
//   console.log("sharecapital", companyId, shareId, data);

//   return shareApi.put(`/companies/` + companyId + `/shares/` + shareId, data);
// };

// const updateTreasuryShare = (companyId, shareId, treasurysharesId, data) => {
//   return shareApi.put(
//     `/companies/` +
//       companyId +
//       `/shares/` +
//       shareId +
//       `/treasuryshares/` +
//       treasurysharesId,
//     data
//   );
// };

export default {
  getRequests,
  getRequestById,
  getApplication,
  getAppType,
  updateRequestStatus,
  getRequestDocuments,
  // createShareCapital,
  // createTreasuryShare,
  // getShareCapital,
  // getTreasuryShare,
  // updateShareCapital,
  // updateTreasuryShare,
  createRequest_other,
};

export const APPLICATION_TYPES = {
  SHARE_APPLICATION: 'shareapplications',
  SHARE_TRANSFER_APPLICATION: 'sharetransferapplicationsv2',
  COMPANY_ADDRESS_CHANGE_APPLICATION: 'companyaddressapplications',
  COMPANY_NAME_CHANGE_APPLICATION: 'companynameapplications',
  SHARE_HOLDER_APPLICATION: 'newshareholderapplications',
  CESSATION_APPLICATION: 'cessationapplications',
  FINANCIAL_YEAR_END_APPLICATION: 'financialyearendapplication',
  OFFICER_APPLICATION: 'newofficerapplications',
  BUSINESS_ACTIVITY_APPLICATION: 'businessactivityapplication',
  DIVIDEND_APPLICATION: 'dividenddeclarationapplications',
  INDIVIDUAL_PARTICULAR_APPLICATION: 'individualparticularapplications',
  // BANK_ACCOUNT_APPLICATION: "openbankaccountapplications",
  OPEN_BANK_ACCOUNT_APPLICATION: 'openbankaccountapplications',
  CLOSE_BANK_ACCOUNT_APPLICATION: 'closebankaccountapplications',
  OTHER_REQUEST_APPLICATION: 'otherrequestapplications',
  SHARE_ALLOTMENT_APPLICATION: 'sharesallotmentapplicationsv2',
  COMPANY_AUDITOR_APPLICATION: 'companyauditorapplications',
  AUDITOR_CESSATION_APPLICATION: 'auditorcessationapplications',
};
