import axios from './customAxios';

const getCarts = async () => {
  const { data } = await axios.get(`/order/carts`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.carts;
};

const getById = async (id) => {
  const { data } = await axios.get(`/order/cart/${id}`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.cart;
};

const addCart = async (body = {}) => {
  return axios.post(`/order/cart/additem`, body);
};

const cartSvc = {
  getCarts,
  getById,
  addCart,
};

export default cartSvc;
