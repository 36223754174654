import React, { useEffect, useState } from 'react';
import { Button, Box, Divider, Backdrop, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import CustomUploadButton from '../../../components/CustomFormFields/CustomUploadButton';
import documentApi from '../../../services/document.service';
import useCommonOptions from '../../../hooks/useCommonOptions';
import fileUploader, { emailUploader } from '../../../utility/fileuploader';
import FileViewerButton2 from '../../../components/FileViewerButton2';
import { useUserState } from '../../../context/UserContext';

const DocumentsListByType = (props) => {
  const {
    disabled = false,
    dataGroups = [],
    dataTypeId = 2,
    dataId,
    documentContainerId = 1,
    preview = true,
    accept,
    urlKey = 'document_url',
  } = props;

  const { userId: authUserId } = useUserState();
  let userId = parseInt(authUserId);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    loading: loadingDocuments,
    rawData: defaultDocuments = [],
    refetch: fetchDocuments,
  } = useCommonOptions(async () => documentApi.getDocumentsList(dataTypeId, dataId), {
    respondKey: 'document',
    labelKey: urlKey,
    valueKey: 'document_id',
    lazyLoad: true,
  });

  let isLoading = loadingDocuments || loading;

  useEffect(() => {
    if (dataId || typeof dataId == 'number') {
      (async () => {
        await fetchDocuments();
      })();
    }
  }, [dataId]);

  useEffect(() => {
    if (!loadingDocuments) {
      setDocuments(defaultDocuments);
    }
  }, [loadingDocuments]);

  let documentGroups = [...dataGroups];
  if (documents?.length > 0) {
    documentGroups = documentGroups.map((anItem) => {
      let ids = [anItem.document_type_id];
      if (anItem?.otherIds?.length > 0) {
        ids = [...ids, ...anItem?.otherIds];
      }
      let filteredDocuments = documents.filter((anItem2) => {
        let result = false;
        if (ids.indexOf(anItem2.document_type_id) >= 0) {
          result = true;
        }
        return result;
      });
      return {
        ...anItem,
        data: filteredDocuments,
      };
    });
  }

  const handleAddDocument = async (files = [], docTypeId) => {
    try {
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        await emailUploader({ current: { files: [files[i]] } }, docTypeId, dataTypeId, dataId);
      }
      fetchDocuments();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveDocument = async (value) => {
    if (value?.document_id && userId) {
      try {
        setLoading(true);
        await documentApi.removeDocumentById(value?.document_id, userId);
        await fetchDocuments();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <Box width="100%">
      {documentGroups.map((anItem, index) => {
        return (
          <Box key={index} mb={2}>
            {index != 0 ? (
              <Box mb={1}>
                <Divider />
              </Box>
            ) : null}
            <DocumentsGroupItem
              data={anItem}
              disabled={disabled}
              onAdd={handleAddDocument}
              onRemove={handleRemoveDocument}
              refetch={fetchDocuments}
              preview={preview}
              accept={accept}
              urlKey={urlKey}
            />
          </Box>
        );
      })}
      <Backdrop open={isLoading} style={{ zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default DocumentsListByType;

const DocumentsGroupItem = (props) => {
  const {
    data: defaultData,
    disabled = false,
    onAdd,
    onRemove,
    preview = true,
    accept,
    urlKey = 'signed_document_url',
  } = props;

  const { label, data, document_type_id } = defaultData;

  let sortedDocuments = data.sort((a, b) => {
    if (b.uploaded_date && a.uploaded_date) {
      return new Date(b.uploaded_date) - new Date(a.uploaded_date);
    }
    return 0;
  });

  let inputProps = {};
  if (accept) {
    inputProps['accept'] = accept;
  }
  const handleAddDocument = async (e) => {
    if (e?.target?.files?.length > 0) {
      onAdd(e?.target?.files, document_type_id);
    }
  };

  const handleRemoveDocument = (value) => {
    if (window.confirm('Confirm Remove Document?')) {
      if (onRemove) {
        onRemove(value);
      }
    }
  };

  const getFileNameFromURL = (value) => {
    let result = value;

    const urlKey = `amazonaws.com/`;
    if (value.indexOf(urlKey) >= 0) {
      let urlArray = value.split(urlKey);
      if (urlArray?.[1]?.length > 36) {
        result = urlArray[1].substring(36);
        result = decodeURI(result);
      }
    }

    return result;
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <b>{label}</b>
        </Box>
        <Box ml="auto">
          <Button
            component="label"
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onChange={handleAddDocument}
          >
            Upload Document
            <input type="file" multiple hidden {...inputProps} />
          </Button>
        </Box>
      </Box>
      {sortedDocuments?.length > 0 ? (
        <Box>
          {sortedDocuments.map((anItem, index) => {
            const { uploaded_date, file_type, file_name } = anItem;
            let filename = file_name || '-';
            if (file_name) {
              filename = getFileNameFromURL(file_name);
            }

            let docURL = anItem?.[urlKey];

            let uploadedDate = '-';
            try {
              uploadedDate = moment(uploaded_date).format(`DD/MM/yyyy hh:mm:ssa`);
            } catch (error) {}

            let buttonProps = {};
            if (file_type) {
              buttonProps['previewFileType'] = file_type;
            }

            return (
              <Box key={index} display={'flex'} alignItems="center" mb={1}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Box mr={2}>
                    {/* <CustomUploadButton
                      previewLink={docURL}
                      previewLabel={'View'}
                      hideUpload={true}
                      size="small"
                      {...buttonProps}
                    /> */}
                    {preview ? (
                      <FileViewerButton2 srcType="url" src={docURL} size="small" document={anItem}>
                        View
                      </FileViewerButton2>
                    ) : (
                      <Button as="a" href={docURL} target="_blank" variant="contained">
                        View
                      </Button>
                    )}
                  </Box>
                  <Box mr={2}>{uploadedDate}</Box>
                  <Box mr={2}>{filename}</Box>
                </Box>
                <Button
                  disabled={disabled}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleRemoveDocument(anItem);
                  }}
                >
                  Remove
                </Button>
              </Box>
            );
          })}
        </Box>
      ) : (
        'No Document'
      )}
    </Box>
  );
};
