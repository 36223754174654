import React, { useEffect, useState, useRef } from 'react';

import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Paper,
  Grid,
  Backdrop,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useUserState } from '../../context/UserContext';
import useBreakpoints from '../../hooks/useBreakpoints';
import taskSvc from '../../services/task.service';
import timelineSvc from '../../services/timeline.service';
import CustomModal from '../../components/CustomModal';
import Rejected from './Rejected';
import documentApi from '../../services/document.service';
import fileUploader from '../../utility/fileuploader';
import useQueryParams from '../../hooks/useQueryParams';
import PageTitle from '../../components/PageTitle/PageTitle';
import FileViewerButton2 from '../../components/FileViewerButton2';

const TaskDocDetails = {
  'Filing of Register of Registrable Controllers (RORC)': {
    task_type_id: 3,
    doc_type_id: 33,
    table_type_id: 2, // company type
  },
  'Registration of CorpPass': {
    task_type_id: 4,
    doc_type_id: 26,
    table_type_id: 2, // company type
  },
  'Appointment of Data Protection Officer (DPO)': {
    task_type_id: 5,
    doc_type_id: 6,
    table_type_id: 2, // company type
  },
  'Appointment of Auditor': {
    task_type_id: 6,
    doc_type_id: 6,
    table_type_id: 2, // company type
  },
  'Appointment of Auditor ': {
    task_type_id: 6,
    doc_type_id: 6,
    table_type_id: 2, // company type
  },
  'Appointment of Company Secretary': {
    task_type_id: 7,
    doc_type_id: 8,
    table_type_id: 2, // company type
  },
  'Prepare Financial Statements (FS)': {
    task_type_id: 15,
    doc_type_id: 45,
    table_type_id: 2, // company type
  },
  'Filing of Estimated Chargeable Income (ECI)': {
    task_type_id: 16,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Annual General Meeting (AGM) Sec 175': {
    task_type_id: 17,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Annual Return (AR) Filing Sec 201': {
    task_type_id: 18,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Extension of Time (EOT) Sec 175': {
    task_type_id: 19,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Extension of Time (EOT) Sec 201': {
    task_type_id: 20,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Filing of Corporate Tax Returns (Form C/ Form C-S)': {
    task_type_id: 21,
    doc_type_id: 49,
    table_type_id: 2, // company type
  },
  'Annual Review': {
    task_type_id: 22,
    doc_type_id: 6,
    table_type_id: 2, // company type
  },
  'Share Transfer': {
    task_type_id: 9,
    doc_type_id: 105,
    table_type_id: 2, // company type
  },
  'IRAS - Stamp Duty payable': {
    task_type_id: 10,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Update change of Officers': {
    task_type_id: 11,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
  'Change of Registered Office': {
    task_type_id: 12,
    doc_type_id: 22,
    table_type_id: 2, // company type
  },
  'Change of Financial Year End': {
    task_type_id: 13,
    doc_type_id: 20,
    table_type_id: 2, // company type
  },
  'Change of SSIC code': {
    task_type_id: 14,
    doc_type_id: 119,
    table_type_id: 2, // company type
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function TaskDetail() {
  const history = useHistory();
  const query = useQueryParams();
  const taskId = query.get('taskid');

  const [toInProgress, setToInProgress] = useState(false);
  const [toApproved, setToApproved] = useState(false);
  const [toRejected, setToRejected] = useState(false);
  const [toResubmitted, setToResubmitted] = useState(false);
  const [isTaskUpdatable, setIsTaskUpdatable] = useState(false);

  const classes = useStyles();

  const [task, setTask] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [documentType, setDocumentType] = useState(1);
  const [tableType, setTableType] = useState(2);
  const [taskDocument, setTaskDocument] = useState();

  const [modal, setModal] = useState({ show: false, data: null });

  const refSelectFile = useRef();

  const companyId = 1;
  let module = 1; // is 1 -corp sec
  let container = 1; // is 1

  const handleSelectFile = (event) => {
    setSelectedFile(refSelectFile.current.files[0]);
    setIsFilePicked(true);
  };

  async function handleUpload() {
    const pushData = async () => {
      const res = await fileUploader(refSelectFile, documentType, tableType, companyId);
      return res;
    };

    let res = await pushData().finally(() => {
      refSelectFile.current.value = '';
    });
    return res;
  }

  const initialTask = async () => {
    setLoading(true);

    setToInProgress(false);
    setToApproved(false);
    setToRejected(false);
    setToResubmitted(false);
    setIsTaskUpdatable(false);

    await taskSvc
      .getTask(taskId)
      .then(async (result) => {
        setTask(result || {});

        if (result.attachment_id) {
          const respDocument = await documentApi.getDocumentsByIdSTS(result.attachment_id);
          console.log('get doc by id >>>', respDocument);
          setTaskDocument(respDocument?.data?.document?.[0]);
        }

        if (TaskDocDetails[result.task_name]) {
          setDocumentType(TaskDocDetails[result.task_name].doc_type_id);
          setTableType(TaskDocDetails[result.task_name].table_type_id);
          setIsTaskUpdatable(true);
        }

        if (result.status_name == 'Pending Review') {
          setToInProgress(true);
        } else if (result.status_name == 'In Progress') {
          setToApproved(true);
          setToRejected(true);
        } else if (result.status_name == 'Rejected') {
          setToResubmitted(true);
        } else if (result.status_name == 'Resubmitted') {
          setToApproved(true);
          setToRejected(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    (async () => {
      await initialTask();
    })();
  }, [taskId]);

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'PENDING':
        statusColor = '#FF7F09';
        textLabel = 'Pending';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography variant="body1" align="right" style={{ fontWeight: 600, color: statusColor }}>
        {textLabel}
      </Typography>
    );
  };

  async function updateToInProgress(task_id) {
    //async function updateToInProgress(task_id, company_id, event_name, user_id, start_date, end_date, period) {
    //update task to in progress
    setLoadingSubmit(true);
    let uploadRes = await handleUpload();
    console.log('attachment id >>>', uploadRes);
    let attachment_id = uploadRes?.data?.document?.[0]?.document_id;
    await taskSvc.updateTask(task_id, 2, attachment_id, ''); // In Progress
    await initialTask();
    setLoadingSubmit(false);

    // not need to add timeline as it is done in DAL by task update
    //await timelineSvc.addTimeline(company_id, task_id, event_name, user_id, "In Progress", start_date, end_date, period);
  }

  async function updateToApproved(task_id) {
    setLoadingSubmit(true);

    //async function updateToApproved(task_id, company_id, event_name, user_id, start_date, end_date, period) {
    // update task to approved
    // no attachment
    //await handleUpload();
    //console.log('attachment id >>>', attachment_id);
    await taskSvc.updateTask(task_id, 3, null, ''); // Approved
    await initialTask();
    setLoadingSubmit(false);

    // not need to add timeline as it is done in DAL by task update
    //await timelineSvc.addTimeline(company_id, task_id, event_name, user_id, "Approved", start_date, end_date, period);
  }

  async function updateToRejected(task_id, reason) {
    setLoadingSubmit(true);

    //async function updateToRejected(task_id, company_id, event_name, user_id, start_date, end_date, period) {
    // update task to rejected
    // no attachment
    //await handleUpload();
    //console.log('attachment id >>>', attachment_id);
    await taskSvc.updateTask(task_id, 4, null, reason); // Rejected
    await initialTask();
    setLoadingSubmit(false);

    // not need to add timeline as it is done in DAL by task update
    //await timelineSvc.addTimeline(company_id, task_id, event_name, user_id, "Rejected", start_date, end_date, period);
  }

  async function updateToResubmitted(task_id) {
    setLoadingSubmit(true);

    // no attachment
    //await handleUpload();
    //console.log('attachment id >>>', attachment_id);
    await taskSvc.updateTask(task_id, 6, null, ''); // resubmitted
    setLoadingSubmit(false);
  }

  if (isLoading) return <CircularProgress />;

  const handleTaskDismiss = (res) => {
    console.log('task dismiss res >>>', res.data);

    if (res?.data) {
      updateToRejected(task.task_id, res.data);
    }
    setModal({ show: false, data: null });
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/tasks' })}
      >
        BACK TO TASKS
      </Button>
      <PageTitle title={`Task Detail (ID: ${taskId})`} />

      <Paper component={Box} mb={3} p={2}>
        <Grid container>
          <Grid item xs={6}>
            <Box
              style={{
                width: '100%',
              }}
            >
              <Box display={'flex'}>
                <Typography variant="body1" align="left">
                  Date: {moment(task.created_date).format(`DD/MM/yyyy`)}
                </Typography>
              </Box>
              <Box>
                <Box display="flex">
                  <Box mr={2}>Name:</Box>
                  {task.task_name}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" flexDirection={'column'} textAlign="right">
              <Typography variant="caption">Status</Typography>
              <b>{task.status_name}</b>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {task.attachment_id && (
        <Box mt={2}>
          <FileViewerButton2
            srcType="url"
            src={taskDocument?.signed_document_url}
            size="small"
            document={taskDocument}
          >
            View Task File
          </FileViewerButton2>
        </Box>
      )}

      {isTaskUpdatable && (toInProgress || toResubmitted) && (
        <>
          <Box mt={2}>
            <Button
              className={classes.formControl}
              variant="outlined"
              onChange={handleSelectFile}
              style={{
                width: 400,
                marginRight: 50,
              }}
              component="label"
            >
              Choose Files
              <input type="file" hidden ref={refSelectFile} />
            </Button>
          </Box>

          <label className={classes.formControl}>
            {isFilePicked ? (
              <div>
                <p>Filename: {selectedFile.name}</p>
                <p>Filetype: {selectedFile.type}</p>
              </div>
            ) : (
              <div>Select a file to show detail</div>
            )}
          </label>
        </>
      )}

      {isTaskUpdatable && toResubmitted && (
        <Box mt={2}>
          <Button
            className={classes.formControl}
            color="primary"
            variant="contained"
            component="span"
            style={{
              width: 150,
              marginRight: 50,
            }}
            onClick={() => {
              //updateToResubmitted(task.task_id);
              updateToInProgress(task.task_id);
            }}
          >
            Resubmit
          </Button>
        </Box>
      )}
      {isTaskUpdatable && toInProgress && (
        <Button
          className={classes.formControl}
          color="primary"
          variant="contained"
          component="span"
          style={{
            width: 150,
            marginTop: 25,
            marginBottom: 25,
            marginRight: 50,
          }}
          onClick={() => {
            updateToInProgress(task.task_id);
          }}
        >
          In Progress
        </Button>
      )}
      {isTaskUpdatable && toApproved && (
        <Button
          className={classes.formControl}
          color="primary"
          variant="contained"
          component="span"
          style={{
            width: 150,
            marginTop: 25,
            marginBottom: 25,
            marginRight: 50,
          }}
          onClick={() => {
            updateToApproved(task.task_id);
          }}
        >
          Approve
        </Button>
      )}
      {isTaskUpdatable && toRejected && (
        <Button
          className={classes.formControl}
          color="primary"
          variant="contained"
          component="span"
          style={{
            width: 150,
            marginTop: 25,
            marginBottom: 25,
            marginRight: 50,
          }}
          onClick={() => {
            setModal({ show: true, data: null });
            //updateToRejected(task.task_id);
          }}
        >
          Reject
        </Button>
      )}
      <CustomModal
        show={modal.show}
        onHide={handleTaskDismiss}
        scrollable={false}
        centered={true}
        title={'Rejected Task'}
      >
        <Rejected task={modal?.data} onDismiss={handleTaskDismiss} />
      </CustomModal>
      <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
