import { Button, Grid, Toolbar, Typography, Box, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import productSvc from '../../services/product.service';
import documentSvc from '../../services/document.service';

import Table from './components/Table/Table';
import ProductDetail from './Detail';
import CustomModal from '../../components/CustomModal';
import useStyles from './styles';

const defaultProductForm = { show: false, data: null };
export default function Products(props) {
  const { productCategoryId, title } = props;
  const classes = useStyles();
  const history = useHistory();
  const [productForm, setProductForm] = useState(defaultProductForm);
  // const { showSuccessSnackbar, showErrorSnackbar } = useAppSnackBar();
  const [isLoading, setLoading] = useState(true);
  const [productList, setProductList] = useState([]);

  const handleProductFormOpen = (value = null) => {
    setProductForm({ show: true, data: value });
  };
  const handleProductFormClose = (value = null) => {
    setProductForm(defaultProductForm);
  };

  const fetchProducts = async () => {
    setLoading(true);
    let products;
    try {
      products = await productSvc.products();
      if (productCategoryId) {
        products = products.filter((anItem) => {
          return anItem.product_category_id == productCategoryId;
        });
      }
      setProductList(products);
    } catch (error) {
      console.log('error', error);
      setProductList([]);
    }
    setLoading(false);

    return products;
  };
  useEffect(() => {
    (async () => {
      await fetchProducts();
    })();
  }, [productCategoryId]);

  if (productList?.length > 0) {
    productList.sort((a, b) => {
      return b.id - a.id;
    });
  }
  // const handleDetailDismiss = (res) => {
  //   console.log(res);
  //   if (res.data) {
  //     const index = productList.findIndex((p) => p.id === res.data.id);

  //     if (index >= 0) {
  //       setProductList([...productList.map((p) => (p.id === res.data.id ? res.data : p))]);
  //     } else {
  //       setProductList([res.data, ...productList]);
  //     }
  //   }
  //   setProductForm({ show: false, data: null });
  // };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>

      <PageTitle
        title={title || 'Products'}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleProductFormOpen();
            }}
          >
            CREATE PRODUCT
          </Button>
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Paper component={Box}>
              <Table
                data={productList}
                onViewDetail={(data) => {
                  handleProductFormOpen(data);
                }}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
      <CustomModal
        show={productForm.show}
        onHide={handleProductFormClose}
        title={
          productForm?.data
            ? `${productForm?.data?.name} (ID: ${productForm?.data?.id})`
            : 'New Product'
        }
        scrollable={false}
      >
        {productForm.show ? (
          <ProductDetail
            product={productForm?.data}
            onHide={handleProductFormClose}
            refetch={fetchProducts}
            show={productForm.show}
            productCategoryId={productCategoryId}
          />
        ) : null}
      </CustomModal>
    </>
  );
}
