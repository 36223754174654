import { Chip, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};

export default function TableComponent({ data, onViewDetail }) {
  const history = useHistory();
  var keys = ['ID', 'NAME', 'CATEGORY', 'DESCRIPTION', 'PRICE', ''];
  const getSortData = (list, key) => {
    return list.slice().sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  };

  data = getSortData(data || [], 'company_application_id');
  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map((key) => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((prod) => (
          <TableRow key={prod.id}>
            <TableCell className="pl-3 fw-normal">{prod.id}</TableCell>
            <TableCell className="pl-3 fw-normal">{prod.name}</TableCell>
            <TableCell className="pl-3 fw-normal">{prod.product_category}</TableCell>

            <TableCell>{prod.description}</TableCell>
            <TableCell>{prod.price}</TableCell>
            <TableCell
              onClick={() => {
                onViewDetail(prod);
              }}
            >
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
