import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import useFetchData from "../useFetchData";
import userApi from "../../../services/user.service";
import companyApi from "../../../services/company.service";
import CustomModal from "../../../components/CustomModal";
import CustomMenu from "../../../components/CustomMenu";
import { useUserState } from "../../../context/UserContext";

const LinkUserModal = (props) => {
  const {
    unavailableCompanyUserIds = [],
    refetchData,
    title = "",
    show = false,
    onHide,
    children,
  } = props;

  const theme = useTheme();
  const { userId: authUserId } = useUserState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCompanyUser, setSelectedCompanyUser] = useState();
  const showModal = Boolean(show);

  const [
    fetchIndividuals,
    {
      data: individualsData,
      error: errorIndividuals,
      loading: loadingIndividuals,
    },
  ] = useFetchData(
    {
      callback: async () => await userApi.getUsers("", 1, 100000),
    },
    true
  );

  const [
    fetchCompanies,
    { data: companiesData, error: errorCompanies, loading: loadingCompanies },
  ] = useFetchData(
    {
      callback: async () => await companyApi.getCompanies("", 1, 100000),
    },
    true
  );
  const [
    getCompanyUsers,
    {
      data: companyUsersData,
      error: errorUserCompany,
      loading: loadingUserCompany,
    },
  ] = useFetchData({}, true);

  let companyUsers = companyUsersData?.userCompanyRoles || [];
  let companyUserOptions = companyUsers.map((anItem) => {
    return {
      label: `${anItem.first_name}${
        anItem.last_name ? ` ${anItem.last_name}` : ""
      }${anItem.nric ? ` (NRIC: ${anItem.nric})` : ""}${
        anItem.passport ? ` (PASSPORT: ${anItem.passport})` : ""
      }`,
      value: anItem.user_id,
    };
  });

  const [
    linkUser,
    { error: errorLinkUser, loading: loadingLinkUser },
  ] = useFetchData({}, true);

  let individuals = individualsData?.users || [];

  if (
    unavailableCompanyUserIds &&
    unavailableCompanyUserIds.length > 0 &&
    individualsData?.users?.length > 0
  ) {
    individuals = [];

    individualsData?.users.forEach((anItem) => {
      if (unavailableCompanyUserIds.indexOf(anItem.user_id) < 0) {
        individuals.push(anItem);
      }
    });
  }

  let companies = companiesData?.companies || [];
  const companyOptions = companies.map((anItem) => {
    return {
      label: `${anItem.company_name}${
        anItem.company_registration_no
          ? ` (Registration No: ${anItem.company_registration_no})`
          : ""
      }`,
      value: anItem.company_id,
    };
  });
  useEffect(() => {
    if (showModal) {
      if (fetchIndividuals) {
        (async () => {
          await fetchIndividuals();
        })();
      }
      if (fetchCompanies) {
        (async () => {
          await fetchCompanies();
        })();
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanyUser();
      getCompanyUsers({
        callback: async () =>
          await companyApi.getCompanyOfficers(selectedCompany),
      });
    }
  }, [selectedCompany]);

  const handleOnClose = () => {
    setSelectedCompanyUser();
    if (onHide) {
      onHide();
    }
  };

  const handleOnSelectUser = (user) => {
    setSelectedCompanyUser(user);
  };

  const handleOnLinkUser = async () => {
    if (authUserId && selectedCompany && selectedCompanyUser && show?.user_id) {
      let foundSelectedUserObj = companyUsers.find(
        (anItem) => anItem.user_id == selectedCompanyUser
      );
      if (foundSelectedUserObj) {
        linkUser({
          callback: async () =>
            await userApi.linkCompanyUserToSysInfoUser(show.user_id, {
              company_user_id: foundSelectedUserObj.user_id,
              role_id: 1, // for now this is set value 1 (owner)
              username: foundSelectedUserObj?.username || "",
              company_id: selectedCompany,
              user_id: authUserId, // in normal condition, invitation user_id and created_by same value,  remove this before sending
              created_by: authUserId,
            }),
        })
          .then((result) => {
            if (result?.is_success) {
              refetchData();
              handleOnClose();
            }
          })
          .catch((error) => {
            console.log("linkCompanyUserToSysInfoUser error", error);
          });
      }
    }
  };

  return (
    <CustomModal
      show={showModal}
      onHide={handleOnClose}
      loading={loadingIndividuals}
      title={
        <Typography variant="h5">
          {show.username} (ID: {show.user_id})
        </Typography>
      }
      footer={
        <>
          <Button
            variant="contained"
            onClick={handleOnClose}
            style={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedCompanyUser}
            onClick={handleOnLinkUser}
          >
            {loadingLinkUser ? <CircularProgress /> : "Link Now"}
          </Button>
        </>
      }
    >
      <Box mb={2}>
      <CustomMenu
        data={companyOptions}
        value={selectedCompany}
        label="Company"
        onChange={(event) => {
          setSelectedCompany(event.target.value);
        }}
        searchable={true}
      />

      </Box>
      <CustomMenu
        data={companyUserOptions}
        value={selectedCompanyUser}
        label="Users"
        onChange={(event) => {
          setSelectedCompanyUser(event.target.value);
        }}
        searchable={true}
        disabled={
          companyUserOptions && companyUserOptions.length > 0 ? false : true
        }
      />
      {/* {individuals && individuals.length > 0 ? (
        <List>
          {individuals.map((anItem, index) => {
            let isSelected = selectedCompanyUser?.user_id == anItem.user_id;

            return (
              <ListItem
                key={index}
                button
                onClick={() => {
                  handleOnSelectUser(anItem);
                }}
                selected={isSelected}
              >
                <ListItemText>
                  {anItem.username} (ID: {anItem.user_id})
                </ListItemText>
                {isSelected && (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" disabled={true}>
                      <CheckIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box>No Data</Box>
      )} */}
    </CustomModal>
  );
};

export default LinkUserModal;
