import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';

import userApi from '../../services/user.service';
import commonApi from '../../services/common.service';
import CustomTable from '../../components/CustomTable';
import CustomMenu from '../../components/CustomMenu';
import useQueryParams from '../../hooks/useQueryParams';
import useCommonOptions from '../../hooks/useCommonOptions';

export default function Dashboard(props) {
  var classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();

  // local
  const [isLoading, setLoading] = useState(true);
  var [userList, setUserlist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const respUserslist = await userApi.getUsers('', 1, 100000);
      let individuals = respUserslist?.data?.users || [];
      individuals.sort((a, b) => {
        return b?.user_id - a?.user_id;
      });
      setUserlist(individuals);

      //   setTimeout(function () {}, 2000);
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  const {
    options: countryOptions,
    loading: loadingCountries,
    rawData: countries,
  } = useCommonOptions(commonApi.getCountry, {
    respondKey: 'countries',
    labelKey: 'country_name',
    valueKey: 'country_id',
  });

  const filters = [
    {
      label: 'First Name / Last Name / Email / Identification Number',
      valueKey: 'search',
      type: 'text',
    },
    {
      label: 'Country',
      valueKey: 'nationality_id',
      type: 'select',
      options: countryOptions,
    },
  ];
  let filterValues = {};
  filters.forEach((anItem) => {
    let value = queryParams.get(anItem.valueKey);
    if (value) {
      filterValues[anItem.valueKey] = queryParams.get(anItem.valueKey);
    }
  });

  const handleFilterOnChange = (key, value) => {
    if (queryParams.get(key) == value) {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }
    let newQueryParams = queryParams.toString();
    history.replace({
      pathname: '/individual',
      search: '?' + newQueryParams,
    });
  };

  const handleClearFilter = () => {
    history.replace({
      pathname: '/individual',
    });
  };

  let filteredIndividuals = userList.filter((aUser) => {
    let filterKeys = Object.keys(filterValues);
    let result = true;

    filters.forEach((aFilter) => {
      let filterKey = aFilter.valueKey;
      let filterKeyValue = filterValues?.[filterKey];
      if (filterKeyValue != undefined && filterKeyValue != null && filterKeyValue != '') {
        if (aFilter.type == 'text') {
          let lowercaseFilterValue = (filterKeyValue || '').toLocaleLowerCase();
          let foundFirstName =
            (aUser?.['first_name'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >= 0;
          let foundLastName =
            (aUser?.['last_name'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >= 0;
          let foundEmail =
            (aUser?.['email'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >= 0;
          let foundNRIC =
            (aUser?.['nric'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >= 0;
          let foundPassport =
            (aUser?.['passport'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >= 0;

          if (!foundFirstName && !foundLastName && !foundEmail && !foundNRIC && !foundPassport) {
            result = false;
          }
        } else {
          if (aUser[filterKey] != filterKeyValue) {
            result = false;
          }
        }
      }
    });

    return result;
  });

  const tableColumns = [
    {
      id: 'user_id',
      label: 'ID',
    },
    {
      id: 'first_name',
      label: 'FIRST NAME',
      render: (text) => {
        return text || '_';
      },
    },
    {
      id: 'last_name',
      label: 'LAST NAME',
      render: (text) => {
        return text || '_';
      },
    },
    {
      id: 'email',
      label: 'EMAIL',
      render: (text) => {
        return text || '_';
      },
    },
    {
      id: 'nric',
      label: 'IDENTIFICATION NUMBER',
      render: (text, data) => {
        if (data?.nric) {
          return (
            <div>
              <div>
                <small>NRIC</small>
              </div>
              <div>{data.nric}</div>
            </div>
          );
        } else if (data?.passport) {
          return (
            <div>
              <div>
                <small>Passport</small>
              </div>
              <div>{data.passport}</div>
            </div>
          );
        }
        return '_';
      },
    },
    {
      id: 'nationality',
      label: 'COUNTRY',
    },
    {
      id: 'action',
      render: (_, data) => {
        return (
          <Button variant="contained" color="primary" size="small" component="div">
            <Link
              to={{
                pathname: '/individualdetails',
                search: `?user=${data?.user_id}`,
              }}
              style={{ color: '#fff', textDecoration: 'none' }}
            >
              EDIT
            </Link>
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Individual"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => {
              history.push({
                pathname: '/form',
                //   search: "?company=" + company_id,
                state: {
                  // company_id: localStorage.getItem("company_id"),
                  // attachment_type_id: "1",
                  // role_id: post.role_id,
                  // company_name: localStorage.getItem("company_name"),
                  // attachment_type_name: "Appointment Letter",
                  // role_name: post.title,
                },
              });
            }}
          >
            Add New Individual
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb={2} border={1} p={2} borderRadius={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <b>Filter by: </b>
                      </Box>
                      {Object.keys(filterValues)?.length > 0 ? (
                        <Box>
                          <Button
                            onClick={handleClearFilter}
                            size="small"
                            color={'secondary'}
                            variant="outlined"
                          >
                            Clear Filter
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  {filters.map((anItem, index) => {
                    if (anItem.type == 'text') {
                      return (
                        <Grid key={index} item xs={12} md={6}>
                          <TextField
                            label={anItem.label}
                            variant="outlined"
                            value={filterValues?.[anItem.valueKey] || ''}
                            onChange={(e) => {
                              let value = e.target.value;
                              handleFilterOnChange(anItem.valueKey, value);
                            }}
                            fullWidth={true}
                            size="small"
                          />
                        </Grid>
                      );
                    } else if (anItem.type == 'select') {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <CustomMenu
                            label={anItem.label}
                            data={anItem.options}
                            value={filterValues?.[anItem.valueKey] || ''}
                            // label="Status"
                            onChange={(e) => {
                              let value = e.target.value;
                              handleFilterOnChange(anItem.valueKey, value);
                            }}
                            variant="outlined"
                            size="small"
                            // searchable={true}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Box>
              <CustomTable columns={tableColumns} data={filteredIndividuals} rowKey="user_id" />
            </>
          )}
          {/* <Pagination count={10} /> */}
        </Grid>
      </Grid>
    </>
  );
}
