import shareApi from "./shareapi";

const createShareCapital = (companyId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareApi.post(`/companies/` + companyId + `/shares`, data);
};

const createTreasuryShare = (companyId, shareId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareApi.post(
    `/companies/` + companyId + `/shares/` + shareId + `/treasuryshares`,
    data
  );
};

const getShareCapital = (companyId) => {
  return shareApi.get(`/companies/` + companyId + `/shares`);
};

const getTreasuryShare = (companyId, shareId) => {
  return shareApi.get(
    `/companies/` + companyId + `/shares/` + shareId + `/treasuryshares`
  );
};

const updateShareCapital = (companyId, shareId, data) => {
  console.log("sharecapital", companyId, shareId, data);

  return shareApi.put(`/companies/` + companyId + `/shares/` + shareId, data);
};

const updateTreasuryShare = (companyId, shareId, treasurysharesId, data) => {
  return shareApi.put(
    `/companies/` +
      companyId +
      `/shares/` +
      shareId +
      `/treasuryshares/` +
      treasurysharesId,
    data
  );
};

export default {
  createShareCapital,
  createTreasuryShare,
  getShareCapital,
  getTreasuryShare,
  updateShareCapital,
  updateTreasuryShare,
};
