import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Box, Chip } from '@material-ui/core';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import CustomTable from '../../../../components/CustomTable';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomRadioGroupField from '../../../../components/CustomFormFields/CustomRadioGroupField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import CustomPhoneNumberField from '../../../../components/CustomFormFields/CustomPhoneNumberField';

import userApi from '../../../../services/user.service';
import useCommonOptions from '../../../../hooks/useCommonOptions';

const sysInfoUserRoleOptions = [
  {
    value: 1,
    label: 'Super Admin',
  },
  {
    value: 2,
    label: 'View & Make Request',
  },
  {
    value: 3,
    label: 'View Only',
  },
  {
    value: 4,
    label: 'Request Only',
  },
];

let defaultAdminsTableColumns = [
  {
    label: 'EMAIL',
    id: 'sysinfo_role_username',
    render: (text, data) => {
      return text || data?.email || '-';
    },
  },
  {
    label: 'FIRST NAME',
    id: 'first_name',
    render: (text) => {
      return text || '-';
    },
  },
  {
    label: 'LAST NAME',
    id: 'last_name',
    render: (text) => {
      return text || '-';
    },
  },
  {
    label: 'CONTACT NUMBER',
    id: 'contact_number',
    render: (text) => {
      return text || '-';
    },
  },
];

export const SuperAdminTable = (props) => {
  const {
    data = [],
    onEdit,
    disabled = false,
    canResendEmail = false,
    onSendEmail,
    ...restProps
  } = props;

  const routeHistory = useHistory();

  const handleOnEdit = (data) => {
    if (onEdit) {
      onEdit(data);
    }
  };

  const handleOnSendEmail = async (value) => {
    if (onSendEmail) {
      await onSendEmail(value);
    }
  };

  let resendEmailColumn = canResendEmail
    ? [
        {
          label: 'Resend Email',
          id: 'resendEmail',
          render: (_, record) => {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOnSendEmail(record);
                }}
                disabled={false}
              >
                Resend Email
              </Button>
            );
          },
        },
      ]
    : [];

  let adminsTableColumns = defaultAdminsTableColumns;
  if (onEdit) {
    adminsTableColumns = [
      {
        label: '',
        id: '',
        render: (_, data) => {
          console.log('data', data);
          return (
            <Box mb={2} display="flex" alignItems="center">
              <Box flexGrow={1} mr={1}>
                <Chip
                  color="secondary"
                  onClick={() => handleOnEdit(data)}
                  clickable
                  label={data?.sysinfo_user_id ? 'EDIT ROLE' : 'EDIT'}
                  disabled={disabled}
                />
              </Box>
              {data?.sysinfo_user_id ? (
                <Chip
                  onClick={() => {
                    routeHistory.push(`/sysinfo-users?id=${data?.sysinfo_user_id}`);
                  }}
                  clickable
                  label="EDIT DETAIL"
                />
              ) : null}
              {/* <Button
                  variant="contained"
                  onClick={() => {
                    routeHistory.push(`/sysinfo-users?id=${data?.sysinfo_user_id}`);
                  }}
                  size="small"
                >
                  Edit Detail
                </Button> */}
            </Box>
          );
        },
      },
      ...resendEmailColumn,
      {
        label: 'Role',
        id: 'role_id',
        render: (text) => {
          let foundRole = sysInfoUserRoleOptions.find((anItem) => anItem.value == text);
          return foundRole ? foundRole.label : '-';
        },
      },
      ...defaultAdminsTableColumns,
    ];
  }

  return <CustomTable data={data} columns={adminsTableColumns} rowKey={'user_id'} {...restProps} />;
};

export const validationSchemaObj = {
  is_existing_user: Yup.string().default('1'),
  user_id: Yup.number(),
  role_id: Yup.string(),
  // username: Yup.string(),
  first_name: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('First Name is required'),
  }),
  last_name: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Last Name is required'),
  }),
  email: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Email Address is required'),
  }),
  contact_number: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Contact Number is required'),
  }),
  // gender_id: Yup.string()
  //   .default("1")
  //   .when("user_id", {
  //     is: "0",
  //     then: Yup.string().required("Gender is required"),
  //   }),
  // dob: Yup.string(),
  // birth_country_id: Yup.string(),
  // nationality_id: Yup.string(),
  // password: Yup.string(),
};

let validationSchema = Yup.object(validationSchemaObj);

export const fields = {
  is_existing_user: {
    name: 'is_existing_user',
    label: '',
  },
  user_id: {
    name: 'user_id',
    label: 'User',
  },
  role_id: {
    name: 'role_id',
    label: 'Role',
  },
  first_name: {
    name: 'first_name',
    label: 'First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
  },
  email: {
    name: 'email',
    label: 'Email Address',
  },
  contact_number: {
    name: 'contact_number',
    label: 'Contact Number',
  },
  gender_id: {
    name: 'gender_id',
    label: 'Gender',
  },
  dob: {
    name: 'dob',
    label: 'Date Of Birth',
  },
  birth_country_id: {
    name: 'birth_country_id',
    label: 'Country Of Birth',
  },
  nationality_id: {
    name: 'nationality_id',
    label: 'Nationality',
  },
};

export const SuperAdminModalForm = (props) => {
  // unique key for all records: user_id/sysinfo user role id
  const {
    data,
    show = false,
    onHide,
    title,
    disabled = false,
    onSubmit,
    onRemove,
    superAdmins = [],
  } = props;

  const [loading, setLoading] = useState(false);
  let isNew = !Boolean(data);
  let defaultValues = isNew ? validationSchema.cast() : data;

  const {
    options: sysInfoUserOptions,
    loading: loadingSysInfoUsers,
    rawData: sysInfoUsers = [],
    refetch: fetchSysInfoUsers,
  } = useCommonOptions(userApi.getSysInfoUsers, {
    respondKey: 'users',
    labelKey: 'email',
    valueKey: 'user_id',
    render: (_, data) => {
      return `${data.email} (${data.first_name} ${data.last_name})`;
    },
    lazyLoad: true,
  });

  let filteredSysInfoUserOptions = sysInfoUserOptions;

  if (superAdmins?.length > 0) {
    let adminIds = superAdmins.map((anItem) => anItem.user_id);
    if (isNew) {
      filteredSysInfoUserOptions = filteredSysInfoUserOptions.filter((anItem) => {
        let foundAdded = adminIds.indexOf(anItem.value) >= 0;
        return !foundAdded;
      });
    } else {
      filteredSysInfoUserOptions = filteredSysInfoUserOptions.filter((anItem) => {
        if (anItem.value != data?.user_id) {
          let foundAdded = adminIds.indexOf(anItem.value) >= 0;
          return !foundAdded;
        }
        return true;
      });
    }
  }

  useEffect(() => {
    (async () => {
      if (show) {
        try {
          setLoading(true);
          if (fetchSysInfoUsers) {
            await fetchSysInfoUsers();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [show]);

  const handleOnSubmit = (values) => {
    let newBody = values;
    let selectedUserId = values[fields.user_id.name];
    if (selectedUserId) {
      let foundSysInfoUser = sysInfoUsers?.find((anItem) => {
        return anItem[fields.user_id.name] == values[fields.user_id.name];
      });
      if (foundSysInfoUser) {
        newBody = {
          ...newBody,
          [fields.email.name]: (newBody?.[fields.email.name] || '').trim(),
          ...foundSysInfoUser,
        };
      }
    }
    if (onSubmit) {
      onSubmit(newBody);
    }
  };

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(data);
    }
  };
  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={title || isNew ? 'New Admin' : 'Edit Admin Role'}
    >
      {loading ? (
        'Loading'
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(props) => {
            const { values, handleSubmit, setValues } = props;
            let isExistingUser = values[fields.is_existing_user.name] == '1' || !isNew;
            return (
              <form onSubmit={handleSubmit}>
                {isNew && (
                  <Box mb={2}>
                    <CustomRadioGroupField
                      name={fields.is_existing_user.name}
                      label={fields.is_existing_user.label}
                      options={[
                        {
                          label: 'Existing Meyzer360 User',
                          value: '1',
                        },
                        {
                          label: 'New Meyzer360 User',
                          value: '0',
                        },
                      ]}
                      // required={true}
                    />
                  </Box>
                )}
                {isExistingUser ? (
                  <Box mb={2}>
                    <CustomSelectField
                      name={fields.user_id.name}
                      label={fields.user_id.label}
                      options={filteredSysInfoUserOptions}
                      disabled={!isNew}
                    />
                  </Box>
                ) : null}
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.role_id.name}
                    label={fields.role_id.label}
                    options={sysInfoUserRoleOptions}
                    // required={true}
                  />
                </Box>
                {isExistingUser ? null : (
                  <>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.first_name.name}
                        label={fields.first_name.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.last_name.name}
                        label={fields.last_name.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.email.name}
                        label={fields.email.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomPhoneNumberField
                        label={fields.contact_number.label}
                        name={fields.contact_number.name}
                      />
                    </Box>
                    {/* <Box mb={2}>
                        <CustomRadioGroupField
                          name={fields.gender_id.name}
                          label={fields.gender_id.label}
                          options={genderList}
                          // required={true}
                        />
                      </Box>
                      <Box mb={2}>
                        <CustomTextField
                          name={fields.dob.name}
                          label={fields.dob.label}
                          type="date"
                        />
                      </Box>
                      <Box mb={2}>
                        <CustomSelectField
                          name={fields.birth_country_id.name}
                          label={fields.birth_country_id.label}
                          options={countrylist}
                        />
                      </Box>
                      <Box mb={2}>
                        <CustomSelectField
                          name={fields.nationality_id.name}
                          label={fields.nationality_id.label}
                          options={countrylist}
                        />
                      </Box> */}
                  </>
                )}

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && onRemove && (
                      <Box ml={2}>
                        <Button variant="contained" onClick={handleOnRemove}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );
};
