import React, { useState, useEffect } from 'react';
import { Button, Box, Chip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import CustomTable from '../../../../components/CustomTable';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import companyApi from '../../../../services/company.service';
import commonApi from '../../../../services/common.service';

export const CompanyOfficerTable = ({
  data = [],
  onEdit,
  disabled = false,
  companyRoleOptions = [],
  ...restProps
}) => {
  const handleOnEdit = (data) => {
    if (onEdit) {
      onEdit(data);
    }
  };
  const officerTableColumns = [
    {
      id: 'row_id',
      label: '',
      render: (text, data) => {
        return (
          <Chip
            color="secondary"
            onClick={() => handleOnEdit(data)}
            clickable
            label={'EDIT'} //+ row_id}
            disabled={disabled}
          />
        );
      },
    },
    {
      label: 'FIRST NAME',
      id: 'first_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'LAST NAME',
      id: 'last_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'IDENTIFICATION',
      id: 'nric',
      render: (_, record) => {
        const { nric: user_nric, passport: user_passport } = record;
        return user_nric !== null && user_nric !== undefined
          ? 'NRIC ' + user_nric
          : user_passport !== null && user_passport !== undefined
          ? 'Passport ' + user_passport
          : '-';
      },
    },
    {
      label: 'COMPANY ROLE',
      id: 'company_role_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'DATE OF APPOINTMENT',
      id: 'date_of_appointment',
      render: (text) => {
        return moment(text).format('DD MMM YYYY') || '-';
      },
    },
    {
      label: 'CESSATION',
      id: 'cessation_type_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'CESSATION DATE',
      id: 'cessation_date',
      render: (text) => {
        return text !== '0000-00-00' && text !== null && text != undefined
          ? moment(text).format('DD MMM YYYY') || '-'
          : '-';
      },
    },
  ];

  return (
    <CustomTable
      data={data}
      columns={officerTableColumns}
      rowKey={'user_company_role_id'}
      {...restProps}
      // rowExtraDisplay={(rowData, rowIndex) => {
      //   return (
      //     <div>
      //       {rowData?.isNew ? <div>New</div> : rowData?.isEdited ? <div>Edited</div> : null}
      //     </div>
      //   );
      // }}
    />
  );
};

export const fields = {
  user_id: {
    name: 'user_id',
    label: 'Officer',
  },
  company_role_id: {
    name: 'company_role_id',
    label: 'Company Role',
  },
  date_of_appointment: {
    name: 'date_of_appointment',
    label: 'Date of Appointment',
  },
  cessation_type: {
    name: 'cessation_type_id',
    label: 'Cessation Type',
  },
  cessation_date: {
    name: 'cessation_date',
    label: 'Date of Cessation',
  },
};

//     nominee_id: officerData.nominee_id === 'null' ? null : officerData.nominee_id,
//     nominator_id: officerData.nominator_id === 'null' ? null : officerData.nominator_id,
//     controller_id: officerData.controller_id === 'null' ? null : officerData.controller_id,
//     named_secretary_id:
//       officerData.named_secretary_id === 'null' ? null : officerData.named_secretary_id,

export const validationSchemaObj = {
  user_id: Yup.string().required(`${fields.user_id.label} is required`).default(''),
  company_role_id: Yup.string().required(`${fields.company_role_id.label} is required`).default(''),
  date_of_appointment: Yup.string()
    .required(`${fields.date_of_appointment.label} is required`)
    .default(''),
  cessation_type_id: Yup.string().default(''),
  cessation_date: Yup.string().default(''),
};
let validationSchema = Yup.object(validationSchemaObj);

// unique key for all records: user_company_role_id
export const CompanyOfficerModalForm = ({
  data: defaultData,
  show = false,
  onHide,
  disabled = false,
  title,
  onSubmit,
  onRemove,

  userData = [],
}) => {
  let data = {
    ...defaultData,
  };
  let appointmentDateValue = defaultData?.[fields.date_of_appointment.name];
  let dateCessationValue = defaultData?.[fields.cessation_date.name];
  if (appointmentDateValue) {
    data[fields.date_of_appointment.name] =
      moment(appointmentDateValue).format('YYYY-MM-DD') || null;
  }
  if (dateCessationValue) {
    data[fields.cessation_date.name] = moment(dateCessationValue).format('YYYY-MM-DD') || null;
  }

  let isNew = !Boolean(defaultData);
  let defaultValues = isNew ? validationSchema.cast() : data;

  const [loading, setLoading] = useState(false);

  const {
    options: companyRoleOptions,
    loading: loadingCompanyRoles,
    rawData: companyRoles = [],
    refetch: fetchCompanyRoles,
  } = useCommonOptions(commonApi.getCompanyRole, {
    respondKey: 'companyroles',
    labelKey: 'role_name',
    valueKey: 'company_role_id',
    lazyLoad: true,
  });

  const {
    options: cessationTypeOptions,
    loading: loadingCessationTypes,
    rawData: cessationTypes = [],
    refetch: fetchCessationTypes,
  } = useCommonOptions(commonApi.getCessationType, {
    respondKey: 'cessationtypes',
    labelKey: 'cessation_type_name',
    valueKey: 'cessation_type_id',
    lazyLoad: true,
  });

  useEffect(() => {
    (async () => {
      if (show) {
        try {
          setLoading(true);
          if (fetchCompanyRoles) {
            await fetchCompanyRoles();
          }
          if (fetchCessationTypes) {
            await fetchCessationTypes();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [show]);

  const handleOnSubmit = (values) => {
    let foundUser = userData.find((anItem) => anItem.user_id == values[fields.user_id.name]) || {};
    let foundCompanyRole =
      companyRoles.find(
        (anItem) => anItem.company_role_id == values[fields.company_role_id.name]
      ) || {};
    let newCompanyRole = {};
    if (foundCompanyRole) {
      newCompanyRole = {
        company_role_id: foundCompanyRole?.company_role_id,
        company_role_name: foundCompanyRole?.role_name,
      };
    }
    let foundCessationType =
      cessationTypes.find((anItem) => anItem.cessation_type_id == values.cessation_type_id) || {};
    let newBody = {
      ...foundUser,
      ...values,
      ...newCompanyRole,
      ...foundCessationType,
    };
    if (onSubmit) {
      onSubmit(newBody);
    }
  };

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(data);
    }
  };
  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  const userOptions =
    userData?.map((anItem) => {
      return {
        label: `${anItem?.first_name}${anItem?.last_name ? ' ' + anItem?.last_name : ''} (${
          anItem.nric ? `NRIC - ${anItem.nric}` : `Passport - ${anItem.passport}`
        })`,
        value: anItem.user_id,
      };
    }) || [];

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={title ? title : `${isNew ? 'Add' : 'Edit'} Company Officer`}
    >
      {loading ? (
        'Loading'
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(props) => {
            const { handleSubmit } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.user_id.name}
                    label={fields.user_id.label}
                    options={userOptions}
                    required={true}
                    disabled={!isNew}
                  />
                </Box>
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.company_role_id.name}
                    label={fields.company_role_id.label}
                    options={companyRoleOptions}
                    required={true}
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    name={fields.date_of_appointment.name}
                    label={fields.date_of_appointment.label}
                    type="date"
                    required={true}
                  />
                </Box>
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.cessation_type.name}
                    label={fields.cessation_type.label}
                    options={cessationTypeOptions}
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    name={fields.cessation_date.name}
                    label={fields.cessation_date.label}
                    type="date"
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && onRemove && (
                      <Box ml={2}>
                        <Button variant="contained" onClick={handleOnRemove}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );
};
