const defaultDocumentGroups_bk = [
  {
    document_type_id: 5,
    label: 'Financial Statements',
    data: [],
  },
  // {
  //   document_type_id: 12,
  //   label: 'Audited Report',
  //   data: [],
  // },
  // {
  //   document_type_id: 57,
  //   label: 'Management Accounts',
  //   data: [],
  // },
  {
    document_type_id: 28,
    label: 'Constitutional Documents / M&A',
    data: [],
  },
  {
    document_type_id: 143,
    label: 'Register of Registrable Controllers (RORC)',
    data: [],
  },
  {
    document_type_id: 144,
    label: 'Register of Secretaries',
    data: [],
  },
  {
    document_type_id: 145,
    label: 'Register of Auditors',
    data: [],
  },
  {
    document_type_id: 146,
    label: 'Register of Members',
    data: [],
  },
  {
    document_type_id: 103,
    label: 'Share Certificate',
    data: [],
  },
  {
    document_type_id: 48,
    label: 'Form 45B',
    data: [],
  },
  {
    document_type_id: 117,
    label: 'Minutes and Resolutions',
    data: [],
  },
  {
    document_type_id: 2,
    label: 'AGM Notice & Letters',
    data: [],
  },
];

export const defaultDocumentGroups = [
  {
    document_type_id: 45,
    label: 'Financial Statements',
    otherIds: [5, 12, 57],
    data: [],
  },
  {
    document_type_id: 28,
    label: 'Constitutional Documents / M&A',
    otherIds: [58],
    data: [],
  },
  {
    document_type_id: 136,
    label: 'Register of Director and CEO',
    data: [],
  },
  {
    document_type_id: 121,
    label: 'Register of Directors and CEO Shareholdings and Other Interest',
    data: [],
  },
  {
    document_type_id: 122,
    label: 'Register of Managers',
    data: [],
  },
  {
    document_type_id: 144,
    label: 'Register of Secretaries',
    data: [],
  },
  {
    document_type_id: 145,
    label: 'Register of Auditors',
    data: [],
  },
  {
    document_type_id: 123,
    label: 'Register of Mortgages and Charges',
    data: [],
  },
  {
    document_type_id: 143,
    label: 'Register of Registrable Controllers (RORC)',
    data: [],
  },
  {
    document_type_id: 125,
    label: 'Register of Nominee Directors',
    data: [],
  },
  {
    document_type_id: 146,
    label: 'Register of Members',
    data: [],
  },
  {
    document_type_id: 126,
    label: 'Register of Debenture',
    data: [],
  },
  {
    document_type_id: 103,
    label: 'Share Certificate',
    data: [],
  },
  {
    document_type_id: 127,
    label: 'Directors Meetings and Resolutions',
    data: [],
  },
  {
    document_type_id: 128,
    label: 'Shareholders Meetings and Resolutions',
    data: [],
  },
  {
    document_type_id: 115,
    label: 'ACRA Filings',
    data: [],
  },
  {
    document_type_id: 119,
    label: 'Miscellaneous',
    data: [],
  },
  {
    document_type_id: 120,
    label: 'Scanned Mail',
    data: [],
  },
  {
    document_type_id: 159,
    label: 'KYC - Meyzer Corpsec (Operation)',
    data: [],
  },
  {
    document_type_id: 160,
    label: 'Agreements - Meyzer Corpsec (Operation)',
    data: [],
  },
  {
    document_type_id: 161,
    label: 'Correspondance - Meyzer Corpsec (Operation)',
    data: [],
  },
  {
    document_type_id: 162,
    label: 'Onboarding Digital Banking Users - Transwap (Operation)',
    data: [],
  },
  {
    document_type_id: 163,
    label: 'Invoices - Meyzer Corpsec (Operation)',
    data: [],
  },
];
