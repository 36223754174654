import { Grid, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import CloseIcon from '@material-ui/icons/Close';
import productSvc from '../../services/product.service';
import CustomLoading from '../../components/CustomLoading';
import { useUserState } from '../../context/UserContext';

const validationSchema = Yup.object({
  reason: Yup.string().required('reason is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Rejected({ task, onDismiss }) {
  var classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const user = useUserState();
  useEffect(() => {}, []);

  const formikOnSubmit = async (values, actions) => {
    console.log('onsubmit values >>>', values);
    onDismiss({ data: values.reason });
  };

  return (
    <>
      <Container>
        <Formik initialValues={{}} onSubmit={formikOnSubmit} validationSchema={validationSchema}>
          {(props) => (
            <Form>
              <CustomTextField
                name="reason"
                // icon="ic:round-email"
                label="Reason"
                placeholder=" "
                fullWidth
                shrink
                variant="standard"
                error
              />

              <CustomLoading loading={isLoading} />
              <Button
                color="primary"
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: !(props.isValid && props.dirty) ? null : '#DEAD00',
                  color: '#FFFFFF',
                  borderRadius: 50,
                  width: 120,
                  textTransform: 'initial',
                  fontWeight: 600,
                }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
