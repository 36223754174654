import React, { useRef, useMemo, useEffect, useCallback } from "react";
import { connect, getIn } from "formik";

export function useThrottle(fun, timeout) {
  const timer = useRef(null);

  const cancel = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);

      timer.current = null;
    }
  }, [timer]);

  useEffect(() => {
    cancel();
  }, [cancel]);

  return (...args) => {
    cancel();

    timer.current = setTimeout(() => {
      timer.current = null;

      fun.apply(this, args);
    }, timeout);
  };
}

const FormikDummyField = ({ field, onChange, timeout, formik }) => {
  const value = useMemo(() => getIn(formik.values, field), [
    field,
    formik.values,
  ]);

  const handle = useThrottle(onChange, timeout);

  useEffect(() => {
    handle(value, formik);
  }, [value, onChange]);

  return null;
};

export default connect(FormikDummyField);
