import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import CustomModal from '../../../../components/CustomModal';

import LogTimelineForm from '../../LogTimelineForm';
import logTimelineSvc from '../../../../services/logtimeline.service';

const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};
export default function TableComponent({ data, refetch }) {
  const history = useHistory();

  const [modal, setModal] = useState({ show: false, data: null });
  console.log('modal', modal);
  const handleDismiss = async (res) => {
    console.log('>>> edit log timeline <<<');
    console.log('log timeline dismiss res >>>', res.data);

    if (res?.data) {
      //      updateToRejected(order.task_id, res.data);

      if (refetch) {
        refetch();
      }
      // await logTimelineSvc.updateLogTimeline(
      //   res.data.log_timeline_id,
      //   res.data.company_id,
      //   res.data.timeline_date,
      //   res.data.action_type,
      //   res.data.action,
      //   res.data.notes,
      //   res.data.status,
      //   res.data.status_id
      // );
    }

    setModal({ show: false, data: null });
  };

  const handleOnSubmit = () => {
    if (refetch) {
      refetch();
    }
    setModal({ show: false, data: null });
  };
  var keys = [
    'ID',
    'COMPANY NAME',
    'TIMELINE DATE',
    'ACTION TYPE',
    'ACTION',
    'NOTES',
    'STATUS',
    'ACTION',
  ];
  const getSortData = (list, key) => {
    return list.slice().sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  };

  data = getSortData(data, 'log_timeline_id');
  console.log('logtimeline', data);
  return (
    <>
      <Table className="mb-0">
        <TableHead>
          <TableRow>
            {keys.map((key, index) => (
              <TableCell key={index}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((logtimeline, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{logtimeline.log_timeline_id}</TableCell>
              <TableCell className="pl-3 fw-normal">{logtimeline.company_name}</TableCell>
              <TableCell>
                {logtimeline.timeline_date
                  ? moment(logtimeline.timeline_date).format('YYYY-MM-DD')
                  : '-'}
              </TableCell>

              <TableCell>{logtimeline.action_type}</TableCell>
              <TableCell>{logtimeline.action}</TableCell>
              <TableCell>{logtimeline.notes}</TableCell>
              <TableCell>{logtimeline.status_name}</TableCell>
              <TableCell>
                <Chip
                  color="primary"
                  label="EDIT"
                  onClick={() => {
                    //alert('not implemented');
                    //                  history.push(`/logtimeline?logtimelineid=${logtimeline.log_timeline_id}`);
                    setModal({ show: true, data: logtimeline });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <CustomModal show={modal.show} onHide={handleDismiss} title="Log Timeline Form">
        <LogTimelineForm
          logTimeline={modal?.data}
          onDismiss={handleDismiss}
          onSubmit={handleOnSubmit}
        />
      </CustomModal>
    </>
  );
}
