import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Box,
} from '@material-ui/core';
import { Field } from 'formik';
import * as Yup from 'yup';
import useStyles from '../../styles';

import FormFieldContainer from '../../../../components/CustomFormFields/FormFieldContainer';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import commonApi from '../../../../services/common.service';

// share capital
export const fieldNames = {
  shareType_ordinary: {
    name: 'shareType_ordinary',
    label: 'Ordinary',
  },
  shareType_preference: {
    name: 'shareType_preference',
    label: 'Preference',
  },
  shareType_others: {
    name: 'shareType_others',
    label: 'Others',
  },
  share_type_id: {
    name: 'share_type_id',
    label: 'Share Types',
  },
  issued_share_capital: {
    name: 'issued_share_capital',
    label: 'Amount Capital',
  },
  number_of_shares: {
    name: 'number_of_shares',
    label: 'Issued Number of Shares',
  },
  paid_up_capital: {
    name: 'paid_up_capital',
    label: 'Paidup Capital',
  },
  currency_id: {
    name: 'currency_id',
    label: 'Currency',
  },
};

const ordinarySchema_required = {
  [fieldNames.shareType_ordinary.name]: Yup.object().shape({
    [fieldNames.share_type_id.name]: Yup.number()
      .required(`${fieldNames.share_type_id.label} is required`)
      .default(1),
    [fieldNames.issued_share_capital.name]: Yup.number().required(
      `${fieldNames.issued_share_capital.label} is required`
    ),
    [fieldNames.number_of_shares.name]: Yup.number().required(
      `${fieldNames.number_of_shares.label} is required`
    ),
    [fieldNames.paid_up_capital.name]: Yup.number()
      .required(`${fieldNames.paid_up_capital.label} is required`)
      .when(fieldNames.issued_share_capital.name, (value, schema) => {
        return schema.test({
          test: (value2) => value >= value2,
          message: `${fieldNames.paid_up_capital.label} must be lower than ${fieldNames.issued_share_capital.label}`,
        });
      }),
    [fieldNames.currency_id.name]: Yup.number().required(
      `${fieldNames.currency_id.label} is required`
    ),
  }),
};
export const ordinarySchema_notRequired = {
  [fieldNames.shareType_ordinary.name]: Yup.object().shape(
    {
      [fieldNames.share_type_id.name]: Yup.number()
        .required(`${fieldNames.share_type_id.label} is required`)
        .default(1),
      [fieldNames.issued_share_capital.name]: Yup.number().when(
        [
          fieldNames.number_of_shares.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.issued_share_capital.label} is required`),
        }
      ),
      [fieldNames.number_of_shares.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.number_of_shares.label} is required`),
        }
      ),
      [fieldNames.paid_up_capital.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.number_of_shares.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number()
            .required(`${fieldNames.paid_up_capital.label} is required`)
            .when(fieldNames.issued_share_capital.name, (value, schema) => {
              return schema.test({
                test: (value2) => value >= value2,
                message: `${fieldNames.paid_up_capital.label} must be lower than ${fieldNames.issued_share_capital.label}`,
              });
            }),
        }
      ),
      [fieldNames.currency_id.name]: Yup.number().test({
        name: 'check_is_required',
        exclusive: false,
        params: {},
        message: `${fieldNames.currency_id.label} is required`,
        test: function (value) {
          if (this.parent) {
            if (
              this.parent[fieldNames.issued_share_capital.name] ||
              this.parent[fieldNames.number_of_shares.name] ||
              this.parent[fieldNames.paid_up_capital.name]
            ) {
              return !!value;
            }
          }
          return true;
        },
      }),
    },
    [
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
      ],
    ]
  ),
};
export const validationSchemaObj = {
  ...ordinarySchema_required,
  // ...ordinarySchema_notRequired,
  [fieldNames.shareType_preference.name]: Yup.object().shape(
    {
      [fieldNames.share_type_id.name]: Yup.number()
        .required(`${fieldNames.share_type_id.label} is required`)
        .default(2),
      [fieldNames.issued_share_capital.name]: Yup.number().when(
        [
          fieldNames.number_of_shares.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.issued_share_capital.label} is required`),
        }
      ),
      [fieldNames.number_of_shares.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.number_of_shares.label} is required`),
        }
      ),
      [fieldNames.paid_up_capital.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.number_of_shares.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number()
            .required(`${fieldNames.paid_up_capital.label} is required`)
            .when(fieldNames.issued_share_capital.name, (value, schema) => {
              return schema.test({
                test: (value2) => value >= value2,
                message: `${fieldNames.paid_up_capital.label} must be lower than ${fieldNames.issued_share_capital.label}`,
              });
            }),
        }
      ),
      [fieldNames.currency_id.name]: Yup.number().test({
        name: 'check_is_required',
        exclusive: false,
        params: {},
        message: `${fieldNames.currency_id.label} is required`,
        test: function (value) {
          if (this.parent) {
            if (
              this.parent[fieldNames.issued_share_capital.name] ||
              this.parent[fieldNames.number_of_shares.name] ||
              this.parent[fieldNames.paid_up_capital.name]
            ) {
              return !!value;
            }
          }
          return true;
        },
      }),
    },
    [
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
      ],
    ]
  ),
  [fieldNames.shareType_others.name]: Yup.object().shape(
    {
      [fieldNames.share_type_id.name]: Yup.number()
        .required(`${fieldNames.share_type_id.label} is required`)
        .default(3),
      [fieldNames.issued_share_capital.name]: Yup.number().when(
        [
          fieldNames.number_of_shares.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.issued_share_capital.label} is required`),
        }
      ),
      [fieldNames.number_of_shares.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.paid_up_capital.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number().required(`${fieldNames.number_of_shares.label} is required`),
        }
      ),
      [fieldNames.paid_up_capital.name]: Yup.number().when(
        [
          fieldNames.issued_share_capital.name,
          fieldNames.number_of_shares.name,
          fieldNames.currency_id.name,
        ],
        {
          is: (v1, v2, v3) => v1 || v2 || v3,
          then: Yup.number()
            .required(`${fieldNames.paid_up_capital.label} is required`)
            .when(fieldNames.issued_share_capital.name, (value, schema) => {
              return schema.test({
                test: (value2) => value >= value2,
                message: `${fieldNames.paid_up_capital.label} must be lower than ${fieldNames.issued_share_capital.label}`,
              });
            }),
        }
      ),
      [fieldNames.currency_id.name]: Yup.number().test({
        name: 'check_is_required',
        exclusive: false,
        params: {},
        message: `${fieldNames.currency_id.label} is required`,
        test: function (value) {
          if (this.parent) {
            if (
              this.parent[fieldNames.issued_share_capital.name] ||
              this.parent[fieldNames.number_of_shares.name] ||
              this.parent[fieldNames.paid_up_capital.name]
            ) {
              return !!value;
            }
          }
          return true;
        },
      }),
    },
    [
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
        fieldNames.currency_id.name,
      ],
      [
        fieldNames.issued_share_capital.name,
        fieldNames.number_of_shares.name,
        fieldNames.paid_up_capital.name,
      ],
    ]
  ),
};
export const validationSchema = Yup.object().shape(validationSchemaObj);

const ShareCapitalRow = (props) => {
  const {
    name,
    shareTypeOptions = [],
    currencyOptions = [],
    isRequired = false,
    totalAssignedShare,
    disabled = false,
    ...restProps
  } = props;

  let required = false;

  const classes = useStyles();

  const validateNumberOfShare = (value) => {
    let errorMessage;
    // console.log('validateNumberOfShare', {
    //   value,
    //   totalAssignedShare,
    // });
    if (value && totalAssignedShare && totalAssignedShare > 0) {
      if (value < totalAssignedShare) {
        errorMessage = "Current shareholders' number of share exceeds share limit";
      }
    }

    return errorMessage;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={2}>
        <Box display="flex" alignItems="center" height="100%" width="100%">
          <b>{fieldNames[name].label}</b>
        </Box>
        <Box display="none">
          <CustomSelectField
            name={`${name ? name + '.' : ''}${fieldNames.share_type_id.name}`}
            label={`${fieldNames.share_type_id.label} ${isRequired ? ' *' : ''}`}
            options={shareTypeOptions}
            disabled={disabled}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name={`${name ? name + '.' : ''}${fieldNames.issued_share_capital.name}`}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
            let error = meta.touched && !!meta.error;
            return (
              <FormFieldContainer
                label={`${fieldNames.issued_share_capital.label}${isRequired ? ' *' : ''}`}
                disabled={disabled}
              >
                <TextField
                  required={required}
                  type="number"
                  fullWidth
                  {...field}
                  error={error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                  disabled={disabled}
                />
              </FormFieldContainer>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Field
          name={`${name ? name + '.' : ''}${fieldNames.number_of_shares.name}`}
          validate={validateNumberOfShare}
        >
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
            let error = meta.touched && !!meta.error;
            return (
              <FormFieldContainer
                label={
                  <Box maxWidth="100%" display="flex" flexWrap="nowrap">
                    <span
                      display="inline-block"
                      style={{
                        display: 'inline-block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      title={fieldNames.number_of_shares.label}
                    >
                      {fieldNames.number_of_shares.label}
                    </span>
                    {isRequired && <span> *</span>}
                  </Box>
                }
                disabled={disabled}
              >
                <TextField
                  required={required}
                  type="number"
                  fullWidth
                  {...field}
                  error={error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                  disabled={disabled}
                />
              </FormFieldContainer>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name={`${name ? name + '.' : ''}${fieldNames.paid_up_capital.name}`}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
            let error = meta.touched && !!meta.error;
            return (
              <FormFieldContainer
                label={`${fieldNames.paid_up_capital.label}${isRequired ? ' *' : ''}`}
                disabled={disabled}
              >
                <TextField
                  required={required}
                  type="number"
                  fullWidth
                  {...field}
                  error={error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                  disabled={disabled}
                />
              </FormFieldContainer>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomSelectField
          name={`${name ? name + '.' : ''}${fieldNames.currency_id.name}`}
          label={`${fieldNames.currency_id.label} ${isRequired ? ' *' : ''}`}
          options={currencyOptions}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

const ShareCapitalFields = (props) => {
  const {
    shareTypeOptions = [],
    totalAssignedShare = {
      [fieldNames.shareType_ordinary.name]: 0,
      [fieldNames.shareType_preference.name]: 0,
      [fieldNames.shareType_others.name]: 0,
    },
    required = false,
    hideHeader = false,
    disabled = false,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const {
    options: currencyOptions = [],
    loading: loadingCurrencies,
    rawData: currencies = [],
    refetch: fetchCurrency,
  } = useCommonOptions(commonApi.getCurrency, {
    respondKey: 'currency',
    labelKey: 'currency_name',
    valueKey: 'currency_id',
    render: (_, data) => {
      return `${data.country_name} - ${data.currency_name}`;
    },
    lazyLoad: true,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchCurrency();
      } catch (error) {}
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {hideHeader ? null : (
        <>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Capital :-
            </Typography>
          </div>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h6">
              Issued Share Capital
            </Typography>
          </div>
        </>
      )}
      {loading ? (
        'Loading'
      ) : (
        <>
          <ShareCapitalRow
            name={fieldNames.shareType_ordinary.name}
            currencyOptions={currencyOptions}
            shareTypeOptions={shareTypeOptions}
            isRequired={required}
            totalAssignedShare={totalAssignedShare[fieldNames.shareType_ordinary.name]}
            disabled={disabled}
          />
          <ShareCapitalRow
            name={fieldNames.shareType_preference.name}
            currencyOptions={currencyOptions}
            shareTypeOptions={shareTypeOptions}
            totalAssignedShare={totalAssignedShare[fieldNames.shareType_preference.name]}
            disabled={disabled}
          />
          <ShareCapitalRow
            name={fieldNames.shareType_others.name}
            currencyOptions={currencyOptions}
            shareTypeOptions={shareTypeOptions}
            totalAssignedShare={totalAssignedShare[fieldNames.shareType_others.name]}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default ShareCapitalFields;
