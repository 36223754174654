import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import taskSvc from '../../services/task.service';
import Table from './components/Table/Table';
// styles
import useStyles from './styles';

export default function Tasks(props) {
  var classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [orderList, setOrderList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const orders = await taskSvc.tasks();
      setOrderList(orders);
    };

    fetchData().finally(() => setLoading(false));
  }, []);
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Tasks" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? <CircularProgress /> : <Table data={orderList} />}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
