import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';

export default function CustomLoading(props) {
  const {
    loading,
    fullscreen = true,
    loadingIndicator = <CircularProgress />,
    className = '',
    style: defaultStyle = {},
    children,
    ...restProps
  } = props;

  let style = {
    zIndex: 5,
    ...defaultStyle,
  };
  return (
    <>
      {fullscreen ? (
        <Backdrop open={Boolean(loading)} style={style}>
          {loadingIndicator}
          {children}
        </Backdrop>
      ) : (
        loading && (
          <Box className={`${className}`} style={style}>
            {loadingIndicator}
            {children}
          </Box>
        )
      )}
    </>
  );
}
