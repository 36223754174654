import React, { useState, useEffect } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import clsx from 'clsx';
import { Formik } from 'formik';

import useFetchData from '../useFetchData';
import userApi from '../../../services/user.service';
import commonApi from '../../../services/common.service';
import addressApi from '../../../services/address.service';
import CustomModal from '../../../components/CustomModal';
import CustomTextField from '../../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../../components/CustomFormFields/CustomSelectField';
import CustomRadioGroupField from '../../../components/CustomFormFields/CustomRadioGroupField';
import CustomPhoneNumberField from '../../../components/CustomFormFields/CustomPhoneNumberField';

// floor_unit_no: ""
// address_line_1: "line 1"
// address_line_2: "line 2"
// address_line_3: "line 3"
// postal_code: "12345"
// state_id: 1
// country_id: 132

// company_email: "edison.tanxx123@meyzergroup.com"
// company_user_id: 64
// company_username: "edison.tanxx123@meyzergroup.com"

// contact_number: "123456789"

const fieldName_username = 'username';
const fieldName_roleId = 'role_id';
const fieldName_firstName = 'first_name';
const fieldName_lastName = 'last_name';
const fieldName_email = 'email';
const fieldName_mobileNumber = 'contact_number';
const fieldName_mobileNumberCountry = 'contact_phone_code';
const fieldName_gender = 'gender_id';
const fieldName_dateOfBirth = 'dob';
const fieldName_countryOfBirth = 'birth_country_id';
const fieldName_nationality = 'nationality_id';
const fieldName_created_by = 'created_by';

const fieldName_floor_unit_no = 'floor_unit_no';
const fieldName_address_line_1 = 'address_line_1';
const fieldName_address_line_2 = 'address_line_2';
const fieldName_address_line_3 = 'address_line_3';
const fieldName_postal_code = 'postal_code';
const fieldName_country_id = 'country_id';
// const fieldName_ = "";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  gridContainer: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
      // borderWidth: "1px",
      // borderStyle: "solid",
      // borderColor: theme.palette.grey[300],
      // display: "flex",
      // alignItems: "center",
    },
    '& .MuiGrid-container.MuiGrid-item': {
      padding: theme.spacing(0),
    },
  },
  successText: {
    color: theme.palette.success.main,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const personalInfoFields = [
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'Gender',
    key: 'gender_name',
  },
  {
    label: 'Nationality',
    key: 'nationality',
  },
];
const contactFields = [
  {
    label: 'Contact Number',
    key: 'contact_number',
  },
];
const addressFields = [
  {
    label: 'Floor/Unit No',
    key: 'floor_unit_no',
  },
  {
    label: 'Address Line 1',
    key: 'address_line_1',
  },
  {
    label: 'Address Line 2',
    key: 'address_line_2',
  },
  {
    label: 'City / State',
    key: 'address_line_3',
  },
];

const userStatuses = {
  ban: 3,
  suspend: 2,
};

const EditModal = (props) => {
  const { refetchData, title = '', show = false, onHide, children } = props;

  const theme = useTheme();
  const classes = useStyles();

  const userId = show?.user_id;
  const companyUserId = show?.company_user_id;
  const userStatusId = show?.status_id;
  const showModal = Boolean(show);

  const [countries, setCountries] = useState([]);
  const countryOptions = countries.map((anItem) => {
    return {
      label: anItem.country_name,
      value: anItem.country_id,
      data: anItem,
    };
  });
  const [genders, setGenders] = useState([]);
  const genderOptions = genders.map((anItem) => {
    return {
      label: anItem.gender_name,
      value: `${anItem.gender_id}`,
      data: anItem,
    };
  });

  // const [
  //   fetchSysInfoUser,
  //   { data: sysInfoUserData, error: errorSysInfoUser, loading: loadingSysInfoUser },
  // ] = useFetchData(
  //   {
  //     callback: async () => await userApi.getSysInfoUserById(userId),
  //   },
  //   true
  // );

  // const [
  //   fetchCompanyUser,
  //   { data: companyUserData, error: errorCompanyUser, loading: loadingCompanyUser },
  // ] = useFetchData(
  //   {
  //     callback: async () => await userApi.getUserById(companyUserId),
  //   },
  //   true
  // );

  // const [
  //   fetchCompanyUserAddress,
  //   {
  //     data: companyUserAddressData,
  //     error: errorCompanyUserAddress,
  //     loading: loadingCompanyUserAddress,
  //   },
  // ] = useFetchData(
  //   {
  //     callback: async () => await addressApi.getUserAddress(companyUserId),
  //   },
  //   true
  // );

  // let sysInfoUser = sysInfoUserData?.users || {};
  // let companyUser = companyUserData?.users || {};
  // let companyUserAddress =
  //   companyUserAddressData?.userAddresses.length > 0
  //     ? companyUserAddressData.userAddresses.find((anAddress) => {
  //         return anAddress.is_default;
  //       })
  //     : {};

  const [
    updateSysInfoUser,
    {
      // data: sysInfoUserData,
      error: errorUpdateSysInfoUser,
      loading: loadingUpdateSysInfoUser,
    },
  ] = useFetchData({}, true);

  const fetchCountries = async () => {
    const countriesResult = await commonApi.getCountry();

    if (countriesResult && countriesResult?.data?.countries) {
      setCountries(countriesResult.data.countries);
    } else {
      setCountries([]);
    }
  };
  const fetchGenders = async () => {
    const gendersResult = await commonApi.getGender();
    if (gendersResult && gendersResult?.data?.genders) {
      setGenders(gendersResult.data.genders);
    } else {
      setGenders([]);
    }
  };
  useEffect(() => {
    (async () => {})();
  }, []);

  useEffect(() => {
    if (showModal) {
      // if (fetchCompanyUser && userId && companyUserId) {
      //   (async () => {
      //     await fetchSysInfoUser();
      //     await fetchCompanyUser();
      //     await fetchCompanyUserAddress();
      //   })();
      // }
      (async () => {
        await fetchCountries();
        await fetchGenders();
      })();
    }
  }, [showModal]);

  const handleOnClose = () => {
    if (refetchData) {
      refetchData();
    }
    if (onHide) {
      onHide();
    }
  };

  const handleOnSubmit = (values) => {
    console.log('values', values);
    const {
      [fieldName_roleId]: roleIdValue,
      [fieldName_gender]: genderValue,
      ...restValues
    } = values;

    let body = {
      ...restValues,
      [fieldName_username]: values[fieldName_email],
      [fieldName_gender]: parseInt(genderValue),
      // [fieldName_roleId]: 1,
      // [fieldName_created_by]: 1,
    };

    updateSysInfoUser({
      callback: async () => await userApi.updateSysInfoUser(userId, body),
    })
      .then((result) => {
        handleOnClose();
      })
      .catch((error) => {});
  };

  const handleSuspendUser = () => {
    if (window.confirm('Confirmed to Suspend this user?')) {
      updateSysInfoUser({
        callback: async () =>
          await userApi.updateSysInfoUser(userId, {
            user_status_id: 2,
            // status_name: 'Suspended',
          }),
      })
        .then((result) => {
          handleOnClose();
        })
        .catch((error) => {});
    }
  };
  const handleBanUser = () => {
    if (window.confirm('Confirmed to Ban this user?')) {
      updateSysInfoUser({
        callback: async () =>
          await userApi.updateSysInfoUser(userId, {
            user_status_id: 3,
            // status_name: 'Banned',
          }),
      })
        .then((result) => {
          handleOnClose();
        })
        .catch((error) => {});
    }
  };

  return (
    <CustomModal
      show={showModal}
      onHide={onHide}
      // loading={loadingCompanyUser}
      title={
        <Typography variant="h5">
          {show?.username} (ID: {userId})
        </Typography>
      }
      footer={
        <>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex">
              <Button variant="outlined" onClick={onHide}>
                Cancel
              </Button>
              <Box p={1} />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSuspendUser}
                disabled={userStatusId == userStatuses.suspend}
              >
                Suspend
              </Button>
              <Box p={1} />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBanUser}
                disabled={userStatusId == userStatuses.ban}
              >
                Ban
              </Button>
            </Box>
            <Button variant="contained" color="primary" type="submit" form="edit-sysinfouser-form">
              Save
            </Button>
          </Box>
        </>
      }
    >
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={
          show
            ? {
                ...show,
                [fieldName_gender]: `${show[fieldName_gender] || 1}`,
              }
            : {}
        }
      >
        {(props) => {
          return (
            <form id="edit-sysinfouser-form" onSubmit={props.handleSubmit}>
              <Box mb={2}>
                <CustomTextField
                  label="First name"
                  name={fieldName_firstName}
                  placeholder="What's your first name"
                />
              </Box>
              <Box mb={2}>
                <CustomTextField
                  label="Last name"
                  name={fieldName_lastName}
                  placeholder="What's your last name"
                />
              </Box>
              <Box mb={2}>
                <CustomTextField
                  label="Email address"
                  name={fieldName_email}
                  placeholder="you@example.com"
                  type="email"
                />
              </Box>
              <Box mb={2}>
                <CustomPhoneNumberField
                  label="Mobile number"
                  name={fieldName_mobileNumber}
                  phoneCodeName={fieldName_mobileNumberCountry}
                />
              </Box>
              <Box mb={2}>
                <CustomRadioGroupField
                  label={'Gender'}
                  name={fieldName_gender}
                  options={genderOptions}
                />
              </Box>
              <Box width={'100%'}>
                <Box mb={2}>
                  <CustomTextField label="Date of birth" name={fieldName_dateOfBirth} type="date" />
                </Box>
                <Box mb={2}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      display: 'flex',
                      marginBottom: '4px',
                    }}
                  >
                    <span>Country of birth</span>
                  </Typography>
                  <CustomSelectField
                    name={fieldName_countryOfBirth}
                    options={countryOptions}
                    placeholder="What's your country of birth"
                  />
                </Box>
                <Box mb={2}>
                  <CustomSelectField
                    label="Nationality"
                    name={fieldName_nationality}
                    options={countryOptions}
                    placeholder="What's your nationality"
                  />
                </Box>
              </Box>

              <Box width={'100%'} pt={2}>
                <Box mb={2} fontWeight="bold">
                  Address
                </Box>
                <Box mb={2}>
                  <CustomTextField label="Floor Unit No." name={fieldName_floor_unit_no} />
                </Box>
                <Box mb={2}>
                  <CustomTextField label="Address Line 1" name={fieldName_address_line_1} />
                </Box>
                <Box mb={2}>
                  <CustomTextField label="Address Line 2" name={fieldName_address_line_2} />
                </Box>
                <Box mb={2}>
                  <CustomTextField label="City / State" name={fieldName_address_line_3} />
                </Box>
                <Box mb={2}>
                  <CustomTextField label="Postal Code" name={fieldName_postal_code} />
                </Box>
                <Box mb={2}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    style={{
                      display: 'flex',
                      marginBottom: '4px',
                    }}
                  >
                    <span>Country</span>
                  </Typography>
                  <CustomSelectField
                    name={fieldName_country_id}
                    options={countryOptions}
                    placeholder="What's your address country"
                  />
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default EditModal;
