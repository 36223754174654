import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

// components
import PageTitle from '../../components/PageTitle';
import Menu from './components/Menu/Menu';
import CompanyMenu from './components/Menu/CompanyMenu';
import ContainerMenu from './components/Menu/ContainerMenu';
import DocumentTypeMenu from './components/Menu/DocumentTypeMenu';
import TableTypeMenu from './components/Menu/TableTypeMenu';
import CustomMenu from '../../components/CustomMenu';
import CustomUploadButton from '../../components/CustomFormFields/CustomUploadButton';

import axios from 'axios';

import fileUploader from '../../utility/fileuploader';

import userApi from '../../services/user.service';
import companyApi from '../../services/company.service';
import cddApi from '../../services/cdd.service';
import addressApi from '../../services/address.service';
import webdevApi from '../../services/webdev';
import requestApi from '../../services/requests.service';
import commonApi from '../../services/common.service';

/*
headers: {
  "Content-type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
}
*/

const documentTableTypes = {
  1: { api: userApi.getUsers, dataKey: 'users' },
  2: { api: companyApi.getCompanies, dataKey: 'companies' },
  3: { api: commonApi.getCompanyRole, dataKey: 'companyroles' }, // User Company Roles
  4: { api: cddApi.getCdd, dataKey: 'customerdd' }, // with user
  5: { api: addressApi.getUserAddress, dataKey: 'userAddresses' }, // with user
  6: { api: requestApi, dataKey: '' }, // Officer Application ?
  7: { api: requestApi, dataKey: '' }, // Cessation Application ?
  8: {
    api: webdevApi.getDevs,
    dataKey: 'webdev',
  },
  9: { api: requestApi, dataKey: '' }, // New Shareholder Application ?
  10: { api: requestApi, dataKey: '', type: 15 }, // Shares Allotment Application ?
  11: { api: companyApi.getOnboardProfiles, dataKey: 'onboard_profiles' },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  tableOverflow: {
    overflow: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 520,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Upload() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarSuccess(false);
  };

  // base values
  const refSelectFile = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [module, setModule] = useState('');
  const [container, setContainer] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [tableType, setTableType] = useState('');

  // conditional values
  const [company, setCompany] = useState('');
  const [user, setUser] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [webdev, setWebdev] = useState('');
  const [onboardProfile, setOnboardProfile] = useState('');
  const [cdd, setCdd] = useState('');
  const [companyRole, setCompanyRole] = useState('');

  // options
  const [companies, setCompanies] = useState([]);
  const [modules, setModules] = useState([]);
  const [containers, setContainers] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [tableTypes, setTableTypes] = useState([]);

  const [users, setUsers] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [webdevs, setWebdevs] = useState([]);
  const [onboardProfiles, setOnboardProfiles] = useState([]);
  const [cdds, setCdds] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([]);

  let moduleOptions = modules.map((anItem) => {
    return {
      label: anItem.module_name,
      value: anItem.module_id,
    };
  });
  if (moduleOptions.length > 0) {
    moduleOptions = moduleOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }

  let containerOptions = containers.map((anItem) => {
    return {
      label: anItem.container_name,
      value: anItem.document_container_id,
    };
  });
  if (containerOptions.length > 0) {
    containerOptions = containerOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  let documentTypeOptions = documentTypes.map((anItem) => {
    return {
      label: anItem.document_type_name,
      value: anItem.document_type_id,
    };
  });
  if (documentTypeOptions.length > 0) {
    documentTypeOptions = documentTypeOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  if (tableType == 1) {
    let userDocumentTypeIds = [74, 75, 76, 77, 109, 110, 94];
    documentTypeOptions = documentTypeOptions.filter((anItem) => {
      if (userDocumentTypeIds.indexOf(anItem.value) >= 0) {
        return true;
      }
      return false;
    });
  }

  let documentTableTypeOptions = tableTypes.map((anItem) => {
    return {
      label: anItem.table_name,
      value: anItem.table_id,
    };
  });
  if (documentTableTypeOptions.length > 0) {
    documentTableTypeOptions = documentTableTypeOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  let companyOptions = companies.map((anItem) => {
    return {
      label: `${anItem.company_name}${
        anItem.company_registration_no
          ? ` (Registration No: ${anItem.company_registration_no})`
          : ''
      }`,
      value: anItem.company_id,
    };
  });
  if (companyOptions.length > 0) {
    companyOptions = companyOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }

  let userOptions = users.map((anItem) => {
    return {
      label: `${anItem.first_name}${anItem.last_name ? ` ${anItem.last_name}` : ''}${
        anItem.nric ? ` (NRIC: ${anItem.nric})` : ''
      }${anItem.passport ? ` (PASSPORT: ${anItem.passport})` : ''}`,
      value: anItem.user_id,
    };
  });
  if (userOptions.length > 0) {
    userOptions = userOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }

  let userAddressOptions = userAddresses.map((anItem) => {
    return {
      label: (
        <div>
          <div>{anItem.address_type_name}</div>
          <div>
            {`${anItem.floor_unit_no}, ${anItem.address_line_1}${
              anItem.address_line_2 ? `, ${anItem.address_line_2}` : ''
            }${anItem.address_line_3 ? `, ${anItem.address_line_3}` : ''}${
              anItem.postal_code ? `, ${anItem.postal_code}` : ''
            }${anItem.country_name ? ` ${anItem.country_name}` : ''}`}
          </div>
        </div>
      ),
      value: anItem.address_id,
    };
  });
  if (userAddressOptions.length > 0) {
    userAddressOptions = userAddressOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }

  let webdevOptions = webdevs.map((anItem) => {
    return {
      label: anItem.webdev_category_name,
      value: anItem.webdev_id,
    };
  });
  if (webdevOptions.length > 0) {
    webdevOptions = webdevOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  let onboardProfileOptions = onboardProfiles.map((anItem) => {
    return {
      label: anItem.company_name,
      value: anItem.onboard_profile_id,
    };
  });
  if (onboardProfileOptions.length > 0) {
    onboardProfileOptions = onboardProfileOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  let cddOptions = cdds.map((anItem) => {
    return {
      label: anItem.customer_due_diligence_id,
      value: anItem.customer_due_diligence_id,
    };
  });
  if (cddOptions.length > 0) {
    cddOptions = cddOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }
  let companyRoleOptions = companyRoles.map((anItem) => {
    return {
      label: anItem.role_name,
      value: anItem.company_role_id,
    };
  });
  if (companyRoleOptions.length > 0) {
    companyRoleOptions = companyRoleOptions.sort((a, b) => {
      return a?.label.localeCompare(b?.label);
    });
  }

  const getModules = async () => {
    const res = await axios.get(
      'https://4wiyg78ofa.execute-api.ap-southeast-1.amazonaws.com/Prod/modules?page=1&page_limit=1'
    );
    setModules(res.data.modules);
    setModule(res.data.modules[0].module_id);
  };

  const getContainers = async () => {
    const res = await axios.get(
      // "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/containers/"
      `${process.env.REACT_APP_DOCUMENT_URL}/containers/`
    );
    setContainers(res.data.containers);
    setContainer(res.data.containers[0].document_container_id);
  };

  const getDocumentTypes = async () => {
    const res = await axios.get(
      // "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documenttypes/"
      `${process.env.REACT_APP_DOCUMENT_URL}/documenttypes/`
    );
    let data = res?.data?.['document-types'];

    setDocumentTypes(data);
    //console.log(res.data['document-types'].length);
    setDocumentType(data[0].document_type_id);
  };

  const getTableTypes = async () => {
    const res = await axios.get(
      // "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documenttabletypes/"
      `${process.env.REACT_APP_DOCUMENT_URL}/documenttabletypes/`
    );

    const tableTypeIds = [1, 2, 4, 5];
    let filteredTableType = [];
    res?.data['table-types']?.forEach((aTableType) => {
      if (tableTypeIds.indexOf(aTableType.table_id) >= 0) {
        filteredTableType.push(aTableType);
      }
    });
    setTableTypes(filteredTableType);
    setTableType(filteredTableType?.[0].table_id);
  };

  const getCompanies = async () => {
    const res = await axios.get(
      // "https://wkl2exkzdf.execute-api.ap-southeast-1.amazonaws.com/Prod/companies/"
      `${process.env.REACT_APP_COMPANY_URL}/companies/`
    );
    setCompanies(res.data.companies);
    // setCompany(res.data.companies[0].company_id);
  };
  const getUsers = async () => {
    const res = await axios.get(
      // "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documenttabletypes/"
      `${process.env.REACT_APP_USERCONTACT_URL}/users/`
    );
    setUsers(res.data['users']);
  };
  const getUserAddresses = async (id) => {
    const res = await addressApi.getUserAddress(id);
    setUserAddresses(res.data['userAddresses']);
  };
  const getWebdevs = async () => {
    const res = await webdevApi.getDevs();
    console.log('resres', res);
    setWebdevs(res);
  };
  const getOnboardProfiles = async () => {
    const res = await companyApi.getOnboardProfiles('', 1, 10000);
    setOnboardProfiles(res.data['onboard_profiles']);
  };

  const getCdd = async (id) => {
    const res = await cddApi.getCdd(id);
    setCdds([res.data['customerdd']]);
    setCdd(res.data['customerdd'].customer_due_diligence_id);
  };
  const getCompanyRoles = async (id) => {
    const res = await commonApi.getCompanyRole();
    console.log('res', res);
    setCompanyRoles(res.data['companyroles']);
  };

  useEffect(() => {
    (async () => {
      setIsLoadingOptions(true);
      await getModules();
      await getContainers();
      await getDocumentTypes();
      await getTableTypes();
      setIsLoadingOptions(false);
    })();
  }, []);

  let withUsers = tableType == 1 || tableType == 4 || tableType == 5;
  useEffect(() => {
    if (withUsers) {
      (async () => {
        await getUsers();
      })();
    }
    (async () => {
      if (tableType == 2) {
        await getCompanies();
      } else if (tableType == 8) {
        await getWebdevs();
      } else if (tableType == 11) {
        await getOnboardProfiles();
      } else if (tableType == 3) {
        await getCompanyRoles();
      }
    })();
  }, [tableType]);

  useEffect(() => {
    if (withUsers && user) {
      (async () => {
        if (tableType == 4) {
          await getCdd(user);
        } else if (tableType == 5) {
          await getUserAddresses(user);
        }
      })();
    }
  }, [user]);

  // const handleSelectFile = (event) => {
  //   console.log("event", event.target.files);
  //   console.log("ref", refSelectFile);

  //   setSelectedFile(refSelectFile.current.files[0]);
  //   setIsFilePicked(true);
  // };

  const handleModuleChange = (event) => {
    setModule(event.target.value);
  };

  const handleContainerChange = (event) => {
    setContainer(event.target.value);
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleTableTypeChange = (event) => {
    setTableType(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };
  const handleUserChange = (event) => {
    setUser(event.target.value);
  };
  const handleUserAddressChange = (event) => {
    setUserAddress(event.target.value);
  };
  const handleWebdevChange = (event) => {
    setWebdev(event.target.value);
  };
  const handleOnboardProfileChange = (event) => {
    setOnboardProfile(event.target.value);
  };
  const handleCddChange = (event) => {
    setCdd(event.target.value);
  };
  const handleCompanyRoleChange = (event) => {
    setCompanyRole(event.target.value);
  };
  // const handleUserChange = (event) => {
  //   setUser(event.target.value);
  // };
  // const handleUserChange = (event) => {
  //   setUser(event.target.value);
  // };

  const dataByTableType = {
    1: user,
    2: company,
    4: cdd,
    5: userAddress,
  };

  let canUpload =
    isFilePicked && refSelectFile && documentType && tableType && dataByTableType?.[tableType];

  function handleUpload(event) {
    console.log('company id', company);
    console.log('module id', module);
    console.log('container id', container);
    console.log('doc type id', documentType);
    console.log(documentTypes[documentType - 1].document_type_name);
    console.log('table id', tableType);
    console.log(tableTypes[tableType - 1].table_name);
    console.log(selectedFile);

    const pushData = async () => {
      // call file uploader
      // const companyId = 1;

      const res = await fileUploader(
        refSelectFile,
        documentType,
        tableType,
        dataByTableType?.[tableType] || null
      )
        .then((res) => {
          setOpenSnackbarSuccess('Upload Success');
        })
        .catch((error) => {
          // setOpenSnackbar("Uploading Error: " + JSON.stringify(error.response.data.message))
          setOpenSnackbar('Uploading Error');
        });
    };

    setIsLoading(true);
    pushData().finally(() => {
      // setIsFilePicked(false);
      setIsLoading(false);

      // reset input type file here
      refSelectFile.current.value = '';
    });
    /*
    const data = {
      "document_container_id": container,
      "document_table_type_id": tableType,
      "document_type_id": documentType,
      "document_url": "https://mbs-docs.s3.ap-southeast-1.amazonaws.com/b1983c0b-d65f-40ca-affb-bc879deff4ba12345",
      "module_id": module,
      "table_primary_id": 4
    }

    const res = await axios.post("https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documents/", data);
    console.log(res.data.document[0]);
*/
  }

  return (
    <>
      <PageTitle title="Upload Document" />
      <Box width="520px" maxWidth="100%">
        {isLoadingOptions ? (
          'Loading'
        ) : (
          <Grid
            container
            // direction="column"
            // justifycontent="flex-start"
            // alignItems="flex-start"
            spacing={2}
          >
            {modules.length > 0 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={moduleOptions}
                  value={module}
                  label="Module"
                  onChange={handleModuleChange}
                />
              </Grid>
            )}
            {containers.length > 0 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={containerOptions}
                  value={container}
                  label="Container"
                  onChange={handleContainerChange}
                />
              </Grid>
            )}
            {tableTypes.length > 0 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={documentTableTypeOptions}
                  value={tableType}
                  label="Document Table Type"
                  onChange={handleTableTypeChange}
                />
              </Grid>
            )}
            {documentTypes.length > 0 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={documentTypeOptions}
                  value={documentType}
                  label="Document Type"
                  onChange={handleDocumentTypeChange}
                />
              </Grid>
            )}
            {tableType == 2 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={companyOptions}
                  value={company}
                  label="Company"
                  searchable={true}
                  onChange={handleCompanyChange}
                />
              </Grid>
            )}
            {withUsers && (
              <Grid item xs={12}>
                <CustomMenu
                  data={userOptions}
                  value={user}
                  label="User"
                  searchable={true}
                  onChange={handleUserChange}
                />
              </Grid>
            )}

            {tableType == 3 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={companyRoleOptions}
                  value={companyRole}
                  label="User Company Role"
                  onChange={handleCompanyRoleChange}
                />
              </Grid>
            )}
            {/* {tableType == 4 && (
            <Grid item xs={12} md={6}>
              <CustomMenu
                data={cddOptions}
                value={cdd}
                label="Customer Due Diligence"
                onChange={handleCddChange}
              />
            </Grid>
          )} */}
            {tableType == 5 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={userAddressOptions}
                  value={userAddress}
                  label="User Address"
                  onChange={handleUserAddressChange}
                />
              </Grid>
            )}
            {tableType == 8 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={webdevOptions}
                  value={webdev}
                  label="WebDev"
                  onChange={handleWebdevChange}
                />
              </Grid>
            )}
            {tableType == 11 && (
              <Grid item xs={12}>
                <CustomMenu
                  data={onboardProfileOptions}
                  value={onboardProfile}
                  label="Onboard Profile"
                  onChange={handleOnboardProfileChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <CustomUploadButton
                ref={refSelectFile}
                fullWidth
                onChange={() => {
                  setSelectedFile(refSelectFile?.current?.files?.[0]);
                  setIsFilePicked(true);
                }}
              />
              {/* <Button
                // className={classes.formControl}
                variant="outlined"
                onChange={handleSelectFile}
                component="label"
                fullWidth
              >
                Choose Files
                <input type="file" hidden ref={refSelectFile} />
              </Button> */}

              <label>
                {isFilePicked && selectedFile ? (
                  <div>
                    <p>Filename: {selectedFile.name}</p>
                    <p>Filetype: {selectedFile.type}</p>
                  </div>
                ) : (
                  <Typography variant="caption">Select a file to show detail</Typography>
                )}
              </label>
            </Grid>

            <Grid item xs={12}>
              {isLoading ? (
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  disabled={true}
                  fullWidth
                >
                  <CircularProgress size={26} />
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  disabled={!canUpload}
                  onClick={handleUpload}
                  fullWidth
                >
                  Upload
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar open={Boolean(openSnackbar)} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {openSnackbar}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(openSnackbarSuccess)}
        autoHideDuration={5000}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert onClose={handleCloseSnackbarSuccess} severity="success">
          {openSnackbarSuccess}
        </Alert>
      </Snackbar>
    </>
  );
}
