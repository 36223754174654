import React from 'react';
import { Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Field } from 'formik';
import FormFieldContainer from '../FormFieldContainer';

const CustomRadioGroupField = (props) => {
  const {
    label,
    name,
    options = [],
    placeholder,
    containerProps: defaultContainerProps = {},
    helperText,
    variant = 'default',
    labelPlacement = 'end',
    disabled,
    required = false,
    children,
    ...restProps
  } = props;

  let fieldProps = {
    name: name,
    row: true,
    disabled,
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta }) => {
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            required={required}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            <RadioGroup {...field} {...fieldProps}>
              {options &&
                options.length > 0 &&
                options.map((anOption, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      label={anOption.label}
                      value={anOption.value}
                      control={<Radio color="primary" />}
                      disabled={disabled}
                      labelPlacement={labelPlacement}
                    />
                  );
                })}
              {children}
            </RadioGroup>
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );

  return (
    <>
      {label && (
        <Typography
          color="textSecondary"
          variant="caption"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '4px',
          }}
        >
          {label}
        </Typography>
      )}
      <Field name={name}>
        {({ field, meta }) => (
          <RadioGroup
            // error={meta.touched && !!meta.error}
            // helperText={
            //   meta.touched && meta.error ? meta.error : helperText || ''
            // }
            {...fieldProps}
            {...field}
          >
            {options?.map((anOption, index) => {
              let optionType = typeof anOption;

              let optionLabel = anOption;
              let optionValue = anOption;
              if (optionType != 'string' && optionType == 'object') {
                optionLabel = anOption?.label;
                optionValue = anOption?.value;
              }

              return (
                <FormControlLabel
                  key={index}
                  label={optionLabel}
                  value={optionValue}
                  control={<Radio color="primary" />}
                  disabled={disabled}
                />
              );
            })}
            {children}
          </RadioGroup>
        )}
      </Field>
    </>
  );
};

export default CustomRadioGroupField;
