import { AppBar, Button, Grid, MenuItem, Select, Toolbar } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
// import DateFnsUtils from "@date-io/date-fns";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
// import "material-ui-phone-number/styles.css";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MuiAlert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// const { commonApi } = require("../../services/commonservice");
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Typography } from '../../components/Wrappers/Wrappers';
import addressApi from '../../services/address.service';
import cddApi from '../../services/cdd.service';
import commonApi from '../../services/common.service';
import documentApi from '../../services/document.service';
import identificationApi from '../../services/identification.service';
import userApi from '../../services/user.service'; // user and contact
// styles
import useStyles from './styles';
import fileUploader from '../../utility/fileuploader';
import useQueryParams from '../../hooks/useQueryParams';

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Form(props) {
  var classes = useStyles();
  var theme = useTheme();
  let history = useHistory();
  let query = useQueryParams();
  // local

  // const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [value, setValue] = React.useState('female');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [errorApiMsg, setErrorApiMsg] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const errorMsg = 'Please fill up.';
  // common
  const [risklevellist, setRisklevellist] = React.useState();
  const [rolelist, setRolelist] = React.useState();
  const [riskcategorylist, setRiskcategorylist] = React.useState();
  const [countrylist, setCountrylist] = React.useState();
  const [identificationlist, setIdentificationlist] = React.useState();
  const [genderlist, setGenderlist] = React.useState();
  const [contactlist, setContactlist] = React.useState();
  const [cessationlist, setCessationlist] = React.useState();
  const [phonecodelist, setPhoneCodeList] = React.useState();
  const [addresstypelist, setAddresstypelist] = React.useState();
  const [requiredIdNric, setRequiredIdNric] = React.useState(false);
  const [requiredIdPassport, setRequiredIdPassport] = React.useState(false);
  const [defaultNric, setDefaultNric] = React.useState(0);
  const [defaultPassport, setDefaultPassport] = React.useState(0);
  const [passportHistoryId, setPassportHistoryId] = React.useState();
  const [nricHistoryId, setNricHistoryId] = React.useState();

  // input fields
  // roles
  const [rolevalue, setRolevalue] = React.useState([]);

  // Personal Identifying Details
  const [idvalue, setIdvalue] = React.useState(1);
  const [iderror, setIderror] = React.useState(false);
  const [gendervalue, setGendervalue] = React.useState(1);
  const [aliasvalue, setAliasvalue] = React.useState();
  const [errorMsgAlias, setErrorMsgAlias] = React.useState('');
  const [countryvalue, setCountryvalue] = React.useState(null);
  const [bodvalue, setBodvalue] = React.useState('');
  const [firstnamevalue, setFirstnamevalue] = React.useState();
  const [errorMsgFirstName, setErrorMsgFirstName] = React.useState('');
  const [lastnamevalue, setLastnamevalue] = React.useState('');
  const [usernamevalue, setUsernamevalue] = React.useState('');
  const [disablePersonalDetails, setDisablePersonalDetails] = React.useState(true);
  const [userId, setUserId] = React.useState();
  const [userEmail, setUserEmail] = React.useState();

  //  user id

  // Residency Details
  const [nationalityvalue, setNationalityvalue] = React.useState();
  const [nationalityerror, setNationalityerror] = React.useState(false);
  const [nricvalue, setNricvalue] = React.useState();
  const [passportvalue, setPassportvalue] = React.useState();
  const [nricCountryvalue, setNricCountryvalue] = React.useState();
  const [nricCountryerror, setNricCountryerror] = React.useState(false);
  const [passportCountryvalue, setPassportCountryvalue] = React.useState();
  const [passportCountryerror, setPassportCountryerror] = React.useState(false);
  const [issuedatevalue, setIssuedatevalue] = React.useState();
  const [expirydatevalue, setExpirydatevalue] = React.useState();
  const [disableResidencyDetails, setDisableResidencyDetails] = React.useState(true);

  // Contact Details
  const [emailvalue, setEmailvalue] = React.useState();
  const [emailId, setEmailId] = React.useState();
  const [wechatvalue, setWechatvalue] = React.useState();
  const [wechatId, setWechatId] = React.useState();
  const [mobilephonevalue, setMobilephonevalue] = React.useState();
  const [mobilephoneId, setMobilephoneId] = React.useState();
  const [phonecode, setPhonecode] = React.useState();
  const [mobilephone2value, setMobilephone2value] = React.useState();
  const [mobilephone2Id, setMobilephone2Id] = React.useState();
  const [homephonevalue, setHomephonevalue] = React.useState();
  const [homephoneId, setHomephoneId] = React.useState();
  const [officephonevalue, setOfficephonevalue] = React.useState();
  const [officephoneId, setOfficephoneId] = React.useState();
  const [whatsappvalue, setWhatsappvalue] = React.useState();
  const [whatsappId, setWhatsappId] = React.useState();
  const [disableContactDetails, setDisableContactDetails] = React.useState(true);

  // Address Details
  const [addresstypevalue, setAddresstypevalue] = React.useState();
  const [addresstypeerror, setAddresstypeerror] = React.useState(false);
  const [addresscountryvalue, setAddresscountryvalue] = React.useState();
  const [addresscountryerror, setAddresscountryerror] = React.useState(false);
  const [addresspostalvalue, setAddresspostalvalue] = React.useState();
  const [errorMsgaddresspostal, setErrorMsgaddresspostal] = React.useState('');
  const [addressroadvalue, setAddressroadvalue] = React.useState();
  const [errorMsgaddressroad, seterrorMsgaddressroad] = React.useState('');
  const [addressline2value, setAddressline2value] = React.useState();
  const [errorMsgaddressline2, setErrorMsgaddressline2] = React.useState('');
  const [addressunitvalue, setAddressunitvalue] = React.useState(null);
  const [disableAddressDetails, setDisableAddressDetails] = React.useState(true);
  const [addressidvalue, setAddressIdValue] = React.useState();
  const [userAddressIdValue, setUserAddressIdValue] = React.useState();
  // user address id

  // KYC CDD Details
  const [risklevelvalue, setRisklevelvalue] = React.useState();
  const [riskcategoryvalue, setRiskcategoryvalue] = React.useState([]);
  const [cddId, setCddId] = React.useState();
  const [disableKycDetails, setDisableKycDetails] = React.useState(true);
  // customer_due_diligence_id

  // Doc
  const [idDocView, setIdDocView] = React.useState();
  const [addressDocView, setAddressDocView] = React.useState();
  const [cddDocView, setCddDocView] = React.useState();

  const refInputIdUpload = useRef();
  const [idDoc, setIdDoc] = React.useState('Upload ID / Passport');
  const refInputUtilityUpload = useRef();
  const [addressDoc, setAddressDoc] = React.useState('Upload Proof of Address Document');
  const refInputSupportingDocUpload = useRef();
  const [supportingDoc, setSupportingDoc] = React.useState('Upload Supporting Document');
  const [refInputUtilityUploadBtn, setRefInputUtilityUploadBtn] = React.useState('outlined');
  const [refInputIdUploadBtn, setRefInputIdUploadBtn] = React.useState('outlined');
  const [refInputSupportingDocUploadBtn, setRefInputSupportingDocUploadBtn] = React.useState(
    'outlined'
  );

  const handleRefInputUtilityUpload = (event) => {
    if (
      refInputUtilityUpload.current.value !== undefined ||
      refInputUtilityUpload.current.value !== null ||
      refInputUtilityUpload.current.value === ''
    ) {
      setRefInputUtilityUploadBtn('contained');
      setAddressDoc('Upload ' + getFileName(refInputUtilityUpload.current.value));
    }
    console.log('refInputUtilityUpload.current.value', refInputUtilityUpload.current.value);
  };
  const handleRefInputIdUpload = (event) => {
    if (
      refInputIdUpload.current.value !== undefined ||
      refInputIdUpload.current.value !== null ||
      refInputIdUpload.current.value === ''
    ) {
      setIdDoc('Upload ' + getFileName(refInputIdUpload.current.value));
      setRefInputIdUploadBtn('contained');
    }
  };
  const handleRefInputSupportingDocUpload = (event) => {
    if (
      refInputSupportingDocUpload.current.value !== undefined ||
      refInputSupportingDocUpload.current.value !== null ||
      refInputSupportingDocUpload.current.value === ''
    ) {
      setSupportingDoc('Upload ' + getFileName(refInputSupportingDocUpload.current.value));
      setRefInputSupportingDocUploadBtn('contained');
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log('refresh data', query.get('user'));
  //     const respUser = await userApi.getUserById(query.get('user'));
  //     console.log('respUser', respUser.data.users);

  //     setUserId(respUser.data.users.user_id);
  //     setGendervalue(respUser.data.users.gender_id);
  //     setAliasvalue(respUser.data.users.alias);
  //     setCountryvalue(respUser.data.users.country_of_birth_id);
  //     setBodvalue(respUser.data.users.date_of_birth);
  //     setFirstnamevalue(respUser.data.users.first_name);
  //     setLastnamevalue(respUser.data.users.last_name);
  //     setNationalityvalue(respUser.data.users.nationality_id);
  //     setUserEmail(respUser.data.users.email);
  //   };
  //   fetchData();
  // }, []);

  const fetchExistData = async () => {
    console.log('fetchExistData', query.get('user'));
    const respUser = await userApi.getUserById(query.get('user'));
    console.log('respUser', respUser.data.users);
    console.log('idDocView', fileViewer(76, 1, query.get('user')));

    setUserId(respUser.data.users.user_id);
    setGendervalue(respUser.data.users.gender_id);
    setAliasvalue(respUser.data.users.alias);
    setCountryvalue(respUser.data.users.country_of_birth_id);
    setBodvalue(respUser.data.users.date_of_birth);
    setFirstnamevalue(respUser.data.users.first_name);
    setLastnamevalue(respUser.data.users.last_name);
    setUsernamevalue(respUser.data.users.username);
    setNationalityvalue(respUser.data.users.nationality_id);

    const respContacts = await userApi.getContacts(query.get('user'));
    console.log('respContacts', respContacts.data.contacts);
    for (var i = 0; i < respContacts.data.contacts.length; i++) {
      if (respContacts.data.contacts[i].contact_type_id === 1) {
        setEmailvalue(respContacts.data.contacts[i].contact_value);
        setEmailId(respContacts.data.contacts[i].contact_id);
        console.log('emailvalue', emailvalue, respContacts.data.contacts[i].contact_value);
      }
      if (respContacts.data.contacts[i].contact_type_id === 2) {
        setMobilephonevalue(respContacts.data.contacts[i].contact_value);
        setMobilephoneId(respContacts.data.contacts[i].contact_id);
      }
      if (respContacts.data.contacts[i].contact_type_id === 3) {
        setMobilephone2value(respContacts.data.contacts[i].contact_value);
        setMobilephone2Id(respContacts.data.contacts[i].contact_id);
      }
      if (respContacts.data.contacts[i].contact_type_id === 8) {
        setOfficephonevalue(respContacts.data.contacts[i].contact_value);
        setOfficephoneId(respContacts.data.contacts[i].contact_id);
      }
      if (respContacts.data.contacts[i].contact_type_id === 7) {
        setHomephonevalue(respContacts.data.contacts[i].contact_value);
        setHomephoneId(respContacts.data.contacts[i].contact_id);
      }
      if (respContacts.data.contacts[i].contact_type_id === 6) {
        setWechatvalue(respContacts.data.contacts[i].contact_value);
        setWechatId(respContacts.data.contacts[i].contact_id);
      }
      if (respContacts.data.contacts[i].contact_type_id === 5) {
        setWhatsappvalue(respContacts.data.contacts[i].contact_value);
        setWhatsappId(respContacts.data.contacts[i].contact_id);
      }
      // setPhonecode();
    }
    try {
      const respId = await identificationApi.getIdentification(query.get('user'));
      console.log('respId', respId.data.identifications);
      for (var i = 0; i < respId.data.identifications.length; i++) {
        // if (respId.data.identifications[i].identification_type_id === 1) {
        //   console.log("existnric", respId.data.identifications);
        //   setNricvalue(respId.data.identifications[i].identification_value);
        // }

        // if (respId.data.identifications[i].identification_type_id === 2) {
        //   console.log("existpassport", respId.data.identifications);
        //   setPassportvalue(
        //     respId.data.identifications[i].identification_value
        //   );
        //   setIssuedatevalue(respId.data.identifications[i].issue_date);
        //   setExpirydatevalue(respId.data.identifications[i].expiry_date);
        // }

        if (
          respId.data.identifications[i].is_default === 1 &&
          respId.data.identifications[i].identification_name === 'NRIC'
        ) {
          // setIdvalue(respId.data.identifications[i].dentification_type_id);
          // idvalue = respId.data.identifications[i].dentification_type_id;
          setIdvalue(1);
          setNricvalue(respId.data.identifications[i].identification_value);
          setNricCountryvalue(respId.data.identifications[i].issuing_country);
          setNricHistoryId(respId.data.identifications[i].identification_history_id);
          setDefaultNric(1);
        }
        if (
          respId.data.identifications[i].is_default === 0 &&
          respId.data.identifications[i].identification_name === 'NRIC'
        ) {
          setIdvalue(1);
          setNricvalue(respId.data.identifications[i].identification_value);
          setNricCountryvalue(respId.data.identifications[i].issuing_country);
          setNricHistoryId(respId.data.identifications[i].identification_history_id);
          setDefaultNric(0);
        }
        if (
          respId.data.identifications[i].is_default === 1 &&
          respId.data.identifications[i].identification_name === 'Passport'
        ) {
          setIdvalue(2);
          setPassportvalue(respId.data.identifications[i].identification_value);
          setPassportCountryvalue(respId.data.identifications[i].issuing_country);
          setIssuedatevalue(respId.data.identifications[i].issue_date);
          setExpirydatevalue(respId.data.identifications[i].expiry_date);
          setPassportHistoryId(respId.data.identifications[i].identification_history_id);
          setDefaultPassport(1);
        }
        if (
          respId.data.identifications[i].is_default === 0 &&
          respId.data.identifications[i].identification_name === 'Passport'
        ) {
          setIdvalue(2);
          setPassportvalue(respId.data.identifications[i].identification_value);
          setPassportCountryvalue(respId.data.identifications[i].issuing_country);
          setIssuedatevalue(respId.data.identifications[i].issue_date);
          setExpirydatevalue(respId.data.identifications[i].expiry_date);
          setPassportHistoryId(respId.data.identifications[i].identification_history_id);
          setDefaultPassport(0);
        }
      }
    } catch (error) {
      setErrorApiMsg('ID Error');
      setOpenSnackbar(true);
      console.log('iderror', error);
      // setHasError(true);
    }

    try {
      const respAddress = await addressApi.getUserAddress(query.get('user'));
      console.log('respAddress', respAddress.data.userAddresses[0]);
      if (respAddress.data.userAddresses[0] !== undefined) {
        setAddresstypevalue(respAddress.data.userAddresses[0].address_type_id);
        setAddresscountryvalue(respAddress.data.userAddresses[0].country_id);
        setAddresspostalvalue(respAddress.data.userAddresses[0].postal_code);
        setAddressroadvalue(respAddress.data.userAddresses[0].address_line_1);
        setAddressline2value(respAddress.data.userAddresses[0].address_line_2);
        setAddressunitvalue(respAddress.data.userAddresses[0].floor_unit_no);
        setAddressIdValue(respAddress.data.userAddresses[0].address_id);
        setUserAddressIdValue(respAddress.data.userAddresses[0].user_address_id);
      }
      console.log(
        'addressDocView' + respAddress.data.userAddresses[0].user_address_id,
        fileViewer(109, 5, respAddress.data.userAddresses[0].user_address_id)
      );
    } catch (error) {
      setErrorApiMsg('Address Error');
      setOpenSnackbar(true);
      console.log('addresserror', error);
      // setHasError(true);
    }

    try {
      const respCdd = await cddApi.getCdd(query.get('user'));
      console.log('respCdd', respCdd.data.customerdd);
      setCddId(respCdd.data.customerdd.customer_due_diligence_id);
      console.log('customer_due_diligence_id', respCdd.data.customerdd.customer_due_diligence_id);
      console.log(
        'cddDocView',
        fileViewer(110, 4, respCdd.data.customerdd.customer_due_diligence_id)
      );
      setRisklevelvalue(respCdd.data.customerdd.risk_level_id + '');
      console.log('setRisklevelvalue', risklevelvalue);
      var arrRiskCatagory = [];
      for (var i = 0; i < respCdd.data.customerdd.customer_risk_categories.length; i++) {
        //   setRiskcategoryvalue(
        //     respCdd.data.customerdd.customer_risk_categories[i].risk_category_id
        //   );
        arrRiskCatagory.push(respCdd.data.customerdd.customer_risk_categories[i].risk_category_id);
        console.log(
          'respCdd.data.customerdd.customer_risk_categories[i].risk_category_id',
          respCdd.data.customerdd.customer_risk_categories[i].risk_category_id
        );
        console.log(
          'before',
          arrRiskCatagory,
          i,
          respCdd.data.customerdd.customer_risk_categories.length - 1
        );
        if (i === respCdd.data.customerdd.customer_risk_categories.length - 1) {
          setRiskcategoryvalue(arrRiskCatagory);
        }
      }
      console.log('last risk category', riskcategoryvalue);
      // } catch (error) {
      //   if (error.response) {
      //     /*
      //      * The request was made and the server responded with a
      //      * status code that falls out of the range of 2xx
      //      */
      //     console.log(error.response.data);
      //     console.log(error.response.status);
      //     console.log(error.response.headers);
      //   } else if (error.request) {
      //     /*
      //      * The request was made but no response was received, `error.request`
      //      * is an instance of XMLHttpRequest in the browser and an instance
      //      * of http.ClientRequest in Node.js
      //      */
      //     console.log(error.request);
      //   } else {
      //     // Something happened in setting up the request and triggered an Error
      //     console.log("Error", error.message);
      //   }
      //   console.log(error);
      // }

      console.log('idvalue', idvalue);
      //   var arrRiskCatagory = [];
      //   arrRiskCatagory = riskcategoryvalue;
      //   if (isChecked) {
      //     arrRiskCatagory.push(key);
      //   } else if (!isChecked) {
      //     removeA(arrRiskCatagory, key);
      //   }
      //   setRiskcategoryvalue(arrRiskCatagory);
      //   console.log("riskcategoryvalue", riskcategoryvalue);
    } catch (error) {
      setErrorApiMsg('CDD Error');
      setOpenSnackbar(true);
      console.log('cdderror', error);
      // setHasError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      };

      const respIdentificationtype = await commonApi.getIdentificationType();
      console.log('respIdentificationtype', respIdentificationtype.data.identificationtypes);
      setIdentificationlist(respIdentificationtype.data.identificationtypes);

      const respCountry = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/country",
        `${process.env.REACT_APP_COMMON_URL}/country`,
        { headers: header }
      );
      console.log('respCountry', respCountry.data.countries);
      setCountrylist(respCountry.data.countries);
      const respCompanyrole = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/companyrole",
        `${process.env.REACT_APP_COMMON_URL}/companyrole`,
        { headers: header }
      );
      setRolelist(respCompanyrole.data.companyroles);
      console.log('respCompanyrole', respCompanyrole.data.companyroles);

      const respaddresstype = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/addresstype",
        `${process.env.REACT_APP_COMMON_URL}/addresstype`,
        { headers: header }
      );
      console.log('respaddresstype', respaddresstype.data.addresstypes);
      setAddresstypelist(respaddresstype.data.addresstypes);
      const resprisklevel = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/risklevel",
        `${process.env.REACT_APP_COMMON_URL}/risklevel`,
        { headers: header }
      );
      console.log('resprisklevel', resprisklevel.data.risklevels);
      setRisklevellist(resprisklevel.data.risklevels);

      const respriskcategory = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/riskcategory",
        `${process.env.REACT_APP_COMMON_URL}/riskcategory`,
        { headers: header }
      );
      console.log('respriskcategory', respriskcategory.data.riskcategories);
      setRiskcategorylist(respriskcategory.data.riskcategories);
      //   const respcontacttype = await axios.get(
      //     "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/contacttype",
      //     { headers: header }
      //   );
      //   console.log("respcontacttype", respcontacttype.data.contacttypes);

      const respcessationtype = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/cessationtype",
        `${process.env.REACT_APP_COMMON_URL}/cessationtype`,
        { headers: header }
      );
      setCessationlist(respcessationtype.data.cessationtypes);
      console.log('respcessationtype', respcessationtype.data.cessationtypes);

      const respphonecode = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/phonecode",
        `${process.env.REACT_APP_COMMON_URL}/phonecode`,
        { headers: header }
      );
      console.log('respphonecode', respphonecode.data.phonecodes);
      setPhoneCodeList(respphonecode.data.phonecodes);
    };

    fetchData()
      .then(() => fetchExistData())
      .finally(() => setLoading(false));
  }, []);

  const handleClickOpen = (event) => {
    // setAge(event.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChangeCountry = (event) => {
    setCountryvalue(event.target.value);
  };

  //   const handleChange = (event) => {
  //     setState({ ...state, [event.target.name]: event.target.checked });
  //   };

  const handleChangeNationality = (event) => {
    if (event.target.value === undefined) {
      setNationalityerror(true);
    } else {
      setNationalityvalue(event.target.value);
      setNationalityerror(false);
    }
  };

  const handleChangeNRICCountry = (event) => {
    if (event.target.value === undefined) {
      setNricCountryerror(true);
    } else {
      setNricCountryvalue(event.target.value);
      setNricCountryerror(false);
    }
  };
  const handleChangePassportCountry = (event) => {
    if (event.target.value === undefined) {
      setPassportCountryerror(true);
    } else {
      setPassportCountryvalue(event.target.value);
      setPassportCountryerror(false);
    }
  };

  const handleChangeAddressCountry = (event) => {
    if (event.target.value === undefined) {
      setAddresscountryerror(true);
    } else {
      setAddresscountryvalue(event.target.value);
      setAddresscountryerror(false);
    }
  };

  const handleChangeAddressType = (event) => {
    if (event.target.value === undefined) {
      setAddresstypeerror(true);
    } else {
      setAddresstypevalue(event.target.value);
      setAddresstypeerror(false);
    }
  };

  const handleChangeGender = (event) => {
    console.log('setGendervalue', gendervalue);
    setGendervalue(event.target.value);
  };

  // const handleChangeId = (event) => {
  //   if (event.target.value === undefined) {
  //     setIderror(true);
  //   } else {
  //     setIdvalue(event.target.value);
  //     setIderror(false);
  //   }
  // };
  const handleChangeId = (event) => {
    if (event.target.value === undefined) {
      setIderror(true);
    } else {
      console.log('idvalue', event.target.value);
      setIdvalue(event.target.value);
      setIderror(false);
      if (event.target.value === 1) {
        setRequiredIdNric(true);
        setDefaultNric(1);
        setDefaultPassport(0);
      } else {
        setRequiredIdNric(false);
      }
      if (event.target.value === 2) {
        setRequiredIdPassport(true);
        setDefaultNric(0);
        setDefaultPassport(1);
      } else {
        setRequiredIdPassport(false);
      }
    }
  };

  const handleChangeAddressRoad = (event) => {
    if (event.target.value === undefined) {
      seterrorMsgaddressroad(errorMsg);
    } else {
      setAddressroadvalue(event.target.value);
      console.log('setAddressroadvalue', addressroadvalue);
      seterrorMsgaddressroad('');
    }
  };

  const handleChangePostal = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgaddresspostal(errorMsg);
    } else {
      setAddresspostalvalue(event.target.value);
      setErrorMsgaddresspostal('');
    }
  };
  const handleChangeAlias = (event) => {
    if (event.target.value === undefined) {
      //   setErrorMsgAlias(errorMsg);
      setAliasvalue(' ');
    } else {
      setAliasvalue(event.target.value);
      //   setErrorMsgAlias("");
    }
  };
  const handleChangeAddress2 = (event) => {
    // if (event.target.value === undefined) {
    //   setErrorMsgaddressline2(errorMsg);
    // } else {
    setAddressline2value(event.target.value);
    //   setErrorMsgaddressline2("");
    // }
  };
  const handleChangeFirstName = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgFirstName(errorMsg);
    } else {
      setFirstnamevalue(event.target.value);
      setErrorMsgFirstName('');
    }
  };

  const getFileName = (fullPath) => {
    return fullPath.replace(/^.*[\\\/]/, '');
  };

  function removeA(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  const handleCheckbox = (event, isChecked, value) => {
    console.log(isChecked, value);
    var arrRole = [];
    arrRole = rolevalue;
    if (isChecked) {
      arrRole.push(value);
    } else if (!isChecked) {
      removeA(arrRole, value);
    }
    setRolevalue(arrRole);
    console.log('rolevalue', rolevalue);
    //   this.res.add(value);
    //   if (this.res.size === 3) console.log(this.res);
  };
  //   const handleCheckboxRisk = (event, isChecked, data) => {
  //     console.log("handleCheckboxRisk", isChecked);
  //   };
  const handleCheckboxRisk = (event, isChecked, key) => {
    console.log('handleCheckboxRisk', isChecked, key, event);
    var arrRiskCatagory = [];
    arrRiskCatagory = riskcategoryvalue;
    if (isChecked) {
      arrRiskCatagory.push(key);
    } else if (!isChecked) {
      removeA(arrRiskCatagory, key);
    }
    setRiskcategoryvalue(arrRiskCatagory);
    console.log('riskcategoryvalue', riskcategoryvalue);
    //   this.res.add(value);
    //   if (this.res.size === 3) console.log(this.res);
  };

  //   const [value, setValue] = React.useState("Low");

  // const handleChangePhonecode = (event) => {
  //   setPhonecode(event.target.value);
  //   console.log("handleChangePhonecode");
  // };
  const handleChangeBod = (event) => {
    if (event.target.value === undefined) {
      setBodvalue(null);
      console.log('handleChangeBod', bodvalue);
    } else {
      setBodvalue(event.target.value);
    }
    console.log('handleChangeBod', bodvalue);
  };

  const handleChangeIssueDate = (event) => {
    setIssuedatevalue(event.target.value);
    console.log('handleChangeIssueDate', event.target.value);
  };

  const handleChangeExpiryDate = (event) => {
    // var CurrentDate = moment(new Date()).format("YYYY-MM-DD");
    // if (CurrentDate <= event.target.value) {
    //   setError(false);
    setExpirydatevalue(event.target.value);
    // } else {
    //   setError(true);
    // }
    console.log('handleChangeExpiryDate', event.target.value);
  };

  const handleChangeRiskLevel = (event) => {
    setRisklevelvalue(event.target.value);
    console.log('handleChangeRiskLevel', event.target.value, risklevelvalue);
  };

  const fileViewer = async (docType, dataType, dataId) => {
    const respDocument = await documentApi.getDocumentsSTS(docType, dataType, dataId);
    console.log('respDocument', respDocument.data.document[respDocument.data.document.length - 1]);
    if (docType === 76) {
      setIdDocView(respDocument.data.document[respDocument.data.document.length - 1]);
      // console.log("idDocViewLoad",idDocView);
    } else if (docType === 109) {
      setAddressDocView(respDocument.data.document[respDocument.data.document.length - 1]);
    } else if (docType === 110) {
      setCddDocView(respDocument.data.document[respDocument.data.document.length - 1]);
    }

    console.log('idDocViewLoad', idDocView);
    console.log('addressDocViewLoad', addressDocView);
    // return respDocument.data.document[respDocument.data.document.length-1];
  };

  const handleChangeUploadSupportingDoc = async (dataId) => {
    fileUploader(refInputSupportingDocUpload, 110, 4, dataId);
  };

  const handleChangeUploadUtility = (dataId) => {
    fileUploader(refInputUtilityUpload, 109, 5, dataId);
  };

  const handleUploadIdPassport = (dataId) => {
    fileUploader(refInputIdUpload, 76, 1, dataId);
  };

  const handleEditPersonalDetails = () => {
    console.log('handleEditPersonalDetails');
    setDisablePersonalDetails(false);
  };

  const handleCancelPersonalDetails = () => {
    console.log('handleCancelPersonalDetails');
    setDisablePersonalDetails(true);
    console.log('submitrefInputIdUpload', refInputIdUpload.current.value);
    refInputIdUpload.current.value = '';
    setRefInputIdUploadBtn('outlined');
    console.log('submitrefInputIdUploadCancel', refInputIdUpload.current.value);
  };

  const handleSubmitPersonalDetails = () => {
    console.log('handleSubmitPersonalDetails');
    if (firstnamevalue === undefined) {
      setErrorMsgFirstName(errorMsg);
    } else {
      setErrorMsgFirstName('');
    }

    if (idvalue === undefined) {
      setIderror(true);
    } else {
      setIderror(false);
    }

    if (aliasvalue === undefined) {
      setAliasvalue(' ');
      //   setErrorMsgAlias(errorMsg);
      // } else {
      //   setErrorMsgAlias("");
    }

    const fetchData = async () => {
      var userData = {
        // username: emailvalue,
        email: emailvalue,
        gender_id: gendervalue,
        nationality_id: nationalityvalue,
        first_name: firstnamevalue,
        last_name: lastnamevalue,
        alias: aliasvalue,
        country_of_birth_id: countryvalue,
        date_of_birth: bodvalue,
      };
      console.log('userData', userData);
      const respPersonalDetails = await userApi.updateUser(query.get('user'), userData);
      // const respNric = await identificationApi.updateIdentificationByIdentificationHistoryId(
      //           query.get("user"),
      //           nricHistoryId,
      //           nricData
      //         );
      console.log('respPersonalDetails', respPersonalDetails);
      const resphandleUploadIdPassport = await handleUploadIdPassport(query.get('user'));
      console.log('update_resphandleUploadIdPassport', resphandleUploadIdPassport);
      setOpen(true);
    };
    setIsLoadingSubmit('pd');
    fetchData().finally(() => handleCompletion());
  };

  const handleEditResidencyDetails = () => {
    console.log('handleEditResidencyDetails');
    setDisableResidencyDetails(false);
  };
  const handleCancelResidencyDetails = () => {
    console.log('handleCancelResidencyDetails');
    setDisableResidencyDetails(true);
  };

  const handleSubmitResidencyDetails = () => {
    setOpen(true);
    console.log('handleSubmitResidencyDetails');

    const fetchData = async () => {
      var userData = {
        // username: usernamevalue,
        // email: "-",
        gender_id: gendervalue,
        nationality_id: nationalityvalue,
        first_name: firstnamevalue,
        last_name: lastnamevalue,
        alias: aliasvalue,
        country_of_birth_id: countryvalue,
        date_of_birth: bodvalue,
      };
      // nricCountryvalue
      console.log('userData', userData);
      const respPersonalDetails = await userApi.updateUser(query.get('user'), userData);
      console.log('respPersonalDetails', respPersonalDetails);
      console.log('nricvalue', nricvalue);
      // var idData = { identifications: [] };

      try {
        const respId = await identificationApi.getIdentification(query.get('user'));
        console.log('update respId', respId);
        if (respId.data.identifications.length === 0) {
          var idData = { identifications: [] };
          if (nricvalue !== undefined) {
            if (defaultNric === 0 && defaultPassport === 0) {
              var nricData = {
                identification_type_id: 1,
                identification_value: nricvalue,
                issuing_country: nricCountryvalue,
                issue_date: null,
                expiry_date: null,
                is_default: 1,
              };
              idData.identifications.push(nricData);
              console.log('idDatapush', idData);
            } else {
              var nricData = {
                identification_type_id: 1,
                identification_value: nricvalue,
                issuing_country: nricCountryvalue,
                issue_date: null,
                expiry_date: null,
                is_default: defaultNric,
              };
              idData.identifications.push(nricData);
              console.log('idDatapush', idData);
            }
          }

          if (
            passportvalue !== undefined &&
            issuedatevalue !== undefined &&
            expirydatevalue !== undefined &&
            passportvalue !== null &&
            issuedatevalue !== null &&
            expirydatevalue !== null
          ) {
            if (defaultNric === 0 && defaultPassport === 0 && nricvalue === undefined) {
              var passportData = {
                identification_type_id: 2,
                identification_value: passportvalue,
                issuing_country: passportCountryvalue,
                issue_date: issuedatevalue,
                expiry_date: expirydatevalue,
                is_default: 1,
              };
              idData.identifications.push(passportData);
              console.log('passportDatapush', idData);
            } else {
              var passportData = {
                identification_type_id: 2,
                identification_value: passportvalue,
                issuing_country: passportCountryvalue,
                issue_date: issuedatevalue,
                expiry_date: expirydatevalue,
                is_default: defaultPassport,
              };
              idData.identifications.push(passportData);
              console.log('passportDatapush', idData);
            }
          }
          console.log('idData', idData);

          try {
            const respId = await identificationApi.createIdentification(query.get('user'), idData);
            console.log('respId', respId);
          } catch (error) {
            setErrorApiMsg('ID Error');
            setOpenSnackbar(true);
            console.log('iderror', error);
            // setHasError(true);
          }
        } else {
          if (nricvalue !== undefined) {
            var nricData = {
              identification_type_id: 1,
              identification_value: nricvalue,
              issuing_country: nricCountryvalue,
              issue_date: null,
              expiry_date: null,
              is_default: defaultNric,
            };
            // idData.identifications.push(nricData);
            // console.log("idDatapush", idData);
            console.log('nricData', nricData);
            try {
              const respNric = await identificationApi.updateIdentificationByIdentificationHistoryId(
                query.get('user'),
                nricHistoryId,
                nricData
              );
              console.log('respNric', respNric);
            } catch (error) {
              setErrorApiMsg(
                'NRIC Update Error: ' + JSON.stringify(error.response.data.error.message)
              );
              setOpenSnackbar(true);
              console.log('nricerror', error.response, JSON.stringify(error.response.data.message));
              // setHasError(true);
            }
          }

          if (
            passportvalue !== undefined ||
            issuedatevalue !== undefined ||
            expirydatevalue !== undefined
          ) {
            if (passportvalue === undefined) {
              setPassportvalue(null);
            }
            if (issuedatevalue === undefined) {
              setIssuedatevalue(null);
            }
            if (expirydatevalue === undefined) {
              setExpirydatevalue(null);
            }

            var passportData = {
              identification_type_id: 2,
              identification_value: passportvalue,
              issuing_country: passportCountryvalue,
              issue_date: issuedatevalue,
              expiry_date: expirydatevalue,
              is_default: defaultPassport,
            };
            console.log('passportData', passportData);
            // idData.identifications.push(passportData);
            // console.log("passportDatapush", idData);
            try {
              const respPassport = await identificationApi.updateIdentificationByIdentificationHistoryId(
                query.get('user'),
                passportHistoryId,
                passportData
              );
              console.log('respPassport', respPassport);
            } catch (error) {
              setErrorApiMsg(
                'Passport Update Error: ' + JSON.stringify(error.response.data.error.message)
              );
              setOpenSnackbar(true);
              console.log(
                'passporterror',
                error.response,
                JSON.stringify(error.response.data.error.message)
              );
              // setHasError(true);
            }
          }
        }
      } catch (error) {
        setErrorApiMsg('ID Error');
        setOpenSnackbar(true);
        console.log('iderror', error);
      }

      // console.log("idData", idData);
      // const respId = await identificationApi.updateIdentification(
      //   query.get("user"),
      //   idData
      // );
    };
    // if (!isError) {
    setIsLoadingSubmit('rd');
    fetchData().finally(() => handleCompletion());
    // } else {
    //   setErrorApiMsg("ID Error");
    //   setOpenSnackbar(true);
    //   console.log("iderror - ");
    // }

    // if(){
    //   fetchData().finally(() => handleCompletion());
    // }else if(){
    //   fetchData().finally(() => handleCompletion());
    // }else if(){
    //   fetchData().finally(() => handleCompletion());
    // }
  };
  const handleEditContactDetails = () => {
    console.log('handleEditContactDetails');
    setDisableContactDetails(false);
  };
  const handleCancelContactDetails = () => {
    console.log('handleCancelContactDetails');
    setDisableContactDetails(true);
  };

  const handleSubmitContactDetails = () => {
    setOpen(true);
    console.log('handleSubmitContactDetails');
    const fetchData = async () => {
      let newContacts = [];
      let updateContacts = [];
      // var contactData = {
      //   contacts: [],
      // };

      var emailData = {
        contact_type_id: 1,
        contact_value: emailvalue || '',
        country_phone_code_id: null,
        is_primary: 0,
        contact_id: emailId,
      };
      if (emailId) {
        updateContacts.push(emailData);
      } else {
        newContacts.push(emailData);
      }

      if (wechatvalue !== undefined) {
        var wechatData = {
          contact_type_id: 6,
          contact_value: wechatvalue,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: wechatId,
        };
        if (wechatId) {
          updateContacts.push(wechatData);
        } else {
          newContacts.push(wechatData);
        }
      }
      console.log('mobilephonevaluemobilephonevalue', mobilephonevalue);
      if (mobilephonevalue !== undefined) {
        var mobilephoneData = {
          contact_type_id: 2,
          contact_value: mobilephonevalue,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: mobilephoneId,
        };
        console.log('mobilephonevaluem mobilephoneId', {
          mobilephoneId,
          mobilephoneData,
        });

        if (mobilephoneId) {
          updateContacts.push(mobilephoneData);
        } else {
          newContacts.push(mobilephoneData);
        }
      }

      if (mobilephone2value !== undefined) {
        var mobilephone2Data = {
          contact_type_id: 3,
          contact_value: mobilephone2value,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: mobilephone2Id,
        };
        if (mobilephone2Id) {
          updateContacts.push(mobilephone2Data);
        } else {
          newContacts.push(mobilephone2Data);
        }
      }

      if (officephonevalue !== undefined) {
        var officephoneData = {
          contact_type_id: 8,
          contact_value: officephonevalue,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: officephoneId,
        };
        if (officephoneId) {
          updateContacts.push(officephoneData);
        } else {
          newContacts.push(officephoneData);
        }
      }
      if (homephonevalue !== undefined) {
        var homephoneData = {
          contact_type_id: 7,
          contact_value: homephonevalue,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: homephoneId,
        };
        if (homephoneId) {
          updateContacts.push(homephoneData);
        } else {
          newContacts.push(homephoneData);
        }
      }
      if (whatsappvalue !== undefined) {
        var whatsappData = {
          contact_type_id: 5,
          contact_value: whatsappvalue,
          country_phone_code_id: null,
          is_primary: 0,
          contact_id: whatsappId,
        };
        if (whatsappId) {
          updateContacts.push(whatsappData);
        } else {
          newContacts.push(whatsappData);
        }
      }

      if (userEmail != emailvalue) {
        var userData = {
          // username: emailvalue,
          email: emailvalue,
          gender_id: gendervalue,
          nationality_id: nationalityvalue,
          first_name: firstnamevalue,
          last_name: lastnamevalue,
          alias: aliasvalue,
          country_of_birth_id: countryvalue,
          date_of_birth: bodvalue,
        };
        console.log('userData', userData);
        const respPersonalDetails = await userApi.updateUser(query.get('user'), userData);
      }
      if (
        wechatvalue !== undefined ||
        whatsappvalue !== undefined ||
        homephonevalue !== undefined ||
        officephonevalue !== undefined ||
        mobilephone2value !== undefined ||
        mobilephonevalue !== undefined
      ) {
        const respContacts = await userApi.getContacts(query.get('user'));
        console.log('respContacts', respContacts.data.contacts);

        // if (respContacts.data.contacts.length === 0) {
        //   const respContact = await userApi.createContacts(
        //     query.get("user"),
        //     contactData
        //   );
        // } else {
        //   for (var i = 0; i < contactData.contacts.length; i++) {
        //     const respContact = await userApi.updateContactById(
        //       query.get("user"),
        //       contactData.contacts[i].contact_id,
        //       contactData.contacts[i]
        //     );
        //     console.log("respContact", respContact);
        //   }
        // }

        if (newContacts.length > 0) {
          const respContact = await userApi.createContacts(query.get('user'), {
            contacts: newContacts,
          });
        }
        if (updateContacts.length > 0) {
          for (var i = 0; i < updateContacts.length; i++) {
            const respContact = await userApi.updateContactById(
              query.get('user'),
              updateContacts[i].contact_id,
              updateContacts[i]
            );
            console.log('respContact', respContact);
          }
        }
      }
    };
    setIsLoadingSubmit('cd');
    fetchData().finally(() => handleCompletion());
  };

  const handleEditAddressDetails = () => {
    console.log('handleEditAddress');
    setDisableAddressDetails(false);
  };
  const handleCancelAddressDetails = () => {
    console.log('handleCancelAddress');
    setDisableAddressDetails(true);
    console.log('submitrefInputUtilityUploadCAncel_before', refInputUtilityUpload.current.value);
    refInputUtilityUpload.current.value = '';
    setRefInputUtilityUploadBtn('outlined');
    console.log('submitrefInputUtilityUploadCancel', refInputUtilityUpload.current.value);
  };

  const handleSubmitAddressDetails = () => {
    console.log('submitrefInputUtilityUpload', refInputUtilityUpload.current.value);
    setOpen(true);

    const fetchData = async () => {
      const addressData = {
        // userAddresses: [
        // {
        address_id: addressidvalue,
        address_type_id: addresstypevalue,
        country_id: addresscountryvalue,
        floor_unit_no: addressunitvalue,
        address_line_1: addressroadvalue,
        address_line_2: addressline2value,
        address_line_3: null,
        postal_code: addresspostalvalue,
        is_default: 1,
        // },
        // ],
      };
      console.log('handleSubmitAddress', addressData);
      try {
        const respAddress = await addressApi.updateUserAddressByAddressId(
          query.get('user'),
          userAddressIdValue,
          addressData
        );

        console.log('respAddress', respAddress);
        console.log(
          'respAddress.data.userAddress.user_address_id',
          respAddress.data.userAddress.user_address_id
        );
        const resphandleChangeUploadUtility = await handleChangeUploadUtility(
          respAddress.data.userAddress.user_address_id
        );
        console.log('update_resphandleChangeUploadUtility', resphandleChangeUploadUtility);
      } catch (error) {
        setErrorApiMsg(
          'Update Address Error: ' + JSON.stringify(error.response.data.error.message)
        );
        setOpenSnackbar(true);
        console.log(JSON.stringify(error.response));
      }
    };

    // refInputUtilityUpload.current.value = "";
    // setRefInputUtilityUploadBtn("outlined");
    // setAddressDoc("Upload Proof of Address Document");
    setIsLoadingSubmit('ad');
    fetchData().finally(() => handleCompletion());
  };

  const handleEditKycDetails = () => {
    console.log('handleEditAddress');
    setDisableKycDetails(false);
  };
  const handleCancelKycDetails = () => {
    console.log('handleCancelKyc');
    setDisableKycDetails(true);
    console.log('submitrefInputKycUpload', refInputSupportingDocUpload.current.value);
    refInputSupportingDocUpload.current.value = '';
    setRefInputSupportingDocUploadBtn('outlined');
    console.log('submitrefInputKycUploadCancel', refInputSupportingDocUpload.current.value);
  };

  const handleSubmitKycDetails = () => {
    setOpen(true);

    console.log('handleSubmitAddress');
    const fetchData = async () => {
      console.log('risklevelvalue', risklevelvalue);
      console.log('riskcategoryvalue', riskcategoryvalue);
      const cddData = {
        risk_level_id: risklevelvalue,
        customer_risk_categories: riskcategoryvalue,
      };
      const respCdd = await cddApi.updateCdd(query.get('user'), cddId, cddData);
      console.log('respCdd', respCdd.data.customerdd.customer_due_diligence_id);

      const resphandleChangeUploadSupportingDoc = await handleChangeUploadSupportingDoc(cddId);
      console.log(
        'update_resphandleChangeUploadSupportingDoc',
        resphandleChangeUploadSupportingDoc
      );
    };
    setIsLoadingSubmit('kyc');
    fetchData().finally(() => handleCompletion());
  };

  const handleRisks = (event, isChecked, key) => {
    var arrRiskCatagory = [];
    arrRiskCatagory = riskcategoryvalue;
    if (isChecked) {
      arrRiskCatagory.push(key);
    } else if (!isChecked) {
      removeA(arrRiskCatagory, key);
    }
    setRiskcategoryvalue(arrRiskCatagory);
    console.log('riskcategoryvalue', riskcategoryvalue);
  };

  const handleRisk1 = (event, isChecked) => {
    console.log(event, isChecked, '1');
    handleRisks(event, isChecked, 1);
  };
  const handleRisk2 = (event, isChecked) => {
    console.log(event, isChecked, '2');
    handleRisks(event, isChecked, 2);
  };
  const handleRisk3 = (event, isChecked) => {
    console.log(event, isChecked, '3');
    handleRisks(event, isChecked, 3);
  };
  const handleRisk4 = (event, isChecked) => {
    console.log(event, isChecked, '4');
    handleRisks(event, isChecked, 4);
  };
  const handleRisk5 = (event, isChecked) => {
    console.log(event, isChecked, '5');
    handleRisks(event, isChecked, 5);
  };

  //   // The first commit of Material-UI
  //   const [selectedDate, setSelectedDate] = React.useState(
  //     new Date("2014-08-18T21:11:54")
  //   );

  //   const handleDateChange = (date) => {
  //     setSelectedDate(date);
  //   };

  //   const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCompletion = () => {
    setOpen(false);
    setOpenDialog(true);
    setDisableAddressDetails(true);
    setDisableContactDetails(true);
    setDisableKycDetails(true);
    setDisablePersonalDetails(true);
    setDisableResidencyDetails(true);
    setIsLoadingSubmit(false);
  };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // window.location.reload(false);
    // history.push({ pathname: "/dashboard" });
  };
  const checkRiskCategory = (data) => {
    var result = false;
    // console.log("checkRiskCategory", data, riskcategoryvalue);
    for (var i = 0; i < riskcategoryvalue.length; i++) {
      if (data === riskcategoryvalue[i]) {
        result = true;
      }
      // console.log("checkRiskCategory", data, result);
    }
    return result;
  };

  return (
    <>
      <AppBar style={{ background: 'transparent', boxShadow: 'none' }} position="static">
        <Toolbar style={{ padding: '0px' }}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={0}
          >
            <Grid item>
              <Button
                size="medium"
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                // onClick={() => history.goBack()}
                onClick={() => history.push({ pathname: '/individual' })}
              >
                BACK TO INDIVIDUALS DATABASE
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                // onClick={() => history.goBack()}
                onClick={() =>
                  history.push({
                    pathname: '/individualdocuments',
                    search: '?user=' + query.get('user'),
                  })
                }
              >
                GO TO INDIVIDUALS DOCUMENTS
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <PageTitle title="Individual Details" />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {/* <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Role
            </Typography>
          </div>
          <Grid container spacing={3}>
            {rolelist.map((element) => (
              <Grid item xs={12}>
                <CheckboxField
                  key={element.company_role_id}
                  label={element.role_name}
                  category={element.company_role_id}
                  onChange={handleCheckbox}
                />
              </Grid>
            ))}
          </Grid> */}
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Personal Identifying Details
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {isLoadingSubmit == 'pd' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={
                      disablePersonalDetails
                        ? handleEditPersonalDetails
                        : handleSubmitPersonalDetails
                    }
                  >
                    {disablePersonalDetails
                      ? 'Edit Personal Details'
                      : 'Confirm Change of Personal Details'}
                  </Button>
                )}
                {disablePersonalDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelPersonalDetails}>
                    Cancel
                  </Button>
                )}
                {/* {isLoadingbtn && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )} */}
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error={iderror} required fullWidth className={classes.formControl}>
                <InputLabel disabled={disablePersonalDetails} id="demo-simple-select-label">
                  Identification Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={idvalue}
                  onChange={handleChangeId}
                  disabled={disablePersonalDetails}
                  //   onInput={(e) => setIdvalue(e.target.value)}
                  error={iderror}
                >
                  <MenuItem value={1}>{'NRIC'}</MenuItem>
                  <MenuItem value={2}>{'Passport'}</MenuItem>
                  {/* {identificationlist.map((element) => (
                    <MenuItem value={element.identification_type_id}>
                      {element.identification_name}
                    </MenuItem>
                  ))} */}
                </Select>
                <FormHelperText hidden={!iderror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstname"
                name="firstname"
                label="First Name"
                value={firstnamevalue}
                disabled={disablePersonalDetails}
                fullWidth
                onChange={handleChangeFirstName}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgFirstName !== '' ? true : false}
                helperText={errorMsgFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastname"
                name="lastname"
                label="Last Name"
                value={lastnamevalue}
                fullWidth
                onInput={(e) => setLastnamevalue(e.target.value)}
                disabled={disablePersonalDetails}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                disabled={disablePersonalDetails}
                required
                component="fieldset"
                // defaultValue={gendervalue}
              >
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  //   defaultValue="1"
                  //   value={gendervalue}
                  onChange={handleChangeGender}
                  row
                >
                  <FormControlLabel
                    value="1"
                    checked={gendervalue === 1}
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="2"
                    checked={gendervalue === 2}
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
              {/* <FormControl required fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gendervalue}
                  onChange={handleChangeGender}
                >
                  <MenuItem value={10}>Male</MenuItem>
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                id="alias"
                name="alias"
                label="Alias"
                disabled={disablePersonalDetails}
                fullWidth
                value={aliasvalue}
                // error={errorMsgAlias !== "" ? true : false}
                // helperText={errorMsgAlias}
                onChange={handleChangeAlias}
                // onInput={(e) => setAliasvalue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="date"
                label="Birthday"
                type="date"
                onChange={handleChangeBod}
                disabled={disablePersonalDetails}
                defaultValue={bodvalue === undefined ? null : moment(bodvalue).format('YYYY-MM-DD')}
                // onInput={(e) => setBodvalue(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel disabled={disablePersonalDetails} id="demo-simple-select-label">
                  Country of Birth
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={countryvalue}
                  onChange={handleChangeCountry}
                  disabled={disablePersonalDetails}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography className={classes.typo}>
                Upload ID/Passport
              </Typography>
              <input
                onChange={handleChangeUploadIdPassport}
                ref={refInputIdUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              />
            </Grid> */}
            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              {idDocView !== undefined ? (
                <Button
                  variant="contained"
                  target="_blank"
                  color={'primary'}
                  href={idDocView.document_url}
                >
                  View ID / Passport
                </Button>
              ) : null}

              {/* <Button
                component={Link}
                target="_blank"
                to="https://mbs-docs.s3.ap-southeast-1.amazonaws.com/0e2505ff-06d4-4ac6-85d1-84eff60da4c912345"
                color="primary"
              >
                MyButton
              </Button> */}
              {/* <Link
                href="https://google.com"
                // onClick={preventDefault}
                // onChange={handleRefInputIdUpload}
              >
                {"abc"}
              </Link> */}
              <Button
                disabled={disablePersonalDetails}
                variant={refInputIdUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputIdUpload}
              >
                {idDoc}
                {/* Upload ID/Passport */}
                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputIdUpload} />
              </Button>
              <InputLabel>{/* {refInputUtilityUpload.current.value === undefined} */}</InputLabel>

              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Residency Details
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {isLoadingSubmit == 'rd' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={
                      disableResidencyDetails
                        ? handleEditResidencyDetails
                        : handleSubmitResidencyDetails
                    }
                  >
                    {disableResidencyDetails
                      ? 'Edit Residency Details'
                      : 'Confirm Change of Residency Details'}
                  </Button>
                )}
                {disableResidencyDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelResidencyDetails}>
                    Cancel
                  </Button>
                )}
                {/* {isLoadingbtn && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )} */}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                error={nationalityerror}
                required
                disabled={disableResidencyDetails}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableResidencyDetails} id="demo-simple-select-label">
                  Nationality
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nationalityvalue}
                  onChange={handleChangeNationality}
                  error={nationalityerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!nationalityerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="nric"
                name="nric"
                label="NRIC No"
                fullWidth
                value={nricvalue}
                disabled={disableResidencyDetails}
                onInput={(e) => setNricvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={nricCountryerror}
                required={requiredIdNric}
                fullWidth
                className={classes.formControl}
                disabled={disableResidencyDetails}
              >
                <InputLabel id="demo-simple-select-label-nric-country">
                  NRIC Issue Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-nric-country"
                  id="demo-simple-select-nric-country"
                  value={nricCountryvalue}
                  onChange={handleChangeNRICCountry}
                  error={nricCountryerror}
                  disabled={disableResidencyDetails}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!nricCountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="passport"
                name="passport"
                disabled={disableResidencyDetails}
                value={passportvalue}
                label="Passport No"
                fullWidth
                onInput={(e) => setPassportvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={passportCountryerror}
                required={requiredIdPassport}
                fullWidth
                className={classes.formControl}
                disabled={disableResidencyDetails}
              >
                <InputLabel id="demo-simple-select-label-passport-country">
                  Passport Issue Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-passport-country"
                  id="demo-simple-select-passport-country"
                  value={passportCountryvalue}
                  onChange={handleChangePassportCountry}
                  error={passportCountryerror}
                  disabled={disableResidencyDetails}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!passportCountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="date"
                label="Date Of Issue"
                type="date"
                value={
                  issuedatevalue === undefined ? null : moment(issuedatevalue).format('YYYY-MM-DD')
                }
                disabled={disableResidencyDetails}
                onChange={handleChangeIssueDate}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: moment().add(-10, 'y').format('YYYY-MM-DD'),
                    max: moment().format('YYYY-MM-DD'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="date"
                label="Date Of Expiry"
                type="date"
                value={
                  expirydatevalue === undefined
                    ? null
                    : moment(expirydatevalue).format('YYYY-MM-DD')
                }
                disabled={disableResidencyDetails}
                onChange={handleChangeExpiryDate}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: moment().add(1, 'd').format('YYYY-MM-DD'),
                    max: moment().add(50, 'y').format('YYYY-MM-DD'),
                  },
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Contact Details
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {isLoadingSubmit == 'cd' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={
                      disableContactDetails ? handleEditContactDetails : handleSubmitContactDetails
                    }
                  >
                    {disableContactDetails
                      ? 'Edit Contact Details'
                      : 'Confirm Change of Contact Details'}
                  </Button>
                )}
                {disableContactDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelContactDetails}>
                    Cancel
                  </Button>
                )}
                {/* {isLoadingbtn && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )} */}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="emailaddress1"
                name="emailaddress1"
                label="Email Address"
                value={emailvalue}
                fullWidth
                disabled={disableContactDetails}
                onInput={(e) => setEmailvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phonecode}
                  onChange={handleChangePhonecode}
                >
                  {phonecodelist.map((element) => (
                    <MenuItem value={element.country_phone_code_id}>
                      {element.country_name} - {element.phone_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="mobilepohne"
                name="mobilepohne"
                label="Mobile Phone"
                onInput={(e) => setMobilephonevalue(e.target.value)}
              /> */}
              <MuiPhoneNumber
                fullWidth
                label="Mobile Phone"
                disabled={disableContactDetails}
                defaultCountry={'sg'}
                value={mobilephonevalue}
                // onChange={setMobilephonevalue}
                onInput={(e) => setMobilephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phonecode}
                  onChange={handleChangePhonecode}
                >
                  {phonecodelist.map((element) => (
                    <MenuItem value={element.country_phone_code_id}>
                      {element.country_name} - {element.phone_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="mobilephone2"
                name="mobilephone2"
                label="Mobile Phone 2"
                onInput={(e) => setMobilephone2value(e.target.value)}
              /> */}
              <MuiPhoneNumber
                fullWidth
                label="Mobile Phone 2"
                defaultCountry={'sg'}
                value={mobilephone2value}
                disabled={disableContactDetails}
                // onChange={setMobilephone2value}
                onInput={(e) => setMobilephone2value(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phonecode}
                  onChange={handleChangePhonecode}
                >
                  {phonecodelist.map((element) => (
                    <MenuItem value={element.country_phone_code_id}>
                      {element.country_name} - {element.phone_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="homephone2"
                name="homeephone"
                label="Home Phone"
                onInput={(e) => setHomephonevalue(e.target.value)}
              /> */}
              <MuiPhoneNumber
                fullWidth
                label="Home Phone"
                defaultCountry={'sg'}
                value={homephonevalue}
                disabled={disableContactDetails}
                // onChange={setHomephonevalue}
                onInput={(e) => setHomephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phonecode}
                  onChange={handleChangePhonecode}
                >
                  {phonecodelist.map((element) => (
                    <MenuItem value={element.country_phone_code_id}>
                      {element.country_name} - {element.phone_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="mobilepohne"
                name="mobilepohne"
                label="Office Phone"
                onInput={(e) => setOfficephonevalue(e.target.value)}
              /> */}
              <MuiPhoneNumber
                fullWidth
                label="Office Phone"
                defaultCountry={'sg'}
                value={officephonevalue}
                disabled={disableContactDetails}
                // onChange={setOfficephonevalue}
                onInput={(e) => setOfficephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phonecode}
                  onChange={handleChangePhonecode}
                >
                  {phonecodelist.map((element) => (
                    <MenuItem value={element.country_phone_code_id}>
                      {element.country_name} - {element.phone_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="mobilepohne"
                name="mobilepohne"
                label="Whatsapp"
                onInput={(e) => setWhatsappvalue(e.target.value)}
              /> */}
              <MuiPhoneNumber
                fullWidth
                label="Whatsapp"
                defaultCountry={'sg'}
                disabled={disableContactDetails}
                value={whatsappvalue}
                // onChange={setWhatsappvalue}
                onInput={(e) => setWhatsappvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="wechat"
                name="wechat"
                label="Wechat"
                disabled={disableContactDetails}
                value={wechatvalue}
                fullWidth
                onInput={(e) => setWechatvalue(e.target.value)}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Address Details
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {isLoadingSubmit == 'ad' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={
                      disableAddressDetails ? handleEditAddressDetails : handleSubmitAddressDetails
                    }
                  >
                    {disableAddressDetails
                      ? 'Edit Address Details'
                      : 'Confirm Change of Address Details'}
                  </Button>
                )}
                {disableAddressDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelAddressDetails}>
                    Cancel
                  </Button>
                )}
                {/* {isLoadingbtn && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )} */}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                error={addresstypeerror}
                required
                fullWidth
                disabled={disableAddressDetails}
                className={classes.formControl}
              >
                <InputLabel disabled={disableAddressDetails} id="demo-simple-select-label">
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresstypevalue}
                  onChange={handleChangeAddressType}
                  error={addresstypeerror}
                >
                  {addresstypelist.map((element) => (
                    <MenuItem value={element.address_type_id}>{element.address_type_name}</MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Office</MenuItem>
                  <MenuItem value={2}>Home</MenuItem> */}
                </Select>
                <FormHelperText hidden={!addresstypeerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="postal"
                name="postal"
                label="Postal Code"
                value={addresspostalvalue}
                disabled={disableAddressDetails}
                fullWidth
                onChange={handleChangePostal}
                // onInput={(e) => setAddresspostalvalue(e.target.value)}
                type="number"
                error={errorMsgaddresspostal !== '' ? true : false}
                helperText={errorMsgaddresspostal}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="unitno"
                name="unitno"
                label="Floor No./Unit No."
                value={addressunitvalue}
                fullWidth
                disabled={disableAddressDetails}
                onInput={(e) => setAddressunitvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="street"
                name="street"
                label="Street / Road"
                fullWidth
                value={addressroadvalue}
                disabled={disableAddressDetails}
                onChange={handleChangeAddressRoad}
                // onInput={(e) => setAddressroadvalue(e.target.value)}
                error={errorMsgaddressroad !== '' ? true : false}
                helperText={errorMsgaddressroad}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // required
                id="addressline2"
                name="addressline2"
                label="Address Line 2"
                value={addressline2value}
                fullWidth
                disabled={disableAddressDetails}
                onChange={handleChangeAddress2}
                // onInput={(e) => setAddressline2value(e.target.value)}
                // error={errorMsgaddressline2 !== "" ? true : false}
                // helperText={errorMsgaddressline2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={addresscountryerror}
                required
                fullWidth
                value={addresscountryvalue}
                disabled={disableAddressDetails}
                className={classes.formControl}
              >
                <InputLabel disabled={disableAddressDetails} id="demo-simple-select-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresscountryvalue}
                  onChange={handleChangeAddressCountry}
                  error={addresscountryerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!addresscountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              {addressDocView !== undefined ? (
                <Button
                  variant="contained"
                  target="_blank"
                  color={'primary'}
                  href={addressDocView.document_url}
                >
                  View Proof of Address
                </Button>
              ) : null}
              <Button
                disabled={disableAddressDetails}
                variant={refInputUtilityUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputUtilityUpload}
              >
                {addressDoc}
                {/* Upload Proof of Address Document */}
                {/* {refInputUtilityUpload === undefined
                  ? null
                  : " - " + refInputUtilityUpload.current.value} */}
                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputUtilityUpload} />
              </Button>
              <InputLabel>{/* {refInputUtilityUpload.current.value === undefined} */}</InputLabel>

              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
          </Grid>

          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              KYC CDD Details
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {isLoadingSubmit == 'kyc' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={disableKycDetails ? handleEditKycDetails : handleSubmitKycDetails}
                  >
                    {disableKycDetails ? 'Edit KYC Details' : 'Confirm Change of KYC Details'}
                  </Button>
                )}
                {disableKycDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelKycDetails}>
                    Cancel
                  </Button>
                )}
                {/* {isLoadingbtn && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )} */}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl disabled={disableKycDetails} component="fieldset">
                <FormLabel component="legend">Risk Level</FormLabel>
                <RadioGroup
                  aria-label="risklevel"
                  name="risklevel"
                  value={risklevelvalue}
                  onChange={handleChangeRiskLevel}
                  row
                >
                  <FormControlLabel
                    // defaultChecked={risklevelvalue === 1}
                    // checked={risklevelvalue === 1}
                    value="1"
                    control={<Radio />}
                    label="Low"
                  />
                  <FormControlLabel
                    // defaultChecked={risklevelvalue === 2}
                    // checked={risklevelvalue === 2}
                    value="2"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    // defaultChecked={risklevelvalue === 3}
                    // checked={risklevelvalue === 3}
                    value="3"
                    control={<Radio />}
                    label="High"
                  />
                  {/* {risklevellist.map((element) => (
                    <FormControlLabel
                      value={element.risk_level_id}
                      control={<Radio />}
                      label={element.risk_level_name}
                    />
                  ))} */}
                </RadioGroup>
              </FormControl>
              {/* <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Low"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="High"
                  />
                </RadioGroup>
              </FormControl> */}
            </Grid>
            <Grid item xs={12}>
              <div>Risk Category</div>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableKycDetails}
                      defaultChecked={checkRiskCategory(1)}
                      onChange={handleRisk1}
                    />
                  }
                  label="Crime"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableKycDetails}
                      defaultChecked={checkRiskCategory(2)}
                      onChange={handleRisk2}
                    />
                  }
                  label="Money Laundering"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableKycDetails}
                      defaultChecked={checkRiskCategory(3)}
                      onChange={handleRisk3}
                    />
                  }
                  label="Politically Exposed Person"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableKycDetails}
                      defaultChecked={checkRiskCategory(4)}
                      onChange={handleRisk4}
                    />
                  }
                  label="Regulatory"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableKycDetails}
                      defaultChecked={checkRiskCategory(5)}
                      onChange={handleRisk5}
                    />
                  }
                  label="Terrorism"
                />
              </FormGroup>
              {/* {riskcategorylist.map((element) => (
                <CheckboxField
                  disabled={disableKycDetails}
                  //   checkedkyc={
                  //     checkRiskCategory(element.risk_category_id)
                  //     // element.risk_category_id ===
                  //     // riskcategoryvalue[element.risk_category_id]
                  //   }
                  key={element.risk_category_id}
                  label={element.risk_category_name}
                  category={element.risk_category_id}
                  onChange={handleCheckboxRisk}
                />
              ))} */}
            </Grid>
            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              {cddDocView !== undefined ? (
                <Button
                  variant="contained"
                  target="_blank"
                  color={'primary'}
                  href={cddDocView.document_url}
                >
                  View Supporting Document
                </Button>
              ) : null}
              <Button
                disabled={disableKycDetails}
                variant={refInputSupportingDocUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputSupportingDocUpload}
              >
                {supportingDoc}
                {/* Upload Supporting Document */}
                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputSupportingDocUpload} />
              </Button>
              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
            {/* <Grid item xs={12}>
              <Typography className={classes.typo}>
                Upload Any Supporting Documents
              </Typography>
              <input
                // onChange={handleChangeUploadSupportingDoc}
                ref={refInputSupportingDocUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              />
            </Grid> */}
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                size="medium"
                color="secondary"
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid> */}
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            onBackdropClick="false"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{'Submission'}</DialogTitle>
            <DialogContent>
              <DialogContentText>The submission is submitted successfully.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorApiMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
