import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  InputBase,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useTheme, withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';
import LinkUserModal from './LinkUserModal';
import VerifyUserModal from './VerifyUserModal';
import EditModal from './EditModal';
import useFetchData from './useFetchData';

import companyApi from '../../services/company.service';
import userApi from '../../services/user.service';
import useQueryParams from '../../hooks/useQueryParams';

const columns = [
  {
    id: 'user_id',
    label: 'ID',
  },
  {
    id: 'username',
    label: 'USERNAME',
  },
  {
    id: 'email',
    label: 'EMAIL',
  },
  {
    id: 'contact_number',
    label: 'CONTACT NUMBER',
  },
  {
    id: 'status_name',
    label: 'STATUS',
    render: (text) => {
      return text || '-';
    },
  },
];

function SysInfoUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [showLinkUserModal, setShowLinkUserModal] = useState(false);
  const [showVerifyUserModal, setShowVerifyUserModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const queryParams = useQueryParams();
  const selectedSysinfoUserId = queryParams.get('id');

  // local
  const [
    fetchSysInfoUsers,
    { data: sysInfoUsersData, error: errorSysInfoUsers, loading: loadingSysInfoUsers },
  ] = useFetchData(
    {
      callback: async () => await userApi.getSysInfoUsers(),
    },
    true
  );

  let sysInfoUsers = sysInfoUsersData?.users || [];
  if (sysInfoUsers.length > 0) {
    sysInfoUsers = sysInfoUsers.sort((a, b) => {
      // if (a?.username < b?.username) {
      //   return -1;
      // }
      // if (a?.username > b?.username) {
      //   return 1;
      // }
      // return 0;

      return b?.user_id - a?.user_id;
      // return a?.username.localeCompare(b?.username);
    });
  }

  let unavailableCompanyUserIds = [];
  sysInfoUsers.forEach((anItem) => {
    if (anItem.company_user_id) {
      unavailableCompanyUserIds.push(anItem.company_user_id);
    }
  });

  useEffect(() => {
    (async () => {
      let res = await fetchSysInfoUsers();
      if (selectedSysinfoUserId) {
        let foundUser = res?.users?.find((anItem) => {
          return anItem.user_id == selectedSysinfoUserId;
        });
        setShowEditModal(foundUser);
      }
    })();
  }, [selectedSysinfoUserId]);

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Sys Info Users" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {loadingSysInfoUsers ? (
              <CircularProgress />
            ) : (
              <CustomTable
                rowKey="user_id"
                data={sysInfoUsers}
                columns={[
                  ...columns,
                  {
                    id: 'company_user_id',
                    label: 'COMPANY USER ID',
                    render: (text, data) => {
                      return (
                        text || (
                          <Button
                            variant="contained"
                            onClick={() => {
                              setShowLinkUserModal(data);
                            }}
                            size="small"
                          >
                            Link User
                          </Button>
                        )
                      );
                    },
                  },
                  {
                    id: '',
                    label: 'Verification Status',
                    render: (_, data, index) => {
                      if (data['company_user_id']) {
                        let fullyVerified =
                          data['is_personalinfo_verified'] &&
                          data['is_contact_verified'] &&
                          data['is_address_verified'] &&
                          data['is_email_verified'];
                        if (fullyVerified) {
                          return 'Verified';
                        }
                        return (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowVerifyUserModal(data);
                            }}
                          >
                            Verify
                          </Button>
                        );
                      }
                      return '-';
                    },
                  },
                  {
                    id: 'action',
                    label: '',
                    render: (text, data) => {
                      return (
                        text || (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setShowEditModal(data);
                            }}
                            size="small"
                          >
                            Edit
                          </Button>
                        )
                      );
                    },
                  },
                ]}
              />
            )}
          </Widget>
        </Grid>
      </Grid>
      <LinkUserModal
        show={showLinkUserModal}
        onHide={() => {
          setShowLinkUserModal(false);
        }}
        unavailableCompanyUserIds={unavailableCompanyUserIds}
        refetchData={fetchSysInfoUsers}
      />
      <VerifyUserModal
        show={showVerifyUserModal}
        onHide={() => {
          setShowVerifyUserModal(false);
        }}
        refetchData={fetchSysInfoUsers}
      />
      <EditModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        refetchData={fetchSysInfoUsers}
      />
    </>
  );
}

export default SysInfoUsers;
