import React, { useState, useEffect } from "react";

const useFetchData = (defaultOptions = {}, isLazy = false) => {
  const [state, setState] = useState({
    data: undefined,
    error: undefined,
    loading: false,
  });

  const fetchData = async (options = defaultOptions) => {
    return new Promise((resolve, reject) => {
      if (options && options.callback) {
        setState((prev) => ({
          ...prev,
          loading: true,
        }));
        options
          .callback()
          .then((result) => {
            if (result?.data?.is_success) {
              setState({
                data: result?.data,
                error: undefined,
                loading: false,
              });
              resolve(result?.data);
            } else {
              setState({
                data: undefined,
                error: result,
                loading: false,
              });
              reject(result);
            }
          })
          .catch((error) => {
            setState({
              data: undefined,
              error: error,
              loading: false,
            });
            reject(error);
          });
      }
    });
  };
  useEffect(() => {
    if (!isLazy) {
      (async () => {
        await fetchData(defaultOptions);
      })();
    }
  }, []);

  return [fetchData, state];
};

export default useFetchData;
