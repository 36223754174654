import axios from "./customAxios";

const logTimeline = async () => {
  const { data } = await axios.get(`/company/logtimeline`);

  if (data && !data.is_success) throw Error(data?.message);
  console.log("logtimelineapi", data?.log_timeline);
  return data?.log_timeline;
};

const getLogTimeline = async (id) => {
  const { data } = await axios.get(`/company/logtimeline/${id}`);
  console.log("getLogTimeline", data);
  if (data && !data.is_success) throw Error(data?.message);

  return data?.log_timeline;
};

const addLogTimeline = async (data) => {
  console.log("insert log timeline data", data);

  return axios.post(`/company/logtimeline`, { ...data, created_by: 1 });
};
const addLogTimeline2 = async (
  company_id,
  timeline_date,
  action_type,
  action,
  notes,
  status,
  status_id
) => {
  let data = {
    company_id: company_id,
    timeline_date: timeline_date,
    action_type: action_type,
    action: action,
    notes: notes,
    status: status,
    status_id: status_id,
    created_by: 1,
  };
  console.log("insert log timeline data", data);

  return axios.post(`/company/logtimeline`, data);
};

const updateLogTimeline = async (id, data = {}) => {
  console.log("update log timeline data", data);

  return axios.put(`/company/logtimeline/` + id, data);
};

const updateLogTimeline2 = async (
  log_timeline_id,
  company_id,
  timeline_date,
  action_type,
  action,
  notes,
  status,
  status_id
) => {
  let data = {
    company_id: company_id,
    timeline_date: timeline_date,
    action_type: action_type,
    action: action,
    notes: notes,
    status: status,
    status_id: status_id,
  };
  console.log("update log timeline data", data);

  return axios.put(`/company/logtimeline/` + log_timeline_id, data);
};

const getCompanies = async () => {
  const { data } = await axios.get(`/company/companies/`);
  console.log("getCompanies", data);
  if (data && !data.is_success) throw Error(data?.message);

  return data?.companies;
};

const logTimelineSvc = {
  logTimeline,
  getLogTimeline,
  updateLogTimeline,
  addLogTimeline,
  getCompanies,
};

export default logTimelineSvc;
