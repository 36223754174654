import React from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams2 = () => {
  return new URLSearchParams(useLocation().search);
};
const useQueryParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
