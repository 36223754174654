import identificationApi from "./identificationapi";

const createIdentification = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return identificationApi.post(`/users/` + userId + `/identifications`, data);
};

const getIdentification = (userId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return identificationApi.get(`/users/` + userId + `/identifications`);
};

const updateIdentification = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return identificationApi.put(`/users/` + userId + `/identifications`, data);
};
const updateIdentificationByIdentificationHistoryId = (
  userId,
  identificationHistoryId,
  data
) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return identificationApi.put(
    `/users/` + userId + `/identifications/` + identificationHistoryId,
    data
  );
};

export default {
  createIdentification,
  getIdentification,
  updateIdentification,
  updateIdentificationByIdentificationHistoryId,
};
