import { Icon } from '@iconify/react';
import { Box, CircularProgress, Typography, Paper, Grid, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import useBreakpoints from '../../hooks/useBreakpoints';
import cartSvc from '../../services/cart.service';
import useQueryParams from '../../hooks/useQueryParams';
import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';

const TypographyStatus = ({ status }) => {
  let statusColor, textLabel;
  switch (status) {
    case 'completed':
      statusColor = '#24FF00';
      textLabel = 'Completed';
      break;
    case 'PENDING':
      statusColor = '#FF7F09';
      textLabel = 'Pending';
      break;
    case 'paid':
      statusColor = '#041DFF';
      textLabel = 'Paid';
      break;
    case 'processing':
      statusColor = '#FF7F09';
      textLabel = 'Processing';
      break;
    case 'failed':
      statusColor = '#FF4D00';
      textLabel = 'Failed';
      break;
  }
  return (
    <Typography variant="body1" align="right" style={{ fontWeight: 600, color: statusColor }}>
      {textLabel}
    </Typography>
  );
};

export default function CartDetail() {
  let history = useHistory();
  let query = useQueryParams();
  const cartId = query.get('cartid');

  const { xsBelow } = useBreakpoints();
  const [order, setOrder] = React.useState({});
  const [isLoading, setLoading] = useState(false);

  let cartItems = order?.items || [];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await cartSvc
        .getById(cartId)
        .then((ord) => {
          setOrder(ord || {});
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [cartId]);

  const cartTableColumns = [
    {
      label: 'No.',
      id: 'index',
      render: (_, __, index) => {
        return `${index + 1}.`;
      },
    },
    {
      label: 'Date',
      id: 'created_at',
      render: (text) => {
        return text ? moment(order.created_at).format(`DD/MM/yyyy`) : '-';
      },
    },
    {
      label: 'Item ID',
      id: 'id',
    },
    {
      label: 'Item Name',
      id: 'product',
      render: (product, data) => {
        return (
          <Box>
            <Box>
              <small>Req No: {data?.company_application_id}</small>
            </Box>
            <Box>{product?.name || '-'}</Box>
          </Box>
        );
      },
    },
    {
      label: 'Price (S$)',
      id: 'price',
      render: (text) => {
        return `${text}`;
      },
    },
    {
      label: 'Quantity',
      id: 'quantity',
    },
  ];

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/carts' })}
      >
        BACK TO CARTS
      </Button>
      <PageTitle title={`Cart Details (ID: ${cartId})`} />
      <Paper component={Box} mb={3} p={2}>
        <Grid container>
          <Grid item xs={6}>
            <Box
              style={{
                width: '100%',
              }}
            >
              <Box display={'flex'}>
                <Typography variant="body1" align="left">
                  Date: {moment(order.created_at).format(`DD/MM/yyyy`)}
                </Typography>
              </Box>
              <Box>
                <Box display="flex">
                  <Box mr={2}>User:</Box>
                  {!order.first_name && !order.last_name
                    ? '-'
                    : `${order.first_name} ${order.last_name}`}{' '}
                  (ID: {order.user_id})
                </Box>
                <Box display="flex">
                  <Box mr={2}>Email:</Box>
                  {order.email || '-'}
                </Box>
                <Box display="flex">
                  <Box mr={2}>Company:</Box>
                  {!order.company_name
                    ? '-'
                    : `${order.company_name} ${
                        order.company_registration_no ? `(${order.company_registration_no})` : ''
                      }`}
                </Box>
                {order.remark && <Box>Remark: {order.remark}</Box>}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" flexDirection={'column'} textAlign="right">
              {/* <Typography variant="caption">Status</Typography>
              <TypographyStatus status={order.status} /> */}
              <Typography variant="caption">Total</Typography>
              <Box>
                <Typography variant="body2">
                  <b>S$ {order.totalPrice}</b>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Box mb={1}>
        <Typography
          variant="h6"
          align="left"
          color="secondary"
          style={{
            fontWeight: 600,
          }}
        >
          {cartItems.length || 0} Item(s)
        </Typography>
      </Box>
      <CustomTable data={cartItems || []} columns={cartTableColumns} />

      {/* <Box display="flex" flexDirection="column">
        {order?.items?.map((item, index) => (
          <>
            <Box
              style={{
                padding: 30,
                borderRadius: 25,
                boxShadow: '0 0 10px #ccc',
                width: '100%',
              }}
              key={item.id}
            >
              <Box display={xsBelow ? 'initial' : 'flex'} justifyContent="space-between">
                <Box display={xsBelow ? 'initial' : 'flex'}>
                  <Typography variant="body1" align="left" style={{ fontWeight: 'bold' }}>
                    {index + 1}. {item.product?.name}
                  </Typography>
                  <Box pr={4} />
                  <Typography variant="body1" align="left">
                    Date: {moment(item.created_at).format(`DD/MM/yyyy`)}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  align={'right'}
                  style={{
                    fontWeight: 600,
                  }}
                >
                  S$ {item.price}
                </Typography>
              </Box>

              <Box display={xsBelow ? 'initial' : 'flex'} justifyContent="space-between">
                <Typography variant="body1" align="left" style={{ fontWeight: 'bold' }}>
                  # {item.id}
                </Typography>
                <Typography variant="body1" align="left">
                  Quantity: {item.quantity}
                </Typography>
              </Box>
            </Box>
            <Box pt={3} />
          </>
        ))}
      </Box> */}
    </>
  );
}
