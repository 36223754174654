import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Chip,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Box,
  Paper,
  Backdrop,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import * as Yup from 'yup';

// styles
// import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import { defaultEmailTypes } from '../emailTemplates/EmailTemplates';
import CustomMenu from '../../components/CustomMenu';
import documentApi from '../../services/document.service';
import userApi from '../../services/user.service';
import utilsApi from '../../services/utils.service';
import useCommonOptions from '../../hooks/useCommonOptions';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import { useUserState } from '../../context/UserContext';

const getTemplateFields = async (templateURL, distinct = false) => {
  let result = [];
  try {
    let res = await axios.get(templateURL);
    let htmlString = res?.data;
    //     /\[{2}.*?\]{2}/g           double square bracket with [[]]
    ///    /(?<=\[{2})[^\]]*(?=\]{2})/g   double square bracket without [[]]
    let matches = htmlString.match(/(?<=\[{2})[^\]]*(?=\]{2})/g);
    if (distinct) {
      matches?.forEach((value) => {
        if (result?.indexOf(value) < 0) {
          result.push(value);
        }
      });
    } else {
      result = matches;
    }
  } catch (error) {
    console.log('getTemplateFields error', error);
  }
  return result;
};

const getValidationSchemaObj = (fieldsData = {}, prefix = '', required = true) => {
  let result = {};
  let fieldsDataKeys = Object.keys(fieldsData);
  fieldsDataKeys.forEach((aKey) => {
    let fieldName = prefix + fieldsData[aKey]['name'];
    result[fieldName] = Yup.string().nullable();

    if (fieldsData[aKey].required != undefined) {
      let isRequired = fieldsData[aKey].required;
      if (isRequired) {
        result[fieldName] = Yup.string().required('Please fill up this field').nullable();
      }
    } else {
      if (required) {
        result[fieldName] = Yup.string().required('Please fill up this field').nullable();
      }
    }

    if (fieldsData[aKey]?.custom) {
      result[fieldName] = fieldsData[aKey]?.custom;
    }
  });
  return result;
};

export default function SendEmail(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [sysInfoUsers, setSysInforUsers] = useState([]);
  const [selectedEmailType, setSelectedEmailType] = useState();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState();
  const [emailTemplateFieldNames, setEmailTemplateFieldNames] = useState([]);
  const { userId: authUserId } = useUserState();

  const fetchEmailTemplateByDocTypeId = async (docTypeId) => {
    setLoading(true);
    try {
      let res = await documentApi.getDocuments(docTypeId, 25, 0);
      setEmailTemplates(res?.data?.document || []);
    } catch (error) {
      setEmailTemplates([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        let res = await userApi.getSysInfoUsers();
        setSysInforUsers(res?.data?.users);
      } catch (error) {
        setSysInforUsers([]);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedEmailType) {
      fetchEmailTemplateByDocTypeId(selectedEmailType);
    } else {
      setEmailTemplates([]);
    }
    setSelectedEmailTemplate();
  }, [selectedEmailType]);

  useEffect(() => {
    if (selectedEmailTemplate) {
      let foundEmailTemplate = emailTemplates.find((anItem) => {
        return anItem.document_id == selectedEmailTemplate;
      });
      if (foundEmailTemplate) {
        (async () => {
          setLoading(true);
          let res = await getTemplateFields(foundEmailTemplate?.document_url, true);
          setEmailTemplateFieldNames(res);
          setLoading(false);
        })();
      }
    } else {
      setEmailTemplateFieldNames([]);
    }
  }, [selectedEmailTemplate]);

  let emailTypeOptions = defaultEmailTypes?.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.document_type_id,
    };
  });
  let emailTemplateOptions = emailTemplates?.map((anItem) => {
    let uploadedDate = '-';
    try {
      uploadedDate = moment(anItem.uploaded_date).format(`DD/MM/yyyy hh:mm:ssa`);
    } catch (error) {}
    return {
      label: `${anItem.file_name} ${uploadedDate}`,
      value: anItem.document_id,
    };
  });

  let defaultEmailTemplateFields = {
    to_email: Yup.array()
      .of(Yup.string())
      .min(1, 'Please select at least one')
      .required('Please fill up this field')
      .nullable()
      .default([]),
    subject: Yup.string().required('Please fill up this field').nullable(),
  };

  let emailTemplateFields = {};
  if (emailTemplateFieldNames?.length > 0) {
    emailTemplateFieldNames.forEach((anItem) => {
      emailTemplateFields[anItem] = {
        name: anItem,
        label: anItem,
      };
    });
  } else {
    emailTemplateFields = {};
  }

  let sysInfoUserOptions = sysInfoUsers?.map((anItem) => {
    return {
      label: `${anItem?.email} (${anItem?.first_name} ${anItem?.last_name})`,
      value: anItem?.email,
    };
  });

  let validationSchema = Yup.object().shape({
    ...defaultEmailTemplateFields,
    ...getValidationSchemaObj(emailTemplateFields),
  });
  let initialValues = validationSchema.cast();

  const onSubmit = async (values) => {
    let foundEmailTemplateType = defaultEmailTypes?.find((anItem) => {
      return anItem.document_type_id == selectedEmailType;
    });

    let to_email = values?.['to_email']?.join(',');
    let body = {
      ...values,
      to_email: to_email || '',
      from_email: 'support@meyzer360.com',
      email_type: foundEmailTemplateType?.label,
      template_id: selectedEmailType,
      created_by: authUserId,
    };

    setLoading(true);
    try {
      let res = await utilsApi.sendEmail(body);
      console.log('sendEmail res', res);
      if (res?.data?.is_success) {
        enqueueSnackbar('Email is sent', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to send email', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to send email', {
        variant: 'error',
      });
      console.log('sendEmail error', error);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Send Email" />
      <Paper component={Box} p={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div>Email Template Type</div>
            <CustomMenu
              data={emailTypeOptions}
              value={selectedEmailType}
              onChange={(e) => {
                setSelectedEmailType(e.target.value);
              }}
              searchable={true}
              // label="Document Type"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div>Email Template</div>
            <CustomMenu
              data={emailTemplateOptions}
              value={selectedEmailType}
              onChange={(e) => {
                setSelectedEmailTemplate(e.target.value);
              }}
              searchable={true}
              // label="Document Type"
            />
          </Grid>
          {emailTemplateFieldNames?.length > 0 ? (
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {(formikProps) => {
                  const { values, handleSubmit, validateForm, setTouched, touched } = formikProps;

                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <CustomSelectField
                            name={'to_email'}
                            label={'Send To'}
                            options={sysInfoUserOptions}
                            required={true}
                            variant="outlined"
                            multiple={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomTextField
                            name={'subject'}
                            label={'Subject'}
                            required={true}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          Values
                        </Grid>
                        {emailTemplateFieldNames?.map((anItem) => {
                          return (
                            <Grid item xs={12} md={6} key={anItem}>
                              <CustomTextField
                                name={anItem}
                                label={anItem}
                                required={true}
                                variant="outlined"
                              />
                            </Grid>
                          );
                        })}

                        <Grid item xs={12}>
                          <Box ml="auto" width="100%" display="flex" justifyContent="space-between">
                            <Box ml="auto">
                              <Button variant="contained" color="primary" type="submit">
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          ) : (
            <Box
              width="100%"
              minHeight="400px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Email Template not selected.
            </Box>
          )}
        </Grid>
      </Paper>
      <Backdrop open={loading} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
