import React, { useState, useEffect } from 'react';
import { Button, Box, Chip, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import CustomTable from '../../../../components/CustomTable';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import companyApi from '../../../../services/company.service';
import commonApi from '../../../../services/common.service';

let defaultCompanyAddressTableColumns = [
  // {
  //   label: 'ID',
  //   id: 'company_address_id',
  // },
  {
    label: 'TYPE',
    id: 'address_type_name',
  },
  {
    label: 'ADDRESS',
    id: 'address',
    render: (_, data) => {
      let result = [];
      let addressKeys = [
        'floor_unit_no',
        'address_line_1',
        'address_line_2',
        'address_line_3',
        'postal_code',
        'country_name',
      ];
      addressKeys.forEach((aKey) => {
        let value = data?.[aKey];
        if (value) {
          result.push(value.trim());
        }
      });
      if (result.length > 0) {
        return result.join(', ');
      }
      return '-';
    },
  },
  {
    label: 'IS DEFAULT',
    id: 'is_default',
    render: (text) => {
      return text ? 'YES' : 'NO';
    },
  },
];

export const CompanyAddressTable = ({ data = [], onEdit, disabled = false, ...restProps }) => {
  const handleOnEdit = (data) => {
    if (onEdit) {
      onEdit(data);
    }
  };
  let companyAddressTableColumns = defaultCompanyAddressTableColumns;
  if (onEdit) {
    companyAddressTableColumns = [
      {
        label: '',
        id: 'action',
        render: (text, data) => {
          return (
            <Chip
              color="secondary"
              onClick={() => handleOnEdit({ ...data })}
              clickable
              label={'EDIT'}
              disabled={disabled}
            />
          );
        },
      },
      ...defaultCompanyAddressTableColumns,
    ];
  }

  return (
    <CustomTable
      data={data}
      columns={companyAddressTableColumns}
      rowKey={'auditor_company_id'}
      {...restProps}
    />
  );
};

export const fields = {
  // Registered Address
  address_type_id: {
    name: 'address_type_id',
    label: 'Address Type',
  },
  floor_unit_no: {
    name: 'floor_unit_no',
    label: 'Floor No. / Unit No.',
  },
  address_line_1: {
    name: 'address_line_1',
    label: 'Street / Road',
  },
  address_line_2: {
    name: 'address_line_2',
    label: 'Address Line 2',
  },
  postal_code: {
    name: 'postal_code',
    label: 'Postal Code',
  },
  country_id: {
    name: 'country_id',
    label: 'Country',
  },
  is_default: {
    name: 'is_default',
    label: 'Is Default',
  },
  // address_line_3: null,
  // is_default: 1,
  // is_applying: 0,
  // is_registered: 1,
  // registration_datetime: "2021-03-31 00:00:00",
};

// export const fields = {
//   auditor_company_id: {
//     name: 'auditor_company_id',
//     label: 'Company',
//   },
//   appointment_date: {
//     name: 'appointment_date',
//     label: 'Date of Appointment',
//   },
//   cessation_type: {
//     name: 'cessation_type_id',
//     label: 'Cessation Types',
//   },
//   cessation_date: {
//     name: 'cessation_date',
//     label: 'Date of Cessation',
//   },
// };

export const validationSchemaObj = {
  address_type_id: Yup.string().required(`${fields.address_type_id.label} is required`).default(''),
  floor_unit_no: Yup.string().default(''),
  address_line_1: Yup.string().required(`${fields.address_line_1.label} is required`).default(''),
  address_line_2: Yup.string().default(''),
  postal_code: Yup.string().required(`${fields.postal_code.label} is required`).default(''),
  country_id: Yup.string().required(`${fields.country_id.label} is required`).default(''),
  is_default: Yup.number(),
};

let validationSchema = Yup.object(validationSchemaObj);

export const CompanyAddressModalForm = ({
  data: defaultData,
  show = false,
  onHide,
  disabled = false,
  title,
  onSubmit,
  onRemove,
}) => {
  let data = {
    ...defaultData,
  };

  let isNew = !Boolean(defaultData);
  let defaultValues = isNew ? validationSchema.cast() : data;

  const [loading, setLoading] = useState(false);

  const {
    options: addressTypeOptions,
    loading: loadingAddressTypes,
    rawData: addressTypes = [],
  } = useCommonOptions(commonApi.getAddressType, {
    respondKey: 'addresstypes',
    labelKey: 'address_type_name',
    valueKey: 'address_type_id',
  });

  const {
    options: countryOptions,
    loading: loadingCountries,
    rawData: countries = [],
  } = useCommonOptions(commonApi.getCountry, {
    respondKey: 'countries',
    labelKey: 'country_name',
    valueKey: 'country_id',
  });

  const {
    options: companyOptions,
    loading: loadingCompanies,
    rawData: companies = [],
    refetch: fetchCompanies,
  } = useCommonOptions(
    async () => {
      let res = await companyApi.getCompanies('', 1, 10000);
      let result = res?.data?.companies || [];
      let filtered = result.filter((anItem) => {
        return anItem.company_client_type_id == 3;
      });

      return {
        ...res,
        data: {
          ...res.data,
          companies: filtered,
        },
      };
    },
    {
      respondKey: 'companies',
      labelKey: 'company_name',
      valueKey: 'company_id',
      render: (text, data) => {
        return `${text} (${data.company_registration_no})`;
      },
      lazyLoad: true,
    }
  );
  const {
    options: cessationTypeOptions,
    loading: loadingCessationTypes,
    rawData: cessationTypes = [],
    refetch: fetchCessationTypes,
  } = useCommonOptions(commonApi.getCessationType, {
    respondKey: 'cessationtypes',
    labelKey: 'cessation_type_name',
    valueKey: 'cessation_type_id',
    lazyLoad: true,
  });

  useEffect(() => {
    (async () => {
      if (show) {
        try {
          setLoading(true);
          // if (fetchCompanies) {
          //   await fetchCompanies();
          // }
          // if (fetchCessationTypes) {
          //   await fetchCessationTypes();
          // }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [show]);

  const handleOnSubmit = (values) => {
    let foundCountry =
      countries.find((anItem) => anItem.country_id == values[fields.country_id.name]) || {};
    let foundAddressType =
      addressTypes.find((anItem) => anItem.address_type_id == values.address_type_id) || {};

    let newBody = {
      ...values,
      ...foundAddressType,
      ...foundCountry,
    };

    if (onSubmit) {
      onSubmit(newBody);
    }
  };

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(data);
    }
  };
  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={title ? title : `${isNew ? 'Add' : 'Edit'} Company Address`}
    >
      {loading ? (
        'Loading'
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(props) => {
            const { handleSubmit } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CustomSelectField
                      name={fields.address_type_id.name}
                      label={fields.address_type_id.label}
                      options={addressTypeOptions}
                      required={true}
                      disablePortal={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      name={fields.floor_unit_no.name}
                      label={fields.floor_unit_no.label}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      name={fields.address_line_1.name}
                      label={fields.address_line_1.label}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      name={fields.address_line_2.name}
                      label={fields.address_line_2.label}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      name={fields.postal_code.name}
                      label={fields.postal_code.label}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomSelectField
                      name={fields.country_id.name}
                      label={fields.country_id.label}
                      options={countryOptions}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={fields.is_default.name}>
                      {({ field, meta, form }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                // checked={false}
                                // onChange={handleChange}
                                // {...field}
                                color="primary"
                                checked={field?.value}
                                onChange={(e) => {
                                  form.setFieldValue(
                                    fields.is_default.name,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                            }
                            label={fields.is_default.label}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && onRemove && (
                      <Box ml={2}>
                        <Button variant="contained" onClick={handleOnRemove}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );
};
