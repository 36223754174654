import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  InputBase,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';

import companyApi from '../../services/company.service';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const columns = [
  {
    label: '',
    id: 'onboard_profile_id',
    render: (text, data) => {
      return (
        <Button variant="contained" color="primary" component="div" size="small">
          <Link
            to={`/company-account-request-public/${text}`}
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            DETAIL
          </Link>
        </Button>
      );
    },
  },
  {
    id: 'onboard_profile_id',
    label: 'ID',
  },
  {
    id: 'created_date',
    label: 'DATE',
    render: (text) => {
      return moment(text).format(`DD/MM/yyyy hh:mm:ssa`);
    },
  },
  {
    id: 'remarks',
    label: 'SERVICE',
    render: (text) => {
      if (text) {
        let result = '-';
        let matches = text.match(/\[(.*?)\]/);
        let objString = matches?.[1];
        if (objString) {
          try {
            let obj = JSON.parse(objString);
            result = obj?.product;
          } catch (error) {}
        }
        return result;
      }
      return '-';
    },
  },
  {
    id: 'status_name',
    label: 'COMPANY STATUS',
  },
  {
    id: 'email',
    label: 'EMAIL',
  },
  {
    id: 'company_name',
    label: 'COMPANY NAME',
  },
  {
    id: 'company_country_name',
    label: 'COUNTRY OF INCORPORATION',
  },
  {
    id: 'entity_type',
    label: 'COMPANY TYPE',
  },
  {
    id: 'bus_registration_no',
    label: 'COMPANY REGISTRATION NO',
  },
];

const actionss = {
  1: 'In Progress',
  2: 'Pending',
  3: 'Complete',
  4: 'Rejected',
  5: 'Draft',
  6: 'Pending Third Party Approval',
  7: 'Approved',
  8: 'Order Checkout',
  9: 'Order Pending',
  10: 'Payment Complete',
  11: 'In Cart',
  12: 'Active',
  13: 'Suspend',
  14: 'Trash',
};

const actionOptions = [
  {
    label: 'Active',
    value: 12,
  },
  {
    label: 'Suspend',
    value: 13,
  },
  {
    label: 'Move to Pending',
    value: 2,
  },
  {
    label: 'Move to Trash',
    value: 14,
    color: 'dangerText',
  },
  {
    label: 'Approved',
    value: 7,
  },
  {
    label: 'Rejected',
    value: 4,
  },
];
function OnboardProfiles(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();

  // local

  const [isLoading, setLoading] = useState(true);
  const [selectedAction, setSelectedAction] = useState();
  const [statusFilter, setStatusFilter] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [onboardProfiles, setOnboardProfiles] = useState([]);
  const [onboardProfilesByStatus, setOnboardProfilesByStatus] = useState([]);

  let totalActive = 0;
  let totalSuspend = 0;
  let totalPending = 0;
  let totalTrash = 0;

  onboardProfiles.forEach((aProfile) => {
    if (aProfile.onboard_status_id) {
      let statusId = aProfile.onboard_status_id;
      if (statusId == 12) {
        totalActive += 1;
      } else if (statusId == 13) {
        totalSuspend += 1;
      } else if (statusId == 2) {
        totalPending += 1;
      } else if (statusId == 14) {
        totalTrash += 1;
      }
    }
  });

  const profileStatuses = [
    {
      id: '',
      label: 'All',
      value: null,
      color: 'primary',
      total: onboardProfiles?.length,
    },
    {
      id: 1,
      label: 'Active',
      value: 12,
      color2: 'success',
      total: totalActive,
    },
    {
      id: 5,
      label: 'Suspended',
      value: 13,
      color2: 'warning',
      total: totalSuspend,
    },
    {
      id: 2,
      label: 'Pending',
      value: 2,
      total: totalPending,
    },
    {
      id: 4,
      label: 'Trash',
      value: 14,
      color2: 'danger',
      total: totalTrash,
    },
    {
      id: 8,
      label: 'Approved',
      value: 7,
      color2: 'success',
      total: 0,
    },
    {
      id: 9,
      label: 'Rejected',
      value: 4,
      color2: 'danger',
      total: 0,
    },
  ];

  const fetchData = async (fetchAll = false) => {
    setSelectedRowKeys([]);

    if (statusFilter) {
      setLoading(true);
      const respOnboardProfilelist = await companyApi.getOnboardProfilesByStatusId(statusFilter);
      setOnboardProfilesByStatus(respOnboardProfilelist.data.onboard_profiles);
    } else {
      setLoading(true);
      const respOnboardProfilelist = await companyApi.getOnboardProfilesPublic('', 1, 10000);
      let onboardProfilesData = respOnboardProfilelist?.data?.onboard_profiles || [];
      onboardProfilesData.sort((a, b) => {
        return new Date(b.created_date) - new Date(a.created_date);
      });
      setOnboardProfiles(onboardProfilesData);
    }

    if (fetchAll && statusFilter) {
      setLoading(true);
      const respOnboardProfilelist = await companyApi.getOnboardProfilesPublic('', 1, 10000);
      setOnboardProfiles(respOnboardProfilelist.data.onboard_profiles);
    }
  };

  useEffect(() => {
    fetchData().finally(() => setLoading(false));
  }, [statusFilter]);

  const onCheckRow = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  const handleAction = async () => {
    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedAction) {
      for (let i = 0; i < selectedRowKeys.length; i++) {
        const foundOnboardProfile = (statusFilter ? onboardProfilesByStatus : onboardProfiles).find(
          (anItem) => {
            return anItem?.onboard_profile_id == selectedRowKeys[i];
          }
        );
        const updateRes = await companyApi.updateOnboardProfile(selectedRowKeys[i], {
          onboard_status_id: selectedAction,
          to_email: foundOnboardProfile?.email,
          from_email: 'support@meyzer360.com',
        });
        console.log('updateResupdateRes', updateRes);
      }
    }
    fetchData(true).finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Onboarding Company Public Requests"
        // button={
        //   <Button
        //     variant="contained"
        //     size="medium"
        //     color="secondary"
        //     onClick={() => {
        //       history.push({
        //         pathname: "/companyform",
        //         //   search: "?company=" + company_id,
        //         state: {
        //           // company_id: localStorage.getItem("company_id"),
        //           // attachment_type_id: "1",
        //           // role_id: post.role_id,
        //           // company_name: localStorage.getItem("company_name"),
        //           // attachment_type_name: "Appointment Letter",
        //           // role_name: post.title,
        //         },
        //       });
        //     }}
        //   >
        //     Add New Company
        //   </Button>
        // }
      />
      <Grid container spacing={1} style={{ marginBottom: theme.spacing(1) }}>
        {profileStatuses.map((aStatus, index) => {
          let dataLength = aStatus?.total || 0;
          let isActive = statusFilter == aStatus.value;

          const handleOnClick = () => {
            setStatusFilter(aStatus.value);
          };

          let btnProps = {
            className: classes.button,
          };
          if (aStatus.color) {
            btnProps['color'] = aStatus.color;
          }
          if (aStatus.color2) {
            btnProps['className'] = clsx(
              classes.button,
              isActive
                ? classes[`${aStatus.color2}Contained`]
                : classes[`${aStatus.color2}Outlined`]
            );
          }

          return (
            <Grid key={index} item>
              <Button
                disableElevation={true}
                variant={isActive ? 'contained' : 'outlined'}
                onClick={handleOnClick}
                {...btnProps}
              >{`${aStatus.label} (${dataLength})`}</Button>
            </Grid>
          );
        })}
      </Grid>
      {/* <Grid container spacing={1} style={{ marginBottom: theme.spacing(1) }}>
        <Grid item>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value);
              }}
              input={<BootstrapInput />}
              displayEmpty={true}
              renderValue={(value) => {
                let options = actionOptions;
                let result = (
                  <span style={{ color: theme.palette.grey["500"] }}>
                    Select Action
                  </span>
                );
                let foundValue = options.find((anOption) => {
                  return anOption.value == value;
                });
                if (foundValue) {
                  result = foundValue.label;
                }
                return result;
              }}
            >
              <MenuItem value="" disabled>
                <em>Select Action</em>
              </MenuItem>
              {actionOptions.map((anAction, index) => {
                let menuItemProps = {};
                if (anAction.color) {
                  menuItemProps["className"] = classes[anAction.color];
                }
                return (
                  <MenuItem
                    key={index}
                    value={anAction.value}
                    {...menuItemProps}
                  >
                    {anAction.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            className={classes.button}
            onClick={handleAction}
            disabled={
              !(selectedRowKeys && selectedRowKeys.length > 0 && selectedAction)
            }
          >
            Submit
          </Button>
        </Grid>
      </Grid> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CustomTable
              rowKey="onboard_profile_id"
              data={statusFilter ? onboardProfilesByStatus : onboardProfiles}
              columns={columns}
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: onCheckRow,
              // }}
              // onClickRow={(rowId) => {
              //   history.push(`/company-account-request/${rowId}`);
              // }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default OnboardProfiles;
