import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};
export default function TableComponent({ data }) {
  const history = useHistory();
  var keys = ['ID', 'TASK', 'STATUS', 'COMPANY ID', ''];
  const getSortData = (list, key) => {
    return list.slice().sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  };

  data = getSortData(data, 'task_id');
  console.log('tasks', data);
  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map((key) => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((task) => (
          <TableRow key={task.id}>
            <TableCell className="pl-3 fw-normal">{task.task_id}</TableCell>
            <TableCell className="pl-3 fw-normal">{task.task_name}</TableCell>
            <TableCell>{task.status_name}</TableCell>
            <TableCell>{task.company_id}</TableCell>
            {/* <TableCell>{task.created_at}</TableCell> */}
            <TableCell>
              <Chip
                color="primary"
                label="DETAIL"
                onClick={() => {
                  history.push(`/task?taskid=${task.task_id}`);
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
