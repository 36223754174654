import businessApi from "./businessapi";

const createBusinessActivity = (companyId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return businessApi.post(
    `/companies/` + companyId + `/businessactivities`,
    data
  );
};

const getBusinessActivity = (companyId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return businessApi.get(`/companies/` + companyId + `/businessactivities`);
};

const updateBusinessActivity = (companyId, businessId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return businessApi.put(
    `/companies/` + companyId + `/businessactivities/` + businessId,
    data
  );
};

export default {
  createBusinessActivity,
  getBusinessActivity,
  updateBusinessActivity,
};

// class TutorialDataService {
//   getAll() {
//     return http.get("/tutorials");
//   }

//   get(id) {
//     return http.get(`/tutorials/${id}`);
//   }

//   create(data) {
//     return http.post("/tutorials", data);
//   }

//   update(id, data) {
//     return http.put(`/tutorials/${id}`, data);
//   }

//   delete(id) {
//     return http.delete(`/tutorials/${id}`);
//   }

//   deleteAll() {
//     return http.delete(`/tutorials`);
//   }

//   findByTitle(title) {
//     return http.get(`/tutorials?title=${title}`);
//   }
// }

// export default new TutorialDataService();
