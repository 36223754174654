import axios from 'axios';
import { Cookies } from 'react-cookie';

let addressURL = process.env.REACT_APP_ADDRESS_URL;
let addressURLV2 = `${process.env.REACT_APP_API_V2_URL}/address`;

const customAxios = axios.create({
  baseURL: addressURLV2,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

customAxios.interceptors.request.use((config) => {
  let accessToken = new Cookies().get('accessToken');
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

const createCompanyAddress = (companyId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.post(`/companies/` + companyId + `/addresses`, data);
};

const getCompanyAddress = (companyId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.get(`/companies/` + companyId + `/addresses`);
};

const updateCompanyAddress = (companyId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.put(`/companies/` + companyId + `/addresses`, data);
};
const updateCompanyAddressByAddressId = (companyId, addressId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.put(`/companies/` + companyId + `/addresses/` + addressId, data);
};
const removeCompanyAddress = (companyId, addressId) => {
  return customAxios.delete(`/companies/` + companyId + `/addresses/` + addressId);
};

const createUserAddress = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.post(`/users/` + userId + `/addresses`, data);
};

const getUserAddress = (userId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.get(`/users/` + userId + `/addresses`);
};

const updateUserAddress = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.put(`/users/` + userId + `/addresses`, data);
};

const updateUserAddressByAddressId = (userId, addressId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.put(`/users/` + userId + `/addresses/` + addressId, data);
};

export default {
  createCompanyAddress,
  getCompanyAddress,
  updateCompanyAddress,
  updateCompanyAddressByAddressId,
  removeCompanyAddress,
  createUserAddress,
  getUserAddress,
  updateUserAddress,
  updateUserAddressByAddressId,
};

// class TutorialDataService {
//   getAll() {
//     return http.get("/tutorials");
//   }

//   get(id) {
//     return http.get(`/tutorials/${id}`);
//   }

//   create(data) {
//     return http.post("/tutorials", data);
//   }

//   update(id, data) {
//     return http.put(`/tutorials/${id}`, data);
//   }

//   delete(id) {
//     return http.delete(`/tutorials/${id}`);
//   }

//   deleteAll() {
//     return http.delete(`/tutorials`);
//   }

//   findByTitle(title) {
//     return http.get(`/tutorials?title=${title}`);
//   }
// }

// export default new TutorialDataService();
