import React from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, Chip } from '@material-ui/core';
import useStyles from '../../styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CustomTable from '../../../../components/CustomTable';

const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};

export default function TableComponent({
  data,
  companyRoles,
  cessationTypes,
  handleEditOfficer,
  handleRemoveOfficer,
  existOfficer,
  disabled = false,
}) {
  const classes = useStyles();
  //   var keys = Object.keys(data[0]).map((i) => i.toUpperCase());
  //   keys.shift(); // delete "id" key
  // const companyofficerData = {
  //   user_id: 8,
  //   company_id: 5,
  //   company_role_id: 3,
  //   date_of_appointment: "2021-03-31 00:00:00",
  //   nominee_id: null,
  //   nominator_id: null,
  //   controller_id: null,
  //   named_secretary_id: null,
  //   cessation_type_id: null,
  //   cessation_date: null,
  // };
  var keys = [
    ''.toUpperCase(),
    'name'.toUpperCase(),
    'identification'.toUpperCase(),
    'company role'.toUpperCase(),
    'date of appointment'.toUpperCase(),
    'nominee'.toUpperCase(),
    'nominator'.toUpperCase(),
    'controller'.toUpperCase(),
    'named secretary'.toUpperCase(),
    'cessation'.toUpperCase(),
  ];

  //   cessation_type: 3
  // company_role: 2
  // controller_id: 7
  // controller_name: "Dolan"
  // date_appointment: "2021-06-18"
  // date_cessation: "2021-06-02"
  // named_secretary_id: 7
  // named_secretary_name: "Dolan"
  // nominator_id: 7
  // nominator_name: "Dolan"
  // nominee_id: 6
  // nominee_name: "Dolan"
  // user_firstname: "Dolan"
  // user_id: 6
  // user_idtype: "NRIC"
  // user_idvalue: "A5346avf"
  console.log('cotbl', data);
  console.log('existOfficer', existOfficer);
  // console.log("cessationTypes", date);

  const officerTableColumns = [
    {
      id: 'row_id',
      label: '',
      render: (text) => {
        return (
          <Chip
            color="secondary"
            onClick={() => handleEditOfficer(text)}
            clickable
            label={'EDIT'} //+ row_id}
            disabled={disabled}
          />
        );
      },
    },
    {
      label: 'NAME',
      id: 'user_firstname',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'LAST NAME',
      id: 'user_lastname',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'IDENTIFICATION',
      id: 'user_nric',
      render: (_, record) => {
        const { user_nric, user_passport } = record;
        return user_nric !== null && user_nric !== undefined
          ? 'NRIC ' + user_nric
          : user_passport !== null && user_passport !== undefined
          ? 'Passport ' + user_passport
          : null;
      },
    },
    {
      label: 'COMPANY ROLE',
      id: 'company_role',
      render: (text) => {
        let foundCompanyRole = companyRoles.find((v) => v.company_role_id === text);
        return foundCompanyRole?.role_name || '-';
      },
    },
    {
      label: 'DATE OF APPOINTMENT',
      id: 'date_appointment',
      render: (text) => {
        return moment(text).format('DD MMM YYYY');
      },
    },
    {
      label: 'CESSATION',
      id: 'cessation_type_name',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'CESSATION DATE',
      id: 'date_cessation',
      render: (text) => {
        return text !== '0000-00-00' && text !== null && text != undefined
          ? moment(text).format('DD MMM YYYY') || '-'
          : '-';
      },
    },
  ];
  return <CustomTable data={data} columns={officerTableColumns} rowKey={'user_id'} />;
  return (
    <Paper style={{ width: '100%', overflowX: 'auto' }}>
      <Table className="mb-0">
        <TableHead>
          <TableRow>
            {keys.map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (
              {
                row_id,
                user_id,
                user_firstname,
                user_idtype,
                user_idvalue,
                user_nric,
                user_passport,
                company_role,
                date_appointment,
                nominee_name,
                nominator_name,
                controller_name,
                named_secretary_name,
                cessation_type,
                cessation_type_name,
                date_cessation,
              },
              i
            ) => (
              <TableRow key={row_id}>
                <TableCell>
                  <Chip
                    color="secondary"
                    onClick={() => handleEditOfficer(row_id)}
                    clickable
                    label={'EDIT'} //+ row_id}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{user_firstname}</TableCell>
                <TableCell>
                  {user_nric !== null && user_nric !== undefined
                    ? 'NRIC ' + user_nric
                    : user_passport !== null && user_passport !== undefined
                    ? 'Passport ' + user_passport
                    : null}
                </TableCell>
                <TableCell>
                  {companyRoles.find((v) => v.company_role_id === company_role).role_name}
                </TableCell>
                {/* <TableCell>{companyRoles[company_role].role_name}</TableCell> */}
                {/* <TableCell>{company_role}</TableCell> */}

                <TableCell>{moment(date_appointment).format('DD MMM YYYY')}</TableCell>
                <TableCell>{nominee_name !== 'null' ? nominee_name : '-'}</TableCell>
                <TableCell>{nominator_name !== 'null' ? nominator_name : '-'}</TableCell>
                <TableCell>{controller_name !== 'null' ? controller_name : '-'}</TableCell>
                <TableCell>
                  {named_secretary_name !== 'null' ? named_secretary_name : '-'}
                </TableCell>
                <TableCell>
                  <Typography style={{ display: 'inline-block' }}>
                    {cessation_type_name !== 'null' ? cessation_type_name : '-'}
                  </Typography>
                  <Typography style={{ display: 'inline-block' }}>
                    {date_cessation !== '0000-00-00' && date_cessation !== null
                      ? moment(date_cessation).format('DD MMM YYYY')
                      : null}
                  </Typography>
                </TableCell>

                {/* <TableCell onClick={() => handleEditOfficer(i)}>Edit</TableCell> */}
                {/* <TableCell onClick={() => handleRemoveOfficer()}>
                Remove
              </TableCell> */}
                {/* <TableCell>{i}</TableCell> */}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
