import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import logTimelineSvc from '../../services/logtimeline.service';
import Table from './components/Table/Table';
// styles
import useStyles from './styles';

import LogTimelineForm from './LogTimelineForm';
import CustomModal from '../../components/CustomModal';

export default function LogTimeline(props) {
  var classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [orderList, setOrderList] = useState();
  const [modal, setModal] = useState({ show: false, data: null });

  const fetchData = async () => {
    setLoading(true);
    try {
      const orders = await logTimelineSvc.logTimeline();
      setOrderList(orders);
    } catch (error) {}
    setLoading(false);
  };

  const handleDismiss = async (res) => {
    // add new log timeline
    console.log('log timeline dismiss res data >>>', res.data);

    //     if (res?.data) {
    //         console.log("log timeline dismiss res >>>", res.data.timeline_date, res.data.company_id);
    // //      updateToRejected(order.task_id, res.data);
    //         await logTimelineSvc.addLogTimeline(
    //             res.data.company_id,
    //             res.data.timeline_date,
    //             res.data.action_type,
    //             res.data.action,
    //             res.data.notes,
    //             res.data.status,
    //             res.data.status_id);
    //     }

    setModal({ show: false, data: null });
  };

  const handleOnSubmit = () => {
    setModal({ show: false, data: null });
    fetchData();
  };

  useEffect(() => {
    // fetchData().finally(() => setLoading(false));
    fetchData();
  }, []);
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Company Compliance Timeline"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => {
              setModal({ show: true, data: null });
            }}
          >
            Add New Timeline
          </Button>
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? <CircularProgress /> : <Table data={orderList} refetch={fetchData} />}
          </Widget>
        </Grid>
      </Grid>
      <CustomModal show={modal?.show} onHide={handleDismiss} title="Log Timeline Form">
        <LogTimelineForm
          logTimeline={modal?.data}
          onDismiss={handleDismiss}
          onSubmit={handleOnSubmit}
        />
      </CustomModal>
    </>
  );
}
