import React, { useState, useEffect } from "react";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function CheckboxField(props) {
  // export class CheckboxField extends React.PureComponent {
  const handleCheck = (event, isInputChecked) => {
    props.onChange(event, isInputChecked, props.category);
  };

  //   render() {
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleCheck}
          name={props.category}
          color="primary"
        />
      }
      label={props.label}
    />
    // <Checkbox
    //   name={props.category}
    //   iconStyle={{ fill: "#000" }}
    //   value={props.category}
    //   onCheck={handleCheck}
    // />
  );
  //   }
}
