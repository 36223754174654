import { Icon } from '@iconify/react';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Divider,
  Paper,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import PageTitle from '../../components/PageTitle/PageTitle';
import requestsApi, { APPLICATION_TYPES } from '../../services/requests.service';
import commonApi from '../../services/common.service';
import useStyles from './styles';
import CustomMenu from '../../components/CustomMenu';
import RequestDocuments from './components/RequestDocuments';
import useQueryParams from '../../hooks/useQueryParams';

import {
  OpenBankAccountApplication,
  CloseBankAccountApplication,
  BusinessActivityApplication,
  CessationApplication,
  CompanyAddressChangeApplication,
  CompanyNameChangeApplication,
  DividendDeclarationApplication,
  FinancialYearApplication,
  OfficerApplication,
  OtherRequestApplication,
  ShareAllotmentApplication,
  ShareApplication,
  ShareHolderApplication,
  ShareTransferApplication,
  IndividualParticularApplication,
  CompanyAuditorApplication,
  AuditorCessationApplication,
} from './components/applications';

const enabledStatus = [2, 4, 6, 7];

export default function RequestDetail() {
  const history = useHistory();
  var classes = useStyles();
  let query = useQueryParams();
  const applicationId = query.get('application_id');
  const applicationType = query.get('application_type');
  const applicationTypeId = query.get('application_type_id');
  const companyApplicationId = query.get('company_application_id');

  const email = query.get('email');
  const first_name = query.get('first_name');
  const last_name = query.get('last_name');
  const company_name = query.get('company_name');
  const company_registration_no = query.get('company_registration_no');

  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const handleResponsePopup = (msg = '', variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  const [request, setRequest] = useState({});
  const [application, setApplication] = useState();
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState(
    request?.company_application_status_id || ''
  );

  // const applicationStatusOptions = applicationStatus.map((anItem) => {
  //   return {
  //     label: anItem.status_name,
  //     value: anItem.company_application_status_id,
  //   };
  // });
  const applicationStatusOptions = [];
  applicationStatus.forEach((anItem) => {
    if (enabledStatus.indexOf(anItem.company_application_status_id) >= 0) {
      applicationStatusOptions.push({
        label: anItem.status_name,
        value: anItem.company_application_status_id,
      });
    } else {
      applicationStatusOptions.push({
        label: anItem.status_name,
        value: anItem.company_application_status_id,
        disabled: true,
      });
    }
  });

  console.log('detailed dataaaa:', {
    request,
    application,
    applicationStatusOptions,
  });

  const {
    company_application_type_id,
    application_type_table_name = '',
    company_id,
    company_application_status_id,
    status_name = '',
  } = request;

  useEffect(() => {
    if (request?.company_application_status_id)
      setSelectedApplicationStatus(company_application_status_id);
  }, [request]);

  useEffect(() => {
    (() => {
      requestsApi
        .getRequestById(companyApplicationId)
        .then((result) => {
          // console.log("result", result?.data?.applications);
          if (result?.data?.applications) {
            setRequest(result?.data?.applications || {});
          }
        })
        .catch((error) => {
          setRequest({});
        });
      requestsApi
        .getApplication(applicationId, applicationTypeId)
        .then((res) => {
          // console.log(res);
          setApplication(res);
        })
        .finally(() => {
          setLoading(false);
        });
      commonApi
        .getApplicationStatus()
        .then((res) => {
          // console.log(res);
          // setApplication(res);
          if (res?.data?.applicationstatus) {
            setApplicationStatus(res?.data?.applicationstatus || []);
          }
        })
        .catch((error) => {
          setApplicationStatus([]);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [history]);

  const handleOnStatusChange = (e) => {
    setSelectedApplicationStatus(e?.target?.value || '');
  };
  const handleUpdateStatus = () => {
    if (companyApplicationId && selectedApplicationStatus) {
      requestsApi
        .updateRequestStatus(companyApplicationId, selectedApplicationStatus)
        .then((result) => {
          handleResponsePopup('Status updated', 'success');
          // console.log("updateRequestStatus result", result);
        })
        .catch((error) => {
          handleResponsePopup('Error updating status', 'error');
          // console.log("updateRequestStatus error", error);
        });
    }
    console.log('selectedApplicationStatus', selectedApplicationStatus);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/requests' })}
      >
        BACK TO REQUESTS
      </Button>
      <Box display="flex" flexDirection="column">
        {/* <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(222, 173, 0, 1)',
            cursor: 'pointer',
          }}
          onClick={() => {}}
        >
          <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
          <Typography
            variant="body1"
            align="left"
            style={{
              fontWeight: 600,
            }}
            onClick={() => history.push({ pathname: '/requests' })}
          >
            BACK TO REQUESTS
          </Typography>
        </Box> */}

        <PageTitle title={`${applicationType} (ID:${companyApplicationId})`} />

        <Paper component={Box} mb={3} p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Box
                style={{
                  width: '100%',
                }}
              >
                <Box display={'flex'}>
                  <Typography variant="body1" align="left">
                    Date: {moment(request.created_at).format(`DD/MM/yyyy`)}
                  </Typography>
                </Box>
                <Box>
                  <Box display="flex">
                    <Box mr={2}>User:</Box>
                    {!first_name && !last_name ? '-' : `${first_name} ${last_name}`}
                  </Box>
                  <Box display="flex">
                    <Box mr={2}>Email:</Box>
                    {email || '-'}
                  </Box>
                  <Box display="flex">
                    <Box mr={2}>Company:</Box>
                    {!company_name
                      ? '-'
                      : `${company_name} ${
                          company_registration_no ? `(${company_registration_no})` : ''
                        }`}
                  </Box>
                </Box>
              </Box>
              {/* <Box>
                  <Typography variant="caption">Company</Typography>
                </Box>
                <Box>{company_name}</Box> */}
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography variant="caption">Status</Typography>
              </Box>
              <Box display="flex">
                <CustomMenu
                  data={applicationStatusOptions}
                  value={selectedApplicationStatus}
                  // label="Status"
                  onChange={handleOnStatusChange}
                  variant="outlined"
                />
                &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateStatus}
                  disabled={!(enabledStatus.indexOf(selectedApplicationStatus) >= 0)}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper component={Box} p={2} mb={2}>
          <RenderApplication application={application} request={request} type={applicationTypeId} />
        </Paper>

        <RequestDocuments application={request} id={companyApplicationId} />
      </Box>
    </>
  );
}

const RenderApplication = ({ application, request, type }) => {
  switch (requestsApi.getAppType(type)) {
    case APPLICATION_TYPES.SHARE_APPLICATION:
      return <ShareApplication application={application} request={request} />;
    case APPLICATION_TYPES.SHARE_TRANSFER_APPLICATION:
      return <ShareTransferApplication application={application} request={request} />;
    case APPLICATION_TYPES.COMPANY_ADDRESS_CHANGE_APPLICATION:
      return <CompanyAddressChangeApplication application={application} request={request} />;
    case APPLICATION_TYPES.COMPANY_NAME_CHANGE_APPLICATION:
      return <CompanyNameChangeApplication application={application} request={request} />;
    case APPLICATION_TYPES.CESSATION_APPLICATION:
      return <CessationApplication application={application} request={request} />;
    case APPLICATION_TYPES.OFFICER_APPLICATION:
      return <OfficerApplication application={application} request={request} />;
    case APPLICATION_TYPES.BUSINESS_ACTIVITY_APPLICATION:
      return <BusinessActivityApplication application={application} request={request} />;
    case APPLICATION_TYPES.OPEN_BANK_ACCOUNT_APPLICATION:
      return <OpenBankAccountApplication application={application} request={request} />;
    case APPLICATION_TYPES.CLOSE_BANK_ACCOUNT_APPLICATION:
      return <CloseBankAccountApplication application={application} request={request} />;
    case APPLICATION_TYPES.SHARE_HOLDER_APPLICATION:
      return <ShareHolderApplication application={application} request={request} />;
    case APPLICATION_TYPES.FINANCIAL_YEAR_END_APPLICATION:
      return <FinancialYearApplication application={application} request={request} />;
    case APPLICATION_TYPES.OTHER_REQUEST_APPLICATION:
      return <OtherRequestApplication application={application} request={request} />;
    case APPLICATION_TYPES.DIVIDEND_APPLICATION:
      return <DividendDeclarationApplication application={application} request={request} />;
    case APPLICATION_TYPES.SHARE_ALLOTMENT_APPLICATION:
      return <ShareAllotmentApplication application={application} request={request} />;
    case APPLICATION_TYPES.INDIVIDUAL_PARTICULAR_APPLICATION:
      return <IndividualParticularApplication application={application} request={request} />;
    case APPLICATION_TYPES.COMPANY_AUDITOR_APPLICATION:
      return <CompanyAuditorApplication application={application} request={request} />;
    case APPLICATION_TYPES.AUDITOR_CESSATION_APPLICATION:
      return <AuditorCessationApplication application={application} request={request} />;

    default:
      return <h1>Not Found</h1>;
  }
};
