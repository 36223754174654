import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import webdevSvc from '../../services/webdev';
import Table from './components/Table/Table';
// styles
import useStyles from './styles';

export default function WebDevs(props) {
  var classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [devList, setDevList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const devs = await webdevSvc.getDevs();
      setDevList(devs);
    };

    fetchData().finally(() => setLoading(false));
  }, []);
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => history.push({ pathname: '/webdev' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Web Devs" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? <CircularProgress /> : <Table data={devList} />}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
