// const fetchData = async () => {
//       const header = {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         "Content-Type": "application/json",
//       };

//       const respIdentificationtype = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/identificationtype",
//         { headers: header }
//       );
//       console.log(
//         "respIdentificationtype",
//         respIdentificationtype.data.identificationtypes
//       );
//       setIdentificationlist(respIdentificationtype.data.identificationtypes);

//       const respCountry = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/country",
//         { headers: header }
//       );
//       console.log("respCountry", respCountry.data.countries);
//       setCountrylist(respCountry.data.countries);
//       const respCompanyrole = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/companyrole",
//         { headers: header }
//       );
//       setRolelist(respCompanyrole.data.companyroles);
//       console.log("respCompanyrole", respCompanyrole.data.companyroles);

//       const respaddresstype = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/addresstype",
//         { headers: header }
//       );
//       console.log("respaddresstype", respaddresstype.data.addresstypes);

//       const resprisklevel = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/risklevel",
//         { headers: header }
//       );
//       console.log("resprisklevel", resprisklevel.data.risklevels);
//       setRisklevellist(resprisklevel.data.risklevels);

//       const respriskcategory = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/riskcategory",
//         { headers: header }
//       );
//       console.log("respriskcategory", respriskcategory.data.riskcategories);
//       setRiskcategorylist(respriskcategory.data.riskcategories);
//       //   const respcontacttype = await axios.get(
//       //     "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/contacttype",
//       //     { headers: header }
//       //   );
//       //   console.log("respcontacttype", respcontacttype.data.contacttypes);

//       const respcessationtype = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/cessationtype",
//         { headers: header }
//       );
//       setCessationlist(respcessationtype.data.cessationtypes);
//       console.log("respcessationtype", respcessationtype.data.cessationtypes);

//       const respphonecode = await axios.get(
//         "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/phonecode",
//         { headers: header }
//       );
//       console.log("respphonecode", respphonecode.data.phonecodes);
//       setPhoneCodeList(respphonecode.data.phonecodes);
//     };

// const { commonApi } = require("./api");

// const getIdentificationType = () => {
//   return commonApi.get("/identificationtype");
// };

// export default {
//   getIdentificationType,
// };
import commonApi from "../services/commonapi";
// import Api from "../services/api";

const getIdentificationType = () => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return commonApi.get(`/identificationtype`);
};

const getAddressType = () => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return commonApi.get(`/addresstype`);
};

const getCountry = () => {
  return commonApi.get(`/country`);
};

const getCompanyType = () => {
  return commonApi.get(`/companytype`);
};

const getCompanyRole = () => {
  return commonApi.get(`/companyrole`);
};

const getCompanyStatusCategory = () => {
  return commonApi.get(`/companystatuscategory`);
};

const getCompanyStatus = () => {
  return commonApi.get(`/companystatus`);
};

const getCurrency = () => {
  return commonApi.get(`/currency`);
};

const getShareType = () => {
  return commonApi.get(`/sharetype`);
};

const getSsicCode = () => {
  return commonApi.get(`/ssiccode`);
};

const getCessationType = () => {
  return commonApi.get(`/cessationtype`);
};

const getSsicCodeByCode = (search) => {
  return commonApi.get(`/ssiccode?search=` + search);
};

const getApplicationStatus = () => {
  return commonApi.get(`/companyapplicationstatus`);
};

const getGender = () => {
  return commonApi.get(`/gender`);
};

export default {
  getCessationType,
  getIdentificationType,
  getAddressType,
  getCountry,
  getCompanyRole,
  getCompanyType,
  getCompanyStatus,
  getCompanyStatusCategory,
  getCurrency,
  getShareType,
  getSsicCode,
  getSsicCodeByCode,
  getApplicationStatus,
  getGender,
};
