import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Chip,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';

// styles
import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import CustomMenu from '../../components/CustomMenu';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import utilsSvc from '../../services/utils.service';
import DocumentsListByType from './components/DocumentsListByType';

export const defaultEmailTypes = [
  {
    document_type_id: 147,
    label: 'Onboarding Company Approved',
    data: [],
  },
  {
    document_type_id: 148,
    label: 'Onboarding Company Rejected',
    data: [],
  },
  {
    document_type_id: 149,
    label: 'New Requests',
    data: [],
  },
  {
    document_type_id: 150,
    label: 'New User',
    data: [],
  },
  {
    document_type_id: 151,
    label: 'New User Invitation',
    data: [],
  },
  {
    document_type_id: 152,
    label: 'User Invitation',
    data: [],
  },
  {
    document_type_id: 153,
    label: 'New User Invitation Manage User',
    data: [],
  },
  {
    document_type_id: 154,
    label: 'Forgot Password',
    data: [],
  },
  {
    document_type_id: 155,
    label: 'Onboarding Company Request',
    data: [],
  },
  {
    document_type_id: 156,
    label: 'User Invitation Manage User',
    data: [],
  },
  {
    document_type_id: 165,
    label: 'Onboarding Company Public Invoice',
    data: [],
  },
  {
    document_type_id: 166,
    label: 'Onboarding Company Public Receipt',
    data: [],
  },
  {
    document_type_id: 168,
    label: 'Reminder',
    data: [],
  },
];

export default function EmailTemplates(props) {
  var classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Email Templates" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle bodyClass={classes.tableWidget}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <DocumentsListByType
                  dataGroups={defaultEmailTypes}
                  dataTypeId={25}
                  documentContainerId={2}
                  dataId={0}
                  preview={false}
                  accept=".html"
                  // urlKey="document_url"
                  isPublic={false}
                />
              </>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
