import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Chip,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
// styles
import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import CustomMenu from '../../components/CustomMenu';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import utilsSvc from '../../services/utils.service';
import useQueryParams from '../../hooks/useQueryParams';

const fieldName_remarks = 'remarks';
const fieldName_status = 'status';
const statusOptions = [
  {
    label: 'In Progress',
    value: 'in progress',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];
export default function Requests(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParams();

  // local
  var [mainChartState, setMainChartState] = useState('monthly');
  const [isLoading, setLoading] = useState(true);
  var [requestList_initial, setRequestList] = useState();
  var [selectedApplicationType, setSelectedApplicationType] = useState('all');
  var [selectedApplicationStatus, setSelectedApplicationStatus] = useState('all');
  var [defaultContactSupports, setContactSupports] = useState([]);
  var [selectedRecord, setSelectedRecord] = useState();

  let contactSupports = defaultContactSupports.sort((a, b) => {
    return b?.contact_support_id - a?.contact_support_id;
  });
  const handleEditRemarkOpen = (data) => {
    setSelectedRecord(data);
  };
  const handleEditRemarkClose = () => {
    setSelectedRecord();
  };

  const fetchData = async () => {
    setLoading(true);
    const resContactSupports = await utilsSvc.getContactSupports();
    if (resContactSupports?.data?.contact_support) {
      setContactSupports(resContactSupports?.data?.contact_support);
    }

    //   setTimeout(function () {}, 2000);
  };

  const handleChangeApplicationType = (event) => {
    setSelectedApplicationType(event.target.value);
  };
  const handleChangeApplicationStatus = (event) => {
    setSelectedApplicationStatus(event.target.value);
  };

  useEffect(() => {
    fetchData().finally(() => setLoading(false));
  }, []);

  const handleOnSubmit = (values, actions) => {
    console.log('valuesss', values);
    if (selectedRecord?.contact_support_id) {
      let newData = {
        [fieldName_remarks]: values?.[fieldName_remarks] || '',
        [fieldName_status]: values?.[fieldName_status] || '',
      };
      utilsSvc
        .updateContactSupport(selectedRecord.contact_support_id, newData)
        .then((result) => {
          enqueueSnackbar('Remarks updated', { variant: 'success' });
          handleEditRemarkClose();
          fetchData().finally(() => setLoading(false));
        })
        .catch((error) => {
          enqueueSnackbar('Failed to update remarks', { variant: 'error' });
        });
    }
  };
  // let requestList = requestList_initial;
  // if (selectedApplicationType != "all" || selectedApplicationStatus != "all") {
  //   requestList = requestList_initial.filter((anItem) => {
  //     let matchType =
  //       selectedApplicationType != "all"
  //         ? anItem["company_application_type_id"] == selectedApplicationType
  //         : true;
  //     let matchStatus =
  //       selectedApplicationStatus != "all"
  //         ? anItem["company_application_status_id"] == selectedApplicationStatus
  //         : true;

  //     return matchType == true && matchStatus == true;
  //   });
  // }
  // console.log("requestList", requestList);

  // let distinctApplicationTypes = {};
  // requestList?.forEach((anItem) => {
  //   let applicationTypeId = anItem["company_application_status_id"];
  //   let applicationTypeName = anItem["status_name"];

  //   if (!distinctApplicationTypes[applicationTypeId]) {
  //     distinctApplicationTypes[applicationTypeId] = applicationTypeName;
  //   }
  // });
  // console.log("distinctApplicationTypes", distinctApplicationTypes);

  //   contact_support_id: 1
  // email: "email"
  // message: "message"
  // remarks: "rema"
  // status: "cancelled"
  // subject: "subject"
  // sysinfo_user_id: 1
  // username: "username"

  const filters = [
    {
      label: 'Status',
      valueKey: 'status',
      type: 'toggle',
    },
    {
      label: 'Subject',
      valueKey: 'subject',
      type: 'toggle',
    },
  ];

  const subjectOptions = [
    {
      label: 'Contact Support',
      value: 'Contact Support',
    },
    {
      label: 'Upcoming Service',
      value: 'Upcoming Service',
    },
    {
      label: 'Keep Me Updated',
      value: 'Keep Me Updated',
    },
  ];

  let filterValues = {};
  filters.forEach((anItem) => {
    let value = queryParams.get(anItem.valueKey);
    if (value) {
      filterValues[anItem.valueKey] = queryParams.get(anItem.valueKey);
    }
  });
  const handleFilterOnChange = (key, value) => {
    if (queryParams.get(key) == value) {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }
    let newQueryParams = queryParams.toString();
    history.replace({
      pathname: '/contactsupports',
      search: '?' + newQueryParams,
    });
  };

  const handleClearFilter = () => {
    history.replace({
      pathname: '/contactsupports',
    });
  };

  let filteredContactSupports = contactSupports.filter((anItem) => {
    let filterStatusValue = filterValues?.['status'];
    let filterSubjectValue = filterValues?.['subject'];

    let result = true;
    if (filterStatusValue != undefined) {
      if (anItem['status'] != filterStatusValue) {
        result = false;
      }
    }

    if (filterSubjectValue != undefined) {
      if (result) {
        let itemSubjectValue = anItem?.['subject']?.toLocaleLowerCase();
        let itemMessageValue = anItem?.['message']?.toLocaleLowerCase();

        if (
          itemSubjectValue.indexOf(filterSubjectValue.toLocaleLowerCase()) < 0 &&
          itemMessageValue.indexOf(filterSubjectValue.toLocaleLowerCase()) < 0
        ) {
          result = false;
        }
      }
    }

    return result;
  });

  const tableColumns = [
    {
      label: 'ID',
      id: 'contact_support_id',
    },
    {
      label: 'EMAIL',
      id: 'email',
    },
    {
      label: 'USERNAME',
      id: 'username',
    },
    {
      label: 'SUBJECT',
      id: 'subject',
      render: (text, data) => {
        return text || '-';
      },
    },
    {
      label: 'MESSAGE',
      id: 'message',
      render: (text, data) => {
        return text || '-';
      },
    },
    {
      label: 'REMARKS',
      id: 'remarks',
      render: (text, data) => {
        return <div>{text || '-'} </div>;
      },
    },
    {
      label: 'STATUS',
      id: 'status',
      render: (text, data) => {
        let foundStatus = statusOptions.find((anItem) => {
          return anItem.value == text;
        });
        if (foundStatus) {
          return foundStatus.label;
        }
        return '-';
      },
    },
    {
      label: '',
      id: 'action',
      render: (text, data) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              handleEditRemarkOpen(data);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Contact Supports" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb={2} border={1} p={2} borderRadius={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <b>Filter by: </b>
                      </Box>
                      {Object.keys(filterValues)?.length > 0 ? (
                        <Box>
                          <Button
                            onClick={handleClearFilter}
                            size="small"
                            color={'secondary'}
                            variant="outlined"
                          >
                            Clear Filter
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <small>Status</small>
                    </Box>
                    <ButtonToggleGroup
                      options={statusOptions}
                      value={filterValues?.['status'] || ''}
                      // label="Status"
                      onChange={(value) => {
                        handleFilterOnChange('status', value);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <small>Subject</small>
                    </Box>
                    <ButtonToggleGroup
                      options={subjectOptions}
                      value={filterValues?.['subject'] || ''}
                      // label="Status"
                      onChange={(value) => {
                        handleFilterOnChange('subject', value);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <CustomTable
                rowKey="contact_support_id"
                columns={tableColumns}
                data={filteredContactSupports}
              />
            </>
          )}
        </Grid>
      </Grid>
      <CustomModal show={Boolean(selectedRecord)} onHide={handleEditRemarkClose} scrollable={false}>
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={{
            [fieldName_remarks]: selectedRecord?.[fieldName_remarks] || '',
            [fieldName_status]: selectedRecord?.[fieldName_status] || '',
          }}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <Box mb={2}>
                  <Box>Status</Box>
                  <CustomSelectField name={fieldName_status} options={statusOptions} />
                </Box>
                <Box>
                  <Box>Remarks</Box>
                  <TextField
                    // label="Remarks"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values[fieldName_remarks]}
                    name={fieldName_remarks}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button variant="outlined" onClick={handleEditRemarkClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
}

const ButtonToggleGroup = (props) => {
  const { options = [], value, onChange } = props;
  console.log('valuevalue', value);
  return (
    <Box display="flex" flexWrap="wrap">
      {options.map((anItem, index) => {
        let isActive = anItem.value == value;
        return (
          <Box key={index} mr={1} mb={1}>
            <Button
              disableElevation={true}
              variant={isActive ? 'contained' : 'outlined'}
              color={'primary'}
              onClick={(e) => {
                onChange(anItem.value);
              }}
            >
              {anItem.label}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
