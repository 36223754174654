import React, { useEffect, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './FileDropzone.styles';

const CustomFileDropzone = ({
  multiple = false,
  files = [],
  setFiles,
  error,
  disabled,
  ...restProps
}) => {
  const classes = useStyles();

  const uploadRef = useRef();
  const onDrop = useCallback((acceptedFiles) => {
    console.log('acceptedFiles', acceptedFiles);
    if (setFiles) {
      setFiles(acceptedFiles);
    }
  }, []);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple,
    onDrop,
  });

  // useEffect(() => {
  //   if (setFiles) {
  //     setFiles(acceptedFiles);
  //   }
  // }, [acceptedFiles]);

  function bytesToSize(bytes) {
    const units = ['byte', 'kilobyte', 'megabyte', 'terabyte', 'petabyte'];
    const unit = Math.floor(Math.log(bytes) / Math.log(1024));
    return new Intl.NumberFormat('en', {
      style: 'unit',
      unit: units[unit],
    }).format(bytes / 1024 ** unit);
  }

  // const handleOnChange = (e) => {
  //   if (
  //     uploadRef.current.value !== undefined ||
  //     uploadRef.current.value !== null ||
  //     uploadRef.current.value !== ''
  //   ) {
  //     let newFiles = Array.from(uploadRef?.current?.files);

  //     setFiles([...files, ...newFiles]);
  //     uploadRef.current.value = '';

  //     //   console.log('imageInputRef.current.value', imageInputRef.current.files);
  //     if (!uploadRef?.current?.files?.[0]?.type) {
  //     } else {
  //     }
  //   }
  // };

  const handleOnRemove = (index) => {
    if (setFiles) {
      let newValues = files || [];
      if (files.length > 0 && index > -1) {
        newValues.splice(index, 1);
      }
      setFiles(newValues, true);
    }
  };

  return (
    <>
      <section className={error ? classes.errorContainer : classes.container}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} disabled={disabled} />
          <Box
            px={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            p={3}
          >
            <CloudUploadIcon fontSize={'large'} />
            <Box display="flex" flexDirection="column" px={2}>
              <div>
                <Typography color="textSecondary">Drag and drop here / Click to upload</Typography>
              </div>
              <Box mt={2}>
                <Typography color="textSecondary">Accepted formats: .jpg / .pdf / .png</Typography>
              </Box>
              <Typography color="textSecondary">Click to Upload</Typography>
            </Box>
          </Box>
        </div>
        {files.length > 0 && (
          <Box mt={2}>
            {files.map((aFile, index) => {
              return (
                <Box key={index} px={3} mb={2} display="flex" alignItems={'center'}>
                  <Box flexGrow={1} pr={1}>
                    <Box>{aFile.name}</Box>
                    <Box>{aFile?.size ? bytesToSize(aFile.size) : '-'}</Box>
                  </Box>

                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => handleOnRemove(index)}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              );
            })}
          </Box>
        )}
      </section>
      {error && (
        <Typography variant="caption" className={classes.errorText}>
          File is required
        </Typography>
      )}
    </>
  );
};
export default CustomFileDropzone;
