import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Button,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import clsx from "clsx";

import useFetchData from "../useFetchData";
import userApi from "../../../services/user.service";
import addressApi from "../../../services/address.service";
import CustomModal from "../../../components/CustomModal";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
  },
  gridContainer: {
    "& .MuiGrid-item": {
      padding: theme.spacing(1),
      // borderWidth: "1px",
      // borderStyle: "solid",
      // borderColor: theme.palette.grey[300],
      // display: "flex",
      // alignItems: "center",
    },
    "& .MuiGrid-container.MuiGrid-item": {
      padding: theme.spacing(0),
    },
  },
  successText: {
    color: theme.palette.success.main,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

const personalInfoFields = [
  {
    label: "First Name",
    key: "first_name",
  },
  {
    label: "Last Name",
    key: "last_name",
  },
  {
    label: "Gender",
    key: "gender_name",
  },
  {
    label: "Nationality",
    key: "nationality",
  },
];
const contactFields = [
  {
    label: "Contact Number",
    key: "contact_number",
  },
];
const addressFields = [
  {
    label: "Floor/Unit No",
    key: "floor_unit_no",
  },
  {
    label: "Address Line 1",
    key: "address_line_1",
  },
  {
    label: "Address Line 2",
    key: "address_line_2",
  },
  {
    label: "City / State",
    key: "address_line_3",
  },
];

const VerifyUserModal = (props) => {
  const { refetchData, title = "", show = false, onHide, children } = props;

  const theme = useTheme();
  const classes = useStyles();

  const userId = show?.user_id;
  const companyUserId = show?.company_user_id;
  const showModal = Boolean(show);

  const [
    fetchSysInfoUser,
    {
      data: sysInfoUserData,
      error: errorSysInfoUser,
      loading: loadingSysInfoUser,
    },
  ] = useFetchData(
    {
      callback: async () => await userApi.getSysInfoUserById(userId),
    },
    true
  );

  const [
    fetchCompanyUser,
    {
      data: companyUserData,
      error: errorCompanyUser,
      loading: loadingCompanyUser,
    },
  ] = useFetchData(
    {
      callback: async () => await userApi.getUserById(companyUserId),
    },
    true
  );

  const [
    fetchCompanyUserAddress,
    {
      data: companyUserAddressData,
      error: errorCompanyUserAddress,
      loading: loadingCompanyUserAddress,
    },
  ] = useFetchData(
    {
      callback: async () => await addressApi.getUserAddress(companyUserId),
    },
    true
  );

  const [
    fetchCompanyUserContacts,
    {
      data: companyUserContactData,
      error: errorCompanyUserContacts,
      loading: loadingCompanyUserContacts,
    },
  ] = useFetchData(
    {
      callback: async () => await userApi.getContacts(companyUserId),
    },
    true
  );

  let sysInfoUser = sysInfoUserData?.users || {};
  let companyUser = companyUserData?.users || {};
  let companyUserAddress =
    companyUserAddressData?.userAddresses.length > 0
      ? companyUserAddressData.userAddresses.find((anAddress) => {
          return anAddress.is_default;
        })
      : {};
  let companyUserContact = {};
  if (companyUserContactData?.contacts.length > 0) {
    let filteredContacts = companyUserContactData.contacts.filter(
      (anItem) => anItem.contact_type_name != "email"
    );
    if (filteredContacts.length > 0) {
      let foundContact = filteredContacts.find((aContact) => {
        return aContact.is_primary;
      });
      if (foundContact) {
        companyUserContact = foundContact;
      } else {
        companyUserContact = filteredContacts?.[0];
      }
    }
  }

  const [
    updateSysInfoUser,
    {
      // data: sysInfoUserData,
      error: errorUpdateSysInfoUser,
      loading: loadingUpdateSysInfoUser,
    },
  ] = useFetchData({}, true);

  useEffect(() => {
    if (showModal) {
      if (fetchCompanyUser && userId && companyUserId) {
        (async () => {
          await fetchSysInfoUser();
          await fetchCompanyUser();
          await fetchCompanyUserAddress();
          await fetchCompanyUserContacts();
        })();
      }
    }
  }, [showModal]);

  const handleOnClose = () => {
    if (refetchData) {
      refetchData();
    }
    if (onHide) {
      onHide();
    }
  };

  const handleUpdateSysInfoUser = async (body) => {
    updateSysInfoUser({
      callback: async () => await userApi.updateSysInfoUser(userId, body),
    })
      .then((result) => {
        fetchSysInfoUser();
      })
      .catch((error) => {});
  };

  return (
    <CustomModal
      show={showModal}
      onHide={handleOnClose}
      loading={loadingCompanyUser}
      title={
        <Typography variant="h5">
          {sysInfoUser?.username} (ID: {sysInfoUser?.user_id})
        </Typography>
      }
      footer={
        <>
          <Button
            variant="contained"
            onClick={handleOnClose}
            style={{ marginRight: theme.spacing(1) }}
          >
            Cancel
          </Button>
        </>
      }
    >
      {companyUser ? (
        <Grid
          container
          spacing={0}
          className={classes.gridContainer}
          style={{ width: "100%" }}
        >
          <Grid container item xs={10}>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <b>Sys Info User</b>
            </Grid>
            <Grid item xs={5}>
              <b>Company User</b>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <b>Verified</b>
          </Grid>

          <Grid container item xs={10}>
            <Grid item xs={12}>
              <b>Personal Info</b>
            </Grid>
            {personalInfoFields.map((anItem, index) => {
              return (
                <Grid container item xs={12} key={index}>
                  <Grid item xs={2}>
                    {anItem.label}
                  </Grid>
                  <Grid item xs={5}>
                    {sysInfoUser?.[anItem.key] || "-"}
                  </Grid>
                  <Grid item xs={5}>
                    {companyUser?.[anItem.key] || "-"}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={2}>
            {sysInfoUser?.is_personalinfo_verified ? (
              <span className={clsx(classes.successText, classes.flexCenter)}>
                <CheckCircleRoundedIcon />
              </span>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  handleUpdateSysInfoUser({
                    is_personalinfo_verified: true,
                  });
                }}
                disabled={loadingSysInfoUser}
              >
                {loadingSysInfoUser ? <CircularProgress /> : "Checked"}
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={2}>
              <b>Email</b>
            </Grid>
            <Grid item xs={5}>
              {sysInfoUser?.email}
            </Grid>
            <Grid item xs={5}>
              {companyUser?.email}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {sysInfoUser?.is_email_verified ? (
              <span className={clsx(classes.successText, classes.flexCenter)}>
                <CheckCircleRoundedIcon />
              </span>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  handleUpdateSysInfoUser({
                    is_email_verified: true,
                  });
                }}
                disabled={loadingSysInfoUser}
              >
                {loadingSysInfoUser ? <CircularProgress /> : "Checked"}
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <b>Contact</b>
            </Grid>
            {contactFields.map((anItem, index) => {
              return (
                <Grid container item xs={12} key={index}>
                  <Grid item xs={2}>
                    {anItem.label}
                  </Grid>
                  <Grid item xs={5}>
                    {sysInfoUser?.[anItem.key] || "-"}
                  </Grid>
                  <Grid item xs={5}>
                    {companyUserContact?.["contact_value"] || "-"}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={2}>
            {sysInfoUser?.is_contact_verified ? (
              <span className={clsx(classes.successText, classes.flexCenter)}>
                <CheckCircleRoundedIcon />
              </span>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  handleUpdateSysInfoUser({
                    is_contact_verified: true,
                  });
                }}
                disabled={loadingSysInfoUser}
              >
                {loadingSysInfoUser ? <CircularProgress /> : "Checked"}
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <b>Address</b>
            </Grid>
            {addressFields.map((anItem, index) => {
              return (
                <Grid container item xs={12} key={index}>
                  <Grid item xs={2}>
                    {anItem.label}
                  </Grid>
                  <Grid item xs={5}>
                    {sysInfoUser?.[anItem.key] || "-"}
                  </Grid>
                  <Grid item xs={5}>
                    {companyUserAddress?.[anItem.key] || "-"}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={2}>
            {sysInfoUser?.is_address_verified ? (
              <span className={clsx(classes.successText, classes.flexCenter)}>
                <CheckCircleRoundedIcon />
              </span>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  handleUpdateSysInfoUser({
                    is_address_verified: true,
                  });
                }}
                disabled={loadingSysInfoUser}
              >
                {loadingSysInfoUser ? <CircularProgress /> : "Checked"}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box>No Data</Box>
      )}
    </CustomModal>
  );
};

export default VerifyUserModal;
