import React, { useState, useEffect } from 'react';

const defaultValues = {
  options: [],
  rawData: [],
  loading: false,
  error: false,
};
const useCommonOptions = (api, props) => {
  const { respondKey, labelKey, valueKey, render, isString = false, lazyLoad = false } = props;
  const [value, setValue] = useState(defaultValues);

  const fetchData = async (...args) => {
    setValue((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    return new Promise(async (resolve, reject) => {
      let apiRes;
      try {
        apiRes = await api(...args);
        let response = apiRes?.data?.[respondKey];
        let options = [];

        if (response && response.length > 0) {
          if (labelKey && valueKey) {
            response.map((aData, index) => {
              let newOption = {
                label: aData?.[labelKey],
                value: isString ? `${aData?.[valueKey]}` : aData?.[valueKey],
              };
              if (render && typeof render == 'function') {
                newOption['label'] = render(aData?.[labelKey], aData, index);
              }
              options.push(newOption);
            });
          }
          setValue((prev) => {
            return {
              ...prev,
              options: options,
              rawData: response,
              loading: false,
              error: false,
            };
          });
        } else {
          setValue(defaultValues);
        }
        resolve(apiRes);
      } catch (error) {
        setValue({
          ...defaultValues,
          error: error,
        });
        reject(error);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (!lazyLoad && isMounted) {
      (async () => {
        await fetchData();
      })();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return {
    ...value,
    refetch: fetchData,
  };
};

export default useCommonOptions;
