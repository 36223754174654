import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};
export default function TableComponent({ data }) {
  const history = useHistory();
  var keys = ['ID', 'THEME', 'PLAN', 'TEMPLATE', 'CATEGORY', 'DOMAIN', ''];
  const getSortData = (list, key) => {
    return list.slice().sort(function (a, b) {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  };

  data = getSortData(data, 'company_application_id');
  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map((key) => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((dev) => (
          <TableRow key={dev.webdev_id}>
            <TableCell className="pl-3 fw-normal">{dev.webdev_id}</TableCell>
            <TableCell className="pl-3 fw-normal">{dev.theme}</TableCell>
            <TableCell>{dev.plan}</TableCell>
            <TableCell>{dev.template_content}</TableCell>
            <TableCell>{dev.webdev_category_name}</TableCell>
            <TableCell>{dev.domain_name}</TableCell>
            <TableCell>
              <Chip
                color="primary"
                label="DETAIL"
                onClick={() => {
                  history.push(`/webdev?devid=${dev.webdev_id}`);
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
