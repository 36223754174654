import axios from 'axios';
import { Cookies } from 'react-cookie';

let documentURL = process.env.REACT_APP_DOCUMENT_URL;
let documentURLV2 = `${process.env.REACT_APP_API_V2_URL}/document`;

const customAxios = axios.create({
  baseURL: documentURLV2,
});

customAxios.interceptors.request.use((config) => {
  let accessToken = new Cookies().get('accessToken');
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

const getDocuments = async (docType, tableType, primaryId) => {
  return customAxios.get(
    `/documents?doc_type=${docType}&table_type=${tableType}&primary_id=${primaryId}`
  );
};

const getDocumentsList = async (tableType, primaryId) => {
  return customAxios.get(`/documents?table_type=${tableType}&primary_id=${primaryId}`);
};

const getDocumentById = async (docId) => {
  return customAxios.get(`/documents/${docId}`);
};

const getDocumentsByIds = async (ids = []) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });
  let idsString = idsStringArray.join('&');

  return customAxios.get(`/multi-documents?${idsString}`);
};

const getProductImageDocumentsByIds = async (ids = []) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });
  let idsString = idsStringArray.join('&');

  return customAxios.get(`/multi-documents?${idsString}`);
};

const createRequestDocument = (companyApplicationId, data) => {
  // https://nztftlfooj.execute-api.ap-southeast-1.amazonaws.com/Prod/companyapplications/{companyApplicationId}/documents
  return axios.post(
    `https://nztftlfooj.execute-api.ap-southeast-1.amazonaws.com/Prod/companyapplications/${companyApplicationId}/documents`,
    data
  );
};
const getRequestDocuments = (companyApplicationId) => {
  let url = `https://nztftlfooj.execute-api.ap-southeast-1.amazonaws.com/Prod/companyapplications/${companyApplicationId}/documents`;
  // let url = `${process.env.REACT_APP_API_URL}/companyapplications/${companyApplicationId}/documents`;
  // let url = `${baseURL}/companyapplications/${companyApplicationId}/documents`;
  return axios.get(url);
};

const removeDocumentById = async (id, deleted_by) => {
  return customAxios.put(`/documents/${id}`, {
    deleted_by: deleted_by,
  });
};

const addDocument = async (docType, dataType, dataId, document_url, fileName, fileType) => {
  const header = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  };
  let body = {
    module_id: 1,
    document_container_id: 1,
    document_type_id: docType, //76 NRIC, 109 Proof of Residential, 110 CDD documents
    document_table_type_id: dataType, //table: Users, 5 UserAddresses, 4 CDD
    table_primary_id: dataId, //user_id, user_address_id, customer_due_diligence_id
    document_url: document_url,
    file_type: fileType || null,
    file_name: fileName || null,
  };
  return await customAxios.post(`/documents`, body, {
    headers: header,
  });
};

// private doc
const getDocumentsSTS = async (doc_type, table_type, primary_id) => {
  let res = [];
  res = customAxios.get(
    `/admin-documents?doc_type=${doc_type}&table_type=${table_type}&primary_id=${primary_id}`
  );
  return res;
};

const getDocumentsListSTS = async (tableType, primaryId) => {
  return customAxios.get(`/admin-documents?table_type=${tableType}&primary_id=${primaryId}`);
};

const getDocumentsByIdsSTS = async (ids = []) => {
  let idsStringArray = [];
  ids.forEach((anId) => {
    idsStringArray.push(`doc_ids=${anId}`);
  });

  let idsString = idsStringArray.join('&');

  let res = customAxios.get(`/admin-multi-documents?${idsString}`);
  return res;
};

const getDocumentsByIdSTS = async (id) => {
  return new Promise((resolve, reject) => {
    customAxios
      .get(`/admin-documents/${id}`)
      .then((result) => {
        let newBody = result?.data?.body;
        if (typeof newBody == 'string') {
          try {
            newBody = JSON.parse(result?.data?.body);
          } catch (error) {}
        }
        resolve({
          ...result,
          data: {
            ...result.data,
            ...newBody,
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  getDocuments,
  getDocumentsList,
  getDocumentById,
  getRequestDocuments,
  createRequestDocument,
  removeDocumentById,
  addDocument,
  getDocumentsByIds,
  getDocumentsSTS,
  getDocumentsListSTS,
  getDocumentsByIdsSTS,
  getDocumentsByIdSTS,
  getProductImageDocumentsByIds,
};
