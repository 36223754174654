import utilsApi from './utilsapi';

const getContactSupports = () => {
  return utilsApi.get('/contactsupport');
};

const updateContactSupport = (id, data = {}) => {
  return utilsApi.put(`/contactsupport/${id}`, data);
};

const getCompanyApplicationLogs = () => {
  return utilsApi.get('/companyapplicationslog');
};

const getEmailLogs = () => {
  return utilsApi.get('/email-log');
};
const sendEmail = (body) => {
  return utilsApi.post('/send-template-email', body);
};

export default {
  getContactSupports,
  updateContactSupport,
  getCompanyApplicationLogs,
  getEmailLogs,
  sendEmail,
};
