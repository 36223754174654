import cddApi from "./cddapi";

const createCdd = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return cddApi.post(`/users/` + userId + `/customerdd`, data);
};

const getCdd = (userId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return cddApi.get(`/users/` + userId + `/customerdd`);
};

const updateCdd = (userId, ccdId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return cddApi.put(`/users/` + userId + `/customerdd/` + ccdId, data);
};

export default {
  createCdd,
  getCdd,
  updateCdd,
};
