import axios from "axios";

export default axios.create({
  // baseURL: "https://api.meyzer360.com/application",
  baseURL: `${process.env.REACT_APP_APPLICATION_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
