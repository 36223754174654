import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Collapse,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import CustomTableHead from './TableHead';
import TablePaginationActions from './TablePaginationActions';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
    // width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function CustomTable(props) {
  const {
    data: defaultData = [],
    columns = [],
    rowSelection = {},
    rowKey = '',
    onClickRow,
    size = 'medium',
    pagination,
    customRowOverlay,
    renderRow,
    className = '',
    style = {},
    ...restProps
  } = props;
  const classes = useStyles();
  // console.log('defaultData', {
  //   defaultData,
  //   columns,
  // });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  let data = defaultData || [];
  if (!Array.isArray(defaultData)) {
    data = [];
  }
  if (orderBy && order) {
    data = stableSort(data, getComparator(order, orderBy));
  }

  // selections
  const { selectedRowKeys: selected, onChange: setSelected } = rowSelection;
  // const [selected, setSelected] = useState([]);
  let withSelection = selected && setSelected;

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (withSelection) {
      if (event.target.checked) {
        const newSelecteds = data.map((n, index) => {
          if (rowKey && n?.[rowKey]) {
            return n[rowKey];
          }
          return index;
        });
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (event, name, rowData, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  if (rowsPerPage > 0 && pagination) {
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} {...restProps}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="enhanced table"
          >
            <CustomTableHead
              columns={columns}
              classes={classes}
              numSelected={selected && selected.length}
              order={order}
              orderBy={orderBy}
              withSelection={withSelection}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data.map((row, index) => {
                let rowId = rowKey ? row?.[rowKey] : index;
                const isItemSelected = withSelection ? isSelected(rowId) : false;
                const labelId = `enhanced-table-checkbox-${index}`;

                if (row?.children?.length > 0) {
                  return (
                    <CustomTableRow
                      hover
                      onClick={(event) => {
                        if (withSelection) {
                          handleClick(event, rowId, row, index);
                        }
                      }}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${index}-${rowId}`}
                      selected={isItemSelected}
                      color="primary"
                      row={row}
                    />
                  );
                }

                let rowOverlay = null;
                if (customRowOverlay && typeof customRowOverlay == 'function') {
                  rowOverlay = customRowOverlay(row, index);
                }
                return (
                  <React.Fragment key={`${index}-${rowId}`}>
                    <TableRow
                      hover
                      onClick={(event) => {
                        if (withSelection) {
                          handleClick(event, rowId, row, index);
                        }
                        if (onClickRow) {
                          onClickRow(rowId, row, index);
                        }
                      }}
                      // role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      color="primary"
                      style={customRowOverlay ? { position: 'relative' } : {}}
                    >
                      {rowOverlay && rowOverlay != null ? (
                        <td
                          key="overlay"
                          style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            zIndex: 1,
                          }}
                        >
                          {rowOverlay}
                        </td>
                      ) : null}
                      {withSelection && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                          />
                        </TableCell>
                      )}
                      {columns.map((aColumn, index2) => {
                        let cellData = row?.[aColumn.id];
                        return (
                          <TableCell key={index2} id={labelId}>
                            {aColumn.render ? aColumn.render(cellData, row, index) : cellData}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {renderRow ? (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={columns?.length || 1}
                        >
                          {renderRow(row, index)}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {data?.length > 0 ? null : (
                <TableRow style={{ height: '150px' }}>
                  <TableCell colSpan={columns?.length || 1} style={{ textAlign: 'center' }}>
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{ height: (size == "small" ? 33 : 53) * emptyRows }}
                >
                  <TableCell
                    colSpan={columns?.length || 1}
                    style={{ textAlign: "center" }}
                  >
                    {data?.length > 0 ? null : "No Record Found"}
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
            {/* <TableFooter></TableFooter> */}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
            colSpan={12}
            count={defaultData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </Paper>
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  rowSelection: PropTypes.object,
  rowKey: PropTypes.string,
  size: PropTypes.string,
};

export default CustomTable;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function CustomTableRow(props) {
  const { row, columns = [], ...restProps } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((aColumn, index) => {
          let cellData = row?.[aColumn.id];
          return (
            <TableCell key={index}>
              {aColumn.render ? aColumn.render(cellData, row, index) : cellData}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow
        key="selection-checkbox"
        hover
        // role="checkbox"
        tabIndex={-1}
        color="primary"
        {...restProps}
      >
        <TableCell>
          <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <CustomTableHead
                  columns={columns}
                  classes={classes}
                  // numSelected={selected && selected.length}
                  // order={order}
                  // orderBy={orderBy}
                  // withSelection={withSelection}
                  // onSelectAllClick={handleSelectAllClick}
                  // onRequestSort={handleRequestSort}
                  // rowCount={data.length}
                />
                <TableBody>
                  {row.children.map((subRow, index) => (
                    <TableRow key={index}>
                      {columns.map((aColumn, index2) => {
                        let cellData = subRow[aColumn.id];
                        return (
                          <TableCell key={index2}>
                            {aColumn.render ? aColumn.render(cellData, subRow, index2) : cellData}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
