import React, { useState, useEffect } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Modal,
  Backdrop,
  Fade,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal2: {
    display: 'flex',
    // alignItems: "center",
    justifyContent: 'center',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    // minWidth: "50%",
    // maxWidth: "90%",
    width: '90%',
    // height: '500px',
    height: '90%',
    maxHeight: '90%',
    overflow: 'hidden',
  },
  paper2: {
    minWidth: '50%',
    maxWidth: '90%',
    // minHeight: "500px",
    height: 'fit-content',
    // overflow: "hidden",
  },
}));

const CustomModal = (props) => {
  const {
    title = '',
    footer,
    show = false,
    onHide,
    loading = false,
    scrollable = true,
    transitionDuration = 0,
    maxWidth = 'sm',
    fullWidth = true,
    style = {},
    children,
    ...restProps
  } = props;

  const classes = useStyles();

  let modalContentWrapperProps = {};
  let modalBodyProps = {};
  if (scrollable) {
    modalContentWrapperProps = {
      height: '100%',
    };
    modalBodyProps = { overflow: 'auto' };
  }

  return (
    <Dialog
      open={Boolean(show)}
      onClose={onHide}
      transitionDuration={transitionDuration}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...restProps}
    >
      <DialogTitle>
        {' '}
        <Box display="flex" alignItems="center">
          <Box fontWeight="bold">
            <Typography variant="body2">{title}</Typography>
          </Box>
          <Box ml="auto">
            <IconButton onClick={onHide || (() => {})} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>{loading ? <CircularProgress /> : children}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${scrollable ? classes.modal : classes.modal2}`}
      open={Boolean(show)}
      onClose={onHide}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={Boolean(show)}>
        <Paper className={scrollable ? classes.paper : classes.paper2} style={style}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box display="flex" flexDirection="column" {...modalContentWrapperProps}>
              <Box display="flex" alignItems="center" p={2}>
                {title && (
                  <Box style={{ borderColor: '#efefef' }} fontWeight="bold">
                    {title}
                  </Box>
                )}
                <Box ml="auto">
                  <IconButton onClick={onHide} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box flexGrow={1} p={2} {...modalBodyProps}>
                {children}
              </Box>
              {footer && (
                <Box
                  borderTop={1}
                  style={{ borderColor: '#efefef' }}
                  p={2}
                  fontWeight="bold"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {footer}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
