import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Divider, Typography, CircularProgress } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import CustomTable from '../../../components/CustomTable';
import CustomModal from '../../../components/CustomModal';
import CustomMenu from '../../../components/CustomMenu';
import CustomUploadButton from '../../../components/CustomFormFields/CustomUploadButton';

import requestsApi from '../../../services/requests.service';
import documentApi from '../../../services/document.service';
import fileUploader from '../../../utility/fileuploader';
import { useUserState } from '../../../context/UserContext';
import { defaultDocumentGroups } from '../../../constants/documentGroups';
import useCommonOptions from '../../../hooks/useCommonOptions';
const defaultData = [
  {
    id: 1,
    type: 'submitted',
    label: 'Submitted Files',
  },
  {
    id: 2,
    type: 'prepared',
    label: 'Prepared Files',
  },
  {
    id: 3,
    type: 'signed',
    label: 'Signed Files',
  },
  {
    id: 4,
    type: 'processed',
    label: 'Processed Files',
  },
  {
    id: 5,
    type: 'approved',
    label: 'Approved Files',
  },
];
const defaultColumns = [
  {
    id: 'id',
    label: 'NO',
    // render: (_, __, index) => {
    //   return index + 1;
    // },
  },
  {
    id: 'label',
    label: 'TASK TITLE',
  },
  {
    id: 'status',
    label: 'STATUS',
    render: (text) => {
      return 'In Progress';
    },
  },
  {
    id: 'items',
    label: 'NO OF FILES',
    render: (text) => {
      return text?.length > 0 ? text.length : 0;
    },
  },
  // {
  //   id: "start_date",
  //   label: "START DATE",
  //   render: (text) => {
  //     return text || "-";
  //   },
  // },
  // {
  //   id: "due_date",
  //   label: "DUE DATE",
  //   render: (text) => {
  //     return text || "-";
  //   },
  // },
];

const defaultDocumentModal = {
  show: false,
  data: null,
};
const RequestDocuments = (props) => {
  const { id, application } = props;
  const companyId = application?.company_id;
  const { userId: authUserId } = useUserState();
  const [documentModal, setDocumentModal] = useState(defaultDocumentModal);
  const [documents, setDocuments] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [linkDocumentModal, setLinkDocumentModal] = useState(defaultDocumentModal);
  const [selectedDocumentType, setSelectedDocumentType] = useState();
  let documentTypeOptions = defaultDocumentGroups.map((anItem) => {
    return {
      label: anItem.label,
      value: anItem.document_type_id,
    };
  });

  const {
    loading: loadingDocuments,
    rawData: defaultDocuments = [],
    refetch: fetchCompanyDocuments,
  } = useCommonOptions(async () => documentApi.getDocumentsList(2, companyId), {
    respondKey: 'document',
    labelKey: 'document_url',
    valueKey: 'document_id',
    lazyLoad: true,
  });

  const fetchDocuments = () => {
    if (id) {
      // let res = requestsApi.getRequestDocuments(id);
      let res = documentApi
        .getRequestDocuments(id)
        .then((result) => {
          if (result?.data?.body?.rows?.length > 0) {
            setDocuments(result?.data?.body?.rows);
          } else {
            setDocuments([]);
          }
        })
        .catch((error) => {
          setDocuments([]);
        });
    }
  };
  useEffect(() => {
    fetchDocuments();
  }, [id]);

  const handleOnHideModal = () => {
    setDocumentModal(defaultDocumentModal);
  };
  const handleOnHideLinkDocModal = () => {
    setLinkDocumentModal(defaultDocumentModal);
    setSelectedDocumentType();
  };
  const handleSubmitLinkDocument = async () => {
    let companyDocumentsRes = await fetchCompanyDocuments();
    let companyDocuments = companyDocumentsRes?.data?.document || [];
    let canLink = false;
    let document_url = linkDocumentModal?.data?.document_url;
    if (document_url) {
      canLink = true;
    }

    if (companyDocuments?.length > 0) {
      let foundLinked = companyDocuments.find((anItem) => {
        return anItem.document_url == document_url;
      });
      if (foundLinked) {
        canLink = false;
      }
    }

    if (canLink) {
      try {
        await documentApi.addDocument(
          selectedDocumentType,
          2,
          companyId,
          document_url,
          linkDocumentModal?.data?.document_name,
          linkDocumentModal?.data?.file_type
        );
        handleOnHideLinkDocModal();
      } catch (error) {
        enqueueSnackbar(
          error?.response?.data?.error?.message || `Failed to Link Document to Company`,
          { variant: 'error' }
        );
      }
    }
  };

  const handleUpload = async () => {
    if (
      uploadRef?.current?.value !== undefined ||
      uploadRef?.current?.value !== null ||
      uploadRef?.current?.value !== ''
    ) {
      setLoading(true);
      try {
        let uploadRes = await fileUploader(uploadRef, 137, 6, application?.company_id);

        if (uploadRes?.data?.document?.length > 0) {
          for (let i = 0; i < uploadRes?.data?.document.length; i++) {
            let aNewDoc = uploadRes?.data?.document[i];
            let documentBody = {
              ...aNewDoc,
              company_id: application?.company_id,
              document_name: aNewDoc?.document_name || 'Untitled File',
              company_application_id: id,
              uploaded_by: authUserId,
              type: documentModal?.data?.type,
            };
            console.log('documentBodydocumentBody', documentBody);
            await documentApi.createRequestDocument(id, documentBody);

            //   {
            //     "company_application_id": 2078,
            // "document_id": 1,
            // "company_id": 63,
            // "document_name": "test",
            // "module_id": null,
            // "document_container_name": null,
            // "document_type_name": null,
            // "document_table_type_name": null,
            // "document_url": "qweasd",
            // "file_type": null,
            // "uploaded_by": 1,
            // "uploaded_date": "2022-03-24 12:23:26",
            // "is_deleted": null,
            // "deleted_by": null,
            // "deleted_date": null,
            // "type": "submitted"
            // }

            //           container_name: "mbs-docs"
            // document_container_id: 1
            // document_id: 1253
            // document_type_id: 137
            // document_type_name: "Deed Poll"
            // document_url: "https://mbs-docs.s3.ap-southeast-1.amazonaws.com/14023c22-271f-43ce-95ad-9ca9ed4b59a2test-file"
            // file_type: null
            // module_id: 1
            // table_id: 6
            // table_name: "Officer Application"
            // table_primary_id: 0
            // uploaded_date: "2022-04-11T16:44:06.000Z"
          }
        }
        fetchDocuments();
      } catch (error) {
        window.alert('Failed to upload');
        // enqueueSnackbar(
        //   error?.response?.data?.error?.message ||
        //     `Failed to Upload Certificate Of Incorporation`,
        //   { variant: "error" }
        // );
        // return;
      }
      setLoading(false);
    }
  };

  let columns = [
    ...defaultColumns,
    {
      id: 'action',
      label: 'ACTION',
      render: (_, data) => {
        return (
          <Box display="flex">
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setDocumentModal({
                    show: true,
                    data: data,
                  });
                }}
              >
                View
              </Button>
            </Box>
            {/* <Box mr={2}>
              <Button size="small" variant="contained">
                Upload
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setDocumentModal({
                    show: true,
                    data: data,
                  });
                }}
              >
                Download
              </Button>
            </Box> */}
          </Box>
        );
      },
    },
  ];

  let documentGroupedByType = {};
  if (documents?.length > 0) {
    documents.forEach((anItem) => {
      let oldData = documentGroupedByType[anItem.type];
      if (oldData) {
        documentGroupedByType[anItem.type] = [...oldData, anItem];
      } else {
        documentGroupedByType[anItem.type] = [anItem];
      }
    });
  }

  let documentsByType = [];
  if (documentModal?.show && documentModal?.data?.type) {
    documentsByType = documentGroupedByType[documentModal.data.type];
  }

  let data = defaultData.map((anItem) => {
    let docs = documentGroupedByType?.[anItem.type] || [];

    return {
      ...anItem,
      items: docs,
    };
  });

  return (
    <>
      <CustomTable columns={columns} data={data} />
      <CustomModal
        show={documentModal.show}
        onHide={handleOnHideModal}
        title={documentModal?.data?.label}
      >
        {/* <Box display="flex">
          <Box px={2}>
            <SubjectIcon />
          </Box>
          <Box flexGrow={1}>
            <Box>
              <b>Description</b>
            </Box>
            <Box>
              Next Friday should be done. Next Monday we should deliver the first iteration. Make
              sure, we have a good result to be delivered by the day. Next Friday should be done.
              Next Monday we should deliver the first iteration.
            </Box>
          </Box>
        </Box>
        <Box py={3}>
          <Divider />
        </Box> */}
        <Box display="flex">
          <Box px={2}>
            <AttachFileOutlinedIcon />
          </Box>
          <Box flexGrow={1}>
            <Box mb={1} display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr={2}>
                <b>Attachment</b>
                &nbsp;&nbsp;&nbsp;
                <CustomUploadButton
                  ref={uploadRef}
                  placeholder="+ Upload"
                  size="small"
                  onChange={async (ref, resetButtonProps) => {
                    await handleUpload();
                    if (uploadRef?.current?.value) {
                      // uploadRef.current.value = "";
                      resetButtonProps();
                    }
                  }}
                />
              </Box>
              {loading && <CircularProgress />}
              {/* <Button onClick={handleUpload} size="small">
                  Upload now
                </Button> */}
              {/* <Button size="small" variant="contained">
                + Upload
              </Button> */}
            </Box>
            <Box>
              {documentsByType?.length > 0
                ? documentsByType.map((anItem, index) => {
                    return (
                      <FileItem
                        key={index}
                        {...anItem}
                        action={
                          <Box ml={1}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setLinkDocumentModal((prev) => ({
                                  ...prev,
                                  show: true,
                                  data: anItem,
                                }));
                              }}
                            >
                              Link to Company
                            </Button>
                          </Box>
                        }
                      />
                    );
                  })
                : 'No file uploaded'}
            </Box>
          </Box>
        </Box>
      </CustomModal>
      <CustomModal
        show={linkDocumentModal.show}
        onHide={handleOnHideLinkDocModal}
        title={'Choose a Document Type'}
        scrollable={false}
      >
        <Box mb={2}>
          <CustomMenu
            data={documentTypeOptions}
            value={selectedDocumentType}
            onChange={(e) => {
              setSelectedDocumentType(e.target.value);
            }}
            searchable={true}
            variant={'standard'}
            // label="Document Type"
          />
        </Box>
        <Box display="flex" justifyContent={'flex-end'}>
          <Button
            variant="contained"
            disabled={!selectedDocumentType}
            onClick={handleSubmitLinkDocument}
          >
            Submit
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

const FileItem = (props) => {
  const {
    document_name = 'filename',
    file_type,
    uploaded_date = '-',
    document_url,
    action,
  } = props;
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box
        width="60px"
        height="60px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border={1}
        mr={2}
      >
        {file_type || 'File'}
      </Box>
      <Box>
        <Box>{document_name}</Box>
        <Box display={'flex'} alignItems="center">
          <Typography variant="caption">
            {`Added ${moment(uploaded_date).format('yyyy-MM-DD hh:mm:ssa')} -- `}
            <a href={document_url} download>
              Download
            </a>{' '}
            -- <a>Delete</a>
          </Typography>
          {action}
        </Box>
      </Box>
    </Box>
  );
};
export default RequestDocuments;
