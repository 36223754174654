import React, { useState, useEffect } from 'react';
import { Button, Box, IconButton } from '@material-ui/core';
import FileViewer from 'react-file-viewer';
import { useSnackbar } from 'notistack';
import GetAppIcon from '@material-ui/icons/GetApp';

import CustomModal from '../../CustomModal';
import getFileName from '../../../utility/getfilename';
import FilePreview from '../../FilePreview';
import FileViewerButton2 from '../../FileViewerButton2';

const CustomUploadButton = React.forwardRef((props, ref) => {
  const {
    placeholder = 'Choose File',
    previewLink,
    previewLabel,
    previewFileType: defaultFileType,
    disabled = false,
    hideUpload = false,
    size = 'medium',
    onChange,
    className = '',
    style = {},
    document,
    ...restProps
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const defaultButtonProps = {
    label: placeholder,
    variant: 'outlined',
  };
  const [buttonProps, setButtonProps] = useState(defaultButtonProps);
  const [showPreview, setShowPreview] = useState(false);
  const [previewFileType2, setPreviewFileType] = useState();

  let previewFileType = defaultFileType ? defaultFileType : previewFileType2;
  const resetButtonProps = () => {
    setButtonProps(defaultButtonProps);
  };
  const handleOnChange = (e) => {
    if (ref.current.value !== undefined || ref.current.value !== null || ref.current.value !== '') {
      if (!ref?.current?.files?.[0]?.type) {
        ref.current.value = '';
        setButtonProps((prev) => {
          return {
            ...prev,
            label: placeholder,
            variant: 'outlined',
          };
        });
        enqueueSnackbar(`Unknown File Selected, Please Select Again`, {
          variant: 'error',
        });
      } else {
        setButtonProps((prev) => {
          return {
            ...prev,
            label: `Upload ${getFileName(ref.current.value)}`,
            variant: 'contained',
          };
        });
      }
    }
    if (onChange) {
      onChange(ref, resetButtonProps);
    }
  };

  const handlePreviewOpen = () => {
    setShowPreview(true);
  };
  const handlePreviewClose = () => {
    setShowPreview(false);
  };

  const handlePreviewOnError = (e) => {
    console.log('handlePreviewOnError', e);
  };

  let supportedFileTypes = ['pdf', 'png', 'jpg'];
  useEffect(() => {
    if (previewLink && showPreview) {
      (async () => {
        await fetch(previewLink)
          .then((response) => {
            // const {
            //   body,
            //   headers
            // }=response
            // let file = new File();
            // console.log('fileeee', response.body);
            let fileType = response.headers
              .get('Content-Type')
              .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
            if (fileType) {
              // if (supportedFileTypes.indexOf(fileType.toLocaleLowerCase()) < 0) {
              //   fileType = 'png';
              // }
              setPreviewFileType(fileType);
            }
            // return response.blob();
          })
          // .then((blob) => {
          //   console.log('fileeee', blob);
          // })
          .catch((error) => {
            setPreviewFileType();
          });
      })();
    }
  }, [previewLink, showPreview]);

  return (
    <Box display="flex">
      {previewLink && (
        <Box mr={2}>
          {/* <Button
            variant="contained"
            // target="_blank"
            color="primary"
            // href={previewLink}
            size={size}
            onClick={handlePreviewOpen}
          >
            {previewLabel || 'Untitled'}
          </Button> */}
          <FileViewerButton2 srcType="url" src={previewLink} document={document} color="primary">
            {previewLabel || 'Untitled'}
          </FileViewerButton2>
          <CustomModal
            show={showPreview && previewFileType}
            onHide={handlePreviewClose}
            maxWidth={'lg'}
            // scrollable={false}
            title={
              <Box>
                Preview{' '}
                {/* <IconButton
                  aria-label="downloadfile"
                  color="primary"
                  as="a"
                  href={previewLink}
                  size="small"
                >
                  <GetAppIcon />
                </IconButton> */}
              </Box>
            }
            // title={isNew ? "New Admin" : "Edit Admin"}
          >
            <Box width="100%" textAlign="center">
              <FilePreview src={previewLink} />
              {/* <FileViewer
                fileType={previewFileType}
                filePath={previewLink}
                errorComponent={CustomErrorComponent}
                onError={handlePreviewOnError}
              /> */}
            </Box>
          </CustomModal>
        </Box>
      )}
      {!hideUpload && (
        <Button
          variant={buttonProps.variant}
          component="label"
          color="primary"
          onChange={handleOnChange}
          disabled={disabled}
          size={size}
          {...restProps}
        >
          {buttonProps.label}
          <input type="file" hidden ref={ref} />
        </Button>
      )}
    </Box>
  );
});

export default CustomUploadButton;

const CustomErrorComponent = (props) => {
  console.log('props', props);
  return 'HAHA';
};
