import React, { useEffect, createContext, useContext, useReducer, useMemo } from 'react';
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import userApi from '../services/user.service';

var UserStateContext = createContext();
var UserDispatchContext = createContext();
var UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'SIGN_OUT_SUCCESS':
      return { isAuthenticated: false };
    case 'SET_STATE':
      return { ...state, ...(action?.state || {}) };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = useReducer(userReducer, {
    // isAuthenticated: !!localStorage.getItem('idToken'),
    // userId: localStorage.getItem('idToken'),
    isAuthenticated: false,
    userId: undefined,
  });

  const idToken = new Cookies().get('idToken');
  const idTokenData = jwt.decode(idToken);

  const initialUser = async () => {
    const idToken = new Cookies().get('idToken');

    let newState = {
      idToken,
      isAuthenticated: false,
    };
    if (idToken) {
      const idTokenData = jwt.decode(idToken);
      const userId = idTokenData?.sub;
      // console.log('idTokenData', idTokenData);

      if (userId) {
        // ListSysInfoUser(userId);
        let userRes;
        try {
          userRes = await userApi.getAdminUserById(userId);
          // console.log('userRes', userRes);
          let userObj = userRes?.data?.users || {};
          newState = {
            idToken,
            user: { ...userObj, user_id: userId },
            userId: userId,
            isAuthenticated: userId != undefined,
          };
        } catch (error) {
          console.log('userRes error', error);
        }
      }
    }
    methods.setState((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  const initialUserProd = async () => {
    const idToken = new Cookies().get('idToken');

    let newState = {
      idToken,
      isAuthenticated: false,
    };
    if (idToken) {
      let userRes;
      try {
        userRes = await userApi.getAdminUserById(idToken);
        // console.log('userRes', userRes);
        let userObj = userRes?.data?.users || {};
        newState = {
          idToken,
          user: { ...userObj, user_id: idToken },
          userId: idToken,
          isAuthenticated: idToken != undefined,
        };
      } catch (error) {
        console.log('userRes error', error);
      }
    }
    methods.setState((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  const methods = {
    signOut: async () => {
      const idToken = new Cookies().get('idToken');
      // await new Cookies().remove('company');
      await new Cookies().remove('idToken');
      await new Cookies().remove('accessToken');
      // localStorage.removeItem('idToken');
      dispatch({ type: 'SIGN_OUT_SUCCESS' });
      window.location.href = `${process.env.REACT_APP_SSO}/session/end?id_token_hint=${idToken}&post_logout_redirect_uri=${window.origin}`;

      // history.push('/login');
      // return dispatch({ type: 'SIGN_OUT_SUCCESS' });
    },
    signOutProd: async (history) => {
      await new Cookies().remove('idToken');
      await new Cookies().remove('accessToken');
      dispatch({ type: 'SIGN_OUT_SUCCESS' });
      history.push('/login');
    },
    loginUser: async (_, login, password, history, setIsLoading, setError) => {
      setError(false);
      setIsLoading(true);

      if (!!login && !!password) {
        axios
          .post(`${process.env.REACT_APP_USERCONTACT_URL}/adminusers/login`, {
            username: login,
            password: password,
          })
          .then((res) => {
            // console.log('res.datares.data', res.data);

            if (!!res.data.is_success) {
              let idToken = res.data?.users?.user_id;
              new Cookies().set('idToken', idToken || null);
              // localStorage.setItem('idToken', res.data?.users?.user_id || null);
              setError(null);
              setIsLoading(false);
              // dispatch({ type: 'LOGIN_SUCCESS' });
              dispatch({
                type: 'SET_STATE',
                state: {
                  idToken,
                  user: { ...(res.data?.users || {}), user_id: idToken },
                  userId: idToken,
                  isAuthenticated: idToken != undefined,
                },
              });
            } else {
              setError(true);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setError(err);
            setIsLoading(false);
          });
      } else {
        setError(true);
        setIsLoading(false);
      }
    },
    setState: async (callback = () => {}) => {
      return dispatch({ type: 'SET_STATE', state: callback(state) });
    },
    initialUser,
    initialUserProd,
  };
  // const methods = useMemo(
  //   () => ({
  //     signOut: async () => {
  //       const idToken = new Cookies().get('idToken');
  //       dispatch({ type: 'SIGN_OUT_SUCCESS' });
  //       await new Cookies().remove('company');
  //       await new Cookies().remove('idToken');
  //       // localStorage.removeItem('idToken');
  //       console.log('idTokenidTokenidTokenidToken', idToken);
  //       window.location.href = `${process.env.REACT_APP_SSO}/session/end?id_token_hint=${idToken}&post_logout_redirect_uri=${window.origin}`;

  //       // history.push('/login');
  //       // return dispatch({ type: 'SIGN_OUT_SUCCESS' });
  //     },
  //     setState: async (callback = () => {}) => {
  //       return dispatch({ type: 'SET_STATE', state: callback(state) });
  //     },
  //   }),
  //   []
  // );

  useEffect(() => {
    (async () => {
      await initialUser();
    })();
  }, [idToken]);

  return (
    <UserContext.Provider value={[state, methods]}>
      <UserStateContext.Provider value={state}>
        <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
      </UserStateContext.Provider>
    </UserContext.Provider>
  );
}

function useUserState() {
  var context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function useUserContext() {
  const state = useContext(UserStateContext);
  const dispatch = useContext(UserDispatchContext);
  const contextData = useContext(UserContext);

  return contextData;
}

export { UserProvider, useUserState, useUserDispatch, useUserContext, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    axios
      .post(`${process.env.REACT_APP_USERCONTACT_URL}/adminusers/login`, {
        username: login,
        password: password,
      })
      .then((res) => {
        // console.log('res.datares.data', res.data);

        if (!!res.data.is_success) {
          setTimeout(() => {
            // localStorage.setItem('idToken', res.data?.users?.user_id || null);
            setError(null);
            setIsLoading(false);
            dispatch({ type: 'LOGIN_SUCCESS' });

            // history.push('/upload');
          }, 2000);
        } else {
          setError(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setIsLoading(false);
      });
  } else {
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('idToken');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}
