import React, { useEffect, useRef } from "react";
import { TextField } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Field } from "formik";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

import FormFieldContainer from "../FormFieldContainer";

const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: "#fff",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
    },
  },
}))(TextField);

const useStyles = makeStyles(() => {
  return {
    fieldBorder: {
      borderStyle: "solid",
      borderRadius: "4px",
      borderWidth: "1px",
    },
  };
});
const CustomPhoneNumberField = (props) => {
  const {
    label,
    name,
    phoneCodeName = "contact_phone_code",
    containerProps: defaultContainerProps = {},
    helperText,
    required = false,
    children,
    ...restProps
  } = props;

  const classes = useStyles();

  let fieldProps = {
    name: name,
    variant: "outlined",
    fullWidth: true,
    size: "small",
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        const { setFieldValue } = form;
        const { value, onChange } = field;
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            required={required}
            helperText={meta.touched && meta.error ? meta.error : ""}
            {...defaultContainerProps}
          >
            {/* <input {...field} hidden /> */}
            <IntlTelInput
              value={value || "65"}
              containerClassName={`intl-tel-input MuiInputBase-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense MuiOutlinedInput-notchedOutline ${classes.fieldBorder}`}
              inputClassName="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              preferredCountries={["sg", "my"]}
              onSelectFlag={(
                currentNumber,
                selectedCountryData,
                fullNumber,
                isValid
              ) => {
                setFieldValue(phoneCodeName, selectedCountryData.dialCode);
                setFieldValue(name, selectedCountryData.dialCode);
                // setFieldValue(name, {
                //   ...value,
                //   [phoneCodeName]: selectedCountryData.dialCode,
                // });
              }}
              onPhoneNumberChange={(
                isValid,
                rawValue,
                countryData,
                formattedValue,
                extension
              ) => {
                setFieldValue(name, rawValue);
                // setFieldValue(name, {
                //   ...value,
                //   [name]: rawValue,
                // });
              }}
            />
            {/* <StyledTextField
              ref={inputRef}
              {...field}
              error={error}
              {...fieldProps}
            /> */}
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomPhoneNumberField;
