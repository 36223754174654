import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Chip,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';

// styles
import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import CustomTable from '../../components/CustomTable';
import utilsApi from '../../services/utils.service';
import commonApi from '../../services/common.service';

const applicationTypes = {
  1: 'Share Application',
  2: 'Share Transfer Application',
  3: 'Company Address Application',
  4: 'Company Name Application',
  5: 'Officer Application',
  6: 'Shareholder Application',
  7: 'Cessation Application',
  8: 'Individual Particular Application',
  9: 'Financial Year End Application',
  10: 'Open Bank Account Application',
  11: 'Close Bank Account Application',
  12: 'Business Activity Application',
  13: 'Declare Interim Dividend Application',
  14: 'Other Request',
  15: 'Shares Allotment Application',
};

const applicationStatuses = {
  1: 'In Progress',
  2: 'Pending',
  3: 'Complete',
  4: 'Rejected',
  7: 'Approved',
  8: 'Order Checkout',
  9: 'Order Pending',
  10: 'Payment Complete',
  11: 'In Cart',
};

// const x = {
//   applicationstatus: [
//     { company_application_status_id: 1, status_name: "In Progress" },
//     { company_application_status_id: 2, status_name: "Pending" },
//     { company_application_status_id: 3, status_name: "Complete" },
//     { company_application_status_id: 4, status_name: "Rejected" },
//     { company_application_status_id: 5, status_name: "Draft" },
//     {
//       company_application_status_id: 6,
//       status_name: "Pending Third Party Approval",
//     },
//     { company_application_status_id: 7, status_name: "Approved" },
//     { company_application_status_id: 8, status_name: "Order Checkout" },
//     { company_application_status_id: 9, status_name: "Order Pending" },
//     { company_application_status_id: 10, status_name: "Payment Complete" },
//     { company_application_status_id: 11, status_name: "In Cart" }, *
//     { company_application_status_id: 12, status_name: "Active" },
//     { company_application_status_id: 13, status_name: "Suspend" },
//     { company_application_status_id: 14, status_name: "Trash" },
//   ],
// };
const enabledStatus = [2, 4, 6, 7];

export default function CompanyApplicationLogs(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [requestList_initial, setRequestList] = useState();
  var [selectedApplicationType, setSelectedApplicationType] = useState('all');
  var [selectedApplicationStatus, setSelectedApplicationStatus] = useState('all');
  const [applicationStatus, setApplicationStatus] = useState([]);
  const applicationStatusOptions = [];
  applicationStatus.forEach((anItem) => {
    // if (enabledStatus.indexOf(anItem.company_application_status_id) >= 0) {
    applicationStatusOptions.push({
      label: anItem.status_name,
      value: anItem.company_application_status_id,
    });
    // }
  });

  const handleChangeApplicationType = (event) => {
    setSelectedApplicationType(event.target.value);
  };
  const handleChangeApplicationStatus = (event) => {
    setSelectedApplicationStatus(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const respCompanyApplicationLogs = await utilsApi.getCompanyApplicationLogs();
      // console.log("respCompanieslist", respCompanieslist.data.applications);
      let requestData = respCompanyApplicationLogs?.data?.logs || [];
      requestData.sort((a, b) => {
        return new Date(b.created_date) - new Date(a.created_date);
      });
      setRequestList(requestData);

      commonApi
        .getApplicationStatus()
        .then((res) => {
          // console.log(res);
          // setApplication(res);
          if (res?.data?.applicationstatus) {
            setApplicationStatus(res?.data?.applicationstatus || []);
          }
        })
        .catch((error) => {
          setApplicationStatus([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  let requestList = requestList_initial;
  if (selectedApplicationType != 'all' || selectedApplicationStatus != 'all') {
    requestList = requestList_initial.filter((anItem) => {
      let matchType =
        selectedApplicationType != 'all'
          ? anItem['company_application_type_id'] == selectedApplicationType
          : true;
      let matchStatus =
        selectedApplicationStatus != 'all'
          ? anItem['company_application_status_id'] == selectedApplicationStatus
          : true;

      return matchType == true && matchStatus == true;
    });
  }
  // console.log("requestList", requestList);

  // let distinctApplicationTypes = {};
  // requestList?.forEach((anItem) => {
  //   let applicationTypeId = anItem["company_application_status_id"];
  //   let applicationTypeName = anItem["status_name"];

  //   if (!distinctApplicationTypes[applicationTypeId]) {
  //     distinctApplicationTypes[applicationTypeId] = applicationTypeName;
  //   }
  // });
  // console.log("distinctApplicationTypes", distinctApplicationTypes);

  const tableColumns = [
    {
      label: 'ID',
      id: 'company_application_id',
    },
    {
      label: 'DATE',
      id: 'created_date',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy`);
      },
    },
    {
      label: 'CREATED BY',
      id: 'created_by',
      render: (text, data) => {
        if (!text) {
          return '-';
        }
        return `${text} ${
          data?.first_name ? `(${data?.first_name} ${data?.last_name || ''})` : ''
        }`;
      },
    },
    {
      label: 'COMPANY NAME',
      id: 'company_name',
      render: (text, data) => {
        if (!text) {
          return '-';
        }
        return `${text} ${
          data?.company_registration_no ? `(${data?.company_registration_no})` : ''
        }`;
      },
    },
    // {
    //   label: "APPLICATION",
    //   id: "application_type_table_name",
    // },
    {
      label: 'STATUS',
      id: 'status_name',
    },
    // {
    //   label: "",
    //   id: "",
    //   render: (text, data) => {
    //     const {
    //       company_application_id,
    //       application_primary_id,
    //       application_type_table_name,
    //       company_application_type_id,
    //       email,
    //       first_name,
    //       last_name,
    //       company_name,
    //       company_registration_no,
    //     } = data;
    //     return (
    //       <Button variant="contained" color="primary" component="div">
    //         <Link
    //           to={{
    //             pathname: "/request",
    //             search: `?application_id=${application_primary_id}&application_type=${application_type_table_name}&application_type_id=${company_application_type_id}&company_application_id=${company_application_id}&email=${email}&first_name=${first_name}&last_name=${last_name}&company_name=${company_name}&company_registration_no=${company_registration_no}`,
    //             // hash: "#the-hash",
    //             // state: { fromDashboard: true }
    //           }}
    //           target="_blank"
    //           style={{ color: "#fff" }}
    //         >
    //           DETAIL
    //         </Link>
    //       </Button>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Company Application Logs"
        // button={
        // <Button
        //   variant="contained"
        //   size="medium"
        //   color="secondary"
        //   onClick={() => {
        //     history.push({
        //       pathname: "/companyform",
        //       //   search: "?company=" + company_id,
        //       state: {
        //         // company_id: localStorage.getItem("company_id"),
        //         // attachment_type_id: "1",
        //         // role_id: post.role_id,
        //         // company_name: localStorage.getItem("company_name"),
        //         // attachment_type_name: "Appointment Letter",
        //         // role_name: post.title,
        //       },
        //     });
        //   }}
        // >
        //   Add New Company
        // </Button>
        // }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label-application-type">
              Application Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label-application-type"
              id="demo-simple-select-outlined-application-type"
              value={selectedApplicationType}
              onChange={handleChangeApplicationType}
              label="Application Type"
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {Object.keys(applicationTypes).map((aKey, index) => {
                return (
                  <MenuItem key={index} value={aKey}>
                    {applicationTypes[aKey]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label-application-status">
              Application Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label-application-status"
              id="demo-simple-select-outlined-application-status"
              value={selectedApplicationStatus}
              onChange={handleChangeApplicationStatus}
              label="Application Status"
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {applicationStatusOptions.map((anItem, index) => {
                return (
                  <MenuItem key={index} value={anItem.value}>
                    {anItem.label}
                  </MenuItem>
                );
              })}
              {/* {Object.keys(applicationStatuses).map((aKey, index) => {
                return (
                  <MenuItem key={index} value={aKey}>
                    {applicationStatuses[aKey]}
                  </MenuItem>
                );
              })} */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {/* <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            /> */}
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <CustomTable
                  rowKey="company_application_id"
                  columns={tableColumns}
                  data={requestList}
                />
                {/* <Table data={requestList} /> */}
              </>
            )}
            {/* <Pagination count={10} /> */}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
