import { Drawer, IconButton, List } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
// context
import { toggleSidebar, useLayoutDispatch, useLayoutState } from '../../context/LayoutContext';
// components
import SidebarLink from './components/SidebarLink/SidebarLink';
// styles
import useStyles from './styles';

import { useUserState } from '../../context/UserContext';

function Sidebar({ location, nav = [] }) {
  var classes = useStyles();
  var theme = useTheme();
  const { userId } = useUserState();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={() => toggleSidebar(layoutDispatch)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {nav.map((link, index) => {
          if (link?.children?.length > 0) {
            return (
              <SidebarLink
                key={index}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
                children={link?.children}
                onClick={() => {
                  if (!isPermanent) {
                    toggleSidebar(layoutDispatch);
                  }
                }}
              />
            );
          }
          return (
            <SidebarLink
              key={index}
              location={location}
              isSidebarOpened={isSidebarOpened}
              onClick={() => {
                if (!isPermanent) {
                  toggleSidebar(layoutDispatch);
                }
              }}
              {...link}
            />
          );
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
