import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  Divider,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import companyApi from '../../services/company.service';
import Companydetails_draft from './Companydetails_draft';
import Companydetails_published from './Companydetails_published';
import useQueryParams from '../../hooks/useQueryParams';

const CompanyDetailForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  let query = useQueryParams();
  const selectedCompanyId = parseInt(query.get('company'));

  const [isLoadingData, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState();

  const fetchCompanyDetails = async () => {
    let result = {};
    try {
      const companyDetailsRes = await companyApi.getCompanyDetails(selectedCompanyId);
      let companyData = companyDetailsRes?.data?.company;
      result = companyData;
      setCompanyData(companyData);
    } catch (error) {}
    return result;
  };

  const fetchInitialValues = async () => {
    setLoading(true);

    let companyDetailsRes = await fetchCompanyDetails();
    setLoading(false);
  };
  useEffect(() => {
    if (selectedCompanyId) {
      fetchInitialValues();
    }
  }, [selectedCompanyId]);

  if (isLoadingData) {
    return (
      <>
        <Button
          size="medium"
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          // onClick={() => history.goBack()}
          onClick={() => history.push({ pathname: '/company' })}
        >
          BACK TO COMPANY DATABASE
        </Button>
        <div>Loading</div>
      </>
    );
  } else {
    if (companyData) {
      let publishStatusId = companyData?.publish_status_id;
      // draft 1
      if (publishStatusId && publishStatusId == 1) {
        return <Companydetails_draft refetchCompany={fetchCompanyDetails} />;
      } else {
        return <Companydetails_published refetchCompany={fetchCompanyDetails} />;
      }
    } else {
      return 'Company not Found';
    }
  }
};

export default CompanyDetailForm;

/*
publish_status_id:

1: Draft
3: Published
4: Complianced
5: Editing
6: Terminated 
*/
