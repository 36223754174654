import { Grid, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Widget from '../../components/Widget/Widget';
import { Box, Button, Container, Typography, CircularProgress, Backdrop } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import productSvc from '../../services/product.service';
import { useUserState } from '../../context/UserContext';

import companySvc from '../../services/company.service';
import logTimelineSvc from '../../services/logtimeline.service';

import CompanyMenu from './components/Menu/CompanyMenu';
import CustomMenu from '../../components/CustomMenu';

const options = [
  { value: 'developer', label: 'Software Developer' },
  { value: 'chef', label: 'Chef' },
  { value: 'enginner', label: 'Enginner' },
  { value: 'painter', label: 'Painter' },
];

const statuses = [
  { value: '1', label: 'Pending Review' },
  { value: '2', label: 'In Progress' },
  { value: '3', label: 'Approved' },
  { value: '4', label: 'Rejected' },
  { value: '5', label: 'Request Received' },
  { value: '6', label: 'Resubmitted' },
];

const action_types = [
  {
    type: 'Meyzer 360',
    names: [
      { value: 'New Incorporation' },
      { value: 'Onboarding Meyzer Corporate Secretary' },
      { value: 'Registration of Shareholders' },
      { value: 'Registration of Office' },
      { value: 'Notice of Register of Members and Index' },
      { value: 'Annual General Meeting' },
      { value: 'Financial Statement' },
    ],
  },
  {
    type: 'Change of Officers',
    names: [
      { value: 'Appointment of Director' },
      { value: 'Appointment of Secretary' },
      { value: 'Appointment of Auditor' },
      { value: 'Resignation of Director' },
      { value: 'Resignation of Secretary' },
      { value: 'Resignation of Auditor' },
    ],
  },
  {
    type: 'Change Personal Particulars',
    names: [{ value: 'Change Director Particulars' }, { value: 'Change Shareholder Particulars' }],
  },
  {
    type: 'Change Company Particulars',
    names: [
      { value: 'Change Company Name' },
      { value: 'Change Company Address' },
      { value: 'Change Company Business Activity' },
      { value: 'Change Company Financial Year End' },
    ],
  },
  {
    type: 'Change Share Information',
    names: [{ value: 'Allotment of Shares' }, { value: 'Transfer of Shares' }],
  },
  {
    type: 'Filing of Other Resolutions',
    names: [
      { value: 'Declare Interim Dividend' },
      { value: 'Open Bank Account' },
      { value: 'Close Bank Account' },
      { value: 'Other Request' },
    ],
  },
  {
    type: 'ACRA Filing',
    names: [{ value: 'Filing Annual Returns' }],
  },
];

const action_otherRequest = 'Other Request';
const validationSchema = Yup.object({
  //company_id: Yup.string().required("company id is required"),
  //timeline_date: Yup.string().required("timeline date id is required"),
  //action_type: Yup.string().required("action type is required"),
  //action: Yup.string().required("action id is required"),
  //other_request: Yup.string().required("This field is required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function LogTimelineForm({ logTimeline, onDismiss, onSubmit }) {
  console.log('form data', logTimeline);

  var classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  let names = logTimeline?.action_type
    ? action_types.find(({ type }) => type == logTimeline.action_type).names
    : action_types[0].names;
  //const [actionNames, setActionNames] = React.useState(action_types[0].names);
  const [actionNames, setActionNames] = React.useState(names);

  const [timelineDatevalue, setTimelineDatevalue] = React.useState(logTimeline?.timeline_date);
  const [companyId, setCompanyId] = React.useState(logTimeline?.company_id);
  const [companies, setCompanies] = React.useState([]);
  // const [company, setCompany] = React.useState();
  const [statusId, setStatusId] = React.useState(
    logTimeline?.status_id ? logTimeline.status_id : statuses[0].value
  );
  const [propsChanged, setPropsChanged] = React.useState(false);
  const [actionType, setActionType] = React.useState(
    logTimeline?.action_type ? logTimeline.action_type : action_types[0].type
  );

  let defaultAction = logTimeline?.action || action_types?.[0]?.names?.[0]?.value;
  let defaultActionOtherRequestValue = '';
  if (defaultAction.indexOf(action_otherRequest) >= 0) {
    let splited = defaultAction.split(': ');
    defaultAction = splited?.[0] || '';
    defaultActionOtherRequestValue = splited?.[1] || '';
  }
  const [actionName, setActionName] = React.useState(defaultAction);
  /*
  const [actionType, setActionType] = React.useState();
  const [action, setAction] = React.useState();
  const [notes, setNotes] = React.useState();
*/

  const companyOptions = companies.map((aCompany) => {
    return {
      label: `${aCompany.company_name}${
        aCompany.company_registration_no ? ` (${aCompany.company_registration_no})` : ''
      }`,
      value: aCompany.company_id,
    };
  });

  const allPropsDefined = () => {
    console.log(
      'all props value >>>',
      companyId,
      timelineDatevalue,
      actionType,
      actionName,
      statusId
    );

    return (
      companyId != null &&
      actionName != null &&
      timelineDatevalue != null &&
      actionType != null &&
      statusId != null
    );
  };

  console.log('action names >>>', actionNames);
  //  console.log('all props >>>>>>>>>>>>');
  //  console.log('all props value >>>', companyId, actionName);
  console.log('check all props >>>', allPropsDefined());

  const user = useUserState();

  useEffect(() => {
    (async () => {
      let res = await logTimelineSvc.getCompanies();
      console.log('companies', res);
      // setCompany(res[0].company_id);
      // setCompanyId(res[0].company_id);
      setCompanies(res);
    })();
  }, []);

  // const handleCompanyChange = (event) => {
  //   // this not used?
  //   setCompany(event.target.value);
  // };

  const handleCompanyIdChange = (event) => {
    setCompanyId(event.target.value);
    setPropsChanged(true);
  };

  const handleStatusIdChange = (event) => {
    setStatusId(event.target.value);
    setPropsChanged(true);
  };

  const handleActionTypeChange = (event) => {
    setActionType(event.target.value);
    console.log('action type >>>', event.target.value);
    let names = action_types.find(({ type }) => type == event.target.value).names;
    setActionNames(names);
    setActionName(names[0].value);
    setPropsChanged(true);
  };

  const handleActionNameChange = (event) => {
    setActionName(event.target.value);
    setPropsChanged(true);
  };

  const formikOnSubmit = async (values, actions) => {
    console.log('form onsubmit values (before) >>>', values);

    let logTimelineId = logTimeline?.log_timeline_id;
    let data = {
      ...values,
      log_timeline_id: logTimelineId,
      company_id: companyId,
      timeline_date: timelineDatevalue,
      action_type: actionType,
      action: actionName,
      notes: values?.notes || '',
      status: '',
      status_id: statusId,
    };
    if (actionName == 'Other Request' && data?.['other_request']) {
      data['action'] = `${actionName}: ${data?.['other_request']}`;
    }
    if (logTimelineId) {
      let updateRes = await logTimelineSvc.updateLogTimeline(logTimelineId, data);
    } else {
      let createRes = await logTimelineSvc.addLogTimeline(data);
    }
    console.log('form submit on dismiss (after)>>>', data);
    if (onSubmit) {
      onSubmit({ data: values });
    }
  };

  return (
    <>
      {/* <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Log Timeline Form
        </Typography>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onDismiss}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Container> */}
      <Formik
        initialValues={{
          timeline_date: logTimeline?.timeline_date,
          notes: logTimeline?.notes,
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            {companies.length > 0 && (
              <div>
                <label>Companies</label>
                <div>
                  <CustomMenu
                    searchable={true}
                    value={companyId}
                    onChange={handleCompanyIdChange}
                    data={companyOptions}
                  />
                  <br />
                </div>
              </div>
            )}

            <TextField
              fullWidth
              id="timeline_date"
              name="timeline_date"
              label="Timeline Date"
              type="date"
              value={
                timelineDatevalue === undefined
                  ? null
                  : moment(timelineDatevalue).format('YYYY-MM-DD')
              }
              onChange={(event) => {
                console.log('change date', event.target.value);
                setTimelineDatevalue(event.target.value);
                setPropsChanged(true);
              }}
              //defaultValue={moment().format("YYYY-MM-DD")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  // min: moment().add(-31, "d").format("YYYY-MM-DD"),
                  max: moment().add(50, 'y').format('YYYY-MM-DD'),
                },
              }}
            />
            <br />
            <br />

            <label>Action Type</label>
            <div>
              <Field
                as="select"
                name="action_type"
                value={actionType}
                onChange={handleActionTypeChange}
                className={classes.textField}
              >
                {action_types.map((item, i) => {
                  return <option value={item.type}>{item.type}</option>;
                })}
              </Field>
            </div>
            <br />

            <label>Action</label>
            <div>
              <Field
                as="select"
                name="action"
                value={actionName}
                onChange={handleActionNameChange}
                className={classes.textField}
              >
                {actionNames.map((item, i) => {
                  return <option value={item.value}>{item.value}</option>;
                })}
              </Field>
            </div>
            <br />

            {actionName == 'Other Request' && (
              <CustomTextField
                name="other_request"
                label="Other Request"
                placeholder=" "
                fullWidth
                shrink
                variant="standard"
                error
                defaultValue={defaultActionOtherRequestValue}
              />
            )}

            <CustomTextField
              name="notes"
              label="Notes"
              placeholder=" "
              fullWidth
              shrink
              variant="standard"
              error
            />

            <label>Status</label>
            <div>
              <Field
                as="select"
                name="status_id"
                value={statusId}
                onChange={handleStatusIdChange}
                className={classes.textField}
              >
                {statuses.map((item, i) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </Field>
            </div>
            <br />
            <br />
            <Backdrop open={isLoading} style={{ zIndex: 5 }}>
              <CircularProgress />
            </Backdrop>
            <Button
              color="primary"
              type="submit"
              disabled={!(props.isValid && (props.dirty || propsChanged) && allPropsDefined())}
              variant="contained"
              style={{
                color: '#FFFFFF',
                borderRadius: 50,
                width: 120,
                textTransform: 'initial',
                fontWeight: 600,
              }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      {/* </Container> */}
    </>
  );
}
