import axios from "./customAxios";

const tasks = async () => {
  const { data } = await axios.get(`/company/tasks`);

  if (data && !data.is_success) throw Error(data?.message);
  console.log("taskapi", data?.tasks);
  return data?.tasks;
};

const getTask = async (id) => {
  const { data } = await axios.get(`/company/tasks/${id}`);
  console.log("getTask", data);
  if (data && !data.is_success) throw Error(data?.message);

  return data?.task;
};

const updateTask = async (task_id, task_status_id, attachment_id, reason) => {
  let data = {
    task_status_id: task_status_id,
    attachment_id: attachment_id,
    reason: reason,
  };
  console.log("data", data);

  return axios.put(`/company/tasks/` + task_id + `/status`, data);
};

const taskSvc = {
  tasks,
  getTask,
  updateTask,
};

export default taskSvc;
