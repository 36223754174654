import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  withStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import commonApi from '../../services/common.service';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import userApi from '../../services/user.service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// const { commonApi } = require("../../services/commonservice");

import MuiPhoneNumber from 'material-ui-phone-number';
// import "material-ui-phone-number/styles.css";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// styles
import useStyles from './styles';

// components
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Typography } from '../../components/Wrappers/Wrappers';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

import CheckboxField from './Checkbox';
// import DateFnsUtils from "@date-io/date-fns";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import fileUploader from '../../utility/fileuploader';

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Form(props) {
  var classes = useStyles();
  var theme = useTheme();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // local

  const [isLoading, setLoading] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [value, setValue] = React.useState('female');
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorApiMsg, setErrorApiMsg] = React.useState('');
  const errorMsg = 'Please fill up.';
  // common
  const [risklevellist, setRisklevellist] = React.useState();
  const [rolelist, setRolelist] = React.useState();
  const [riskcategorylist, setRiskcategorylist] = React.useState();
  const [countrylist, setCountrylist] = React.useState();
  const [identificationlist, setIdentificationlist] = React.useState();
  const [genderlist, setGenderlist] = React.useState();
  const [contactlist, setContactlist] = React.useState();
  const [cessationlist, setCessationlist] = React.useState();
  const [phonecodelist, setPhoneCodeList] = React.useState();
  const [addresstypelist, setAddresstypelist] = React.useState();

  // input fields
  // roles
  const [rolevalue, setRolevalue] = React.useState([]);

  // Personal Identifying Details:-
  const [idvalue, setIdvalue] = React.useState();
  const [iderror, setIderror] = React.useState(false);
  const [gendervalue, setGendervalue] = React.useState('1');
  const [aliasvalue, setAliasvalue] = React.useState();
  const [errorMsgAlias, setErrorMsgAlias] = React.useState('');
  const [countryvalue, setCountryvalue] = React.useState(null);
  const [bodvalue, setBodvalue] = React.useState('');
  const [firstnamevalue, setFirstnamevalue] = React.useState();
  const [errorMsgFirstName, setErrorMsgFirstName] = React.useState('');
  const [lastnamevalue, setLastnamevalue] = React.useState('');
  const [requiredIdNric, setRequiredIdNric] = React.useState(false);
  const [requiredIdPassport, setRequiredIdPassport] = React.useState(false);
  const [defaultNric, setDefaultNric] = React.useState(0);
  const [defaultPassport, setDefaultPassport] = React.useState(0);

  // Residency Details :-
  const [nationalityvalue, setNationalityvalue] = React.useState();
  const [nationalityerror, setNationalityerror] = React.useState(false);
  const [nricvalue, setNricvalue] = React.useState();
  const [nricCountryvalue, setNricCountryvalue] = React.useState();
  const [nricCountryerror, setNricCountryerror] = React.useState(false);
  const [passportCountryvalue, setPassportCountryvalue] = React.useState();
  const [passportCountryerror, setPassportCountryerror] = React.useState(false);
  const [errorMsgNric, setErrorMsgNric] = React.useState('');
  const [passportvalue, setPassportvalue] = React.useState();
  const [errorMsgPassport, setErrorMsgPassport] = React.useState('');
  const [errorMsgIssueDate, setErrorMsgIssueDate] = React.useState('');
  const [errorMsgExpiryDate, setErrorMsgExpiryDate] = React.useState('');

  const [issuedatevalue, setIssuedatevalue] = React.useState();
  const [expirydatevalue, setExpirydatevalue] = React.useState();

  // Contact Details :-
  const [emailvalue, setEmailvalue] = React.useState();
  const [emailerror, setEmailerror] = React.useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = React.useState('');
  const [wechatvalue, setWechatvalue] = React.useState();
  const [mobilephonevalue, setMobilephonevalue] = React.useState();
  const [phonecode, setPhonecode] = React.useState();
  const [mobilephone2value, setMobilephone2value] = React.useState();
  const [homephonevalue, setHomephonevalue] = React.useState();
  const [officephonevalue, setOfficephonevalue] = React.useState();
  const [whatsappvalue, setWhatsappvalue] = React.useState();

  // Address Details :-
  const [addresstypevalue, setAddresstypevalue] = React.useState();
  const [addresstypeerror, setAddresstypeerror] = React.useState(false);
  const [addresscountryvalue, setAddresscountryvalue] = React.useState();
  const [addresscountryerror, setAddresscountryerror] = React.useState(false);
  const [addresspostalvalue, setAddresspostalvalue] = React.useState();
  const [errorMsgaddresspostal, setErrorMsgaddresspostal] = React.useState('');
  const [addressroadvalue, setAddressroadvalue] = React.useState();
  const [errorMsgaddressroad, seterrorMsgaddressroad] = React.useState('');
  const [addressline2value, setAddressline2value] = React.useState();
  const [errorMsgaddressline2, setErrorMsgaddressline2] = React.useState('');
  const [addressunitvalue, setAddressunitvalue] = React.useState(null);

  // KYC CDD Details :-
  const [risklevelvalue, setRisklevelvalue] = React.useState('1');
  const [riskcategoryvalue, setRiskcategoryvalue] = React.useState([]);

  // Doc
  const refInputIdUpload = useRef();
  const [idDoc, setIdDoc] = React.useState('Upload ID / Passport');
  const refInputUtilityUpload = useRef();
  const [addressDoc, setAddressDoc] = React.useState('Upload Proof of Address Document');
  const refInputSupportingDocUpload = useRef();
  const [supportingDoc, setSupportingDoc] = React.useState('Upload Supporting Document');

  const [refInputUtilityUploadBtn, setRefInputUtilityUploadBtn] = React.useState('outlined');
  const [refInputIdUploadBtn, setRefInputIdUploadBtn] = React.useState('outlined');
  const [refInputSupportingDocUploadBtn, setRefInputSupportingDocUploadBtn] = React.useState(
    'outlined'
  );

  const handleRefInputUtilityUpload = (event) => {
    if (
      refInputUtilityUpload.current.value !== undefined ||
      refInputUtilityUpload.current.value !== null ||
      refInputUtilityUpload.current.value !== ''
    ) {
      setAddressDoc('Upload ' + getFileName(refInputUtilityUpload.current.value));
      setRefInputUtilityUploadBtn('contained');
    }
    // console.log(refInputUtilityUpload.current.value);
  };
  const handleRefInputIdUpload = (event) => {
    if (
      refInputIdUpload.current.value !== undefined ||
      refInputIdUpload.current.value !== null ||
      refInputIdUpload.current.value !== ''
    ) {
      setIdDoc('Upload ' + getFileName(refInputIdUpload.current.value));
      setRefInputIdUploadBtn('contained');
    }
    // console.log(refInputUtilityUpload.current.value);
  };
  const handleRefInputSupportingDocUpload = (event) => {
    if (
      refInputSupportingDocUpload.current.value !== undefined ||
      refInputSupportingDocUpload.current.value !== null ||
      refInputSupportingDocUpload.current.value !== ''
    ) {
      setSupportingDoc('Upload ' + getFileName(refInputSupportingDocUpload.current.value));
      setRefInputSupportingDocUploadBtn('contained');
    }
    // console.log(refInputUtilityUpload.current.value);
  };

  const getFileName = (fullPath) => {
    return fullPath.replace(/^.*[\\\/]/, '');
  };

  useEffect(() => {
    console.log('api', commonApi.getIdentificationType());
    const fetchData = async () => {
      const header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      };

      const respIdentificationtype = await commonApi.getIdentificationType();
      console.log('respIdentificationtype', respIdentificationtype.data.identificationtypes);
      setIdentificationlist(respIdentificationtype.data.identificationtypes);

      const respCountry = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/country",
        `${process.env.REACT_APP_COMMON_URL}/country`,
        { headers: header }
      );
      console.log('respCountry', respCountry.data.countries);
      setCountrylist(respCountry.data.countries);
      const respCompanyrole = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/companyrole",
        `${process.env.REACT_APP_COMMON_URL}/companyrole`,
        { headers: header }
      );
      setRolelist(respCompanyrole.data.companyroles);
      console.log('respCompanyrole', respCompanyrole.data.companyroles);

      const respaddresstype = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/addresstype",
        `${process.env.REACT_APP_COMMON_URL}/addresstype`,
        { headers: header }
      );
      console.log('respaddresstype', respaddresstype.data.addresstypes);
      setAddresstypelist(respaddresstype.data.addresstypes);
      const resprisklevel = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/risklevel",
        `${process.env.REACT_APP_COMMON_URL}/risklevel`,
        { headers: header }
      );
      console.log('resprisklevel', resprisklevel.data.risklevels);
      setRisklevellist(resprisklevel.data.risklevels);

      const respriskcategory = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/riskcategory",
        `${process.env.REACT_APP_COMMON_URL}/riskcategory`,
        { headers: header }
      );
      console.log('respriskcategory', respriskcategory.data.riskcategories);
      setRiskcategorylist(respriskcategory.data.riskcategories);
      //   const respcontacttype = await axios.get(
      //     "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/contacttype",
      //     { headers: header }
      //   );
      //   console.log("respcontacttype", respcontacttype.data.contacttypes);

      const respcessationtype = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/cessationtype",
        `${process.env.REACT_APP_COMMON_URL}/cessationtype`,
        { headers: header }
      );
      setCessationlist(respcessationtype.data.cessationtypes);
      console.log('respcessationtype', respcessationtype.data.cessationtypes);

      const respphonecode = await axios.get(
        // "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod/phonecode",
        `${process.env.REACT_APP_COMMON_URL}/phonecode`,
        { headers: header }
      );
      console.log('respphonecode', respphonecode.data.phonecodes);
      setPhoneCodeList(respphonecode.data.phonecodes);
    };
    fetchData().finally(() => setLoading(false));
  }, []);

  const handleClickOpen = (event) => {
    // setAge(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountryvalue(event.target.value);
  };

  //   const handleChange = (event) => {
  //     setState({ ...state, [event.target.name]: event.target.checked });
  //   };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChangeEmail = (event) => {
    if (event.target.value === undefined) {
      // setEmailerror(true);
      setErrorMsgEmail(errorMsg);
    } else {
      setEmailvalue(event.target.value);
      setErrorMsgEmail('');
      // setEmailerror(false);
    }
  };

  const handleChangeNationality = (event) => {
    if (event.target.value === undefined) {
      setNationalityerror(true);
    } else {
      setNationalityvalue(event.target.value);
      setNationalityerror(false);
    }
  };

  const handleChangeNRICCountry = (event) => {
    if (event.target.value === undefined) {
      setNricCountryerror(true);
    } else {
      setNricCountryvalue(event.target.value);
      setNricCountryerror(false);
    }
  };
  const handleChangePassportCountry = (event) => {
    if (event.target.value === undefined) {
      setPassportCountryerror(true);
    } else {
      setPassportCountryvalue(event.target.value);
      setPassportCountryerror(false);
    }
  };

  const handleChangeAddressCountry = (event) => {
    if (event.target.value === undefined) {
      setAddresscountryerror(true);
    } else {
      setAddresscountryvalue(event.target.value);
      setAddresscountryerror(false);
    }
  };

  const handleChangeAddressType = (event) => {
    if (event.target.value === undefined) {
      setAddresstypeerror(true);
    } else {
      setAddresstypevalue(event.target.value);
      setAddresstypeerror(false);
    }
  };

  const handleChangeGender = (event) => {
    console.log('setGendervalue', gendervalue);
    setGendervalue(event.target.value);
  };

  const handleChangeId = (event) => {
    if (event.target.value === undefined) {
      setIderror(true);
    } else {
      console.log('idvalue', event.target.value);
      setIdvalue(event.target.value);
      setIderror(false);
      if (event.target.value === 1) {
        setRequiredIdNric(true);
        setDefaultNric(1);
        setDefaultPassport(0);
      } else {
        setRequiredIdNric(false);
      }
      if (event.target.value === 2) {
        setRequiredIdPassport(true);
        setDefaultNric(0);
        setDefaultPassport(1);
      } else {
        setRequiredIdPassport(false);
      }
    }
  };
  const handleChangeAddressRoad = (event) => {
    if (event.target.value === undefined) {
      seterrorMsgaddressroad(errorMsg);
    } else {
      setAddressroadvalue(event.target.value);
      seterrorMsgaddressroad('');
    }
  };

  const handleChangePostal = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgaddresspostal(errorMsg);
    } else {
      setAddresspostalvalue(event.target.value);
      setErrorMsgaddresspostal('');
    }
  };

  const handleChangePassport = (event) => {
    if (event.target.value === undefined && requiredIdPassport) {
      setErrorMsgPassport(errorMsg);
    } else {
      setPassportvalue(event.target.value);
      setRequiredIdPassport(true);
      setErrorMsgPassport('');
    }
  };

  const handleChangeNric = (event) => {
    if (event.target.value === undefined && requiredIdNric) {
      setErrorMsgNric(errorMsg);
    } else {
      setNricvalue(event.target.value);
      setErrorMsgNric('');
    }
  };

  const handleChangeAlias = (event) => {
    if (event.target.value === undefined) {
      //   setErrorMsgAlias(errorMsg);
      setAliasvalue(' ');
    } else {
      setAliasvalue(event.target.value);
      //   setErrorMsgAlias("");
    }
  };

  const handleChangeAddress2 = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgaddressline2(errorMsg);
    } else {
      setAddressline2value(event.target.value);
      setErrorMsgaddressline2('');
    }
  };

  const handleChangeFirstName = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgFirstName(errorMsg);
    } else {
      setFirstnamevalue(event.target.value);
      setErrorMsgFirstName('');
    }
  };

  const handleChangeUploadIdPassport = async (event) => {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };

    console.log('refInputIdUpload', refInputIdUpload);

    console.log('refInputIdUpload files', refInputIdUpload.current.files[0]);
    let file = refInputIdUpload.current.files[0];
    // Split the filename to get the name and type
    let fileParts = refInputIdUpload.current.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    // const respIdUpload = await axios.get(
    //   "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/signedurl?name=" +
    //     fileName +
    //     "&content-type=application%2F" +
    //     fileType,
    //   { headers: header }
    // );
    const respIdUpload = await axios.get(
      `${process.env.REACT_APP_DOCUMENT_URL}/signedurl?name=` +
        fileName +
        '&content-type=application%2F' +
        fileType,
      { headers: header }
    );
    const { current } = refInputIdUpload;
    console.log('respIdUpload', respIdUpload);

    // console.log("refInputIdUpload", refInputIdUpload);
  };

  function removeA(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  const handleCheckbox = (event, isChecked, value) => {
    console.log(isChecked, value);
    var arrRole = [];
    arrRole = rolevalue;
    if (isChecked) {
      arrRole.push(value);
    } else if (!isChecked) {
      removeA(arrRole, value);
    }
    setRolevalue(arrRole);
    console.log('rolevalue', rolevalue);
    //   this.res.add(value);
    //   if (this.res.size === 3) console.log(this.res);
  };

  const handleCheckboxRisk = (event, isChecked, key) => {
    console.log(isChecked, key, event);
    var arrRiskCatagory = [];
    arrRiskCatagory = riskcategoryvalue;
    if (isChecked) {
      arrRiskCatagory.push(key);
    } else if (!isChecked) {
      removeA(arrRiskCatagory, key);
    }
    setRiskcategoryvalue(arrRiskCatagory);
    console.log('riskcategoryvalue', riskcategoryvalue);
    //   this.res.add(value);
    //   if (this.res.size === 3) console.log(this.res);
  };

  //   const [value, setValue] = React.useState("Low");

  // const handleChangePhonecode = (event) => {
  //   setPhonecode(event.target.value);
  //   console.log("handleChangePhonecode");
  // };
  const handleChangeBod = (event) => {
    setBodvalue(event.target.value);
    console.log('handleChangeBod');
  };

  const handleChangeIssueDate = (event) => {
    setIssuedatevalue(event.target.value);
    console.log('handleChangeIssueDate');
    setRequiredIdPassport(true);
    // if (event.target.value === undefined && requiredIdPassport) {
    //   setErrorMsgIssueDate(errorMsg);
    // } else {
    //   setIssuedatevalue(event.target.value);
    //   setErrorMsgIssueDate("");
    // }
  };

  const handleChangeExpiryDate = (event) => {
    console.log('handleChangeExpiryDate');
    if (event.target.value === undefined && requiredIdPassport) {
      setErrorMsgExpiryDate(errorMsg);
    } else {
      setExpirydatevalue(event.target.value);
      setRequiredIdPassport(true);
      setErrorMsgExpiryDate('');
    }
  };
  const handleChangeRiskLevel = (event) => {
    setRisklevelvalue(event.target.value);
    console.log('handleChangeRiskLevel', risklevelvalue);
  };

  const fileUploader2 = async (fileLink, docType, dataType, dataId) => {
    console.log(fileLink + '.current.files', fileLink.current.files);
    if (fileLink.current.files[0] !== undefined) {
      const header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      };
      let file = fileLink.current.files[0];
      // Split the filename to get the name and type
      let fileParts = fileLink.current.files[0].name.split('.');
      let fileName = fileParts[0];
      let fileType = fileParts[1];

      // const respIdSigned = await axios.get(
      //   "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/signedurl?name=" +
      //     fileName +
      //     "&content-type=application%2F" +
      //     fileType,
      //   { headers: header }
      // );
      const respIdSigned = await axios.get(
        `${process.env.REACT_APP_DOCUMENT_URL}/signedurl?name=` +
          fileName +
          '&content-type=application%2F' +
          fileType,
        { headers: header }
      );
      const { current } = refInputIdUpload;
      console.log('respIdSigned', respIdSigned);

      console.log('refInputIdUpload', refInputIdUpload);
      var options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Content-Type': 'application/' + fileType,
        },
      };
      const respUploadFile = await axios.put(
        respIdSigned.data.uploadInfo.uploadURL.toString(),
        file,
        options
        // {
        //   headers: header,
        // }
      );

      console.log('respUploadFile', respUploadFile.status);

      if (respUploadFile.status === 200) {
        const docData = {
          module_id: 1,
          document_container_id: 1,
          document_type_id: docType, //76 NRIC, 109 Proof of Residential, 110 CDD documents
          document_table_type_id: dataType, //table: Users, 5 UserAddresses, 4 CDD
          table_primary_id: dataId, //user_id, user_address_id, customer_due_diligence_id
          document_url: respIdSigned.data.uploadInfo.fileURL,
        };
        // const respDocument = await axios.post(
        //   "https://r2vorp79i8.execute-api.ap-southeast-1.amazonaws.com/Prod/documents/",
        //   docData,
        //   { headers: header }
        // );
        const respDocument = await axios.post(
          `${process.env.REACT_APP_DOCUMENT_URL}/documents/`,
          docData,
          { headers: header }
        );

        console.log('respDocument', respDocument);
      }
    }
  };

  const handleChangeUploadSupportingDoc = async (dataId) => {
    fileUploader(refInputSupportingDocUpload, 110, 4, dataId);
  };
  const handleChangeUploadUtility = (dataId) => {
    fileUploader(refInputUtilityUpload, 109, 5, dataId);
  };

  const handleUploadIdPassport = (dataId) => {
    fileUploader(refInputIdUpload, 76, 1, dataId);
  };

  const handleSubmit = () => {
    if (firstnamevalue === undefined) {
      setErrorMsgFirstName(errorMsg);
    } else {
      setErrorMsgFirstName('');
    }

    if (aliasvalue === undefined) {
      setAliasvalue(' ');
      //   setErrorMsgAlias(errorMsg);
      // } else {
      //   setErrorMsgAlias("");
    }

    if (addresspostalvalue === undefined) {
      setErrorMsgaddresspostal(errorMsg);
    } else {
      setErrorMsgaddresspostal('');
    }

    // if (addressline2value === undefined) {
    //   setErrorMsgaddressline2(errorMsg);
    // } else {
    //   setErrorMsgaddressline2("");
    // }

    if (addressroadvalue === undefined) {
      seterrorMsgaddressroad(errorMsg);
    } else {
      seterrorMsgaddressroad('');
    }

    if (nricvalue === undefined && requiredIdNric) {
      setErrorMsgNric(errorMsg);
    } else {
      setErrorMsgNric('');
    }
    if (passportvalue === undefined && requiredIdPassport) {
      setErrorMsgPassport(errorMsg);
    } else {
      setErrorMsgPassport('');
    }

    if (expirydatevalue === undefined && requiredIdPassport) {
      setErrorMsgExpiryDate(errorMsg);
    } else {
      setErrorMsgExpiryDate('');
    }

    if (addresstypevalue === undefined) {
      setAddresstypeerror(true);
    } else {
      setAddresstypeerror(false);
    }

    if (addresscountryvalue === undefined) {
      setAddresscountryerror(true);
    } else {
      setAddresscountryerror(false);
    }

    // if (emailvalue === undefined) {
    //   setEmailerror(true);
    // } else {
    //   setEmailerror(false);
    // }

    if (nationalityvalue === undefined) {
      setNationalityerror(true);
    } else {
      setNationalityerror(false);
    }

    if (nricCountryvalue === undefined && requiredIdNric) {
      setNricCountryerror(true);
    } else {
      setNricCountryerror(false);
    }

    if (passportCountryvalue === undefined && requiredIdPassport) {
      setPassportCountryerror(true);
    } else {
      setPassportCountryerror(false);
    }

    if (idvalue === undefined) {
      setIderror(true);
    } else {
      setIderror(false);
    }

    const fetchData = async () => {
      setOpen(true);
      console.log('addresscountryvalue', addresscountryvalue);
      console.log('idvalue', idvalue);
      if (aliasvalue === undefined) {
        setAliasvalue(' ');
      }
      if (
        firstnamevalue !== undefined &&
        aliasvalue !== undefined &&
        addresspostalvalue !== undefined &&
        // addressline2value !== undefined &&
        addressroadvalue !== undefined &&
        addresstypevalue !== undefined &&
        nationalityvalue !== undefined &&
        idvalue !== undefined &&
        addresscountryvalue !== undefined
      ) {
        console.log('addresspostalvalue', bodvalue);
        const header = {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
          'Content-Type': 'application/json',
        };

        var userData = {
          // username: emailvalue,
          email: emailvalue,
          gender_id: gendervalue,
          nationality_id: nationalityvalue,
          first_name: firstnamevalue,
          last_name: lastnamevalue,
          alias: aliasvalue,
          country_of_birth_id: countryvalue,
          date_of_birth: bodvalue,
        };
        console.log('userData', userData);
        // const respUser = await axios.post(
        //   "https://l1968cbgy7.execute-api.ap-southeast-1.amazonaws.com/Prod/users/",
        //   userData,
        //   { headers: header }
        // );
        let respUser;
        try {
          respUser = await axios.post(`${process.env.REACT_APP_USERCONTACT_URL}/users/`, userData, {
            headers: header,
          });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Create Individual`, {
            variant: 'error',
          });
          setOpen(false);
          setIsLoadingSubmit(false);
          return;
        }

        if (respUser?.data?.users?.user_id) {
          console.log('respUser', respUser.data.users.user_id);

          var contactData = {
            contacts: [],
          };

          if (emailvalue !== undefined) {
            var emailData = {
              contact_type_id: 1,
              contact_value: emailvalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(emailData);
          }

          if (wechatvalue !== undefined) {
            var wechatData = {
              contact_type_id: 6,
              contact_value: wechatvalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(wechatData);
          }
          if (mobilephonevalue !== undefined) {
            var mobilephoneData = {
              contact_type_id: 2,
              contact_value: mobilephonevalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(mobilephoneData);
          }

          if (mobilephone2value !== undefined) {
            var mobilephone2Data = {
              contact_type_id: 3,
              contact_value: mobilephone2value,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(mobilephone2Data);
          }

          if (officephonevalue !== undefined) {
            var officephoneData = {
              contact_type_id: 8,
              contact_value: officephonevalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(officephoneData);
          }
          if (homephonevalue !== undefined) {
            var homephoneData = {
              contact_type_id: 7,
              contact_value: homephonevalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(homephoneData);
          }
          if (whatsappvalue !== undefined) {
            var whatsappData = {
              contact_type_id: 5,
              contact_value: whatsappvalue,
              country_phone_code_id: null,
              is_primary: 0,
            };
            contactData.contacts.push(whatsappData);
          }
          if (
            wechatvalue !== undefined ||
            whatsappvalue !== undefined ||
            homephonevalue !== undefined ||
            officephonevalue !== undefined ||
            mobilephone2value !== undefined ||
            mobilephonevalue !== undefined
            // emailvalue !== undefined
          ) {
            const respContact = await userApi.createContacts(
              respUser.data.users.user_id,
              contactData
            );

            // const respContact = await axios.post(
            //   "https://l1968cbgy7.execute-api.ap-southeast-1.amazonaws.com/Prod/users/" +
            //     respUser.data.users.user_id +
            //     "/contacts/",
            //   contactData,
            //   { headers: header }
            // );

            console.log('respContact', respContact);
          }

          console.log('nricvalue', nricvalue);
          var idData = { identifications: [] };
          if (nricvalue !== undefined && nricCountryvalue !== undefined && idvalue == 1) {
            var nricData = {
              identification_type_id: 1,
              identification_value: nricvalue,
              // issuing_country: nricissuingcountryvalue,
              issuing_country: nricCountryvalue,
              issue_date: null,
              expiry_date: null,
              is_default: defaultNric,
            };
            idData.identifications.push(nricData);
            console.log('idDatapush', idData);
          }

          if (
            passportvalue !== undefined &&
            passportCountryvalue !== undefined &&
            issuedatevalue !== undefined &&
            expirydatevalue !== undefined &&
            passportvalue !== null &&
            issuedatevalue !== null &&
            expirydatevalue !== null &&
            idvalue == 2
          ) {
            // if (passportvalue === undefined) {
            //   setPassportvalue(null);
            // }
            // if (issuedatevalue === undefined) {
            //   setIssuedatevalue(null);
            // }
            // if (expirydatevalue === undefined) {
            //   setExpirydatevalue(null);
            // }

            var passportData = {
              identification_type_id: 2,
              identification_value: passportvalue,
              // issuing_country: passportissuingcountryvalue,
              issuing_country: passportCountryvalue,
              issue_date: issuedatevalue,
              expiry_date: expirydatevalue,
              is_default: defaultPassport,
            };
            idData.identifications.push(passportData);
            console.log('passportDatapush', idData);
          }
          console.log('idDatafinal', idData);

          try {
            // const respId = await axios.post(
            //   "https://oe6mwa0p90.execute-api.ap-southeast-1.amazonaws.com/Prod/users/" +
            //     respUser.data.users.user_id +
            //     "/identifications/",
            //   idData,
            //   { headers: header }
            // );
            const respId = await axios.post(
              `${process.env.REACT_APP_IDENTITY_URL}/users/` +
                respUser.data.users.user_id +
                '/identifications/',
              idData,
              { headers: header }
            );

            console.log('respId', respId);
          } catch (error) {
            setErrorApiMsg('ID Error');
            setOpenSnackbar(true);
            console.log('iderror', error);
            // setHasError(true);
          }

          const addressData = {
            userAddresses: [
              {
                address_type_id: addresstypevalue,
                country_id: addresscountryvalue,
                floor_unit_no: addressunitvalue,
                address_line_1: addressroadvalue,
                address_line_2: addressline2value,
                address_line_3: null,
                postal_code: addresspostalvalue,
                is_default: 1,
              },
            ],
          };
          console.log('addressData', addressData);
          // const respAddress = await axios.post(
          //   "https://gx2x6uv93l.execute-api.ap-southeast-1.amazonaws.com/Prod/users/" +
          //     respUser.data.users.user_id +
          //     "/addresses",
          //   addressData,
          //   { headers: header }
          // );
          const respAddress = await axios.post(
            `${process.env.REACT_APP_ADDRESS_URL}/users/` +
              respUser.data.users.user_id +
              '/addresses',
            addressData,
            { headers: header }
          );
          console.log('respAddress', respAddress);

          console.log('risklevelvalue', risklevelvalue);
          console.log('riskcategoryvalue', riskcategoryvalue);
          const cddData = {
            risk_level_id: risklevelvalue,
            customer_risk_categories: riskcategoryvalue,
          };
          // const respCdd = await axios.post(
          //   "https://n5a74lgnv9.execute-api.ap-southeast-1.amazonaws.com/Prod/users/" +
          //     respUser.data.users.user_id +
          //     "/customerdd",
          //   cddData,
          //   { headers: header }
          // );
          const respCdd = await axios.post(
            `${process.env.REACT_APP_CUSTOMERDD_URL}/users/` +
              respUser.data.users.user_id +
              '/customerdd',
            cddData,
            { headers: header }
          );

          console.log('respCdd', respCdd.data.customerdd.customer_due_diligence_id);
          const resphandleUploadIdPassport = await handleUploadIdPassport(
            respUser.data.users.user_id
          );
          const resphandleChangeUploadSupportingDoc = await handleChangeUploadSupportingDoc(
            respCdd.data.customerdd.customer_due_diligence_id
          );
          const resphandleChangeUploadUtility = await handleChangeUploadUtility(
            respAddress.data.userAddresses[respAddress.data.userAddresses.length - 1]
              .user_address_id
          );
          handleCompletion();
        }
      }
    };
    if (requiredIdNric && !requiredIdPassport) {
      if (
        firstnamevalue !== undefined &&
        aliasvalue !== undefined &&
        nricvalue !== undefined &&
        nricCountryvalue !== undefined &&
        addresspostalvalue !== undefined &&
        // addressline2value !== undefined &&
        addressroadvalue !== undefined &&
        addresstypevalue !== undefined &&
        nationalityvalue !== undefined &&
        idvalue !== undefined &&
        addresscountryvalue !== undefined
      ) {
        setIsLoadingSubmit(true);
        fetchData().catch((error) => {
          setErrorApiMsg(
            'Create Individual Error: ' + JSON.stringify(error.response.data.error.message)
          );
          setOpenSnackbar(true);
        });
      }
    } else if (!requiredIdNric && requiredIdPassport) {
      if (
        firstnamevalue !== undefined &&
        aliasvalue !== undefined &&
        passportvalue !== undefined &&
        passportCountryvalue !== undefined &&
        issuedatevalue !== undefined &&
        expirydatevalue !== undefined &&
        addresspostalvalue !== undefined &&
        // addressline2value !== undefined &&
        addressroadvalue !== undefined &&
        addresstypevalue !== undefined &&
        nationalityvalue !== undefined &&
        idvalue !== undefined &&
        addresscountryvalue !== undefined
      ) {
        setIsLoadingSubmit(true);

        fetchData().catch((error) => {
          setErrorApiMsg(
            'Create Individual Error: ' + JSON.stringify(error.response.data.error.message)
          );
          setOpenSnackbar(true);
        });
      }
    } else if (requiredIdNric && requiredIdPassport) {
      if (
        firstnamevalue !== undefined &&
        aliasvalue !== undefined &&
        passportvalue !== undefined &&
        issuedatevalue !== undefined &&
        expirydatevalue !== undefined &&
        nricvalue !== undefined &&
        addresspostalvalue !== undefined &&
        // addressline2value !== undefined &&
        addressroadvalue !== undefined &&
        addresstypevalue !== undefined &&
        nationalityvalue !== undefined &&
        idvalue !== undefined &&
        addresscountryvalue !== undefined
      ) {
        setIsLoadingSubmit(true);

        fetchData().catch((error) => {
          setErrorApiMsg(
            'Create Individual Error: ' + JSON.stringify(error.response.data.error.message)
          );
          setOpenSnackbar(true);
        });
      }
    }
  };

  //   // The first commit of Material-UI
  //   const [selectedDate, setSelectedDate] = React.useState(
  //     new Date("2014-08-18T21:11:54")
  //   );

  //   const handleDateChange = (date) => {
  //     setSelectedDate(date);
  //   };

  //   const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCompletion = () => {
    setOpen(false);
    setOpenDialog(true);
    setIsLoadingSubmit(false);
  };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // window.location.reload(false);
    history.push({ pathname: '/individual' });
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/individual' })}
      >
        BACK TO INDIVIDUAL DATABASE
      </Button>
      <PageTitle title="Individual" />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Personal Identifying Details:-
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error={iderror} required fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Identification Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={idvalue}
                  onChange={handleChangeId}
                  //   onInput={(e) => setIdvalue(e.target.value)}
                  error={iderror}
                >
                  <MenuItem value={1}>{'NRIC'}</MenuItem>
                  <MenuItem value={2}>{'Passport'}</MenuItem>
                  {/* {identificationlist.map((element) => (
                    <MenuItem value={element.identification_type_id}>
                      {element.identification_name}
                    </MenuItem>
                  ))} */}
                </Select>
                <FormHelperText hidden={!iderror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstname"
                name="firstname"
                label="First Name"
                fullWidth
                onChange={handleChangeFirstName}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgFirstName !== '' ? true : false}
                helperText={errorMsgFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastname"
                name="lastname"
                label="Last Name"
                fullWidth
                onInput={(e) => setLastnamevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl required component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  defaultValue="1"
                  value={gendervalue}
                  onChange={handleChangeGender}
                  row
                >
                  <FormControlLabel value="1" control={<Radio />} label="Male" />
                  <FormControlLabel value="2" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // required
                id="alias"
                name="alias"
                label="Alias"
                fullWidth
                // error={errorMsgAlias !== "" ? true : false}
                // helperText={errorMsgAlias}
                onChange={handleChangeAlias}

                // onInput={(e) => setAliasvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="date"
                label="Birthday"
                type="date"
                onChange={handleChangeBod}
                // onInput={(e) => setBodvalue(e.target.value)}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Country of Birth</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={countryvalue}
                  onChange={handleChangeCountry}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              <Button
                variant={refInputIdUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputIdUpload}
              >
                {idDoc}
                {/* {refInputIdUpload !== undefined &&
                refInputIdUpload !== null &&
                refInputIdUpload !== ""
                  ? "Upload ID/Passport"
                  : "Upload " + getFileName(refInputIdUpload.current.value)} */}
                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputIdUpload} />
              </Button>
              <InputLabel>{/* {refInputUtilityUpload.current.value === undefined} */}</InputLabel>

              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Residency Details :-
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                error={nationalityerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={nationalityvalue}
                  onChange={handleChangeNationality}
                  error={nationalityerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!nationalityerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="nric"
                name="nric"
                label="NRIC No"
                required={requiredIdNric}
                fullWidth
                // onInput={(e) => setNricvalue(e.target.value)}
                onChange={handleChangeNric}
                error={errorMsgNric !== '' ? true : false}
                helperText={errorMsgNric}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={nricCountryerror}
                required={requiredIdNric}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label-nric-country">
                  NRIC Issue Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-nric-country"
                  id="demo-simple-select-nric-country"
                  value={nricCountryvalue}
                  onChange={handleChangeNRICCountry}
                  error={nricCountryerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!nricCountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={requiredIdPassport}
                id="passport"
                name="passport"
                label="Passport No"
                fullWidth
                onChange={handleChangePassport}
                // onInput={(e) => setPassportvalue(e.target.value)}
                error={errorMsgPassport !== '' ? true : false}
                helperText={errorMsgPassport}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={passportCountryerror}
                required={requiredIdPassport}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label-passport-country">
                  Passport Issue Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-passport-country"
                  id="demo-simple-select-passport-country"
                  value={passportCountryvalue}
                  onChange={handleChangePassportCountry}
                  error={passportCountryerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!passportCountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={requiredIdPassport}
                fullWidth
                id="date"
                label="Passport Date Of Issue"
                type="date"
                onChange={handleChangeIssueDate}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                // error={errorMsgPassport !== "" ? true : false}
                // helperText={errorMsgPassport}
                error={errorMsgExpiryDate !== '' ? true : false}
                helperText={errorMsgExpiryDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={requiredIdPassport}
                fullWidth
                id="date"
                label="Passport Date Of Expiry"
                type="date"
                onChange={handleChangeExpiryDate}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errorMsgExpiryDate !== '' ? true : false}
                helperText={errorMsgExpiryDate}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Contact Details :-
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                // required
                id="emailaddress1"
                name="emailaddress1"
                label="Email Address"
                fullWidth
                onChange={handleChangeEmail}
                // onInput={(e) => setEmailvalue(e.target.value)}
                error={errorMsgEmail !== '' ? true : false}
                helperText={errorMsgEmail}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                label="Mobile Phone"
                defaultCountry={'sg'}
                value={mobilephonevalue}
                // onChange={setMobilephonevalue}
                onInput={(e) => setMobilephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                label="Mobile Phone 2"
                defaultCountry={'sg'}
                value={mobilephone2value}
                // onChange={setMobilephone2value}
                onInput={(e) => setMobilephone2value(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                label="Home Phone"
                defaultCountry={'sg'}
                value={homephonevalue}
                // onChange={setHomephonevalue}
                onInput={(e) => setHomephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                label="Office Phone"
                defaultCountry={'sg'}
                value={officephonevalue}
                // onChange={setOfficephonevalue}
                onInput={(e) => setOfficephonevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                label="Whatsapp"
                defaultCountry={'sg'}
                // value={whatsappvalue}
                // onChange={setWhatsappvalue}
                onInput={(e) => setWhatsappvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="wechat"
                name="wechat"
                label="Wechat"
                fullWidth
                onInput={(e) => setWechatvalue(e.target.value)}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Address Details :-
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                error={addresstypeerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresstypevalue}
                  onChange={handleChangeAddressType}
                  error={addresstypeerror}
                >
                  {addresstypelist.map((element) => (
                    <MenuItem value={element.address_type_id}>{element.address_type_name}</MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Office</MenuItem>
                  <MenuItem value={2}>Home</MenuItem> */}
                </Select>
                <FormHelperText hidden={!addresstypeerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="postal"
                name="postal"
                label="Postal Code"
                fullWidth
                onChange={handleChangePostal}
                // onInput={(e) => setAddresspostalvalue(e.target.value)}
                type="number"
                error={errorMsgaddresspostal !== '' ? true : false}
                helperText={errorMsgaddresspostal}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="unitno"
                name="unitno"
                label="Floor No./Unit No."
                fullWidth
                onInput={(e) => setAddressunitvalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="street"
                name="street"
                label="Street / Road"
                fullWidth
                onChange={handleChangeAddressRoad}
                // onInput={(e) => setAddressroadvalue(e.target.value)}
                error={errorMsgaddressroad !== '' ? true : false}
                helperText={errorMsgaddressroad}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // required
                id="addressline2"
                name="addressline2"
                label="Address Line 2"
                fullWidth
                onChange={handleChangeAddress2}
                // onInput={(e) => setAddressline2value(e.target.value)}
                // error={errorMsgaddressline2 !== "" ? true : false}
                // helperText={errorMsgaddressline2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={addresscountryerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresscountryvalue}
                  onChange={handleChangeAddressCountry}
                  error={addresscountryerror}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!addresscountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              <Button
                variant={refInputUtilityUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputUtilityUpload}
              >
                {addressDoc}
                {/* {refInputUtilityUpload !== undefined &&
                refInputUtilityUpload !== null &&
                refInputUtilityUpload !== ""
                  ? getFileName(refInputUtilityUpload.current.value)
                  : "Upload Proof of Address Document"} */}
                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputUtilityUpload} />
              </Button>
              <InputLabel>{/* {refInputUtilityUpload.current.value === undefined} */}</InputLabel>

              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
          </Grid>

          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              KYC CDD Details :-
            </Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Risk Level</FormLabel>
                <RadioGroup
                  aria-label="risklevel"
                  name="risklevel"
                  value={risklevelvalue}
                  onChange={handleChangeRiskLevel}
                  row
                >
                  <FormControlLabel value="1" control={<Radio />} label="Low" />
                  <FormControlLabel value="2" control={<Radio />} label="Medium" />
                  <FormControlLabel value="3" control={<Radio />} label="High" />
                  {/* {risklevellist.map((element) => (
                    <FormControlLabel
                      value={element.risk_level_id}
                      control={<Radio />}
                      label={element.risk_level_name}
                    />
                  ))} */}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div>Risk Category</div>
              {riskcategorylist.map((element) => (
                <CheckboxField
                  key={element.risk_category_id}
                  label={element.risk_category_name}
                  category={element.risk_category_id}
                  onChange={handleCheckboxRisk}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              {/* <InputLabel disabled={disableAddressDetails}>
                Upload Utility Bill / ID
              </InputLabel> */}
              <Button
                variant={refInputSupportingDocUploadBtn}
                component="label"
                color={'primary'}
                onChange={handleRefInputSupportingDocUpload}
              >
                {supportingDoc}
                {/* {refInputSupportingDocUpload !== undefined &&
                refInputSupportingDocUpload !== null &&
                refInputSupportingDocUpload !== ""
                  ? "Upload Supporting Document"
                  : "Upload " +
                    getFileName(refInputSupportingDocUpload.current.value)} */}

                {/* <input type="file" hidden onChange={handleRefInputUtilityUpload} />  */}
                <input type="file" hidden ref={refInputSupportingDocUpload} />
              </Button>
              <InputLabel>{/* {refInputUtilityUpload.current.value === undefined} */}</InputLabel>

              {/* <input
                disabled={disableAddressDetails}
                // onChange={handleChangeUploadUtility}
                ref={refInputUtilityUpload}
                // ref={(ref) => {
                //   //   this.uploadInput = ref;
                // }}
                type="file"
              /> */}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoadingSubmit ? (
                <Button color="primary" variant="link" component="span" disabled={true}>
                  <CircularProgress size={26} />
                </Button>
              ) : (
                <Button onClick={handleSubmit} variant="contained" size="medium" color="secondary">
                  SUBMIT
                </Button>
              )}
            </Grid>
          </Grid>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            onBackdropClick="false"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{'Submission'}</DialogTitle>
            <DialogContent>
              <DialogContentText>The submission is submitted successfully.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorApiMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
