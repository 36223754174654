import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  InputBase,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from '../../styles';
// const companyofficerData = {
//   user_id: 8,
//   company_id: 5,
//   company_role_id: 3,
//   date_of_appointment: "2021-03-31 00:00:00",
//   nominee_id: null,
//   nominator_id: null,
//   controller_id: null,
//   named_secretary_id: null,
//   cessation_type_id: null,
//   cessation_date: null,
// };
const CompanyOfficerDialog = ({
  open,
  handleSubmit,
  handleClose,
  existOfficer,
  userData,
  companyRoles,
  cessationTypes,
  handleRemoveOfficer,
}) => {
  var classes = useStyles();
  const nullValue = { user_id: 'null', first_name: 'null' };

  const errorSelectMsg = 'Please select.';
  const errorMsg = 'Please fill up.';
  const [userValue, setUservalue] = useState();
  const [companyroleerror, setCompanyroleerror] = useState();
  const [companyRoleValue, setCompanyRolevalue] = useState();
  const [dateAppointmentValue, setDateAppointmentvalue] = useState();
  const [dateCessationValue, setDateCessationvalue] = useState('null');
  const [typeCessationValue, setTypeCessationvalue] = useState('null');
  const [nomineeValue, setNomineevalue] = useState(nullValue);
  const [nominatorValue, setNominatorvalue] = useState(nullValue);
  const [secretaryValue, setSecretaryvalue] = useState(nullValue);
  const [controllerValue, setControllervalue] = useState(nullValue);
  const [errorMsgUser, setErrorMsgUser] = useState('');
  const [errorMsgDateAppointment, setErrorMsgDateAppointment] = useState('');
  const [userCompanyRoleId, setUserCompanyRoleId] = useState();

  const [isEdit, setIsEdit] = useState(false);

  function handleReset() {
    setUservalue();
    setControllervalue(nullValue);
    setCompanyRolevalue();
    setDateAppointmentvalue();
    setNominatorvalue(nullValue);
    setSecretaryvalue(nullValue);
    setNomineevalue(nullValue);
    setTypeCessationvalue('null');
    setDateCessationvalue('null');
    setErrorMsgUser('');
    setCompanyroleerror(false);
    setErrorMsgDateAppointment('');
    setUserCompanyRoleId();
  }

  useEffect(() => {
    if (open) {
      if (existOfficer) {
        setIsEdit(true);
        setUservalue({
          user_id: existOfficer.user_id,
          first_name: existOfficer.user_firstname,
        });
        setCompanyRolevalue(existOfficer.company_role);
        setDateAppointmentvalue(existOfficer.date_appointment);
        setNomineevalue({
          user_id: existOfficer.nominee_id,
          first_name: existOfficer.nominee_name,
        });
        setNominatorvalue({
          user_id: existOfficer.nominator_id,
          first_name: existOfficer.nominator_name,
        });
        setSecretaryvalue({
          user_id: existOfficer.named_secretary_id,
          first_name: existOfficer.named_secretary_name,
        });
        setControllervalue({
          user_id: existOfficer.controller_id,
          first_name: existOfficer.controller_name,
        });
        setDateCessationvalue(existOfficer.date_cessation);
        setTypeCessationvalue(existOfficer.cessation_type);
        setUserCompanyRoleId(existOfficer.user_company_role_id);
      } else {
        setIsEdit(false);
      }
    } else {
      handleReset();
    }
  }, [isEdit, existOfficer, open]);

  function handleChangeUser(event, value) {
    if (value === undefined) {
      setErrorMsgUser(errorSelectMsg);
    } else {
      setUservalue(value);
      setErrorMsgUser('');
    }
  }

  function handleChangeSecretary(event, value) {
    console.log('handleChangeSecretary', value);
    if (value === undefined) {
      setSecretaryvalue(nullValue);
    } else {
      setSecretaryvalue(value);
    }
  }

  function handleChangeController(event, value) {
    console.log('handleChangeController', value);
    if (value === undefined) {
      setControllervalue(nullValue);
    } else {
      setControllervalue(value);
    }
  }

  function handleChangeNominator(event, value) {
    console.log('handleChangeNominator', value);
    setNominatorvalue(value);
    if (value === undefined) {
      setNominatorvalue(nullValue);
    } else {
      setNominatorvalue(value);
    }
  }

  function handleChangeNominee(event, value) {
    console.log('handleChangeNominee', value);
    if (value === undefined) {
      setNomineevalue(nullValue);
    } else {
      setNomineevalue(value);
    }
  }

  function handleChangeCompanyRole(event) {
    if (event.target.value === undefined) {
      setCompanyroleerror(true);
    } else {
      setCompanyRolevalue(event.target.value);
      setCompanyroleerror(false);
    }
  }

  function handleChangeDateAppointment(event) {
    if (event.target.value === undefined) {
      setErrorMsgDateAppointment(errorSelectMsg);
    } else {
      setDateAppointmentvalue(event.target.value);
      setErrorMsgDateAppointment('');
    }
  }

  function handleChangeCessation(event) {
    if (event.target.value === undefined) {
      setTypeCessationvalue(null);
    } else {
      setTypeCessationvalue(event.target.value);
    }
  }

  function handleChangeDateCessation(event) {
    if (event.target.value === undefined) {
      setDateCessationvalue(null);
    } else {
      setDateCessationvalue(event.target.value);
    }
  }
  function findByMatchingProperties(set, properties) {
    return set.filter(function (entry) {
      return Object.keys(properties).every(function (key) {
        return entry[key] === properties[key];
      });
    });
  }

  const defaultProps = {
    // defaultValue: findByMatchingProperties(userData, { user_id: 1 }),
    // defaultValue={{userData.find((user) => user.user_id === "1")}},
    options: userData,
    getOptionLabel: (option) => {
      if (option.nric !== null) {
        return (
          option.first_name +
          (option.last_name != null ? ' ' + option.last_name : '') +
          ' (NRIC-' +
          option.nric +
          ')'
        );
      } else {
        return (
          (option.last_name != null ? option.last_name + ' ' : '') +
          option.first_name +
          ' (Passport-' +
          option.passport +
          ')'
        );
      }
    },
  };
  let closeImg = {
    cursor: 'pointer',
    float: 'right',
    marginTop: '5px',
    width: '20px',
  };

  return (
    <Dialog onBackdropClick="false" open={open} onClose={handleClose} padding={2}>
      <DialogTitle id="simple-dialog-title">
        {existOfficer === undefined ? 'Add Company Officer' : 'Edit Company Officer'}
        <img
          src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
          style={closeImg}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              // options={userData}
              // getOptionLabel={(option) =>
              //   option.first_name +
              //   " (" +
              //   option.identification_name +
              //   " - " +
              //   option.identification_value +
              //   ")"
              // }
              defaultValue={
                existOfficer === undefined
                  ? null
                  : userData.find((v) => v.user_id === existOfficer.user_id)
              }
              id="auto-complete"
              autoComplete
              includeInputInList
              onChange={handleChangeUser}
              //   value={props.user}
              disabled={existOfficer}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Officer"
                  margin="normal"
                  error={errorMsgUser !== '' ? true : false}
                  helperText={errorMsgUser}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={companyroleerror}
              required
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-label">Company Roles</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={currencyvalue}
                onChange={handleChangeCompanyRole}
                error={companyroleerror}
                // defaultValue={1}
                defaultValue={
                  existOfficer === undefined
                    ? null
                    : companyRoles?.find((v) => v.company_role_id === existOfficer.company_role)
                        .company_role_id
                }
              >
                {companyRoles?.map((element) => (
                  <MenuItem value={element.company_role_id}>{element.role_name}</MenuItem>
                ))}
              </Select>
              <FormHelperText hidden={!companyroleerror} error>
                Please select.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="date"
              label="Date of Appointment"
              type="date"
              error={errorMsgDateAppointment !== '' ? true : false}
              helperText={errorMsgDateAppointment}
              onChange={handleChangeDateAppointment}
              defaultValue={
                // "2017-05-24"
                existOfficer === undefined
                  ? null
                  : moment(existOfficer.date_appointment).format('YYYY-MM-DD')
              }
              //   onInput={(e) => setWefdatevalue(e.target.value)}
              // defaultValue={moment().format("YYYY-MM-DD")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              onChange={handleChangeNominee}
              defaultValue={
                existOfficer === undefined
                  ? null
                  : userData.find((v) => v.user_id === existOfficer.nominee_id)
              }
              //   value={props.user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nominee"
                  margin="normal"
                  //   error={errorMsgActivity1 !== "" ? true : false}
                  //   helperText={errorMsgActivity1}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              onChange={handleChangeNominator}
              defaultValue={
                existOfficer === undefined
                  ? null
                  : userData.find(
                      (v) => v.user_id === existOfficer.nominator_id
                    )
              }
              //   value={props.user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nominator"
                  margin="normal"
                  //   error={errorMsgActivity1 !== "" ? true : false}
                  //   helperText={errorMsgActivity1}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              onChange={handleChangeController}
              //   value={props.user}
              defaultValue={
                existOfficer === undefined
                  ? null
                  : userData.find(
                      (v) => v.user_id === existOfficer.controller_id
                    )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Controller"
                  margin="normal"
                  //   error={errorMsgActivity1 !== "" ? true : false}
                  //   helperText={errorMsgActivity1}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              {...defaultProps}
              id="auto-complete"
              autoComplete
              includeInputInList
              onChange={handleChangeSecretary}
              //   value={props.user}
              defaultValue={
                existOfficer === undefined
                  ? null
                  : userData.find(
                      (v) => v.user_id === existOfficer.named_secretary_id
                    )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Named Secretary"
                  margin="normal"
                  //   error={errorMsgActivity1 !== "" ? true : false}
                  //   helperText={errorMsgActivity1}
                />
              )}
            />
          </Grid>
          */}
          <Grid item xs={12}>
            <FormControl
              // error={sharetypeerror}
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-label">Cessation Types</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //   value={currencyvalue}
                onChange={handleChangeCessation}
                //   error={sharetypeerror}
                // defaultValue={
                //   existOfficer === undefined
                //     ? null
                //     : cessationTypes.find(
                //         (v) =>
                //           v.cessation_type_id === existOfficer.cessation_type
                //       ).cessation_type_id
                // }
                defaultValue={existOfficer === undefined ? null : existOfficer.cessation_type}
              >
                {cessationTypes?.map((element) => (
                  <MenuItem value={element.cessation_type_id}>
                    {element.cessation_type_name}
                  </MenuItem>
                )) || null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="date"
              label="Date of Cessation"
              type="date"
              onChange={handleChangeDateCessation}
              defaultValue={
                // "2017-05-24"
                existOfficer === undefined
                  ? null
                  : moment(existOfficer.date_cessation).format('YYYY-MM-DD')
              }
              //   onInput={(e) => setWefdatevalue(e.target.value)}
              // defaultValue={moment().format("YYYY-MM-DD")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isEdit && (
          <Button
            variant="contained"
            onClick={() => {
              if (handleRemoveOfficer) {
                handleRemoveOfficer(existOfficer);
              }
            }}
          >
            Remove
          </Button>
        )}
        <Button
          onClick={() => {
            console.log('HandelSubmit', userValue, companyRoleValue, dateAppointmentValue);
            if (userValue === undefined) {
              setErrorMsgUser(errorMsg);
            } else {
              setErrorMsgUser('');
              console.log('user', userValue);
            }
            if (companyRoleValue === undefined) {
              setCompanyroleerror(true);
            } else {
              setErrorMsgUser(false);
            }

            if (dateAppointmentValue === undefined) {
              setErrorMsgDateAppointment(errorMsg);
            } else {
              setErrorMsgDateAppointment('');
            }

            if (secretaryValue === undefined) {
              setSecretaryvalue(nullValue);
            }

            if (controllerValue === undefined) {
              setControllervalue(nullValue);
            }

            if (nominatorValue === undefined) {
              setNominatorvalue(nullValue);
            }

            if (nomineeValue === undefined) {
              setNomineevalue(nullValue);
            }
            console.log('submitCompanyOfficer', userCompanyRoleId);
            if (
              userValue !== undefined &&
              companyRoleValue !== undefined &&
              dateAppointmentValue !== undefined
            ) {
              handleSubmit({
                is_edit: isEdit,
                user_id: userValue.user_id,
                user_firstname: userValue.first_name,
                user_idtype: userValue.identification_name,
                user_idvalue: userValue.identification_value,
                date_appointment: dateAppointmentValue,
                company_role: companyRoleValue,
                named_secretary_id: secretaryValue?.user_id || null,
                named_secretary_name: secretaryValue?.first_name || null,
                nominee_id: nomineeValue.user_id,
                nominee_name: nomineeValue.first_name,
                nominator_id: nominatorValue.user_id,
                nominator_name: nominatorValue.first_name,
                controller_id: controllerValue.user_id,
                controller_name: controllerValue.first_name,
                cessation_type: typeCessationValue,
                date_cessation: dateCessationValue,
                user_company_role_id: userCompanyRoleId,
              });
              handleReset();
            }
          }}
          //   onClick={handleClose}
          fullWidth
          variant="contained"
          size="medium"
          color="secondary"
        >
          SUBMIT
        </Button>
      </DialogActions>
      {/* <div>Shareh
      olderDialog</div> */}
      {/* <Form handleClose={handleClose} /> */}
    </Dialog>
  );
};

export default CompanyOfficerDialog;
