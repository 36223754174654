import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  fontWeightBold: {
    fontWeight: "bold",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));
