import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
  Typography,
  TextField,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSnackbar } from 'notistack';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import orderSvc from '../../services/order.service';
import requestsApi from '../../services/requests.service';
import { useUserState } from '../../context/UserContext';
import FormFieldContainer from '../../components/CustomFormFields/FormFieldContainer';

import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
// styles
import useStyles from './styles';

const defaultRefundModal = {
  show: false,
  data: null,
};
const refundStatus = 'Request For Refund';
const order_status_paid = 'PAID';
const order_status_refunded = 'REFUNDED';
const order_status_completed = 'COMPLETED';

export default function OrderItemsByProductCategory(props) {
  const { productCategoryId, title } = props;
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useUserState();
  let userId = user?.userId;

  const [isLoading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [refundModal, setRefundModal] = useState(defaultRefundModal);
  const [refundRemark, setRefundRemark] = useState('');

  const fetchOrderItems = async () => {
    setLoading(true);
    let ordersRes = await orderSvc.orders();
    let orders = ordersRes?.length > 0 ? ordersRes : [];
    let newOrderItems = [];
    if (productCategoryId != undefined) {
      orders = orders.filter((anItem) => {
        let found = false;
        let orderItems = anItem?.items || [];
        orderItems = orderItems.filter((anOrderItem) => {
          return (
            anOrderItem?.product?.product_category_id == productCategoryId &&
            anOrderItem?.status != order_status_refunded
          );
        });
        newOrderItems = [...newOrderItems, ...orderItems];
        if (orderItems?.length > 0) {
          found = true;
        }
        return found;
      });
    }
    setOrderList(newOrderItems);
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  const handleRefundModalClose = () => {
    setRefundModal(defaultRefundModal);
    setRefundRemark('');
  };

  const handleRequestOrderItemRefund = async (value) => {
    if (value?.id && userId) {
      if (window.confirm('Confirm request for refund for this item?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, refundStatus, userId);
          // if (value.company_application_id) {
          //   await requestsApi.updateRequestStatus(value.company_application_id, 17);
          // }
          fetchOrderItems();
          enqueueSnackbar(`Successfully requested for refund`, { variant: 'success' });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to request for refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleCancelRequestRefund = async (value) => {
    let remark = 'Cancel Request for Refund' + (refundRemark ? ` - ${refundRemark}` : '');
    if (value?.id && userId) {
      if (window.confirm('Confirm to cancel this request?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_paid, userId, remark);
          // if (value.company_application_id) {
          //   await requestsApi.updateRequestStatus(value.company_application_id, 10);
          // }
          fetchOrderItems();
          enqueueSnackbar(`Request cancelled`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to cancel`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleRejectRequestRefund = async (value) => {
    let remark = 'Reject Request for Refund' + (refundRemark ? ` - ${refundRemark}` : '');

    if (value?.id && userId) {
      if (window.confirm('Confirm to reject this request?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_paid, userId, remark);
          // if (value.company_application_id) {
          //   await requestsApi.updateRequestStatus(value.company_application_id, 10);
          // }
          fetchOrderItems();
          enqueueSnackbar(`Request rejected`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to reject`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleRefunded = async (value) => {
    let remark = 'Refunded' + (refundRemark ? ` - ${refundRemark}` : '');

    if (value?.id && userId) {
      if (window.confirm('Confirm this item is refunded?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_refunded, userId, remark);
          // if (value.company_application_id) {
          //   await requestsApi.updateRequestStatus(value.company_application_id, 16);
          // }
          fetchOrderItems();
          enqueueSnackbar(`Item Refunded`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const handleCompleted = async (value) => {
    if (value?.id && userId) {
      if (window.confirm('Confirm this item is refunded?')) {
        setLoadingSubmit(true);

        try {
          await orderSvc.updateOrderItemStatus(value?.id, order_status_completed, userId);
          // if (value.company_application_id) {
          //   await requestsApi.updateRequestStatus(value.company_application_id, 16);
          // }
          fetchOrderItems();
          enqueueSnackbar(`Item Refunded`, { variant: 'success' });
          handleRefundModalClose();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to refund`, {
            variant: 'error',
          });
        }
        setLoadingSubmit(false);
      }
    }
  };

  const orderItemColumns = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'DATE',
      id: 'created_at',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy`);
      },
    },
    {
      label: 'NAME',
      id: 'product',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text?.name || '-'}</Box>;
      },
    },
    {
      label: 'PRICE',
      id: 'price',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    {
      label: 'QUANTITY',
      id: 'quantity',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    {
      label: 'STATUS',
      id: 'status',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: '',
      id: 'action',
      render: (_, data) => {
        let isCompleted = data?.status == order_status_completed;
        let isRequestedForRefund = data?.status == refundStatus;
        let isOrderItemRefunded = data?.status == order_status_refunded;

        let refundButton = null;
        if (!isCompleted) {
          if (isRequestedForRefund) {
            refundButton = (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setRefundModal({
                    show: true,
                    data: data,
                  });
                }}
              >
                Refund
              </Button>
            );
          } else {
            if (!isOrderItemRefunded) {
              refundButton = (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleRequestOrderItemRefund(data);
                  }}
                >
                  Request for Refund
                </Button>
              );
            }
          }
        }
        return (
          <>
            {isCompleted ? null : (
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      handleCompleted(data);
                    }}
                  >
                    Completed
                  </Button>
                </Box>
                {refundButton ? <Box>{refundButton}</Box> : null}
              </Box>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title={title || 'Orders'} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <CustomTable
                  rowKey="id"
                  columns={orderItemColumns}
                  data={orderList}
                  //   renderRow={(rowData) => {
                  //     return (
                  //       <CustomTable
                  //         rowKey="id"
                  //         columns={orderItemColumns}
                  //         data={rowData?.items || []}
                  //       />
                  //     );
                  //   }}
                />
              </>
            )}
          </Widget>
        </Grid>
      </Grid>
      <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomModal
        show={refundModal.show}
        onHide={handleRefundModalClose}
        title={
          <Typography variant="h5">
            {refundModal?.data?.product?.name} (Req No: {refundModal?.data?.company_application_id})
          </Typography>
        }
        scrollable={false}
      >
        <Box>
          <Box mb={2}>
            <FormFieldContainer label="Remark">
              <TextField
                placeholder="Optional"
                multiline
                rows={4}
                variant="outlined"
                fullWidth={true}
                value={refundRemark}
                onChange={(e) => {
                  setRefundRemark(e.target.value);
                }}
              />
            </FormFieldContainer>
          </Box>
          <Box display="flex">
            <Box mr={2}>
              <Button
                variant="contained"
                onClick={() => {
                  handleCancelRequestRefund(refundModal?.data);
                }}
              >
                Cancel Request
              </Button>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                handleRejectRequestRefund(refundModal?.data);
              }}
            >
              Reject
            </Button>
            <Box ml={'auto'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleRefunded(refundModal?.data);
                }}
              >
                Refunded
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
