import axios from 'axios';
import { Cookies } from 'react-cookie';

let companyURL = process.env.REACT_APP_COMPANY_URL;
let companyURLV2 = `${process.env.REACT_APP_API_V2_URL}/company`;

const customAxios = axios.create({
  baseURL: companyURLV2,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

customAxios.interceptors.request.use((config) => {
  let accessToken = new Cookies().get('accessToken');
  config.headers['Authorization'] = `Bearer ${accessToken}`;
  return config;
});

const createCompany = (data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return customAxios.post(`/companies/`, data);
};

const getCompanies = (filterData, pageData, pageLimitData) => {
  return customAxios.get(
    `/companies?filter=` + filterData + `&page=` + pageData + `&page_limit=` + pageLimitData
  );
};

const getCompaniesByUserId = (userId) => {
  return customAxios.get(`/companies/user/${userId}`);
};

const getCompanyDetails = (companyId) => {
  return customAxios.get(`/companies/` + companyId);
};
const updateCompanyDetails = (companyId, data) => {
  console.log('updateCompanyDetails', data);
  return customAxios.put(`/companies/` + companyId, data);
};

const addCompanyOfficer = (companyId, data) => {
  return customAxios.post(`/companies/` + companyId + `/officers`, data);
};

const removeCompanyOfficer = (companyId, officerId) => {
  return customAxios.delete(`/companies/${companyId}/officers/${officerId}`);
};

const getCompanyOfficers = (companyId) => {
  return customAxios.get(`/companies/` + companyId + `/officers`);
};

const updateCompanyOfficer = (companyId, officerID, data) => {
  return customAxios.put(`/companies/` + companyId + `/officers/` + officerID, data);
};

const getOnboardProfiles = (filterData, pageData, pageLimitData) => {
  return customAxios.get(
    `/onboardprofiles?filter=` + filterData + `&page=` + pageData + `&page_limit=` + pageLimitData
  );
};
const getOnboardProfileById = (id) => {
  return customAxios.get(`/onboardprofiles/${id}`);
};

const getOnboardProfilesByStatusId = (id) => {
  return customAxios.get(`/onboardprofiles/status/${id}`);
};
const updateOnboardProfile = (id, body, pageLimitData) => {
  return customAxios.put(`/onboardprofiles/${id}`, body);
};

const updateCompanyStatus = (companyId, statusId, body = {}) => {
  return customAxios.put(`/companies/publish-status/` + companyId, {
    ...body,
    publish_status_id: statusId,
  });
};

const updateCompanyClientType = (companyId, statusId) => {
  return customAxios.put(`/companies/client-type/` + companyId, {
    company_client_type_id: statusId,
  });
};

const getCompanyAuditorsByCompanyId = (id) => {
  // return customAxios.get(`/companyauditors/company/${id}?type=inactive`);
  return customAxios.get(`/companyauditors/company/${id}`);
};
const createCompanyAuditor = (data) => {
  return customAxios.post(`/companyauditors`, data);
};
const updateCompanyAuditor = (id, data = {}) => {
  return customAxios.put(`/companyauditors/${id}`, data);
};
const removeCompanyAuditor = (id, deleted_by) => {
  return customAxios.delete(`/companyauditors/${id}`, {
    data: { deleted_by: deleted_by },
  });
};

const getOnboardProfilesPublic = (filterData, pageData, pageLimitData) => {
  return customAxios.get(`/public-onboardprofiles`);
};
const getOnboardProfilesPublicById = (id) => {
  return customAxios.get(`/public-onboardprofiles/${id}`);
};
const updateOnboardProfilesPublicById = (id, body = {}) => {
  return customAxios.put(`/public-onboardprofiles/${id}`, body);
};
export default {
  createCompany,
  addCompanyOfficer,
  removeCompanyOfficer,
  getCompanyOfficers,
  updateCompanyOfficer,
  getCompanies,
  getCompaniesByUserId,
  getCompanyDetails,
  updateCompanyDetails,
  getOnboardProfiles,
  getOnboardProfileById,
  getOnboardProfilesByStatusId,
  updateOnboardProfile,
  updateCompanyStatus,
  updateCompanyClientType,
  getCompanyAuditorsByCompanyId,
  createCompanyAuditor,
  updateCompanyAuditor,
  removeCompanyAuditor,
  getOnboardProfilesPublic,
  getOnboardProfilesPublicById,
  updateOnboardProfilesPublicById,
};
