import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Button,
  Grid,
  MenuItem,
  Select,
  Toolbar,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Snackbar from '@material-ui/core/Snackbar';
// import "material-ui-phone-number/styles.css";
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import PageTitle from '../../components/PageTitle/PageTitle';
import { Typography } from '../../components/Wrappers/Wrappers';
import addressApi from '../../services/address.service';
import businessApi from '../../services/business.service';
import commonApi from '../../services/common.service';
import companyApi from '../../services/company.service';
import documentApi from '../../services/document.service';
import shareApi from '../../services/share.service';
import shareholderApi from '../../services/shareholder.service';
import userApi from '../../services/user.service';
import fileUploader from '../../utility/fileuploader';
import getFileName from '../../utility/getfilename';
import CompanyOfficerDialog from './components/Dialog/CompanyOfficerDialog';
import { useUserState } from '../../context/UserContext';
import useCommonOptions from '../../hooks/useCommonOptions';
import CustomUploadButton from '../../components/CustomFormFields/CustomUploadButton';
import FormFieldContainer from '../../components/CustomFormFields/FormFieldContainer';
import useQueryParams from '../../hooks/useQueryParams';

// dialog
import ShareholderDialog from './components/Dialog/ShareholderDialog';
// import Autocomplete from "@material-ui/lab/Autocomplete";
import CompanyOfficerTable from './components/Table/CompanyOfficerTable';
import ShareholderTable from './components/Table/ShareholderTable';
import SuperAdminTable from './components/Table/SuperAdminTable';

import {
  AuditorCompanyTable,
  AuditorCompanyModalForm,
} from '../companyform/components/forms/CompanyAuditor';
import {
  CompanyAddressTable,
  CompanyAddressModalForm,
} from '../companyform/components/forms/CompanyAddress';
import CompanyDocumentsGroupItem from '../companyform/components/forms/CompanyDocumentsGroupItem';
import { defaultDocumentGroups } from '../../constants/documentGroups';
import { CompanyTerminationModalForm } from './components/CompanyTermination';

// styles
import useStyles from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const fields_companyDetail = {
  // Company Details
  company_client_type_id: {
    name: 'company_client_type_id',
    label: 'Company Category',
  },
  company_registration_no: {
    name: 'company_registration_no',
    label: 'Registered Number',
  },
  company_name: {
    name: 'company_name',
    label: 'Company Name',
  },
  former_name: {
    name: 'former_name',
    label: 'Former Name (if any)',
  },
  name_effective_from: {
    name: 'name_effective_from',
    label: 'wef',
  },
  incorporation_date: {
    name: 'incorporation_date',
    label: 'Incorporation Date',
  },
  company_type_id: {
    name: 'company_type_id',
    label: 'Company Type',
  },
  company_status_id: {
    name: 'company_status_id',
    label: 'Status',
  },
  status_date: {
    name: 'status_date',
    label: 'Status Date',
  },
  country_of_incorporation_id: {
    name: 'country_of_incorporation_id',
    label: 'Country of Incorporation',
  },
  fye_date: {
    name: 'fye_date',
    label: 'Next Financial Year End Date',
  },
  first_fye_date: {
    name: 'first_fye_date',
    label: 'First Financial Year End Date',
  },
};

const client_company_id = 1;
const client_shareholder_company_id = 2;
const client_auditor_company_id = 3;

const Companyform = ({ refetchCompany }) => {
  var classes = useStyles();
  var theme = useTheme();
  let history = useHistory();
  let query = useQueryParams();
  const { userId: authUserId } = useUserState();
  const selectedCompanyId = parseInt(query.get('company'));

  const { enqueueSnackbar } = useSnackbar();
  const [errorApiMsg, setErrorApiMsg] = useState('');
  const [refreshShareKey, setRefreshShareKey] = useState(0);

  const [editShareholderId, setEditShareholderId] = useState();
  const [editOfficerId, setEditOfficerId] = useState();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const errorMsg = 'Please fill up.';
  const errorSelectMsg = 'Please select.';

  const [isLoadingbtn, setLoadingBtn] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [loadingSubmitSection, setLoadingSubmitSection] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // datatable
  const [arrShareholders, setArrShareholders] = useState([]);
  const [arrCompanyofficers, setArrCompanyofficers] = useState([]);
  const [arrShareData, setArrShareData] = useState([]);
  const [sysInfoUserRolesByCompany, setSysInfoUserRolesByCompany] = useState([]);

  const [selectedNewAdmin, setSelectedNewAdmin] = useState();

  // common
  const [countrylist, setCountrylist] = useState();
  const [companytypelist, setCompanytypelist] = useState();
  const [ssiclist, setSsiclist] = useState();
  const [currencylist, setCurrencylist] = useState();
  const [sharetypelist, setSharetypelist] = useState();
  const [addresstypelist, setAddresstypelist] = useState();
  const [companyrolelist, setCompanyrolelist] = useState();
  const [companystatuslist, setCompanystatuslist] = useState();
  const [userList, setUserlist] = useState();
  const [cessationtypeList, setCessationtypeList] = useState();
  const [identificationypeList, setIdentificationtypeList] = useState();
  const [companyList, setCompanylist] = useState();

  // input fields
  // company name
  const [companyCategoryId, setCompanyCategoryId] = useState(client_company_id);
  const [companyDetail, setCompanyDetail] = useState();
  const [companynamevalue, setCompanynamevalue] = useState();
  const [registerednumbervalue, setRegisterednumbervalue] = useState();
  //   const [nameeffectivefromvalue, setNameeffectivefromvalue] = useState();
  //   const [formernamevalue, setFormernamevalue] = useState();
  const isPublished = companyDetail?.publish_status_id == 3;
  const isShareholderCompany = Boolean(
    companyCategoryId == client_shareholder_company_id ||
      companyCategoryId == client_auditor_company_id
  );

  const [errorMsgRegisteredNumber, setErrorMsgRegisteredNumber] = useState('');
  const [errorMsgCompanyName, setErrorMsgCompanyName] = useState('');
  const [formercompanynamevalue, setFormercompanynamevalue] = useState();
  const [wefdatevalue, setWefdatevalue] = useState();

  const [companytypevalue, setCompanytypevalue] = useState();
  let isPublicCompanyLimitedByGuarantee = companytypevalue == 7;
  let dynamicRequireCompanyType = companytypevalue != 7;

  const [companytypeerror, setCompanytypeerror] = useState();

  const [companystatusvalue, setCompanystatusvalue] = useState();
  const [companystatuserror, setCompanystatuserror] = useState();

  const [statusdatevalue, setStatusdatevalue] = useState();
  const [errorMsgStatusDate, setErrorMsgStatusDate] = useState('');

  const [incorporationdatevalue, setIncorporationdatevalue] = useState();
  const [errorMsgIncorporationDate, setErrorMsgIncorporationDate] = useState('');

  const [incorporationcountryvalue, setIncorporationcountryvalue] = useState();
  const [incorporationcountryerror, setIncorporationcountryerror] = useState();

  const [firstFyeDateValue, setFirstFyeDateValue] = useState();
  const [errorMsgFirstFyeDateValue, setErrorMsgFirstFyeDateValue] = useState('');
  const [fyeDateValue, setFyeDateValue] = useState();
  const [defaultFyeDateValue, setDefaultFyeDateValue] = useState();
  const [errorMsgFyeDateValue, setErrorMsgFyeDateValue] = useState('');

  let incorporation_date = incorporationdatevalue;
  let minDate_fye;
  let maxDate_first_fye;
  let minDate_today = moment().add(1, 'days').format('YYYY-MM-DD');
  if (incorporation_date) {
    minDate_fye = moment(incorporation_date).format('YYYY-MM-DD');
    maxDate_first_fye = moment(incorporation_date).add(18, 'months').format('YYYY-MM-DD');
  }

  const [disableCompanyDetails, setDisableCompanyDetails] = useState(true);

  // Activity
  const [businessActivityId, setBusinessactivityid] = useState();
  const [errorMsgActivity1, setErrorMsgActivity1] = useState('');
  const [activity1value, setActivity1value] = useState();
  const [activity1desc, setActivity1desc] = useState();
  const [activity2value, setActivity2value] = useState();
  const [activity2desc, setActivity2desc] = useState();
  const [disablePrincipalActivities, setDisablePrincipalActivities] = useState(true);

  // Address Details
  const [addressid, setAddressid] = useState();
  const [companyaddressid, setCompanyaddressid] = useState();
  const [addresstypevalue, setAddresstypevalue] = useState();
  const [addresstypeerror, setAddresstypeerror] = useState(false);
  const [addresscountryvalue, setAddresscountryvalue] = useState();
  const [addresscountryerror, setAddresscountryerror] = useState(false);
  const [addresspostalvalue, setAddresspostalvalue] = useState();
  const [errorMsgaddresspostal, setErrorMsgaddresspostal] = useState('');
  const [addressroadvalue, setAddressroadvalue] = useState();
  const [errorMsgaddressroad, seterrorMsgaddressroad] = useState('');
  const [addressline2value, setAddressline2value] = useState();
  const [errorMsgaddressline2, setErrorMsgaddressline2] = useState('');
  const [addressunitvalue, setAddressunitvalue] = useState(null);
  const [disableAddress, setDisableAddress] = useState(true);

  // doc
  const ref_certificateOfIncorporation = useRef();
  const ref_bizFile = useRef();
  const ref_annualReport = useRef();
  const ref_auditedReport = useRef();
  const ref_constitutionalDocument = useRef();
  const ref_managementAccounts = useRef();

  // 18,Certificate of Incorporation	18	2	company id
  // 15,Biz File	15	2	company id
  // 5,Annual Reports	5	2	company id
  // 12,Audited Report	12	2	company id
  // 28,Constitutional Documents	28	2	company id
  // 57,Management Accounts	57	2	company id

  const [bizFileDocView, setBizFileDocView] = useState();
  const [certificateIncorporationDocView, setCertificateIncorporationDocView] = useState();
  const [annualReportDocView, setAnnualReportDocView] = useState();
  const [auditedReportDocView, setAuditedReportDocView] = useState();
  const [constitutionalDocumentView, setConstitutionalDocumentView] = useState();
  const [managementAccountsView, setManagementAccountsView] = useState();
  const [disableDocuments, setDisableDocuments] = useState(true);

  // share

  const [issuedShareCapitals, setIssuedShareCapitals] = useState([]);
  const [amountsharevalue, setAmountsharevalue] = useState();
  const [errorMsgAmountShare, setErrorMsgAmountShare] = useState('');
  const [sharecurrencyvalue, setSharecurrencyvalue] = useState();
  const [sharecurrencyerror, setSharecurrencyerror] = useState();
  const [sharecapitalid, setSharecapitalid] = useState();

  const [sharetypevalue, setSharetypevalue] = useState();
  const [sharetypeerror, setSharetypeerror] = useState();

  const [numbersharevalue, setNumbersharevalue] = useState();
  const [errorMsgNumberShare, setErrorMsgNumberShare] = useState('');
  const [errorMsgPaidupAmount, setErrorMsgPaidupAmount] = useState('');
  const [paidupamountvalue, setPaidupamountvalue] = useState();
  //   number_of_shares: preferencenumbersharevalue,
  //   issued_share_capital: preferenceamountsharevalue,
  //   paid_up_capital: preferencepaidupamountvalue,
  //   currency_id: preferencesharecurrencyvalue,
  const [preferencesharecapitalid, setPreferencesharecapitalid] = useState();
  const [preferencesharetypevalue, setPreferencesharetypevalue] = useState();
  const [preferencenumbersharevalue, setPreferencenumbersharevalue] = useState();
  const [preferenceamountsharevalue, setPreferenceamountsharevalue] = useState();
  const [preferencepaidupamountvalue, setPreferencepaidupamountvalue] = useState();
  const [preferencesharecurrencyvalue, setPreferencesharecurrencyvalue] = useState();

  const [disableShares, setDisableShares] = useState(true);
  const [requirePreferenceshare, setRequirePreferenceshare] = useState(false);
  //   number_of_shares: othernumbersharevalue,
  //   issued_share_capital: otheramountsharevalue,
  //   paid_up_capital: otherpaidupamountvalue,
  //   currency_id: othersharecurrencyvalue,
  const [othersharecapitalid, setOthersharecapitalid] = useState();
  const [othernumbersharevalue, setOthernumbersharevalue] = useState();
  const [otheramountsharevalue, setOtheramountsharevalue] = useState();
  const [otherpaidupamountvalue, setOtherpaidupamountvalue] = useState();
  const [othersharecurrencyvalue, setOthersharecurrencyvalue] = useState();
  const [othersharetypevalue, setOthersharetypevalue] = useState();
  const [requireOthershare, setRequireOthershare] = useState(false);

  const [treasurysharevalue, setTreasurysharevalue] = useState();
  const [treasurysharecapitalid, setTreasurysharecapitalid] = useState();
  const [treasuryshareid, setTreasuryshareid] = useState();
  const [disableTreasuryShare, setDisableTreasuryShare] = useState(true);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDisableCompanyDetails(true);
    // window.location.reload(false);
    // history.push({ pathname: "/dashboard" });
  };

  const [openCompanyOfficerDialog, setOpenCompanyOfficerDialog] = useState(false);
  const [openShareholderDialog, setOpenShareholderDialog] = useState(false);

  const handleOpenCompanyOfficerDialog = () => {
    setOpenCompanyOfficerDialog(true);
  };

  const handleEditOfficer = (row_id) => {
    setOpenCompanyOfficerDialog(true);
    setEditOfficerId(row_id);
  };

  const handleCloseCompanyOfficerDialog = () => {
    setOpenCompanyOfficerDialog(false);
    setArrShareData([]);
    setEditOfficerId();
  };

  const handleSubmitCompanyOfficerDialog = (data) => {
    const companyId = selectedCompanyId;

    if (data.nominee_id === 'null') {
      data.nominee_id = null;
    }
    if (data.nominator_id === 'null') {
      data.nominator_id = null;
    }
    if (data.controller_id === 'null') {
      data.controller_id = null;
    }
    if (data.named_secretary_id === 'null') {
      data.named_secretary_id = null;
    }
    if (data.cessation_type === 'null') {
      data.cessation_type = null;
    }
    if (data.date_cessation === 'null') {
      data.date_cessation = null;
    }
    const fetchData = async () => {
      const companyofficerData = {
        user_id: data.user_id,
        company_id: companyId,
        company_role_id: data.company_role,
        date_of_appointment: data.date_appointment,
        nominee_id: data.nominee_id,
        nominator_id: data.nominator_id,
        controller_id: data.controller_id,
        named_secretary_id: data.named_secretary_id,
        cessation_type_id: data.cessation_type,
        cessation_date: data.date_cessation,
      };

      if (data.is_edit) {
        console.log(
          'handleCloseCompanyOfficerDialog_after',
          data.user_company_role_id,
          companyofficerData
        );
        try {
          const respCompanyOfficer = await companyApi.updateCompanyOfficer(
            selectedCompanyId,
            data.user_company_role_id,
            companyofficerData
          );
          await fetchCompanyOfficers();
          console.log('edit respCompanyOfficer', respCompanyOfficer);
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Update Officer`, {
            variant: 'error',
          });
        }
      } else {
        console.log('handleCloseCompanyOfficerDialog_after', companyofficerData);

        try {
          const respCompanyOfficer = await companyApi.addCompanyOfficer(
            selectedCompanyId,
            companyofficerData
          );
          await fetchCompanyOfficers();

          console.log('add respCompanyOfficer', respCompanyOfficer);
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Officer`, {
            variant: 'error',
          });
        }
        // if (respCompanyOfficer?.data?.is_success) {
        //   let foundUser = userList.find(
        //     (aUser) => aUser.user_id == companyofficerData.user_id
        //   );
        //   if (foundUser && foundUser.email) {
        //     let sysInfoUserRes = await userApi.getSysInfoUserByEmail(
        //       foundUser.email
        //     );

        //     if (
        //       sysInfoUserRes?.data?.is_success &&
        //       sysInfoUserRes?.data?.users?.user_id
        //     ) {
        //       let sysInfoUserId = sysInfoUserRes?.data?.users?.user_id;
        //       let sysInfoUserRolesRes = await userApi.getSysInfoUserRoleByCompanyId(
        //         companyId
        //       );
        //       if (
        //         sysInfoUserRolesRes?.data?.is_success &&
        //         sysInfoUserRolesRes?.data?.users?.length >= 0
        //       ) {
        //         let foundSysInfoUserRoles = sysInfoUserRolesRes?.data?.users;
        //         let foundSysInfoUserRoleWithCompanyId = foundSysInfoUserRoles.find(
        //           (aUser) => {
        //             return (
        //               aUser?.sysinfo_role_username ==
        //               sysInfoUserRes?.data?.users?.email
        //             );
        //           }
        //         );
        //         if (!foundSysInfoUserRoleWithCompanyId) {
        //           // create user role
        //           await userApi.createSysInfoUserRole({
        //             username: sysInfoUserRes?.data?.users?.email,
        //             role_id: 1,
        //             company_id: companyId,
        //             sysinfo_user_id: sysInfoUserId,
        //             user_id: parseInt(authUserId),
        //             created_by: parseInt(authUserId),
        //           });
        //         }
        //       }
        //     }
        //   }
        // }
      }
    };
    fetchData().finally(() => setOpenCompanyOfficerDialog(false));
  };

  const handleRemoveOfficer = async (value) => {
    if (window.confirm('Confirm Remove Officer?')) {
      try {
        const respCompanyOfficer = await companyApi.removeCompanyOfficer(
          selectedCompanyId,
          value?.user_company_role_id
        );
        await fetchCompanyOfficers();
        setOpenCompanyOfficerDialog(false);
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Officer`, {
          variant: 'error',
        });
      }
    }
  };

  // const handleSubmitEditCompanyOfficerDialog = (data) => {
  //   console.log("edit", arrCompanyofficers);
  //   console.log("handleCloseCompanyOfficerDialog", data);
  //   // if (!data.length) {
  //   //   setArrCompanyofficers((arrCompanyofficers) => [
  //   //     ...arrCompanyofficers,
  //   //     data,
  //   //   ]);
  //   //   console.log("arrCompanyofficers_af", arrCompanyofficers);
  //   // }
  //   setOpenCompanyOfficerDialog(false);
  // };

  const [auditorCompanyForm, setAuditorCompanyForm] = useState({
    show: false,
    data: null,
  });
  const [auditorCompanies, setAuditorCompanies] = useState([]);

  const fetchAuditorCompanies = async () => {
    const res = await companyApi.getCompanyAuditorsByCompanyId(selectedCompanyId);
    let data = res?.data?.is_success ? res?.data?.company_auditors : [];
    setAuditorCompanies(data);
  };

  const handleEditAuditorCompanyForm = async (values) => {
    //   {
    //     "client_company_id": 31,
    //     "auditor_company_id": 94,
    //     "appointment_date": "2022-04-01",
    //     "cessation_type_id": null,
    //     "cessation_date": null
    // }

    // update
    if (auditorCompanyForm?.data && values?.company_auditor_id) {
      try {
        await companyApi.updateCompanyAuditor(values?.company_auditor_id, {
          client_company_id: values?.client_company_id || selectedCompanyId,
          auditor_company_id: values?.auditor_company_id,
          appointment_date: values?.appointment_date,
          cessation_type_id: values?.cessation_type_id || null,
          cessation_date: values?.cessation_date || null,
        });
        await fetchAuditorCompanies();
        setAuditorCompanyForm({
          show: false,
          data: null,
        });
      } catch (error) {
        console.log('Update Auditor Company error', error?.response?.data?.error?.message);
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Auditor Company`, {
          variant: 'error',
        });
        // return;
      }
    }
    // create
    else {
      try {
        await companyApi.createCompanyAuditor({
          ...values,
          client_company_id: selectedCompanyId,
          created_by: parseInt(authUserId),
        });
        await fetchAuditorCompanies();
        setAuditorCompanyForm({
          show: false,
          data: null,
        });
      } catch (error) {
        console.log('Add Auditor Company error', error?.response?.data?.error?.message);
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Auditor Company`, {
          variant: 'error',
        });
        // return;
      }
    }
  };

  const handleRemoveAuditorCompany = async (value) => {
    console.log('handleRemoveAuditorCompany', value);
    if (window.confirm('Confirm Remove?')) {
      let companyAuditorId = value?.company_auditor_id;
      if (companyAuditorId) {
        try {
          await companyApi.removeCompanyAuditor(companyAuditorId, parseInt(authUserId));
          await fetchAuditorCompanies();
          setAuditorCompanyForm({
            show: false,
            data: null,
          });
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to remove Company Auditor`,
            { variant: 'error' }
          );
        }
      } else {
        enqueueSnackbar('Company Auditor Not Found', { variant: 'error' });
      }
    }
  };

  const getArrShareData = () => {
    if (
      numbersharevalue !== undefined &&
      amountsharevalue !== undefined &&
      paidupamountvalue !== undefined &&
      sharecapitalid !== undefined &&
      sharecurrencyvalue !== undefined
    ) {
      var ShareData = {
        share_type: 1,
        share_name: 'Ordinary',
        amount: amountsharevalue,
        number: numbersharevalue,
        paidup: paidupamountvalue,
        currency: sharecurrencyvalue,
        share_capital_id: sharecapitalid,
      };
      console.log('arrShareDataaaaa', arrShareData, ShareData);
      if (arrShareData.find((v) => v.share_type === 1)) {
        arrShareData.forEach((element, index) => {
          console.log('arrShareData-foreacg', element, index);
          if (element.share_type === ShareData.share_type) {
            console.log('replacing', element.share_type);
            arrShareData[index] = ShareData;
            console.log('replaced');
          }
        });
      } else {
        setArrShareData((arrShareData) => [...arrShareData, ShareData]);
      }
    }
    if (
      preferencenumbersharevalue !== undefined &&
      preferenceamountsharevalue !== undefined &&
      preferencepaidupamountvalue !== undefined &&
      preferencesharecapitalid !== undefined &&
      preferencesharecurrencyvalue !== undefined
    ) {
      var preferenceShareData = {
        share_type: 2,
        share_name: 'Preference',
        amount: preferenceamountsharevalue,
        number: preferencenumbersharevalue,
        paidup: preferencepaidupamountvalue,
        currency: preferencesharecurrencyvalue,
        share_capital_id: preferencesharecapitalid,
      };

      if (arrShareData.find((v) => v.share_type === 2)) {
        arrShareData.forEach((element, index) => {
          console.log('arrShareData-foreacg', element, index);
          if (element.share_type === ShareData.share_type) {
            console.log('replacing', element.share_type);
            arrShareData[index] = ShareData;
            console.log('replaced');
          }
        });
      } else {
        setArrShareData((arrShareData) => [...arrShareData, preferenceShareData]);
      }
      // setArrShareData((arrShareData) => [...arrShareData, preferenceShareData]);
      console.log('arrShareDataAF', arrShareData);
    }
    if (
      othernumbersharevalue !== undefined &&
      otheramountsharevalue !== undefined &&
      otherpaidupamountvalue !== undefined &&
      othersharecapitalid !== undefined &&
      othersharecurrencyvalue !== undefined
    ) {
      var otherShareData = {
        share_type: 3,
        share_name: 'Other',
        amount: otheramountsharevalue,
        number: othernumbersharevalue,
        paidup: otherpaidupamountvalue,
        currency: othersharecurrencyvalue,
        share_capital_id: othersharecapitalid,
      };

      if (arrShareData.find((v) => v.share_type === 3)) {
        arrShareData.forEach((element, index) => {
          console.log('arrShareData-foreacg', element, index);
          if (element.share_type === ShareData.share_type) {
            console.log('replacing', element.share_type);
            arrShareData[index] = ShareData;
            console.log('replaced');
          }
        });
      } else {
        setArrShareData((arrShareData) => [...arrShareData, otherShareData]);
      }
      // setArrShareData((arrShareData) => [...arrShareData, otherShareData]);
      console.log('arrShareDataAF', arrShareData);
      console.log('othersharecapitalid', othersharecapitalid);
    }
  };

  const handleEditShareholder = (row_id) => {
    getArrShareData();
    setOpenShareholderDialog(true);
    setEditShareholderId(row_id);
  };

  const handleOpenShareholderDialog = () => {
    // setLoadingBtn(true);
    getArrShareData();
    handleCompletionShareholder();
  };

  const handleCompletionShareholder = () => {
    // setLoadingBtn(false);
    setOpenShareholderDialog(true);
  };

  const handleCloseShareholderDialog = () => {
    // setArrShareData([]);
    setOpenShareholderDialog(false);
    setEditShareholderId();
  };

  const handleSubmitShareholderDialog = (data) => {
    const startSubmit = async () => {
      const shareholderData = {
        table_primary_id: data.user_id,
        shareholder_type_id: data.user_idtype,
        share_capital_id: data.share_capital_id,
        user_allotment: parseInt(data.user_sharenumber),
      };

      if (data.is_edit) {
        try {
          const respShareholder = await shareholderApi.updateShareholder(
            selectedCompanyId,
            data.shareholder_id,
            shareholderData
          );
        } catch (error) {
          setErrorApiMsg('Edit Shareholder Error: ' + JSON.stringify(error.response.data.message));
          setOpenSnackbar(true);
          console.log(JSON.stringify(error.response));
        }
      } else {
        try {
          const respShareholder = await shareholderApi.addShareholder(
            selectedCompanyId,
            shareholderData
          );
        } catch (error) {
          setErrorApiMsg('Add Shareholder Error: ' + JSON.stringify(error.response.data.message));
          setOpenSnackbar(true);
          console.log(JSON.stringify(error.response));
        }
      }
      setRefreshShareKey((oldshareKey) => oldshareKey + 1);
    };

    startSubmit().finally(() => {
      setOpenShareholderDialog(false);
      setEditShareholderId();
    });
  };

  const handleRemoveShareholder = async (value) => {
    if (window.confirm('Confirm Remove Shareholder?')) {
      try {
        const respShareholder = await shareholderApi.removeShareholder(
          selectedCompanyId,
          value?.shareholder_id
        );
        await fetchShareholders();
        setOpenShareholderDialog(false);
        setEditShareholderId();
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Shareholder`, {
          variant: 'error',
        });
      }
    }
  };

  const getSysInfoUserRoles = async () => {
    const resSysInfoUserRoles = await userApi.getSysInfoUserRoleByCompanyId(selectedCompanyId);
    if (resSysInfoUserRoles?.data?.is_success) {
      setSysInfoUserRolesByCompany(resSysInfoUserRoles?.data?.users || []);
    } else {
      setSysInfoUserRolesByCompany([]);
    }
  };

  const fetchShareCapital = async () => {
    const respShares = await shareApi.getShareCapital(selectedCompanyId);
    let shareCapitalData = respShares?.data?.shares;
    var sharecapitalidData;
    if (shareCapitalData.length > 0) {
      setIssuedShareCapitals(shareCapitalData);
      for (var i = 0; i < shareCapitalData.length; i++) {
        // ordinary
        if (shareCapitalData[i].share_type_id === 1) {
          setSharecurrencyvalue(shareCapitalData[i].currency_id);
          setSharetypevalue(shareCapitalData[i].share_type_id);
          setAmountsharevalue(shareCapitalData[i].issued_share_capital);
          setNumbersharevalue(shareCapitalData[i].number_of_shares);
          setPaidupamountvalue(shareCapitalData[i].paid_up_capital);
          setSharecapitalid(shareCapitalData[i].share_capital_id);
          sharecapitalidData = shareCapitalData[i].share_capital_id;
          console.log(
            'shareCapitalData[i].share_capital_id',
            shareCapitalData[i].share_capital_id,
            sharecapitalid,
            sharecapitalidData
          );
        }

        // preference
        if (shareCapitalData[i].share_type_id === 2) {
          setPreferencesharetypevalue(shareCapitalData[i].share_type_id);
          setPreferenceamountsharevalue(shareCapitalData[i].issued_share_capital);
          setPreferencepaidupamountvalue(shareCapitalData[i].paid_up_capital);
          setPreferencenumbersharevalue(shareCapitalData[i].number_of_shares);
          setPreferencesharecurrencyvalue(shareCapitalData[i].currency_id);
          setPreferencesharecapitalid(shareCapitalData[i].share_capital_id);
        }

        // others
        if (shareCapitalData[i].share_type_id === 3) {
          setOthersharetypevalue(shareCapitalData[i].share_type_id);
          setOtheramountsharevalue(shareCapitalData[i].issued_share_capital);
          setOtherpaidupamountvalue(shareCapitalData[i].paid_up_capital);
          setOthernumbersharevalue(shareCapitalData[i].number_of_shares);
          setOthersharecurrencyvalue(shareCapitalData[i].currency_id);
          setOthersharecapitalid(shareCapitalData[i].share_capital_id);
        }

        // ??? value always be set to last item
        if (shareCapitalData.length === i + 1) {
          const respTreasuryShares = await shareApi.getTreasuryShare(
            selectedCompanyId,
            sharecapitalidData
          );
          if (respTreasuryShares.data.shares !== undefined) {
            setTreasurysharecapitalid(respTreasuryShares.data.shares.share_capital_id);
            setTreasuryshareid(respTreasuryShares.data.shares.treasury_share_id);
            setTreasurysharevalue(respTreasuryShares.data.shares.number_of_shares);
          }
        }
      }
    }
    return respShares;
  };

  const fetchCompanyDetails = async () => {
    const companyDetailsRes = await companyApi.getCompanyDetails(selectedCompanyId);
    let companyData = companyDetailsRes?.data?.company;
    setCompanyDetail(companyData);
    setCompanyCategoryId(companyData?.company_client_type_id);

    setRegisterednumbervalue(companyData?.company_registration_no);
    setCompanynamevalue(companyData?.company_name);
    setCompanytypevalue(companyData?.company_type_id);
    setCompanystatusvalue(companyData?.company_status_id);
    setStatusdatevalue(companyData?.status_date);
    setIncorporationdatevalue(companyData?.incorporation_date);
    let defaultFirstFyeDate = companyData?.[fields_companyDetail.first_fye_date.name];
    let defaultFyeDate = companyData?.[fields_companyDetail.fye_date.name];
    // if (defaultFyeDate && moment(defaultFyeDate).isValid()) {
    //   if (!moment(defaultFyeDate).isAfter(moment().format('YYYY-MM-DD'))) {
    //     if (defaultFirstFyeDate && moment(defaultFirstFyeDate).isValid()) {
    //       let newFye = moment(defaultFirstFyeDate).add(1, 'years');
    //       if (newFye.isAfter(moment().format('YYYY-MM-DD'))) {
    //         defaultFyeDate = newFye.format('YYYY-MM-DD');
    //       }
    //     }
    //   }
    // }
    setDefaultFyeDateValue(defaultFyeDate);
    setFirstFyeDateValue(defaultFirstFyeDate);
    setFyeDateValue(defaultFyeDate);
    setFormercompanynamevalue(companyData?.former_name);
    setWefdatevalue(companyData?.name_effective_from);
    setIncorporationcountryvalue(companyData?.country_of_incorporation_id);

    return companyDetailsRes;
  };

  const fetchBusinessActivity = async () => {
    const respBiz = await businessApi.getBusinessActivity(selectedCompanyId);
    let businessActivityData = respBiz?.data?.business_activities;
    setBusinessactivityid(businessActivityData?.business_activity_id);
    setActivity1value(businessActivityData?.primary_ssic_id);
    setActivity1desc(businessActivityData?.business_description);
    setActivity2value(businessActivityData?.secondary_ssic_id);
    setActivity2desc(businessActivityData?.secondary_business_description);

    return respBiz;
  };

  const fetchCompanyAddress = async () => {
    const respAddress = await addressApi.getCompanyAddress(selectedCompanyId);

    let companyAddressData = respAddress?.data?.companyAddresses;
    setSelectedCompanyAddresses(companyAddressData);
    for (var i = 0; i < companyAddressData.length; i++) {
      if (companyAddressData[i].is_default === 1) {
        setAddressid(companyAddressData[i].address_id);
        setCompanyaddressid(companyAddressData[i].company_address_id);
        setAddresscountryvalue(companyAddressData[i].country_id);
        setAddressline2value(companyAddressData[i].address_line_2);
        setAddresspostalvalue(companyAddressData[i].postal_code);
        setAddressunitvalue(companyAddressData[i].floor_unit_no);
        setAddresstypevalue(companyAddressData[i].address_type_id);
        setAddressroadvalue(companyAddressData[i].address_line_1);
      }
    }

    return respAddress;
  };

  const fetchDocument = async (docType, dataType, dataId) => {
    const respDocument = await documentApi.getDocumentsSTS(docType, dataType, dataId);

    let documentData = respDocument?.data?.document;

    if (docType === 15) {
      setBizFileDocView(documentData[documentData.length - 1]);
    } else if (docType === 18) {
      setCertificateIncorporationDocView(documentData[documentData.length - 1]);
    } else if (docType === 5) {
      setAnnualReportDocView(documentData[documentData.length - 1]);
    } else if (docType === 12) {
      setAuditedReportDocView(documentData[documentData.length - 1]);
    } else if (docType === 28) {
      setConstitutionalDocumentView(documentData[documentData.length - 1]);
    } else if (docType === 57) {
      setManagementAccountsView(documentData[documentData.length - 1]);
    }
  };

  const fetchCompanyOfficers = async () => {
    const respOfficers = await companyApi.getCompanyOfficers(selectedCompanyId);

    var arrExistOfficer = [];
    for (var i = 0; i < respOfficers.data.userCompanyRoles.length; i++) {
      var existOfficer = {};
      existOfficer.row_id = i;
      existOfficer.user_id = respOfficers.data.userCompanyRoles[i].user_id;
      existOfficer.user_firstname = respOfficers.data.userCompanyRoles[i].first_name;
      existOfficer.user_idtype = '-';
      existOfficer.user_idvalue = '-';
      if (respOfficers.data.userCompanyRoles[i].nric !== null) {
        existOfficer.user_nric = respOfficers.data.userCompanyRoles[i].nric;
      }
      if (respOfficers.data.userCompanyRoles[i].passport !== null) {
        existOfficer.user_passport = respOfficers.data.userCompanyRoles[i].passport;
      }
      existOfficer.company_role = respOfficers.data.userCompanyRoles[i].company_role_id;
      existOfficer.date_appointment = respOfficers.data.userCompanyRoles[i].date_of_appointment;
      existOfficer.nominee_id = respOfficers.data.userCompanyRoles[i].nominee_id;
      existOfficer.nominee_name = respOfficers.data.userCompanyRoles[i].nominee_first_name;
      existOfficer.nominator_id = respOfficers.data.userCompanyRoles[i].nominator_id;
      existOfficer.nominator_name = respOfficers.data.userCompanyRoles[i].nominator_first_name;
      existOfficer.controller_id = respOfficers.data.userCompanyRoles[i].controller_id;
      existOfficer.controller_name = respOfficers.data.userCompanyRoles[i].controller_first_name;
      existOfficer.named_secretary_id = respOfficers.data.userCompanyRoles[i].named_secretary_id;
      existOfficer.named_secretary_name =
        respOfficers.data.userCompanyRoles[i].named_secretary_first_name;
      existOfficer.cessation_type = respOfficers.data.userCompanyRoles[i].cessation_type_id;
      existOfficer.cessation_type_name = respOfficers.data.userCompanyRoles[i].cessation_type_name;
      existOfficer.date_cessation = respOfficers.data.userCompanyRoles[i].cessation_date;
      existOfficer.user_company_role_id =
        respOfficers.data.userCompanyRoles[i].user_company_role_id;
      arrExistOfficer.push(existOfficer);
    }

    setArrCompanyofficers(arrExistOfficer);

    return respOfficers;
    // init company officer
    // const fetchCompanyOfficers = async () => {
    //   setLoadingSubmit(true);
    //   const respOfficers = await companyApi.getCompanyOfficers(
    //     selectedCompanyId
    //   );
    //   console.log("respOfficers", respOfficers.data.userCompanyRoles);
    //   var arrExistOfficer = [];
    //   for (var i = 0; i < respOfficers.data.userCompanyRoles.length; i++) {
    //     var existOfficer = {};
    //     existOfficer.row_id = i;
    //     existOfficer.user_id = respOfficers.data.userCompanyRoles[i].user_id;
    //     existOfficer.user_firstname =
    //       respOfficers.data.userCompanyRoles[i].first_name;
    //     existOfficer.user_idtype = "-";
    //     existOfficer.user_idvalue = "-";
    //     existOfficer.company_role =
    //       respOfficers.data.userCompanyRoles[i].company_role_id;
    //     existOfficer.date_appointment =
    //       respOfficers.data.userCompanyRoles[i].date_of_appointment;
    //     existOfficer.nominee_id =
    //       respOfficers.data.userCompanyRoles[i].nominee_id;
    //     existOfficer.nominee_name =
    //       respOfficers.data.userCompanyRoles[i].nominee_first_name;
    //     existOfficer.nominator_id =
    //       respOfficers.data.userCompanyRoles[i].nominator_id;
    //     existOfficer.nominator_name =
    //       respOfficers.data.userCompanyRoles[i].nominator_first_name;
    //     existOfficer.controller_id =
    //       respOfficers.data.userCompanyRoles[i].controller_id;
    //     existOfficer.controller_name =
    //       respOfficers.data.userCompanyRoles[i].controller_first_name;
    //     existOfficer.named_secretary_id =
    //       respOfficers.data.userCompanyRoles[i].named_secretary_id;
    //     existOfficer.named_secretary_name =
    //       respOfficers.data.userCompanyRoles[i].named_secretary_first_name;
    //     existOfficer.cessation_type =
    //       respOfficers.data.userCompanyRoles[i].cessation_type_id;
    //     existOfficer.cessation_type_name =
    //       respOfficers.data.userCompanyRoles[i].cessation_type_name;
    //     existOfficer.date_cessation =
    //       respOfficers.data.userCompanyRoles[i].cessation_date;
    //     existOfficer.user_company_role_id =
    //       respOfficers.data.userCompanyRoles[i].user_company_role_id;
    //     arrExistOfficer.push(existOfficer);
    //   }
    //   console.log("arrExistOfficer", arrExistOfficer);
    //   setArrCompanyofficers(arrExistOfficer);
    // };

    // fetchCompanyOfficers().finally(() => setLoadingSubmit(false));
  };

  const fetchShareholders = async () => {
    const respShareholders = await shareholderApi.getShareholder(selectedCompanyId);
    let shareholdersData = respShareholders?.data?.shares;

    var arrExistShareholders = [];
    for (var i = 0; i < shareholdersData.length; i++) {
      var existShareholder = {};
      existShareholder.row_id = i;
      existShareholder.shareholder_id = shareholdersData[i].shareholder_id;

      if (shareholdersData[i].user_id !== undefined) {
        existShareholder.user_id = shareholdersData[i].user_id;
      }

      if (shareholdersData[i].company_id !== undefined) {
        shareholdersData[i].identifications[0].identification_type_id = 3;
        existShareholder.user_idtype =
          shareholdersData[i].identifications[0].identification_type_id;
        existShareholder.user_id = shareholdersData[i].company_id;
        console.log('company---', existShareholder.user_idtype, shareholdersData[i].company_id);
      }

      existShareholder.share_capital_id = shareholdersData[i].share_capital_id;
      existShareholder.Name = shareholdersData[i].Name;

      if (shareholdersData[i].identifications.length >= 1) {
        existShareholder.user_idvalue = shareholdersData[i].identifications[0].identification_value;
        existShareholder.user_idtype =
          shareholdersData[i].identifications[0].identification_type_id;
      }

      existShareholder.user_sharetype = shareholdersData[i].share_type_id;
      existShareholder.user_sharenumber = shareholdersData[i].user_allotment;
      console.log('existShareholderxyz' + i, existShareholder);
      arrExistShareholders.push(existShareholder);
      console.log('arrExistShareholders', arrExistShareholders);
    }
    setArrShareholders(arrExistShareholders);
    console.log('setArrShareholders', arrShareholders);

    return respShareholders;
  };

  const {
    loading: loadingDocuments,
    rawData: defaultDocuments = [],
    refetch: fetchDocuments,
  } = useCommonOptions(async () => documentApi.getDocumentsListSTS(2, selectedCompanyId), {
    respondKey: 'document',
    labelKey: 'signed_document_url',
    valueKey: 'document_id',
    lazyLoad: true,
  });
  const [selectedDocuments, setSelectedDocuments] = useState(defaultDocumentGroups);

  const groupDocuments = (groups = [], documents = []) => {
    let documentGroups = [...groups];
    if (documents?.length > 0) {
      documentGroups = documentGroups.map((anItem) => {
        let ids = [anItem.document_type_id];
        if (anItem?.otherIds?.length > 0) {
          ids = [...ids, ...anItem?.otherIds];
        }
        let filteredDocuments = documents.filter((anItem2) => {
          let result = false;
          if (ids.indexOf(anItem2.document_type_id) >= 0) {
            result = true;
          }
          return result;
        });
        return {
          ...anItem,
          data: filteredDocuments,
        };
      });
    }
    return documentGroups;
  };
  const fetchDocumentsByGroup = async () => {
    let res = await fetchDocuments();
    if (res?.data?.document?.length > 0) {
      let documents = res?.data?.document;
      let documentGroups = groupDocuments(defaultDocumentGroups, documents);
      // let documentGroups = [...defaultDocumentGroups];
      // if (documents?.length > 0) {
      //   documentGroups = documentGroups.map((anItem) => {
      //     let ids = [anItem.document_type_id];
      //     if (anItem?.otherIds?.length > 0) {
      //       ids = [...ids, ...anItem?.otherIds];
      //     }
      //     let filteredDocuments = documents.filter((anItem2) => {
      //       let result = false;
      //       if (ids.indexOf(anItem2.document_type_id) >= 0) {
      //         result = true;
      //       }
      //       return result;
      //     });
      //     return {
      //       ...anItem,
      //       data: filteredDocuments,
      //     };
      //   });
      // }
      setSelectedDocuments(documentGroups);
    } else {
      setSelectedDocuments(defaultDocumentGroups);
    }
    return res;
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await fetchCompanyDetails();
      await fetchBusinessActivity();
      await fetchShareCapital();
      await fetchCompanyAddress();

      try {
        // documents
        console.log('cretificationDocView', fetchDocument(18, 2, selectedCompanyId));
        console.log('bizDocView', fetchDocument(15, 2, selectedCompanyId));
        // console.log('annualDocView', fetchDocument(5, 2, selectedCompanyId));
        // console.log('auditedDocView', fetchDocument(12, 2, selectedCompanyId));
        // console.log('accountsDocView', fetchDocument(57, 2, selectedCompanyId));
        // console.log('constitutionalDocView', fetchDocument(28, 2, selectedCompanyId));
        await fetchDocumentsByGroup();
      } catch (error) {}

      // tables
      await fetchCompanyOfficers();
      await fetchShareholders();
      await getSysInfoUserRoles();
      await fetchAuditorCompanies();

      // common
      const respCompanieslist = await companyApi.getCompanies('', 1, 10000);
      setCompanylist(respCompanieslist.data.companies);

      const respIdentificationtypelist = await commonApi.getIdentificationType();
      var identificationList = [];
      identificationList = respIdentificationtypelist.data.identificationtypes;
      identificationList.push(companyIdentification);
      setIdentificationtypeList(identificationList);

      const respUserslist = await userApi.getUsers('', 1, 100000);
      setUserlist(respUserslist.data.users);

      const respCessationlist = await commonApi.getCessationType();
      setCessationtypeList(respCessationlist.data.cessationtypes);

      const respCountrylist = await commonApi.getCountry();
      setCountrylist(respCountrylist.data.countries);

      const respCompanyrolelist = await commonApi.getCompanyRole();
      setCompanyrolelist(respCompanyrolelist.data.companyroles);

      const respCompanytypelist = await commonApi.getCompanyType();
      setCompanytypelist(respCompanytypelist.data.company_types);

      const respSsiclist = await commonApi.getSsicCode();
      setSsiclist(respSsiclist.data.document);

      const respCurrencylist = await commonApi.getCurrency();
      setCurrencylist(respCurrencylist.data.currency);

      const respSharetypelist = await commonApi.getShareType();
      setSharetypelist(respSharetypelist.data.share_types);

      const respAddresstypelist = await commonApi.getAddressType();
      setAddresstypelist(respAddresstypelist.data.addresstypes);

      const respCompanystatuslist = await commonApi.getCompanyStatus();
      setCompanystatuslist(respCompanystatuslist.data.company_statuses);
    };

    if (selectedCompanyId) {
      fetchData().finally(() => {
        setLoading(false);
      });
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSubmit(true);
      await fetchShareholders();
    };
    fetchData().finally(() => setLoadingSubmit(false));
  }, [refreshShareKey]);

  useEffect(() => {
    if (
      othernumbersharevalue ||
      otheramountsharevalue ||
      otherpaidupamountvalue ||
      othersharecurrencyvalue
    ) {
      setRequireOthershare(true);
    } else {
      setRequireOthershare(false);
    }
  }, [
    othernumbersharevalue,
    otheramountsharevalue,
    otherpaidupamountvalue,
    othersharecurrencyvalue,
  ]);

  const companyIdentification = {
    identification_type_id: 3,
    identification_name: 'Company Registered Number',
  };

  const handleCompletion = () => {
    setLoadingSubmit(false);
    setOpenDialog(true);
    setDisableAddress(true);
    setDisableCompanyDetails(true);
    setDisablePrincipalActivities(true);
    setDisableShares(true);
    setDisableTreasuryShare(true);
    setDisableDocuments(true);
    setLoadingSubmitSection(false);

    if (ref_annualReport?.current?.value) {
      ref_annualReport.current.value = '';
    }
    if (ref_auditedReport?.current?.value) {
      ref_auditedReport.current.value = '';
    }
    if (ref_constitutionalDocument?.current?.value) {
      ref_constitutionalDocument.current.value = '';
    }
    if (ref_managementAccounts?.current?.value) {
      ref_managementAccounts.current.value = '';
    }
  };

  const handleChangeAddressType = (event) => {
    if (event.target.value === undefined) {
      setAddresstypeerror(true);
    } else {
      setAddresstypevalue(event.target.value);
      setAddresstypeerror(false);
    }
  };

  const handleChangeAddressRoad = (event) => {
    if (event.target.value === undefined) {
      seterrorMsgaddressroad(errorMsg);
    } else {
      setAddressroadvalue(event.target.value);
      seterrorMsgaddressroad('');
    }
  };
  const handleChangeAddress2 = (event) => {
    // if (event.target.value === undefined) {
    //   setErrorMsgaddressline2(errorMsg);
    // } else {
    setAddressline2value(event.target.value);
    //   setErrorMsgaddressline2("");
    // }
  };

  const handleChangeAddressCountry = (event) => {
    if (event.target.value === undefined) {
      setAddresscountryerror(true);
    } else {
      setAddresscountryvalue(event.target.value);
      setAddresscountryerror(false);
    }
  };
  const handleChangeAddressPostal = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgaddresspostal(errorMsg);
    } else {
      setAddresspostalvalue(event.target.value);
      setErrorMsgaddresspostal('');
    }
  };

  const handleChangeIncorporationCountry = (event) => {
    if (event.target.value === undefined) {
      setIncorporationcountryerror(true);
    } else {
      setIncorporationcountryvalue(event.target.value);
      setIncorporationcountryerror(false);
    }
  };

  const handleChangeStatusDate = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgStatusDate(errorSelectMsg);
    } else {
      setStatusdatevalue(event.target.value);
      setErrorMsgStatusDate('');
    }
  };

  const handleChangeCompanyType = (event) => {
    if (event.target.value === undefined) {
      setCompanytypeerror(true);
    } else {
      setCompanytypevalue(event.target.value);
      setCompanytypeerror(false);
    }
  };

  const handleChangeCompanyStatus = (event) => {
    if (event.target.value === undefined) {
      setCompanystatuserror(true);
    } else {
      setCompanystatusvalue(event.target.value);
      setCompanystatuserror(false);
    }
  };

  const handleChangeRegisteredNumber = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgRegisteredNumber(errorMsg);
    } else {
      setRegisterednumbervalue(event.target.value);
      setErrorMsgRegisteredNumber('');
    }
  };

  const handleChangeCompanyName = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgCompanyName(errorMsg);
    } else {
      setCompanynamevalue(event.target.value);
      setErrorMsgCompanyName('');
    }
  };

  const handleChangeIncorporationDate = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgIncorporationDate(errorSelectMsg);
    } else {
      setIncorporationdatevalue(event.target.value);
      setErrorMsgIncorporationDate('');
    }
  };

  const handleChangeFirstFyeDateDate = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgFirstFyeDateValue(errorSelectMsg);
    } else {
      setFirstFyeDateValue(event.target.value);
      setErrorMsgFirstFyeDateValue('');
    }
  };
  const handleChangeFyeDateDate = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgFyeDateValue(errorSelectMsg);
    } else {
      setFyeDateValue(event.target.value);
      setErrorMsgFyeDateValue('');
    }
  };

  const handleChangeActivity2 = (event, value) => {
    console.log('activity2', value);
    if (value !== undefined && value !== null) {
      setActivity2value(value.ssic_id);
    }
  };

  const handleChangeActivity1 = (event, value) => {
    console.log('activity1', value);
    if (value === undefined || value === null) {
      setErrorMsgActivity1(errorSelectMsg);
    } else {
      setActivity1value(value.ssic_id);
      setErrorMsgActivity1('');
    }
  };

  const handleChangePaidupAmount = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgPaidupAmount(errorMsg);
    } else {
      setPaidupamountvalue(event.target.value);
      //   await insertArr();
      setErrorMsgPaidupAmount('');
    }
  };

  const handleChangeAmountShare = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgAmountShare(errorMsg);
    } else {
      setAmountsharevalue(event.target.value);
      //   await insertArr();
      setErrorMsgAmountShare('');
    }
  };
  const handleChangeNumberShare = (event) => {
    if (event.target.value === undefined) {
      setErrorMsgNumberShare(errorMsg);
    } else {
      setNumbersharevalue(event.target.value);
      //   await insertArr();
      setErrorMsgNumberShare('');
    }
  };

  const handleChangeShareCurrency = (event) => {
    if (event.target.value === undefined) {
      setSharecurrencyerror(true);
    } else {
      setSharecurrencyvalue(event.target.value);
      //   await insertArr();
      setSharecurrencyerror(false);
    }
  };

  // *********************************************
  const handleChangePreferencePaidupAmount = (event) => {
    setPreferencepaidupamountvalue(event.target.value);
    // await insertArr();
  };

  const handleChangePreferenceAmountShare = (event) => {
    setPreferenceamountsharevalue(event.target.value);
    // await insertArr();
  };
  const handleChangePreferenceNumberShare = (event) => {
    setPreferencenumbersharevalue(event.target.value);
    // await insertArr();
  };

  const handleChangePreferenceShareCurrency = (event) => {
    setPreferencesharecurrencyvalue(event.target.value);
    // await insertArr();
  };
  const handleChangePreferenceShareType = (event) => {
    setPreferencesharetypevalue(event.target.value);
  };

  // *********************************************
  const handleChangeOtherPaidupAmount = (event) => {
    setOtherpaidupamountvalue(event.target.value);
    // await insertArr();
  };

  const handleChangeOtherAmountShare = (event) => {
    setOtheramountsharevalue(event.target.value);
    // await insertArr();
  };
  const handleChangeOtherNumberShare = (event) => {
    setOthernumbersharevalue(event.target.value);
    // await insertArr();
  };

  const handleChangeOtherShareCurrency = (event) => {
    setOthersharecurrencyvalue(event.target.value);
    // await insertArr();
  };
  const handleChangeOtherShareType = (event) => {
    setOthersharetypevalue(event.target.value);
  };

  // *********************************************
  const handleChangeShareType = (event) => {
    if (event.target.value === undefined) {
      setSharetypeerror(true);
    } else {
      setSharetypevalue(event.target.value);
      setSharetypeerror(false);
    }
  };

  const handleEditCompanyDetails = () => {
    console.log('handleEditCompanyDetails');
    setDisableCompanyDetails(false);
    let defaultFirstFyeDate = firstFyeDateValue;
    let defaultFyeDate = fyeDateValue;
    // if (defaultFyeDate && moment(defaultFyeDate).isValid()) {
    if (!defaultFyeDate || !moment(defaultFyeDate).isAfter(moment().format('YYYY-MM-DD'))) {
      if (defaultFirstFyeDate && moment(defaultFirstFyeDate).isValid()) {
        let newFye = moment(defaultFirstFyeDate).add(1, 'years');
        if (newFye.isAfter(moment().format('YYYY-MM-DD'))) {
          defaultFyeDate = newFye.format('YYYY-MM-DD');
          setFyeDateValue(defaultFyeDate);
        } else {
          setFyeDateValue(moment().add(1, 'years').format('YYYY-MM-DD'));
        }
      }
    }
    // }
  };
  const handleCancelCompanyDetails = () => {
    console.log('handleCancelCompanyDetails');
    setFyeDateValue(defaultFyeDateValue);
    setDisableCompanyDetails(true);
  };

  const handleSubmitCompanyDetails = () => {
    let canSubmit = true;

    if (registerednumbervalue === undefined) {
      setErrorMsgRegisteredNumber(errorMsg);
      canSubmit = false;
    } else {
      setErrorMsgRegisteredNumber('');
    }

    if (companynamevalue === undefined) {
      setErrorMsgCompanyName(errorMsg);
      canSubmit = false;
    } else {
      setErrorMsgCompanyName('');
    }

    if (!isShareholderCompany) {
      if (companytypevalue === undefined) {
        setCompanytypeerror(true);
        canSubmit = false;
      } else {
        setCompanytypeerror(false);
      }

      if (companystatusvalue === undefined) {
        setCompanystatuserror(true);
        canSubmit = false;
      } else {
        setCompanystatuserror(false);
      }
      if (incorporationdatevalue === undefined) {
        setErrorMsgIncorporationDate(errorSelectMsg);
        canSubmit = false;
      } else {
        setErrorMsgIncorporationDate('');
      }

      if (statusdatevalue === undefined) {
        setErrorMsgStatusDate(errorSelectMsg);
        canSubmit = false;
      } else {
        setErrorMsgStatusDate('');
      }
    }
    if (incorporationcountryvalue === undefined) {
      setIncorporationcountryerror(true);
      canSubmit = false;
    } else {
      setIncorporationcountryerror(false);
    }

    let passedFye = false;
    if (!isShareholderCompany) {
      if (moment(firstFyeDateValue).isValid() || moment(fyeDateValue).isValid()) {
        passedFye = true;
      } else {
        setErrorMsgFirstFyeDateValue(errorMsg);
        setErrorMsgFyeDateValue(errorMsg);
        canSubmit = false;
      }
    } else {
      passedFye = true;
    }

    const fetchData = async () => {
      const companyData = {
        company_name: companynamevalue,
        company_type_id: companytypevalue,
        name_effective_from: wefdatevalue,
        former_name: formercompanynamevalue,
        company_registration_no: registerednumbervalue,
        incorporation_date: incorporationdatevalue,
        country_of_incorporation_id: incorporationcountryvalue,
        country_of_operation_id: incorporationcountryvalue,
        company_status_id: companystatusvalue,
        status_date: statusdatevalue,
        company_client_type_id: companyCategoryId,
        [fields_companyDetail.first_fye_date.name]: firstFyeDateValue,
        [fields_companyDetail.fye_date.name]: fyeDateValue,
      };
      try {
        await companyApi.updateCompanyDetails(selectedCompanyId, companyData);
      } catch (error) {
        enqueueSnackbar(
          error?.response?.data?.error?.message || `Failed to Update Company Details`,
          { variant: 'error' }
        );
        return;
      }

      try {
        await companyApi.updateCompanyClientType(selectedCompanyId, companyCategoryId);
      } catch (error) {
        // enqueueSnackbar(
        //   error?.response?.data?.error?.message ||
        //     `Failed to Update Company Details`,
        //   { variant: "error" }
        // );
      }
      if (ref_certificateOfIncorporation?.current?.value) {
        try {
          const resphandleChangeUploadCertificateIncorporation = await handleChangeUploadCertificateIncorporation(
            selectedCompanyId
          );
          // upload here
          if (resphandleChangeUploadCertificateIncorporation) {
            setCertificateIncorporationDocView(
              resphandleChangeUploadCertificateIncorporation.data.document[
                resphandleChangeUploadCertificateIncorporation.data.document.length - 1
              ]
            );
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message ||
              `Failed to Upload Certificate of Incorporation`,
            { variant: 'error' }
          );
          return;
        }
      }
    };
    if (canSubmit) {
      setLoadingSubmitSection('cd');
      setLoadingSubmit(true);

      fetchData().finally(() => {
        fetchCompanyDetails();
        handleCompletion();
      });
    }
  };

  const handleEditPrincipalActivities = () => {
    setDisablePrincipalActivities(false);
  };
  const handleCancelPrincipalActivities = () => {
    setDisablePrincipalActivities(true);
  };

  const handleSubmitPrincipalActivities = () => {
    setLoadingSubmit(true);
    const fetchData = async () => {
      const bizData = {
        primary_ssic_id: activity1value,
        secondary_ssic_id: activity2value,
        business_description: activity1desc,
        secondary_business_description: activity2desc,
      };
      try {
        if (businessActivityId) {
          const respBusiness = await businessApi.updateBusinessActivity(
            selectedCompanyId,
            businessActivityId,
            bizData
          );
        } else {
          let businessRes = await businessApi.createBusinessActivity(selectedCompanyId, {
            ...bizData,
            created_by: parseInt(authUserId),
          });
        }
      } catch (error) {
        enqueueSnackbar(
          error?.response?.data?.error?.message || `Failed to Update Company Details`,
          { variant: 'error' }
        );
        return;
      }
      // } catch (error) {
      //   setErrorApiMsg(
      //     "Update Business Activity Error: " +
      //       JSON.stringify(error.response.data.message)
      //   );
      //   setOpenSnackbar(true);
      //   console.log(JSON.stringify(error.response));
      // }

      // upload here
      if (ref_bizFile?.current?.value) {
        try {
          const resphandleChangeUploadBizFile = await handleChangeUploadBizFile(selectedCompanyId);
          if (resphandleChangeUploadBizFile) {
            setBizFileDocView(
              resphandleChangeUploadBizFile.data.document[
                resphandleChangeUploadBizFile.data.document.length - 1
              ]
            );
          }
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Upload Biz File`, {
            variant: 'error',
          });
          return;
        }
      }
    };
    setLoadingSubmitSection('pa');
    fetchData().finally(() => handleCompletion());
  };

  const handleEditShareCapital = () => {
    console.log('handleEditShareCapital');
    setDisableShares(false);
  };
  const handleCancelShareCapital = () => {
    console.log('handleCancelShareCapital');
    setDisableShares(true);
  };

  const handleSubmitShareCapital = () => {
    var respPreferenceShare;
    var respOtherShare;
    console.log('handleSubmitShareCapital');

    const shareDataValues = {
      // share_type_id: 1,
      number_of_shares: numbersharevalue,
      issued_share_capital: amountsharevalue,
      paid_up_capital: paidupamountvalue,
      currency_id: sharecurrencyvalue,
    };
    console.log('ashareDataValues', shareDataValues);
    const preferenceShareDataValues = {
      //   share_type_id: 2,
      number_of_shares: preferencenumbersharevalue,
      issued_share_capital: preferenceamountsharevalue,
      paid_up_capital: preferencepaidupamountvalue,
      currency_id: preferencesharecurrencyvalue,
    };

    const otherShareDataValues = {
      //   share_type_id: 3,
      number_of_shares: othernumbersharevalue,
      issued_share_capital: otheramountsharevalue,
      paid_up_capital: otherpaidupamountvalue,
      currency_id: othersharecurrencyvalue,
    };
    const shareTypes = {
      1: 'Share Type - Ordinary',
      2: 'Share Type - Preference',
      3: 'Share Type - Others',
    };

    const validateShareCapitalValue = (formValue, shareType, required = false) => {
      let shareTitle = shareTypes[shareType];
      // if required fields are filled
      let passRequiredField = false;
      let valueKeys = Object.keys(formValue);
      let totalUndefined = 0;
      valueKeys.forEach((aKey) => {
        let value = formValue[aKey];
        if (value == undefined || value == '') {
          totalUndefined += 1;
        }
      });
      if (valueKeys.length == totalUndefined || totalUndefined == 0) {
        if (required) {
          if (totalUndefined == 0) {
            passRequiredField = true;
          } else {
            enqueueSnackbar(`${shareTitle}: Please fill up all required fields`, {
              variant: 'error',
            });
          }
        } else {
          passRequiredField = true;
        }
      } else {
        // alert
        enqueueSnackbar(`${shareTitle}: Please fill up all required fields`, {
          variant: 'error',
        });
      }

      let passShareNumber = false;
      let totalHoldingShares = 0;
      arrShareholders.forEach((aShareholder) => {
        if (aShareholder.user_sharetype == shareType) {
          let aShareNum = parseInt(aShareholder['user_sharenumber']);
          totalHoldingShares += aShareNum;
        }
      });
      let shareLimit = formValue['number_of_shares'] ? parseInt(formValue['number_of_shares']) : 0;
      if (totalHoldingShares <= shareLimit) {
        passShareNumber = true;
      } else {
        enqueueSnackbar(
          `${shareTitle}: Current shareholders' number of share exceeds share limit`,
          { variant: 'error' }
        );
      }

      // if Paid up capital <  Issued share capital
      // Paid up capital must be lower than Issued share capital.
      let passCapitalValue = false;
      if (passRequiredField) {
        if (formValue['issued_share_capital'] && formValue['paid_up_capital']) {
          let issuedShare = parseFloat(formValue['issued_share_capital']);
          let paidUp = parseFloat(formValue['paid_up_capital']);
          if (issuedShare >= paidUp) {
            passCapitalValue = true;
          } else {
            // alert
            enqueueSnackbar(
              `${shareTitle}: Paid up capital must be lower than Issued share capital.`,
              { variant: 'error' }
            );
          }
        } else {
          passCapitalValue = true;
        }
      }
      return passRequiredField && passCapitalValue && passShareNumber;
    };

    let canSubmitShareData = validateShareCapitalValue(shareDataValues, 1, true);
    let canSubmitPreferenceShareData = validateShareCapitalValue(preferenceShareDataValues, 2);
    let canSubmitOtherShareData = validateShareCapitalValue(otherShareDataValues, 3);

    let procceedToSubmit =
      canSubmitShareData && canSubmitPreferenceShareData && canSubmitOtherShareData;

    const fetchData = async () => {
      const shareData = {
        // share_type_id: 1,
        number_of_shares: numbersharevalue,
        issued_share_capital: amountsharevalue,
        paid_up_capital: paidupamountvalue,
        currency_id: sharecurrencyvalue,
      };
      let respShare;
      try {
        if (sharecapitalid) {
          respShare = await shareApi.updateShareCapital(
            selectedCompanyId,
            sharecapitalid,
            shareData
          );
        } else {
          respShare = await shareApi.createShareCapital(selectedCompanyId, {
            ...shareData,
            share_type_id: 1,
            created_by: parseInt(authUserId),
          });
        }
      } catch (error) {
        enqueueSnackbar(
          error?.response?.data?.error?.message || `Failed to Update Share Capital (Ordinary)`,
          { variant: 'error' }
        );
        return;
      }
      if (
        preferencenumbersharevalue !== undefined &&
        preferenceamountsharevalue !== undefined &&
        preferencepaidupamountvalue !== undefined &&
        preferencesharecurrencyvalue !== undefined
      ) {
        try {
          if (preferencesharecapitalid !== null && preferencesharecapitalid !== undefined) {
            const preferenceShareData = {
              //   share_type_id: 2,
              number_of_shares: preferencenumbersharevalue,
              issued_share_capital: preferenceamountsharevalue,
              paid_up_capital: preferencepaidupamountvalue,
              currency_id: preferencesharecurrencyvalue,
            };
            respPreferenceShare = await shareApi.updateShareCapital(
              selectedCompanyId,
              preferencesharecapitalid,
              preferenceShareData
            );
          } else {
            const preferenceShareData = {
              share_type_id: 2,
              number_of_shares: preferencenumbersharevalue,
              issued_share_capital: preferenceamountsharevalue,
              paid_up_capital: preferencepaidupamountvalue,
              currency_id: preferencesharecurrencyvalue,
            };
            respPreferenceShare = await shareApi.createShareCapital(selectedCompanyId, {
              ...preferenceShareData,
              created_by: parseInt(authUserId),
            });
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Update Share Capital (Preference)`,
            { variant: 'error' }
          );
          return;
        }

        console.log('respPreferenceShare', respPreferenceShare.data);
      }
      if (
        othernumbersharevalue !== undefined &&
        otheramountsharevalue !== undefined &&
        otherpaidupamountvalue !== undefined &&
        othersharecurrencyvalue !== undefined
      ) {
        try {
          if (othersharecapitalid !== null && othersharecapitalid !== undefined) {
            const otherShareData = {
              //   share_type_id: 3,
              number_of_shares: othernumbersharevalue,
              issued_share_capital: otheramountsharevalue,
              paid_up_capital: otherpaidupamountvalue,
              currency_id: othersharecurrencyvalue,
            };
            respOtherShare = await shareApi.updateShareCapital(
              selectedCompanyId,
              othersharecapitalid,
              otherShareData
            );
          } else {
            const otherShareData = {
              share_type_id: 3,
              number_of_shares: othernumbersharevalue,
              issued_share_capital: otheramountsharevalue,
              paid_up_capital: otherpaidupamountvalue,
              currency_id: othersharecurrencyvalue,
            };
            respOtherShare = await shareApi.createShareCapital(selectedCompanyId, {
              ...otherShareData,
              created_by: parseInt(authUserId),
            });
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Update Share Capital (Others)`,
            { variant: 'error' }
          );
          return;
        }

        console.log('respOtherShare', respOtherShare.data);
      }

      console.log('respShare111111', respShare.data);
    };
    if (procceedToSubmit) {
      setLoadingSubmit(true);

      setLoadingSubmitSection('isc');
      fetchData().finally(() => {
        fetchShareCapital();
        handleCompletion();
      });
    }
  };

  const handleEditTreasuryShare = () => {
    console.log('handleEditTreasuryShare');
    setDisableTreasuryShare(false);
  };
  const handleCancelTreasuryShare = () => {
    console.log('handleCancelTreasuryShare');
    setDisableTreasuryShare(true);
  };

  const handleSubmitTreasuryShare = () => {
    setLoadingSubmit(true);
    console.log(
      'handleSubmitTreasuryShare',
      sharecapitalid,
      treasurysharecapitalid,
      treasuryshareid
    );
    const fetchData = async () => {
      const treasuryShareData = {
        number_of_shares: treasurysharevalue,
      };
      if (treasurysharevalue !== undefined && treasurysharevalue !== null) {
        console.log('treasurysharevalue', treasurysharevalue);
        try {
          if (
            sharecapitalid !== undefined &&
            sharecapitalid !== null &&
            treasurysharecapitalid !== undefined &&
            treasurysharecapitalid !== null
          ) {
            const respTreasury = await shareApi.updateTreasuryShare(
              selectedCompanyId,
              // sharecapitalid,
              treasurysharecapitalid,
              treasuryshareid,
              treasuryShareData
            );
            console.log('respTreasuryUpdate', respTreasury);
          } else {
            const respTreasury = await shareApi.createTreasuryShare(
              selectedCompanyId,
              sharecapitalid,
              // treasurysharecapitalid,
              { ...treasuryShareData, created_by: parseInt(authUserId) }
            );
            console.log('respTreasuryAdd', respTreasury);
          }
        } catch (error) {
          enqueueSnackbar(
            error?.response?.data?.error?.message || `Failed to Update Treasury Share`,
            { variant: 'error' }
          );
          return;
        }
      }
    };
    setLoadingSubmitSection('ts');
    fetchData().finally(() => handleCompletion());
  };

  // company address
  const [companyAddressForm, setCompanyAddressForm] = useState({
    show: false,
    data: null,
  });
  const [selectedCompanyAddresses, setSelectedCompanyAddresses] = useState([]);

  const handleSubmitCompanyAddressForm = async (values) => {
    let is_default = values?.is_default ? 1 : 0;

    let addressId = companyAddressForm?.data?.['address_id'];
    let company_address_id = companyAddressForm?.data?.company_address_id;
    setLoadingSubmit(true);
    try {
      if (company_address_id) {
        const respCompanyAddress = await addressApi.updateCompanyAddressByAddressId(
          selectedCompanyId,
          company_address_id,
          { ...values, is_default, address_id: addressId }
        );
        if (is_default) {
          let foundOtherDefault = selectedCompanyAddresses.find((anItem) => {
            return anItem.company_address_id != company_address_id && is_default == 1;
          });
          if (foundOtherDefault) {
            const respCompanyAddress = await addressApi.updateCompanyAddressByAddressId(
              selectedCompanyId,
              foundOtherDefault.company_address_id,
              { is_default: 0 }
            );
          }
        }
      } else {
        const respCompanyAddress = await addressApi.createCompanyAddress(selectedCompanyId, {
          companyAddresses: [
            {
              address_line_3: '',
              is_applying: 0,
              is_registered: 1,
              registration_datetime: '2021-03-31 00:00:00',
              ...values,
              is_default: is_default,
            },
          ],
        });
      }
      await fetchCompanyAddress();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Update Address`, {
        variant: 'error',
      });
      return;
    }
    setLoadingSubmit(false);

    setCompanyAddressForm({
      show: false,
      data: null,
    });
  };

  const handleRemoveCompanyAddress = async (value) => {
    let company_address_id = value?.company_address_id;
    if (company_address_id) {
      setLoadingSubmit(true);

      try {
        const respCompanyAddress = await addressApi.removeCompanyAddress(
          selectedCompanyId,
          company_address_id
        );
        await fetchCompanyAddress();
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Address`, {
          variant: 'error',
        });
      }
      setLoadingSubmit(false);
      setCompanyAddressForm({
        show: false,
        data: null,
      });
    }
  };

  const handleEditAddress = () => {
    console.log('handleEditAddress');
    setDisableAddress(false);
  };
  const handleCancelAddress = () => {
    console.log('handleCancelAddress');
    setDisableAddress(true);
  };

  const handleSubmitAddress = () => {
    setLoadingSubmit(true);
    console.log('handleSubmitAddress');
    const fetchData = async () => {
      const companyAddressData = {
        // companyAddresses: [
        //   {
        // address_id: addressid,
        address_type_id: addresstypevalue,
        country_id: addresscountryvalue,
        floor_unit_no: addressunitvalue,
        address_line_1: addressroadvalue,
        address_line_2: addressline2value,
        address_line_3: null,
        postal_code: addresspostalvalue,
        is_default: 1,
        is_applying: 0,
        is_registered: 1,
        registration_datetime: '2021-03-31 00:00:00',
        //   },
        // ],
      };
      try {
        if (addressid) {
          const respCompanyAddress = await addressApi.updateCompanyAddressByAddressId(
            selectedCompanyId,
            companyaddressid,
            { ...companyAddressData, address_id: addressid }
          );
        } else {
          const respCompanyAddress = await addressApi.createCompanyAddress(selectedCompanyId, {
            companyAddresses: [companyAddressData],
          });
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Update Address`, {
          variant: 'error',
        });
        return;
      }
    };
    setLoadingSubmitSection('ra');
    fetchData().finally(() => handleCompletion());
  };

  // const handleSubmitOfficer = () => {
  //   console.log("handleSubmitOfficer");
  //   const fetchData = async () => {};
  //   fetchData().finally(() => handleCloseCompanyOfficerDialog());
  // };

  // const handleSubmitShareholder = () => {
  //   console.log("handleSubmitShareholder");
  //   const fetchData = async () => {};
  //   fetchData().finally(() => handleCloseShareholderDialog());
  // };

  // doccccccccccccccccccc
  const handleChangeUploadCertificateIncorporation = async (dataId) => {
    let result;
    // try {
    result = await fileUploader(ref_certificateOfIncorporation, 18, 2, dataId);
    // } catch (error) {
    //   enqueueSnackbar(
    //     error?.response?.data?.error?.message ||
    //       `Failed to Upload Certificate Of Incorporation`,
    //     { variant: "error" }
    //   );
    // }
    return result;
  };
  const handleChangeUploadBizFile = async (dataId) => {
    let result;
    // try {
    result = await fileUploader(ref_bizFile, 15, 2, dataId);
    // } catch (error) {
    //   enqueueSnackbar(
    //     error?.response?.data?.error?.message || `Failed to Upload Biz File`,
    //     { variant: "error" }
    //   );
    // }
    return result;
  };
  const handleChangeUploadAnnualReport = async (dataId) => {
    let result;
    try {
      result = await fileUploader(ref_annualReport, 5, 2, dataId);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Upload Annual Report`, {
        variant: 'error',
      });
    }
    return result;
  };
  const handleChangeUploadAuditedReport = async (dataId) => {
    let result;
    try {
      result = await fileUploader(ref_auditedReport, 12, 2, dataId);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Upload Audited Report`, {
        variant: 'error',
      });
    }
    return result;
  };
  const handleChangeUploadConstitutionalDocument = async (dataId) => {
    let result;
    try {
      result = await fileUploader(ref_constitutionalDocument, 28, 2, dataId);
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message || `Failed to Upload Constitutional Document`,
        { variant: 'error' }
      );
    }
    return result;
  };
  const handleChangeUploadManagementAccounts = async (dataId) => {
    let result;
    try {
      result = await fileUploader(ref_managementAccounts, 57, 2, dataId);
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error?.message || `Failed to Upload Management Accounts`,
        { variant: 'error' }
      );
    }
    return result;
  };

  const handleEditDocuments = () => {
    setDisableDocuments(false);
  };
  const handleCancelDocuments = () => {
    setSelectedDocuments(groupDocuments(defaultDocumentGroups, defaultDocuments));
    setDisableDocuments(true);
  };

  const handleSubmitDocuments = () => {
    setLoadingSubmit(true);
    const fetchData = async () => {
      for (let i = 0; i < selectedDocuments.length; i++) {
        let documentsByGroup = selectedDocuments[i];
        if (documentsByGroup?.data?.length > 0) {
          for (let j = 0; j < documentsByGroup.data.length; j++) {
            let groupedFiles = documentsByGroup.data[j];
            if (groupedFiles?.file) {
              await fileUploader(
                { current: { files: [groupedFiles.file] } },
                documentsByGroup.document_type_id,
                2,
                selectedCompanyId
              );
            } else if (groupedFiles?.document_id && groupedFiles?.isDeleted) {
              await documentApi.removeDocumentById(groupedFiles?.document_id, parseInt(authUserId));
            }
          }
        }
      }
      await fetchDocumentsByGroup();
      // if (ref_annualReport?.current?.value) {
      //   const resphandleChangeUploadConstitutionalDocument = await handleChangeUploadConstitutionalDocument(
      //     selectedCompanyId
      //   );
      //   // upload here
      //   if (resphandleChangeUploadConstitutionalDocument) {
      //     setConstitutionalDocumentView(
      //       resphandleChangeUploadConstitutionalDocument.data.document[
      //         resphandleChangeUploadConstitutionalDocument.data.document.length - 1
      //       ]
      //     );
      //   }
      // }
      // if (ref_auditedReport?.current?.value) {
      //   const resphandleChangeUploadManagementAccounts = await handleChangeUploadManagementAccounts(
      //     selectedCompanyId
      //   );
      //   if (resphandleChangeUploadManagementAccounts) {
      //     setManagementAccountsView(
      //       resphandleChangeUploadManagementAccounts.data.document[
      //         resphandleChangeUploadManagementAccounts.data.document.length - 1
      //       ]
      //     );
      //   }
      // }
      // if (ref_constitutionalDocument?.current?.value) {
      //   const resphandleChangeUploadAuditedReport = await handleChangeUploadAuditedReport(
      //     selectedCompanyId
      //   );
      //   if (resphandleChangeUploadAuditedReport) {
      //     setAuditedReportDocView(
      //       resphandleChangeUploadAuditedReport.data.document[
      //         resphandleChangeUploadAuditedReport.data.document.length - 1
      //       ]
      //     );
      //   }
      // }
      // if (ref_managementAccounts?.current?.value) {
      //   const resphandleChangeUploadAnnualReport = await handleChangeUploadAnnualReport(
      //     selectedCompanyId
      //   );
      //   if (resphandleChangeUploadAnnualReport) {
      //     setAnnualReportDocView(
      //       resphandleChangeUploadAnnualReport.data.document[
      //         resphandleChangeUploadAnnualReport.data.document.length - 1
      //       ]
      //     );
      //   }
      // }
    };
    fetchData().finally(() => handleCompletion());
  };

  const defaultProps = {
    options: ssiclist,
    getOptionLabel: (option) => option.ssic_name,
  };

  const handleOnPublishCompany = async () => {
    // check conditions before submit
    // publish_status_id 1 (Draft), 2 (Action), 3 (Publish)

    let passedOfficers = arrCompanyofficers?.length > 0 ? true : false;
    let passedSuperAdmin = sysInfoUserRolesByCompany?.length > 0 ? true : false;
    let passedShares = true;
    let passedDocUpload = true;
    let passedFye = true;

    // fye
    if (moment(firstFyeDateValue).isValid() || moment(fyeDateValue).isValid()) {
      passedFye = true;
    } else {
      passedFye = false;
    }
    // share number
    let totalDistributedShares = {};
    arrShareholders.forEach((anItem) => {
      let shareType = anItem.user_sharetype;
      let shareNum = parseInt(anItem?.user_sharenumber || 0);
      if (totalDistributedShares[shareType] != undefined) {
        totalDistributedShares[shareType] =
          parseInt(totalDistributedShares?.[shareType] || 0) + shareNum;
      } else {
        totalDistributedShares[shareType] = shareNum;
      }
    });

    let totalAvailableShares = {};
    issuedShareCapitals.forEach((anItem) => {
      let shareType = anItem.share_type_id;
      let shareNum = anItem.number_of_shares;
      if (totalAvailableShares[shareType] != undefined) {
        totalAvailableShares[shareType] = totalAvailableShares[shareType] + shareNum;
      } else {
        totalAvailableShares[shareType] = shareNum;
      }
    });

    let shareTypes = Object.keys(totalAvailableShares);
    shareTypes.forEach((aType) => {
      if (totalAvailableShares[aType] != undefined && totalDistributedShares[aType] != undefined) {
        if (totalAvailableShares[aType] != totalDistributedShares[aType]) {
          passedShares = false;
        }
      } else {
        passedShares = false;
      }
    });

    // doc
    let countryId_sgp = 190;
    // let isSGPSelected = incorporationcountryvalue == countryId_sgp;
    let isSGPSelected = false;
    let foundCountry = countrylist.find((v) => v.country_id === incorporationcountryvalue);
    if (foundCountry) {
      if (foundCountry?.country_name?.toLocaleLowerCase().indexOf('singapore') >= 0) {
        isSGPSelected = true;
      }
    }
    if (isSGPSelected) {
      if (bizFileDocView?.signed_document_url) {
      } else {
        passedDocUpload = false;
      }
    } else {
      if (certificateIncorporationDocView?.signed_document_url) {
      } else {
        passedDocUpload = false;
      }
    }

    if (!passedFye) {
      enqueueSnackbar(
        `Please fill up either First Financial Year End Date or Financial Year End Date`,
        { variant: 'error' }
      );
    }

    if (!passedOfficers) {
      enqueueSnackbar(`Please add at least one Officer`, { variant: 'error' });
    }
    if (!passedSuperAdmin) {
      enqueueSnackbar(`Please add at least one Super Admin`, {
        variant: 'error',
      });
    }
    if (!passedShares) {
      enqueueSnackbar(
        // `All shares issued must be distributed completely among shareholders`,
        `The number of ordinary or preference or other share owned by shareholder doesnt add up total issued ordinary or preference or other share`,
        { variant: 'error' }
      );
    }
    if (!passedDocUpload) {
      enqueueSnackbar(
        `${
          isSGPSelected
            ? 'Biz File in Principal Activities'
            : 'Certificate of incorporation in Company Details'
        } is required.`,
        { variant: 'error' }
      );
    }

    if (passedFye && passedOfficers && passedSuperAdmin && passedShares && passedDocUpload) {
      let userList = sysInfoUserRolesByCompany.filter((anItem) => {
        return anItem.invitation_accepted == 0;
      });

      let publishBody = {
        company_id: selectedCompanyId,
        publish_status_id: 3,
        user_list: userList,
        // user_list: sysInfoUserRolesByCompany.map((anItem) => {
        //   return {
        //     ...anItem,
        //     is_new_user: anItem.invitation_accepted == 0 ? 0 : 1,
        //   };
        // }),
        created_by: parseInt(authUserId),
      };

      try {
        setLoadingSubmit(true);
        await companyApi.updateCompanyStatus(selectedCompanyId, 3);
        enqueueSnackbar(`Published`, { variant: 'success' });
        // await fetchCompanyDetails();
        await refetchCompany();
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Publish`, {
          variant: 'error',
        });
        setLoadingSubmit(false);

        return;
      }

      if (userList?.length > 0) {
        try {
          let updateSuperAdminRes = await userApi.updateCompanySuperAdminOnPublish(publishBody);
          let isAllSent = true;
          updateSuperAdminRes?.data?.users?.forEach((anItem) => {
            if (!anItem?.email_sent) {
              isAllSent = false;
              enqueueSnackbar(`Failed to send email (${anItem?.email})`, {
                variant: 'error',
              });
            }
          });
          if (isAllSent) {
            enqueueSnackbar(`Email(s) sent succesfully`, { variant: 'success' });
          }
        } catch (error) {
          console.log('updateCompanySuperAdminOnPublish error', error);
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Publish`, {
            variant: 'error',
          });
        }
      }
      setLoadingSubmit(false);
    }
  };

  const handleOnDraftCompany = async () => {
    setLoadingSubmit(true);

    try {
      await companyApi.updateCompanyStatus(selectedCompanyId, 1);
      await fetchCompanyDetails();
      enqueueSnackbar(`Company is set to Draft`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to set to Draft`, {
        variant: 'error',
      });
    }
    setLoadingSubmit(false);
  };

  const [terminationForm, setTerminationForm] = useState({
    show: false,
    data: null,
  });

  return (
    <>
      <AppBar style={{ background: 'transparent', boxShadow: 'none' }} position="static">
        <Toolbar style={{ padding: '0px' }}>
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={0}
          >
            <Grid item>
              <Button
                size="medium"
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                // onClick={() => history.goBack()}
                onClick={() => history.push({ pathname: '/company' })}
              >
                BACK TO COMPANIES DATABASE
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                // onClick={() => history.goBack()}
                onClick={() =>
                  history.push({
                    pathname: '/companydocuments',
                    search: '?company=' + selectedCompanyId,
                  })
                }
              >
                GO TO COMPANY DOCUMENTS
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <PageTitle
        title={`Company Details (ID: ${selectedCompanyId})`}
        // button={
        //   isLoading ? null : (
        //     <Box display="flex" justifyContent="flex-end" alignItems="center">
        //       <Box>
        //         {companyDetail?.publish_status_id == 3 ? (
        //           <Button variant="contained" color="primary" onClick={handleOnDraftCompany}>
        //             Set To Draft
        //           </Button>
        //         ) : (
        //           <Button variant="contained" color="primary" onClick={handleOnPublishCompany}>
        //             Publish Now
        //           </Button>
        //         )}
        //       </Box>
        //       <Box ml={2}>
        //         <h3>Status: {companyDetail?.publish_status_name || '-'}</h3>
        //       </Box>
        //     </Box>
        //   )
        // }
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {/* <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Company Category:-
            </Typography>
          </div>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Client's Shareholder Company{" "}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  "";
                }}
              >
                Change to Client's Company
              </Button>
            </Typography>
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mr={2}>
                <b>Status: {companyDetail?.publish_status_name || '-'}</b>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box>
                    {companyDetail?.publish_status_id == 3 ? (
                      <Button variant="contained" color="primary" onClick={handleOnDraftCompany}>
                        Set To Draft
                      </Button>
                    ) : (
                      <Button variant="contained" color="primary" onClick={handleOnPublishCompany}>
                        Publish Now
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems={'center'}>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => {
                      setTerminationForm((prev) => ({
                        ...prev,
                        show: true,
                      }));
                    }}
                  >
                    Terminate
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h4" size="sm">
              Company Details {companyDetail?.company_id && `(ID: ${companyDetail?.company_id})`}
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'cd' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={
                      disableCompanyDetails ? handleEditCompanyDetails : handleSubmitCompanyDetails
                    }
                  >
                    {disableCompanyDetails
                      ? 'Edit Company Details'
                      : 'Confirm Change of Company Details'}
                  </Button>
                )}
                {disableCompanyDetails ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelCompanyDetails}>
                    Cancel
                  </Button>
                )}
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormFieldContainer label="Company Category">
                <RadioGroup
                  aria-label="company_client_type_id"
                  // name="company_client_type_id"
                  value={companyCategoryId}
                  onChange={(e) => {
                    setCompanyCategoryId(parseInt(e.target.value));
                  }}
                  row
                >
                  <FormControlLabel
                    value={client_company_id}
                    control={<Radio />}
                    label="Client Company"
                    disabled={disableCompanyDetails}
                  />
                  <FormControlLabel
                    value={client_shareholder_company_id}
                    control={<Radio />}
                    label="Client's Shareholder Company"
                    disabled={disableCompanyDetails}
                  />
                  <FormControlLabel
                    value={client_auditor_company_id}
                    control={<Radio />}
                    label="Client's Auditor Company"
                    disabled={disableCompanyDetails}
                  />
                </RadioGroup>
              </FormFieldContainer>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disableCompanyDetails}
                required
                id="registerednumber"
                name="registerednumber"
                label="Registered Number"
                fullWidth
                value={registerednumbervalue}
                onChange={handleChangeRegisteredNumber}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgRegisteredNumber !== '' ? true : false}
                helperText={errorMsgRegisteredNumber}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disableCompanyDetails}
                required
                id="companyname"
                name="companyname"
                label="Company Name"
                fullWidth
                value={companynamevalue}
                onChange={handleChangeCompanyName}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgCompanyName !== '' ? true : false}
                helperText={errorMsgCompanyName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                id="formercompanyname"
                name="formercompanyname"
                label="Former Name if any"
                value={formercompanynamevalue}
                fullWidth
                // onChange={handleChangeFirstName}
                onInput={(e) => setFormercompanynamevalue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                fullWidth
                id="date"
                label="wef"
                type="date"
                defaultValue={
                  // "2017-05-24"
                  wefdatevalue === undefined ? null : moment(wefdatevalue).format('YYYY-MM-DD')
                }
                onInput={(e) => setWefdatevalue(e.target.value)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                required={!isShareholderCompany}
                fullWidth
                id="date"
                label="Incorporation Date"
                type="date"
                // value={incorporationdatevalue}
                defaultValue={
                  // "2017-05-24"
                  incorporationdatevalue === undefined
                    ? null
                    : moment(incorporationdatevalue).format('YYYY-MM-DD')
                }
                onChange={handleChangeIncorporationDate}
                error={errorMsgIncorporationDate !== '' ? true : false}
                helperText={errorMsgIncorporationDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={companytypeerror}
                required={!isShareholderCompany}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableCompanyDetails}>Company Types</InputLabel>
                <Select
                  disabled={disableCompanyDetails}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={companytypevalue}
                  onChange={handleChangeCompanyType}
                  defaultValue={
                    companytypevalue === undefined
                      ? null
                      : companytypelist?.find((v) => v.company_type_id === companytypevalue)
                          ?.company_type_id || null
                  }
                  error={companytypeerror}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {companytypelist?.map((element) => (
                    <MenuItem value={element.company_type_id}>{element.company_type_name}</MenuItem>
                  )) || null}
                </Select>
                <FormHelperText hidden={!companytypeerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={companystatuserror}
                required={!isShareholderCompany}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableCompanyDetails} id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  disabled={disableCompanyDetails}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={companystatusvalue}
                  onChange={handleChangeCompanyStatus}
                  error={companystatuserror}
                  defaultValue={
                    companystatusvalue === undefined
                      ? null
                      : companystatuslist?.find((v) => v.company_status_id === companystatusvalue)
                          ?.company_status_id || null
                  }
                >
                  {companystatuslist?.map((element) => (
                    <MenuItem value={element.company_status_id}>
                      {element.status_category} - {element.company_status_name}
                    </MenuItem>
                  )) || null}
                </Select>
                <FormHelperText hidden={!companystatuserror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                fullWidth
                id="date"
                label="Status Date"
                type="date"
                required={!isShareholderCompany}
                error={errorMsgStatusDate !== '' ? true : false}
                helperText={errorMsgStatusDate}
                onChange={handleChangeStatusDate}
                defaultValue={
                  // "2017-05-24"
                  statusdatevalue === undefined
                    ? null
                    : moment(statusdatevalue).format('YYYY-MM-DD')
                }
                // onInput={(e) => setBodvalue(e.target.value)}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={incorporationcountryerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableCompanyDetails} id="demo-simple-select-label">
                  Country of Incorporation
                </InputLabel>
                <Select
                  disabled={disableCompanyDetails}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={incorporationcountryvalue}
                  onChange={handleChangeIncorporationCountry}
                  error={incorporationcountryerror}
                  defaultValue={
                    incorporationcountryvalue === undefined
                      ? null
                      : countrylist?.find((v) => v.country_id === incorporationcountryvalue)
                          .country_id
                  }
                >
                  {countrylist?.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  )) || null}
                </Select>
                <FormHelperText hidden={!incorporationcountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                fullWidth
                id="date"
                label={fields_companyDetail.first_fye_date.label}
                type="date"
                required={!isShareholderCompany}
                error={errorMsgFirstFyeDateValue !== '' ? true : false}
                helperText={errorMsgFirstFyeDateValue}
                onChange={handleChangeFirstFyeDateDate}
                defaultValue={
                  // "2017-05-24"
                  firstFyeDateValue === undefined
                    ? null
                    : moment(firstFyeDateValue).format('YYYY-MM-DD')
                }
                // onInput={(e) => setBodvalue(e.target.value)}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: minDate_fye,
                  max: maxDate_first_fye,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled={disableCompanyDetails}
                fullWidth
                id="date"
                label={fields_companyDetail.fye_date.label}
                type="date"
                required={!isShareholderCompany}
                error={errorMsgFyeDateValue !== '' ? true : false}
                helperText={errorMsgFyeDateValue}
                onChange={handleChangeFyeDateDate}
                defaultValue={
                  // "2017-05-24"
                  fyeDateValue === undefined ? null : moment(fyeDateValue).format('YYYY-MM-DD')
                }
                value={fyeDateValue}
                // onInput={(e) => setBodvalue(e.target.value)}
                // defaultValue={moment().format("YYYY-MM-DD")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: minDate_today,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_certificateOfIncorporation}
                placeholder="Upload Certificate of Incorporation"
                previewLink={certificateIncorporationDocView?.signed_document_url}
                previewLabel={'View Certificate of Incorporation'}
                disabled={disableCompanyDetails}
                document={certificateIncorporationDocView}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Principal Activities
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'pa' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={
                      disablePrincipalActivities
                        ? handleEditPrincipalActivities
                        : handleSubmitPrincipalActivities
                    }
                  >
                    {disablePrincipalActivities
                      ? 'Edit Principal Activities'
                      : 'Confirm Change of Principal Activities'}
                  </Button>
                )}
                {disablePrincipalActivities ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelPrincipalActivities}>
                    Cancel
                  </Button>
                )}
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                // {...defaultProps}
                options={ssiclist}
                getOptionLabel={(option) => option.ssic_name}
                // options={userList}
                // getOptionLabel={(option) =>
                //   option.first_name +
                //   " (" +
                //   option.identification_name +
                //   " - " +
                //   option.identification_value +
                //   ")"
                // }
                defaultValue={
                  activity1value === undefined
                    ? null
                    : ssiclist.find((v) => v.ssic_id === activity1value)
                }
                // defaultValue={
                //   arrCompanyofficers[0] === undefined
                //     ? null
                //     : userList.find(
                //         (v) => v.user_id === arrCompanyofficers[0].user_id
                //       )
                // }
                id="auto-complete"
                autoComplete
                includeInputInList
                disabled={disablePrincipalActivities}
                onChange={handleChangeActivity1}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Activity 1"
                    margin="normal"
                    // value={activity1value}
                    error={errorMsgActivity1 !== '' ? true : false}
                    helperText={errorMsgActivity1}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                disablePortal={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disablePrincipalActivities}
                id="activty1desc"
                name="activty1desc"
                label="Activity 1 Description"
                fullWidth
                value={activity1desc}
                // onChange={handleChangeFirstName}
                onInput={(e) => setActivity1desc(e.target.value)}
                // error={errorMsgFirstName !== "" ? true : false}
                // helperText={errorMsgFirstName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={ssiclist}
                getOptionLabel={(option) => option.ssic_name}
                defaultValue={
                  activity2value === undefined
                    ? null
                    : ssiclist.find((v) => v.ssic_id === activity2value)
                }
                id="auto-complete"
                autoComplete
                includeInputInList
                disabled={disablePrincipalActivities}
                onChange={handleChangeActivity2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Activity 2"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                disablePortal={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="activty2desc"
                name="activty2desc"
                label="Activity 2 Description"
                fullWidth
                value={activity2desc}
                disabled={disablePrincipalActivities}
                // onChange={handleChangeFirstName}
                onInput={(e) => setActivity2desc(e.target.value)}
                // error={errorMsgFirstName !== "" ? true : false}
                // helperText={errorMsgFirstName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_bizFile}
                placeholder="Upload Biz File"
                previewLink={bizFileDocView?.signed_document_url}
                previewLabel={'View Biz File'}
                disabled={disablePrincipalActivities}
                document={bizFileDocView}
              />
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Capital
            </Typography>
          </div>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h6">
              Issued Share Capital
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'isc' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={disableShares ? handleEditShareCapital : handleSubmitShareCapital}
                  >
                    {disableShares ? 'Edit Share Capital' : 'Confirm Change of ShareCapital'}
                  </Button>
                )}
                {disableShares ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelShareCapital}>
                    Cancel
                  </Button>
                )}
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <FormControl
                // error={sharetypeerror}
                required={!isShareholderCompany && dynamicRequireCompanyType}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Share Types
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={currencyvalue}
                  onChange={handleChangeShareType}
                  //   error={sharetypeerror}
                  defaultValue={1}
                  disabled
                >
                  {sharetypelist?.map((element) => (
                    <MenuItem value={element.share_type_id}>{element.share_type_name}</MenuItem>
                  )) || null}
                </Select>
                {/* <FormHelperText hidden={!sharetypeerror} error>
                  Please select.
                </FormHelperText> */}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                required={!isShareholderCompany && dynamicRequireCompanyType}
                id="amount"
                name="amount"
                label="Amount Capital"
                type="number"
                fullWidth
                value={amountsharevalue}
                disabled={disableShares}
                onChange={handleChangeAmountShare}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgAmountShare !== '' ? true : false}
                helperText={errorMsgAmountShare}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                required={!isShareholderCompany && dynamicRequireCompanyType}
                disabled={disableShares}
                id="numbershares"
                name="numbershares"
                label="Issued Number of Shares"
                type="number"
                value={numbersharevalue}
                fullWidth
                onChange={handleChangeNumberShare}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgNumberShare !== '' ? true : false}
                helperText={errorMsgNumberShare}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required={!isShareholderCompany && dynamicRequireCompanyType}
                id="paidupcapital"
                name="paidupcapital"
                label="Paidup Capital"
                type="number"
                fullWidth
                value={paidupamountvalue}
                disabled={disableShares}
                onChange={handleChangePaidupAmount}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                error={errorMsgPaidupAmount !== '' ? true : false}
                helperText={errorMsgPaidupAmount}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl
                error={sharecurrencyerror}
                required={!isShareholderCompany && dynamicRequireCompanyType}
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Currency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={disableShares}
                  // value={sharecurrencyvalue}
                  onChange={handleChangeShareCurrency}
                  error={sharecurrencyerror}
                  defaultValue={
                    sharecurrencyvalue === undefined
                      ? null
                      : currencylist?.find((v) => v.currency_id === sharecurrencyvalue).currency_id
                  }
                >
                  {currencylist?.map((element) => (
                    <MenuItem value={element.currency_id}>
                      {element.country_name} - {element.currency_name}
                    </MenuItem>
                  )) || null}
                </Select>
                <FormHelperText hidden={!sharecurrencyerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <FormControl
                // error={sharetypeerror}
                // required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Share Types
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={currencyvalue}
                  onChange={handleChangePreferenceShareType}
                  //   error={sharetypeerror}
                  defaultValue={2}
                  disabled
                >
                  {sharetypelist?.map((element) => (
                    <MenuItem value={element.share_type_id}>{element.share_type_name}</MenuItem>
                  )) || null}
                </Select>
                {/* <FormHelperText hidden={!sharetypeerror} error>
                  Please select.
                </FormHelperText> */}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                // required
                id="amount"
                name="amount"
                label="Amount Capital"
                type="number"
                fullWidth
                value={preferenceamountsharevalue}
                disabled={disableShares}
                onChange={handleChangePreferenceAmountShare}
                // onInput={(e) => setFirstnamevalue(e.target.value)}
                // error={errorMsgAmountShare !== "" ? true : false}
                // helperText={errorMsgAmountShare}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requirePreferenceshare}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                // required
                id="numbershares"
                name="numbershares"
                label="Issued Number of Shares"
                type="number"
                value={preferencenumbersharevalue}
                disabled={disableShares}
                fullWidth
                onChange={handleChangePreferenceNumberShare}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requirePreferenceshare}

                // onInput={(e) => setFirstnamevalue(e.target.value)}
                // error={errorMsgNumberShare !== "" ? true : false}
                // helperText={errorMsgNumberShare}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                // required
                id="paidupcapital"
                name="paidupcapital"
                label="Paidup Capital"
                type="number"
                disabled={disableShares}
                value={preferencepaidupamountvalue}
                fullWidth
                onChange={handleChangePreferencePaidupAmount}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requirePreferenceshare}

                // onInput={(e) => setFirstnamevalue(e.target.value)}
                // error={errorMsgPaidupAmount !== "" ? true : false}
                // helperText={errorMsgPaidupAmount}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl
                // error={sharecurrencyerror}
                // required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Currency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={disableShares}
                  // value={preferenccesharecurrencyvalue}
                  onChange={handleChangePreferenceShareCurrency}
                  defaultValue={
                    preferencesharecurrencyvalue === undefined
                      ? null
                      : currencylist?.find((v) => v.currency_id === preferencesharecurrencyvalue)
                          .currency_id
                  }
                  required={requirePreferenceshare}

                  //   error={sharecurrencyerror}
                >
                  {currencylist?.map((element) => (
                    <MenuItem value={element.currency_id}>
                      {element.country_name} - {element.currency_name}
                    </MenuItem>
                  )) || null}
                </Select>
                {/* <FormHelperText hidden={!sharecurrencyerror} error>
                  Please select.
                </FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <FormControl
                // error={sharetypeerror}
                // required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Share Types
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //   value={currencyvalue}
                  onChange={handleChangeOtherShareType}
                  //   error={sharetypeerror}
                  defaultValue={3}
                  disabled
                >
                  {sharetypelist?.map((element) => (
                    <MenuItem value={element.share_type_id}>{element.share_type_name}</MenuItem>
                  )) || null}
                </Select>
                {/* <FormHelperText hidden={!sharetypeerror} error>
                  Please select.
                </FormHelperText> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                // required
                id="amount"
                name="amount"
                label="Amount Capital"
                type="number"
                value={otheramountsharevalue}
                fullWidth
                disabled={disableShares}
                onChange={handleChangeOtherAmountShare}
                // onInput={(e) => setOtheramountsharevalue(e.target.value)}
                // error={errorMsgAmountShare !== "" ? true : false}
                // helperText={errorMsgAmountShare}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requireOthershare}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                // required
                id="numbershares"
                name="numbershares"
                label="Issued Number of Shares"
                type="number"
                fullWidth
                value={othernumbersharevalue}
                disabled={disableShares}
                onChange={handleChangeOtherNumberShare}
                // onInput={(e) => setOthernumbersharevalue(e.target.value)}
                // error={errorMsgNumberShare !== "" ? true : false}
                // helperText={errorMsgNumberShare}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requireOthershare}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                // required
                id="paidupcapital"
                name="paidupcapital"
                label="Paidup Capital"
                type="number"
                fullWidth
                value={otherpaidupamountvalue}
                disabled={disableShares}
                onChange={handleChangeOtherPaidupAmount}
                // onInput={(e) => setOtherpaidupamountvalue(e.target.value)}
                // error={errorMsgPaidupAmount !== "" ? true : false}
                // helperText={errorMsgPaidupAmount}
                InputLabelProps={{
                  shrink: true,
                }}
                required={requireOthershare}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl
                // error={sharecurrencyerror}
                // required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableShares} id="demo-simple-select-label">
                  Currency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={disableShares}
                  // value={othersharecurrencyvalue}
                  //   onInput={(e) => setOthersharecurrencyvalue(e.target.value)}
                  onChange={handleChangeOtherShareCurrency}
                  //   error={sharecurrencyerror}
                  defaultValue={
                    othersharecurrencyvalue === undefined
                      ? null
                      : currencylist?.find((v) => v.currency_id === othersharecurrencyvalue)
                          .currency_id
                  }
                  required={requireOthershare}
                >
                  {currencylist?.map((element) => (
                    <MenuItem value={element.currency_id}>
                      {element.country_name} - {element.currency_name}
                    </MenuItem>
                  )) || null}
                </Select>
                {/* <FormHelperText hidden={!sharecurrencyerror} error>
                  Please select.
                </FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h6">
              Treasury Shares
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'ts' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={
                      disableTreasuryShare ? handleEditTreasuryShare : handleSubmitTreasuryShare
                    }
                  >
                    {disableTreasuryShare
                      ? 'Edit Treasury Share'
                      : 'Confirm Change of Treasury Share'}
                  </Button>
                )}
                {disableTreasuryShare ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelTreasuryShare}>
                    Cancel
                  </Button>
                )}
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="numbershares"
                name="numbershares"
                label="Issued Number of Shares"
                type="number"
                value={treasurysharevalue}
                disabled={disableTreasuryShare}
                fullWidth
                onInput={(e) => setTreasurysharevalue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          {/* <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Registered Address
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'ra' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={disableAddress ? handleEditAddress : handleSubmitAddress}
                  >
                    {disableAddress ? 'Edit Address' : 'Confirm Change of Address'}
                  </Button>
                )}
                {disableAddress ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelAddress}>
                    Cancel
                  </Button>
                )}
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div> */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3" size="sm">
                  Registered Address
                </Typography>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    setCompanyAddressForm({
                      show: true,
                      data: null,
                    });
                  }}
                >
                  Add Company Address
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CompanyAddressTable
                data={selectedCompanyAddresses}
                onEdit={(data) => {
                  setCompanyAddressForm({
                    show: true,
                    data: data,
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl
                error={addresstypeerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableAddress} id="demo-simple-select-label">
                  Address Types
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresstypevalue}
                  disabled={disableAddress}
                  onChange={handleChangeAddressType}
                  error={addresstypeerror}
                >
                  {addresstypelist.map((element) => (
                    <MenuItem value={element.address_type_id}>{element.address_type_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!addresstypeerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="unitno"
                name="unitno"
                label="Floor No./Unit No."
                fullWidth
                value={addressunitvalue}
                disabled={disableAddress}
                onInput={(e) => setAddressunitvalue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="street"
                name="street"
                label="Street / Road"
                fullWidth
                value={addressroadvalue}
                disabled={disableAddress}
                onChange={handleChangeAddressRoad}
                // onInput={(e) => setAddressroadvalue(e.target.value)}
                error={errorMsgaddressroad !== '' ? true : false}
                helperText={errorMsgaddressroad}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // required
                id="addressline2"
                name="addressline2"
                label="Address Line 2"
                fullWidth
                value={addressline2value}
                disabled={disableAddress}
                onChange={handleChangeAddress2}
                // onInput={(e) => setAddressline2value(e.target.value)}
                // error={errorMsgaddressline2 !== "" ? true : false}
                // helperText={errorMsgaddressline2}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="postal"
                name="postal"
                label="Postal Code"
                fullWidth
                value={addresspostalvalue}
                onChange={handleChangeAddressPostal}
                disabled={disableAddress}
                // onInput={(e) => setAddresspostalvalue(e.target.value)}
                type="number"
                error={errorMsgaddresspostal !== '' ? true : false}
                helperText={errorMsgaddresspostal}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                error={addresscountryerror}
                required
                fullWidth
                className={classes.formControl}
              >
                <InputLabel disabled={disableAddress} id="demo-simple-select-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addresscountryvalue}
                  onChange={handleChangeAddressCountry}
                  error={addresscountryerror}
                  disabled={disableAddress}
                >
                  {countrylist.map((element) => (
                    <MenuItem value={element.country_id}>{element.country_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText hidden={!addresscountryerror} error>
                  Please select.
                </FormHelperText>
              </FormControl>
            </Grid> */}
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Upload Documents
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                {loadingSubmitSection == 'pd' ? (
                  <Button color="primary" variant="link" component="span" disabled={true}>
                    <CircularProgress size={26} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isPublished}
                    onClick={disableDocuments ? handleEditDocuments : handleSubmitDocuments}
                  >
                    {disableDocuments ? 'Edit Documents' : 'Confirm Change of Documents'}
                  </Button>
                )}
                {disableDocuments ? null : (
                  <Button size="medium" color="secondary" onClick={handleCancelDocuments}>
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_annualReport}
                placeholder="Upload Annual Report"
                previewLink={annualReportDocView?.signed_document_url}
                previewLabel={'View Annual Report'}
                disabled={disableDocuments}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_auditedReport}
                placeholder="Upload Audited Report"
                previewLink={auditedReportDocView?.signed_document_url}
                previewLabel={'View Audited Report'}
                disabled={disableDocuments}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_constitutionalDocument}
                placeholder="Upload Constitutional Document"
                previewLink={constitutionalDocumentView?.signed_document_url}
                previewLabel={'View Constitutional Document'}
                disabled={disableDocuments}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomUploadButton
                ref={ref_managementAccounts}
                placeholder="Upload Management Accounts"
                previewLink={managementAccountsView?.signed_document_url}
                previewLabel={'View Management Accounts'}
                disabled={disableDocuments}
              />
            </Grid> */}
            <Box width="100%">
              {selectedDocuments.map((anItem, index) => {
                const handleAddDocument = (allDocs = [], selectedDocs = []) => {
                  setSelectedDocuments((prev) => {
                    return prev.map((aPrevItem) => {
                      if (aPrevItem.document_type_id == anItem.document_type_id) {
                        return {
                          ...aPrevItem,
                          data: allDocs,
                        };
                      }
                      return aPrevItem;
                    });
                  });
                };
                const handleRemoveDocument = (allDocs = [], selectedDoc) => {
                  setSelectedDocuments((prev) => {
                    return prev.map((aPrevItem) => {
                      if (aPrevItem.document_type_id == anItem.document_type_id) {
                        return {
                          ...aPrevItem,
                          data: allDocs,
                        };
                      }
                      return aPrevItem;
                    });
                  });
                };
                return (
                  <Box key={index} mb={2}>
                    {index != 0 ? (
                      <Box mb={1}>
                        <Divider />
                      </Box>
                    ) : null}
                    <CompanyDocumentsGroupItem
                      title={anItem.label}
                      value={anItem.data || []}
                      onAdd={handleAddDocument}
                      onRemove={handleRemoveDocument}
                      disabled={disableDocuments}
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Company Officers
            </Typography>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleOpenCompanyOfficerDialog}
              disabled={isPublished}
            >
              Add Company Officer
            </Button>
          </div>
          <Grid container spacing={3}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <CompanyOfficerTable
                data={arrCompanyofficers}
                companyRoles={companyrolelist}
                cessationTypes={cessationtypeList}
                handleEditOfficer={handleEditOfficer}
                disabled={isPublished}
              />
            )}
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Shareholders
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleOpenShareholderDialog}
                  disabled={isPublished}
                >
                  Add Shareholder
                </Button>
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <ShareholderTable
                dataTable={arrShareholders}
                shareData={sharetypelist}
                handleEditShareholder={handleEditShareholder}
                idData={identificationypeList}
                disabled={isPublished}
              />
            )}
          </Grid>
          <div className={classes.pageTitleContainer}>
            <Typography className={classes.typo} variant="h3" size="sm">
              Meyzer360 Company Account Admin
            </Typography>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    setSelectedNewAdmin('new');
                  }}
                  disabled={isPublished}
                >
                  Add Account Admin
                </Button>
                {isLoadingbtn && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <SuperAdminTable
              newAdmins={sysInfoUserRolesByCompany}
              selectedNewAdmin={selectedNewAdmin}
              setSelectedNewAdmin={setSelectedNewAdmin}
              refetchData={getSysInfoUserRoles}
              disabled={isPublished}
            />
          </Grid>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h3" size="sm">
                    Company Auditors
                  </Typography>
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => {
                      setAuditorCompanyForm({
                        show: true,
                        data: null,
                      });
                    }}
                    disabled={isPublished}
                  >
                    Add Company Auditor
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AuditorCompanyTable
                  data={auditorCompanies}
                  onEdit={(data) => {
                    setAuditorCompanyForm({
                      show: true,
                      data: data,
                    });
                  }}
                  disabled={isPublished}
                />
              </Grid>
            </Grid>
          </Box>

          <Dialog
            onBackdropClick="false"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{'Submission'}</DialogTitle>
            <DialogContent>
              <DialogContentText>The submission is submitted successfully.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>

          <CompanyOfficerDialog
            open={openCompanyOfficerDialog}
            handleClose={handleCloseCompanyOfficerDialog}
            handleSubmit={handleSubmitCompanyOfficerDialog}
            // editableOfficer={editableOfficer}
            userData={userList}
            companyRoles={companyrolelist}
            cessationTypes={cessationtypeList}
            existOfficer={arrCompanyofficers[editOfficerId]}
            handleRemoveOfficer={handleRemoveOfficer}
          />
          <ShareholderDialog
            open={openShareholderDialog}
            handleClose={handleCloseShareholderDialog}
            handleSubmit={handleSubmitShareholderDialog}
            // editableShareholder={editableShareholder}
            userData={userList}
            shareData={arrShareData}
            allShareholders={arrShareholders}
            existShareholder={arrShareholders[editShareholderId]}
            companyData={companyList}
            handleRemoveShareholder={handleRemoveShareholder}
          />
          <AuditorCompanyModalForm
            {...auditorCompanyForm}
            onHide={() => {
              setAuditorCompanyForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleEditAuditorCompanyForm}
            onRemove={handleRemoveAuditorCompany}
          />
          <CompanyTerminationModalForm
            show={terminationForm?.show}
            data={companyDetail}
            onHide={() => {
              setTerminationForm({
                show: false,
                data: null,
              });
            }}
            refetchCompany={refetchCompany}
          />
          <CompanyAddressModalForm
            {...companyAddressForm}
            onHide={() => {
              setCompanyAddressForm({
                show: false,
                data: null,
              });
            }}
            onSubmit={handleSubmitCompanyAddressForm}
            onRemove={handleRemoveCompanyAddress}
          />

          {/* <<<<<<< HEAD
          <CompanyTerminationModalForm
            show={terminationForm?.show}
            data={companyDetail}
            onHide={() => {
              setTerminationForm({
=======
          <CompanyAddressModalForm
            {...companyAddressForm}
            onHide={() => {
              setCompanyAddressForm({
>>>>>>> da7be8be8869fa39d9220fb33d40b400eef87d88
                show: false,
                data: null,
              });
            }}
            refetchCompany={refetchCompany}
            onSubmit={handleSubmitCompanyAddressForm}
            onRemove={handleRemoveCompanyAddress}
          /> */}
        </div>
      )}

      <Backdrop className={classes.backdrop} open={loadingSubmit} style={{ zIndex: 1301 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorApiMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Companyform;
