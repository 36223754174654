import { Icon } from '@iconify/react';
import { Box, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import PageTitle from '../../components/PageTitle/PageTitle';
import webdevSvc from '../../services/webdev';
import useQueryParams from '../../hooks/useQueryParams';

import useStyles from './styles';

export default function RequestDetail() {
  const history = useHistory();
  var classes = useStyles();
  let query = useQueryParams();
  const devid = query.get('devid');
  const [loading, setLoading] = useState(true);

  const [dev, setDev] = useState();
  useEffect(() => {
    (() => {
      webdevSvc
        .getDev(devid)
        .then((res) => {
          setDev(res);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [history]);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Box pt={3} display="flex" flexDirection="column">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(222, 173, 0, 1)',
            cursor: 'pointer',
          }}
          onClick={() => {}}
        >
          <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
          <Typography
            variant="body1"
            align="left"
            style={{
              fontWeight: 600,
            }}
            onClick={() => history.push({ pathname: '/webdevs' })}
          >
            BACK TO REQUESTS
          </Typography>
        </Box>

        <PageTitle title="WEB DEV DETAIL" />
        <Box pt={3} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField required label="Status" variant="standard" value={dev.webdev_id} fullWidth />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField required label="Theme" value={dev.theme} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField required label="Plan" value={dev.plan} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField required label="Category" value={dev.webdev_category_name} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField required label="Domain" value={dev.domain_name} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Template"
              value={dev.template_content}
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
