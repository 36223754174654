import React, { useState, useEffect, useRef } from 'react';
import { Table, TableRow, TableHead, TableBody, TableCell, Chip } from '@material-ui/core';
import useStyles from '../../styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import CustomTable from '../../../../components/CustomTable';

const states = {
  sent: 'success',
  pending: 'warning',
  declined: 'secondary',
};

export default function TableComponent({
  dataTable,
  shareData,
  handleEditShareholder,
  idData,
  disabled = false,
}) {
  const classes = useStyles();
  //   var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  //   keys.shift(); // delete "id" key

  const [data, setData] = React.useState([]);
  const [isLoadingTbl, setLoadingTbl] = useState(true);
  console.log('sharetbl_b4', data);
  console.log('sharetbl_dataTable', data);

  useEffect(() => {
    setData(dataTable);
    console.log('sharetbl', data);
    setLoadingTbl(false);
  }, [dataTable, data]);

  var keys = [
    ''.toUpperCase(),
    'name'.toUpperCase(),
    'identification'.toUpperCase(),
    'identification number'.toUpperCase(),
    'share type'.toUpperCase(),
    'number of share'.toUpperCase(),
  ];

  //   user_firstname: "abc"
  //   user_id: 1
  //   user_idtype: "ic"
  //   user_idvalue: "123"
  //   user_sharenumber: 100
  //   user_sharetype: "ordinary"

  const shareholderTableColumns = [
    {
      label: '',
      id: 'row_id',
      render: (text) => {
        return (
          <Chip
            color="secondary"
            onClick={() => handleEditShareholder(text)}
            clickable
            label={'EDIT'} //+ row_id}
            disabled={disabled}
          />
        );
      },
    },
    {
      label: 'NAME',
      id: 'Name',
    },
    {
      label: 'IDENTIFICATION',
      id: 'user_idtype',
      render: (text) => {
        return text === undefined
          ? null
          : idData.find((v) => v.identification_type_id === text)?.identification_name || '-';
      },
    },
    {
      label: 'IDENTIFICATION NUMBER',
      id: 'user_idvalue',
    },
    {
      label: 'SHARE TYPE',
      id: 'user_sharetype',
      render: (text) => {
        return shareData.find((v) => v.share_type_id === text)?.share_type_name || '-';
      },
    },
    {
      label: 'NUMBER OF SHARE',
      id: 'user_sharenumber',
    },
  ];
  return <CustomTable data={dataTable} columns={shareholderTableColumns} rowKey={'user_id'} />;
  return (
    <Paper style={{ width: '100%', overflowX: 'auto' }}>
      <Table className="mb-0">
        <TableHead>
          <TableRow>
            {keys.map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dataTable.map(
            (
              {
                row_id,
                user_id,
                Name,
                user_idtype,
                user_idvalue,
                user_sharetype,
                user_sharenumber,
              },
              i
            ) => (
              <TableRow key={user_id}>
                <TableCell>
                  <Chip
                    color="secondary"
                    onClick={() => handleEditShareholder(row_id)}
                    clickable
                    label={'EDIT'} //+ row_id}
                  />
                </TableCell>
                <TableCell className="pl-3 fw-normal">{Name}</TableCell>
                <TableCell>
                  {user_idtype === undefined
                    ? null
                    : idData.find((v) => v.identification_type_id === user_idtype)
                        .identification_name}
                </TableCell>
                <TableCell>{user_idvalue}</TableCell>
                <TableCell>
                  {/* {user_sharetype} */}
                  {/* {shareData[user_sharetype - 1].share_type_name} */}
                  {shareData.find((v) => v.share_type_id === user_sharetype).share_type_name}
                  {/* {user_sharetype} */}
                </TableCell>
                <TableCell>{user_sharenumber}</TableCell>
                {/* <TableCell onClick={() => handleEditShareholder(i)}>
                Edit
              </TableCell> */}
                {/* <TableCell>Remove</TableCell> */}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
