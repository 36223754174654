import axios from "./customAxios";

const timeline = async () => {
  const { data } = await axios.get(`/company/timeline`);

  if (data && !data.is_success) throw Error(data?.message);
  console.log("timelineapi", data?.timeline);
  return data?.timeline;
};

const getTimeline = async (id) => {
  const { data } = await axios.get(`/company/timeline/${id}`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.timeline;
};

const addTimeline = async (
  company_id,
  task_id,
  event_name,
  user_id,
  event_status,
  start_date,
  end_date,
  period
) => {
  let data = {
    event_type_id: 1,
    event_name: event_name,
    event_description: event_name,
    event_datetime: start_date,
    event_action: event_status,
    company_id: company_id,
    user_id: user_id,
    task_id: task_id,
    event_status: event_status,
    start_date: start_date,
    end_date: end_date,
    period: period,
  };
  console.log("data", data);

  return axios.post(`/timeline`, data);
};

const timelineSvc = {
  timeline,
  getTimeline,
  addTimeline,
};

export default timelineSvc;
