import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

// styles
import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import {
  getAdminUserMenuByUserId,
  createAdminUserMenu,
  getMenu,
} from '../../services/module.service';
import { useUserState } from '../../context/UserContext';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomCheckboxGroupField from '../../components/CustomFormFields/CustomCheckboxGroupField';
import CustomLoading from '../../components/CustomLoading';
import useCommonOptions from '../../hooks/useCommonOptions';
const fields = {
  menu_id: {
    name: 'menu_id',
    label: 'Menu',
  },
};

const validationSchemaObj = {
  [fields.menu_id.name]: Yup.array().default([]),
};

const AdminUserMenuForm = (props) => {
  const { defaultValues = {}, id, afterSubmit } = props;
  const { userId: authUserId } = useUserState();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape(validationSchemaObj);
  let initialValues = { ...validationSchema.cast(), ...defaultValues };
  const [adminUserMenus, setAdminUserMenus] = useState([]);
  const {
    options: defaultMenuOptions,
    loading: loadingMenus,
    rawData: menus = [],
  } = useCommonOptions(getMenu, {
    respondKey: 'menus',
    labelKey: 'menu_name',
    valueKey: 'menu_id',
  });

  useEffect(() => {
    if (authUserId) {
      (async () => {
        try {
          let res = await getAdminUserMenuByUserId(id);
          let resultData =
            res?.data?.admin_user_menus?.length > 0 ? res?.data?.admin_user_menus : [];
          setAdminUserMenus(resultData);
        } catch (error) {
          setAdminUserMenus([]);
        }
      })();
    } else {
      setAdminUserMenus([]);
    }
  }, [authUserId]);

  const onSubmit = async (values, actions) => {
    let selectedMenus = values?.menu_id || [];

    if (selectedMenus?.length > 0) {
      try {
        for (let i = 0; i < selectedMenus.length; i++) {
          let selectedMenu = selectedMenus[i];
          let adminUsersRes = await createAdminUserMenu({
            menu_id: selectedMenu,
            user_id: id,
            created_by: authUserId,
          });
          enqueueSnackbar('Admin User Menu Added', {
            variant: 'success',
          });
        }
        if (afterSubmit) {
          afterSubmit();
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Menu`, {
          variant: 'error',
        });
      }
    }
  };

  let selectedMenu = adminUserMenus.map((anItem) => anItem.menu_id);
  let menuOptions = defaultMenuOptions.filter((anItem) => {
    if (selectedMenu.indexOf(anItem.value) < 0) {
      return true;
    }
    return false;
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { values, handleSubmit, validateForm, setTouched, touched } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {adminUserMenus?.length > 0 ? (
                <Grid item xs={12}>
                  <div>
                    <b>Menu</b>
                  </div>
                  {adminUserMenus?.map((anItem, index) => {
                    return (
                      <div key={index}>
                        <div>{anItem?.menu_name}</div>
                      </div>
                    );
                  })}
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Box height="300px" overflow="auto">
                  <CustomCheckboxGroupField
                    name={fields.menu_id.name}
                    label={fields.menu_id.label}
                    required={true}
                    options={menuOptions}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box ml="auto" width="100%" display="flex" justifyContent="space-between">
                  <Box ml="auto">
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
export default AdminUserMenuForm;
