import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Chip, TextField, CircularProgress, Backdrop } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import CustomModal from '../../../components/CustomModal';
import companyApi from '../../../services/company.service';

export const CompanyTerminationModalForm = (props) => {
  const {
    data = {},
    show = false,
    onHide,
    disabled = false,
    fetchInitialValues,
    refetchCompany,
  } = props;

  const { company_id, publish_status_id, termination_date } = data;
  let isTerminated = publish_status_id == 6;
  let isTerminatedForTakeover = publish_status_id == 7;

  const { enqueueSnackbar } = useSnackbar();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [terminationDate, setTerminationDate] = useState('');

  useEffect(() => {
    if (show) {
      setTerminationDate('');
    }
  }, [show]);

  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  const handleTerminateForTakeover = async () => {
    console.log('terminationDate', { terminationDate, company_id });
    if (company_id && terminationDate) {
      setLoadingSubmit(true);
      try {
        await companyApi.updateCompanyStatus(company_id, 7, { termination_date: terminationDate });
        enqueueSnackbar(`Company Terminated For Takeover`, { variant: 'success' });
        if (fetchInitialValues) {
          await fetchInitialValues();
        }
        handleOnHideForm();
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Terminate`, {
          variant: 'error',
        });
        setLoadingSubmit(false);
        return;
      }
      setLoadingSubmit(false);
    }
  };
  const handleTerminateNow = async () => {
    if (company_id) {
      if (window.confirm('Confirmed to terminate this company?')) {
        setLoadingSubmit(true);
        try {
          await companyApi.updateCompanyStatus(company_id, 6);
          enqueueSnackbar(`Company Terminated`, { variant: 'success' });
          if (fetchInitialValues) {
            await fetchInitialValues();
          }
          handleOnHideForm();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Terminate`, {
            variant: 'error',
          });
          setLoadingSubmit(false);
          return;
        }
        setLoadingSubmit(false);
      }
    }
  };
  const handleUndoTermination = async () => {
    if (company_id) {
      if (window.confirm('Confirmed to undo termination of this company?')) {
        setLoadingSubmit(true);
        try {
          await companyApi.updateCompanyStatus(company_id, 1);
          enqueueSnackbar(`Company is set to Draft`, { variant: 'success' });
          if (refetchCompany) {
            await refetchCompany();
          }
          handleOnHideForm();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to undo termination`, {
            variant: 'error',
          });
          setLoadingSubmit(false);

          return;
        }
        setLoadingSubmit(false);
      }
    }
  };

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={'Terminate Company'}
    >
      {isTerminated ? (
        <Box>
          {/* <Box mb={2}>Terminated on: xx/xx/xxxx</Box> */}
          <Box>
            <Button variant="contained" color="default" onClick={handleUndoTermination}>
              Undo Termination
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {isTerminatedForTakeover ? (
            <Box mb={2}>
              <Box mb={2}>Pending Termination on: {termination_date}</Box>
              <Box>
                <Button variant="contained" color="default" onClick={handleUndoTermination}>
                  Undo Termination
                </Button>
              </Box>
            </Box>
          ) : (
            <Box mb={2} display="flex">
              <Box mr={2}>
                <TextField
                  type="date"
                  onChange={(e) => {
                    setTerminationDate(e.target.value);
                  }}
                  value={terminationDate}
                  inputProps={{
                    min: moment().add(1, 'days').format('YYYY-MM-DD'),
                  }}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={handleTerminateForTakeover}>
                Terminate For Takeover
              </Button>
            </Box>
          )}
          <Box mb={2}>OR</Box>
          <Box>
            <Button variant="contained" color="secondary" onClick={handleTerminateNow}>
              Terminate Now
            </Button>
          </Box>
        </>
      )}
      <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </CustomModal>
  );
};
