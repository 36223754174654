import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 520,
    width: "100%",
  },
}));

export default function MenuComponent({ data, value, label, handleChange }) {
  const classes = useStyles();

  console.log("company menu", data.length);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={handleChange}
        label={label}
      >
        {data.map(({ company_id, company_name }) => {
          return (
            <MenuItem key={company_id} value={company_id} >
              {company_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
