import axios from './customAxios';

const products = async () => {
  const { data } = await axios.get(`/order/products`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.products;
};

const getProducts = async () => {
  return axios.get(`/order/products`);
};

const getProduct = async (id) => {
  return axios.get(`/order/product/${id}`);
};

const create = async (prod) => {
  const { data } = await axios.post(`/order/product`, prod);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.product;
};

const update = async (prod) => {
  const { data } = await axios.put(`/order/product`, prod);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.product;
};

const getProductCategories = async () => {
  return axios.get(`/order/product-categories`);
};
const getProductItemCategories = async () => {
  return axios.get(`/order/item-categories`);
};
const getProductStatuses = async () => {
  return axios.get(`/order/product-statuses`);
};

const getProductImages = async () => {
  return axios.get(`/order/product-document`);
};

const addProductImage = async (body) => {
  return axios.post(`/order/product-document`, body);
};

const removeProductImage = async (productId, id) => {
  return axios.delete(`/order/product-document/product/${productId}/document/${id}`);
};

const productSvc = {
  update,
  create,
  products,
  getProducts,
  getProduct,
  getProductCategories,
  getProductItemCategories,
  getProductStatuses,
  getProductImages,
  addProductImage,
  removeProductImage,
};

export default productSvc;
