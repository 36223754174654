import axios from "./customAxios";

const getDevs = async () => {
  const { data } = await axios.get(`/webdev/webdev`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.webdev;
};

const getDev = async (id) => {
    const { data } = await axios.get(`/webdev/webdev/${id}`);
  
    if (data && !data.is_success) throw Error(data?.message);
  
    return data?.webdev;
  };

const webdevSvc = {
    getDevs,
    getDev
};

export default webdevSvc;
