import axios from "axios";

export default axios.create({
  // baseURL: "https://oe6mwa0p90.execute-api.ap-southeast-1.amazonaws.com/Prod",
  baseURL: `${process.env.REACT_APP_IDENTITY_URL}`,
  // baseURL: `${process.env.REACT_APP_USERCONTACT_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
