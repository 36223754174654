import axios from 'axios';

const customAxios = axios.create({
  // baseURL: "https://gx2x6uv93l.execute-api.ap-southeast-1.amazonaws.com/Prod",
  baseURL: `${process.env.REACT_APP_MODULE_URL}`,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});
export default customAxios;

export const getAdminUserMenuByUserId = (id) => {
  return customAxios.get(`/admin-user-menu/user/${id}`);
};

export const createAdminUserMenu = (body) => {
  return customAxios.post(`/admin-user-menu`, body);
};
export const getMenu = () => {
  return customAxios.get(`/menu`);
};
