import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import productSvc from '../../services/product.service';
import documentSvc from '../../services/document.service';
import { useUserState } from '../../context/UserContext';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import useCommonOptions from '../../hooks/useCommonOptions';
import ProductImages from './components/ProductImages';
import fileuploader, { productImageUploader } from '../../utility/fileuploader';
/*

name: "Resignation of Administrator"
description: "Description"
product_category: "Corporate Secretarial Services"
product_category_id: 1
item_category: null
item_category_id: null
quantity: null
price: 0
sku: null
subscription_type_id: 1
additional_info: null
need_delivery: null
commission_rate: null
product_status: null

id: 1
created_by: 0
updated_by: 0
status_id: null

// not confirmed
unit: null
unit_id: null
available: null
delivery_limit: null
variety: null
location: null
discount: null



*/

const fields = {
  // Company Details
  name: {
    name: 'name',
    label: 'Name',
    required: true,
  },
  description: {
    name: 'description',
    label: 'Description',
  },
  price: {
    name: 'price',
    label: 'Price',
    required: true,
  },
};
const validationSchema = Yup.object({
  [fields.name.name]: Yup.string().required(`${fields.name.label} is required`),
  [fields.description.name]: Yup.string().nullable().default(null),
  // [fields.product_category_id.name]: Yup.string().required(
  //   `${fields.product_category_id.label} is required`
  // ),
  // [fields.item_category_id.name]: Yup.string().nullable().default(null),
  // [fields.quantity.name]: Yup.number().required(`${fields.quantity.label} is required`).nullable(),
  // [fields.quantity.name]: Yup.number().nullable().default(null),
  [fields.price.name]: Yup.number().required(`${fields.price.label} is required`),
  // [fields.sku.name]: Yup.string().nullable().default(null),
  // [fields.subscription_type_id.name]: Yup.string().nullable().default(null),
  // [fields.additional_info.name]: Yup.string().nullable().default(null),
  // [fields.commission_rate.name]: Yup.number().nullable().default(null),
  // [fields.product_status.name]: Yup.string().nullable().default(null),
});

const mergeProductAndImage = (products = [], documents = []) => {
  let result = products.map((aProduct) => {
    if (aProduct?.product_documents?.length > 0) {
      let newProductDocuments = aProduct?.product_documents.map((aProductDocument) => {
        let foundDoc = documents.find((aDoc) => {
          return aDoc.document_id == aProductDocument.document_id;
        });
        if (foundDoc) {
          return {
            ...aProductDocument,
            document: foundDoc,
          };
        }
        return aProductDocument;
      });
      return {
        ...aProduct,
        product_documents: newProductDocuments,
      };
    }
    return aProduct;
  });
  return result;
};

export default function ProductDetail(props) {
  const { product, show, onHide, refetch, productCategoryId } = props;
  // const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const user = useUserState();
  let isNew = product?.id ? false : true;

  const [productImages, setProductImages] = useState([]);

  const ListProductDocuments = async (products = []) => {
    let ids = [];
    products.forEach((aProduct) => {
      if (aProduct?.product_documents?.length > 0) {
        aProduct?.product_documents.forEach((anItem) => {
          ids.push(anItem?.document_id);
        });
      }
    });
    let res;
    if (ids?.length > 0) {
      try {
        res = await documentSvc.getProductImageDocumentsByIds(ids);
        if (res?.data?.documents?.length > 0) {
          let documents = res?.data?.documents?.length > 0 ? res?.data?.documents : [];
          let mergedProducts = mergeProductAndImage(products, documents);

          return mergedProducts;
        }
      } catch (error) {
        // setProductError(error);
      }
    }

    return products;
  };

  useEffect(() => {
    (async () => {
      if (show) {
        if (product?.product_documents?.length > 0) {
          try {
            let productsWithImage = await ListProductDocuments([product]);
            console.log('productsWithImage', productsWithImage);
            setProductImages(productsWithImage?.[0]?.product_documents);
          } catch (error) {
            setProductImages([]);
          }

          // let ids = product?.product_documents.map((anItem) => {
          //   return anItem.document_id;
          // });
          // documentSvc
          //   .getDocumentsByIds(ids)
          //   .then((result) => {
          //     if (result?.data?.documents?.length > 0) {
          //       let productDocuments = product?.product_documents || [];
          //       productDocuments = productDocuments.map((anItem) => {
          //         let foundDoc = result?.data?.documents.find(
          //           (aDoc) => aDoc.document_id == anItem.document_id
          //         );
          //         if (foundDoc) {
          //           return {
          //             ...anItem,
          //             document: foundDoc,
          //           };
          //         }
          //         return anItem;
          //       });
          //       setProductImages(productDocuments);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log('getDocumentsByIds error', error);
          //   });
        }
        // setProductImages(product?.product_documents || []);
      }
    })();
  }, [show]);
  console.log('productImages', productImages);
  // const { loading: loadingProductImages, rawData: defaultProductImages } = useCommonOptions(
  //   productSvc.getProductImages,
  //   {
  //     respondKey: 'product_documents',
  //     labelKey: 'file_name',
  //     valueKey: 'id',
  //   }
  // );

  // const [productImages, setProductImages] = useState([]);

  // useEffect(() => {
  //   if (!isNew) {
  //     let productImages = [];
  //     if (defaultProductImages?.length > 0) {
  //       productImages = defaultProductImages.filter((anItem) => {
  //         return anItem.product_id == product.id;
  //       });
  //     }

  //     setProductImages(productImages);
  //   } else {
  //     setProductImages([]);
  //   }
  // }, [product?.id, show, loadingProductImages]);

  const formikOnSubmit = async (values, actions) => {
    const {
      item_category,
      product_category,
      unit,
      product_status,
      updated_at,
      product_documents,
      ...restValues
    } = values;

    setLoading(true);
    if (isNew) {
      try {
        let productRes = await productSvc.create({
          ...restValues,
          price: +values.price,
          created_by: user.userId,
        });

        // if (productRes?.id && productImages?.length > 0) {
        //   for (let i = 0; i < productImages?.length; i++) {
        //     let productImage = productImages[i];
        //     if (productImage?.isNew && productImage.file) {
        //       let fileRes = await productImageUploader(
        //         { current: { files: [productImage.file] } },
        //         foundDocumentType?.document_type_id,
        //         foundDocumentType?.document_table_type_id,
        //         product?.id,
        //         undefined,
        //         3
        //         // async (data) => {
        //         //   return await productSvc.addProductImage({
        //         //     product_id: productRes?.id,
        //         //     image_url: data?.id,
        //         //     file_type: data?.file_type,
        //         //     file_name: data?.file_name,
        //         //     created_by: user.userId,
        //         //   });
        //         // }
        //       );
        //       let newDocument = fileRes?.data?.document?.[0];
        //       let newDocumentId = newDocument?.document_id;
        //       if (newDocumentId) {
        //         await productSvc.addProductImage({
        //           product_id: product?.id,
        //           document_id: newDocumentId,
        //           file_type: newDocument?.file_type,
        //           file_name: newDocument?.file_name,
        //           created_by: user.userId,
        //         });
        //       }
        //     }
        //   }
        // }
      } catch (error) {
        setLoading(false);
        return;
      }
    } else {
      try {
        await productSvc.update({
          ...restValues,
          id: product?.id,
          price: +values.price,
          updated_by: parseInt(user.userId),
        });
      } catch (error) {
        setLoading(false);
        return;
      }

      //   try {
      //     if (product?.id && productImages?.length > 0) {
      //       for (let i = 0; i < productImages?.length; i++) {
      //         let productImage = productImages[i];
      //         if (productImage?.isNew && productImage.file) {
      //           let fileRes = await productImageUploader(
      //             { current: { files: [productImage.file] } },
      //             foundDocumentType?.document_type_id,
      //             foundDocumentType?.document_table_type_id,
      //             product?.id,
      //             undefined
      //             // async (data) => {
      //             //   return await productSvc.addProductImage({
      //             //     product_id: product?.id,
      //             //     image_url: data?.document_url,
      //             //     file_type: data?.file_type,
      //             //     file_name: data?.file_name,
      //             //     created_by: user.userId,
      //             //   });
      //             // }
      //           );
      //           let newDocument = fileRes?.data?.document?.[0];
      //           let newDocumentId = newDocument?.document_id;
      //           if (newDocumentId) {
      //             await productSvc.addProductImage({
      //               product_id: product?.id,
      //               document_id: newDocumentId,
      //               file_type: newDocument?.file_type,
      //               file_name: newDocument?.file_name,
      //               created_by: user.userId,
      //             });
      //           }
      //         } else {
      //           if (productImage?.isDeleted && productImage?.id) {
      //             // remove image
      //             await productSvc.removeProductImage(product?.id, productImage?.document_id);
      //           }
      //         }
      //       }
      //     }
      //   } catch (error) {
      //     setLoading(false);
      //     return;
      //   }
    }
    setLoading(false);
    if (refetch) {
      refetch();
    }
    if (onHide) {
      onHide();
    }
  };

  let initialValues = product ? product : validationSchema.cast();
  if (productCategoryId != undefined && isNew) {
    initialValues[fields.product_category_id.name] = productCategoryId;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const { handleSubmit } = formikProps;
          return (
            <Form onSubmit={handleSubmit}>
              <ProductFormFields formik={formikProps} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="primary"
                      disabled={!(formikProps.isValid || formikProps.dirty)}
                      type="submit"
                      variant="contained"
                      // style={{
                      //   backgroundColor: !(props.isValid && props.dirty) ? null : '#DEAD00',
                      //   color: '#FFFFFF',
                      //   borderRadius: 50,
                      //   width: 180,
                      //   textTransform: 'initial',
                      //   fontWeight: 600,
                      // }}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Backdrop open={isLoading} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const ProductFormFields = (props) => {
  const { formik } = props;
  const { handleSubmit, values, setFieldValue } = formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <CustomTextField name={fields.name.name} label={fields.name.label} required={true} />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          name={fields.description.name}
          label={fields.description.label}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextField
          name={fields.price.name}
          label={fields.price.label}
          required={true}
          type="number"
        />
      </Grid>
    </Grid>
  );
};
