import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, Chip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';

import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomRadioGroupField from '../../../../components/CustomFormFields/CustomRadioGroupField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import CustomPhoneNumberField from '../../../../components/CustomFormFields/CustomPhoneNumberField';
import CustomModal from '../../../../components/CustomModal';

import commonApi from '../../../../services/common.service';
import userApi from '../../../../services/user.service';
import { useUserState } from '../../../../context/UserContext';
import CustomTable from '../../../../components/CustomTable';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import useQueryParams from '../../../../hooks/useQueryParams';

let defaultAdminsTableColumns = [
  {
    label: 'EMAIL',
    id: 'sysinfo_role_username',
    render: (text) => {
      return text || '-';
    },
  },
  {
    label: 'FIRST NAME',
    id: 'first_name',
    render: (text) => {
      return text || '-';
    },
  },
  {
    label: 'LAST NAME',
    id: 'last_name',
    render: (text) => {
      return text || '-';
    },
  },
  {
    label: 'CONTACT NUMBER',
    id: 'contact_number',
    render: (text) => {
      return text || '-';
    },
  },
];

export const validationSchemaObj = {
  is_existing_user: Yup.string().default('1'),
  user_id: Yup.number(),
  role_id: Yup.string(),
  // username: Yup.string(),
  first_name: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('First Name is required'),
  }),
  last_name: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Last Name is required'),
  }),
  email: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Email Address is required'),
  }),
  contact_number: Yup.string().when('user_id', {
    is: '0',
    then: Yup.string().required('Contact Number is required'),
  }),
  // gender_id: Yup.string()
  //   .default("1")
  //   .when("user_id", {
  //     is: "0",
  //     then: Yup.string().required("Gender is required"),
  //   }),
  // dob: Yup.string(),
  // birth_country_id: Yup.string(),
  // nationality_id: Yup.string(),
  // password: Yup.string(),
};

let validationSchema = Yup.object(validationSchemaObj);

export const fields = {
  is_existing_user: {
    name: 'is_existing_user',
    label: '',
  },
  user_id: {
    name: 'user_id',
    label: 'User',
  },
  role_id: {
    name: 'role_id',
    label: 'Role',
  },
  first_name: {
    name: 'first_name',
    label: 'First Name',
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
  },
  email: {
    name: 'email',
    label: 'Email Address',
  },
  contact_number: {
    name: 'contact_number',
    label: 'Contact Number',
  },
  gender_id: {
    name: 'gender_id',
    label: 'Gender',
  },
  dob: {
    name: 'dob',
    label: 'Date Of Birth',
  },
  birth_country_id: {
    name: 'birth_country_id',
    label: 'Country Of Birth',
  },
  nationality_id: {
    name: 'nationality_id',
    label: 'Nationality',
  },
};

const sysInfoUserRoles = [
  {
    value: 1,
    label: 'Super Admin',
  },
  {
    value: 2,
    label: 'View & Make Request',
  },
  {
    value: 3,
    label: 'View Only',
  },
  {
    value: 4,
    label: 'Request Only',
  },
];

const SuperAdminTable = (props) => {
  const {
    roles = sysInfoUserRoles,
    newAdmins = [],
    selectedNewAdmin,
    setSelectedNewAdmin,
    // sysInfoUsers = [],
    refetchData,
    disabled = false,
  } = props;

  const routeHistory = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { userId: authUserId } = useUserState();
  let query = useQueryParams();
  const selectedCompanyId = parseInt(query.get('company'));

  const {
    options: sysInfoUserOptions,
    loading: loadingSysInfoUsers,
    rawData: sysInfoUsers = [],
    refetch: refetchSysInfoUsers,
  } = useCommonOptions(userApi.getSysInfoUsers, {
    respondKey: 'users',
    labelKey: 'email',
    valueKey: 'user_id',
    render: (_, data) => {
      return `${data?.email} (${data?.first_name} ${data?.last_name})`;
    },
  });

  // const { options: countrylist, loading: loadingCountries } = useCommonOptions(
  //   commonApi.getCountry,
  //   {
  //     respondKey: "countries",
  //     labelKey: "country_name",
  //     valueKey: "country_id",
  //   }
  // );
  // const { options: genderList, loading: loadingGenders } = useCommonOptions(
  //   commonApi.getGender,
  //   {
  //     respondKey: "genders",
  //     labelKey: "gender_name",
  //     valueKey: "gender_id",
  //     isString: true,
  //   }
  // );
  let isNew = selectedNewAdmin?.email ? false : true;
  let defaultValues = isNew ? validationSchema.cast() : selectedNewAdmin;

  useEffect(() => {
    refetchSysInfoUsers();
  }, [selectedNewAdmin]);

  let adminsTableColumns = [
    {
      label: '',
      id: '',
      render: (_, data) => {
        return (
          <Box mb={2} display="flex" alignItems="center">
            <Box mr={1}>
              <Chip
                color="secondary"
                onClick={() => setSelectedNewAdmin(data)}
                clickable
                label={data?.sysinfo_user_id ? 'EDIT ROLE' : 'EDIT'}
                disabled={disabled}
              />
            </Box>
            {data?.sysinfo_user_id ? (
              <Chip
                onClick={() => {
                  routeHistory.push(`/sysinfo-users?id=${data?.sysinfo_user_id}`);
                }}
                clickable
                label="EDIT DETAIL"
              />
            ) : null}
            {/* <Button
              variant="contained"
              onClick={() => {
                routeHistory.push(`/sysinfo-users?id=${data?.sysinfo_user_id}`);
              }}
              size="small"
            >
              Edit Detail
            </Button> */}
          </Box>
        );
      },
    },
    {
      label: 'Role',
      id: 'role_id',
      render: (text) => {
        let foundRole = sysInfoUserRoles.find((anItem) => anItem.value == text);
        return foundRole ? foundRole.label : '-';
      },
    },
    ...defaultAdminsTableColumns,
  ];

  const handleOnHideForm = () => {
    setSelectedNewAdmin();
  };

  const handleOnSubmit = async (values) => {
    let anAdmin = values;
    let email = (anAdmin?.['email'] || '').trim();

    if (isNew) {
      if (anAdmin?.user_id) {
        let foundSysInfoUser = sysInfoUsers?.find((anItem) => {
          return anItem[fields.user_id.name] == values[fields.user_id.name];
        });
        // create sysinfo user role only
        try {
          await userApi.createSysInfoUserRole({
            username: foundSysInfoUser?.email,
            role_id: anAdmin.role_id,
            company_id: selectedCompanyId,
            sysinfo_user_id: anAdmin?.user_id,
            user_id: parseInt(authUserId),
            created_by: parseInt(authUserId),
          });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Super Admin`, {
            variant: 'error',
          });
          return;
        }
      } else {
        // create sysinfo user & sysinfo user role
        let newSysInfoUserBody = {
          ...anAdmin,
          email: email,
          created_by: parseInt(authUserId),
          username: email,
          password: null,
          is_new_user: true,
        };
        let createSysInfoUserRes;
        try {
          createSysInfoUserRes = await userApi.createSysInfoUser(newSysInfoUserBody);
        } catch (error) {
          console.log('createSysInfoUser error', error?.response?.data?.error?.message);
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Super Admin`, {
            variant: 'error',
          });
          return;
        }

        if (createSysInfoUserRes?.data?.is_success) {
          try {
            await userApi.createSysInfoUserRole({
              username: createSysInfoUserRes?.data?.users?.email,
              role_id: anAdmin.role_id,
              company_id: selectedCompanyId,
              sysinfo_user_id: createSysInfoUserRes?.data?.users?.user_id,
              user_id: parseInt(authUserId),
              created_by: parseInt(authUserId),
            });
          } catch (error) {
            enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Add Super Admin`, {
              variant: 'error',
            });
            return;
          }
        }
      }
    } else {
      // update role_id
      if (selectedNewAdmin?.company_id && selectedNewAdmin?.sysinfo_role_username) {
        try {
          await userApi.updateSysInfoUserRole(
            selectedNewAdmin?.sysinfo_role_username,
            selectedNewAdmin?.company_id,
            anAdmin.role_id
          );
          enqueueSnackbar(`Role Updated`, { variant: 'success' });
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Super Admin`, {
            variant: 'error',
          });
          return;
        }
      }
    }
    if (refetchData) {
      await refetchData();
    }

    handleOnHideForm();
  };

  const handleRemoveAdmin = async () => {
    console.log('selectedNewAdmin', selectedNewAdmin);
    if (window.confirm('Confirm Remove Super Admin?')) {
      if (selectedNewAdmin?.company_id && selectedNewAdmin?.sysinfo_role_username) {
        try {
          await userApi.removeSysInfoUserRoleByUsername(
            selectedNewAdmin?.sysinfo_role_username,
            selectedNewAdmin?.company_id,
            authUserId
          );
          if (refetchData) {
            await refetchData();
          }
          enqueueSnackbar(`Removed ${selectedNewAdmin?.sysinfo_role_username}`, {
            variant: 'success',
          });

          handleOnHideForm();
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Super Admin`, {
            variant: 'error',
          });
          return;
        }
      }
    }
  };

  return (
    <>
      <CustomTable data={newAdmins} columns={adminsTableColumns} rowKey={'user_id'} />
      <CustomModal
        show={Boolean(selectedNewAdmin)}
        onHide={handleOnHideForm}
        scrollable={false}
        title={isNew ? 'New Admin' : 'Edit Admin Role'}
      >
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(formikProps) => {
            const { values, setValues, handleSubmit } = formikProps;
            let isExistingUser = values[fields.is_existing_user.name] == '1' || !isNew;
            return (
              <form onSubmit={handleSubmit}>
                {isNew && (
                  <Box mb={2}>
                    <CustomRadioGroupField
                      name={fields.is_existing_user.name}
                      label={fields.is_existing_user.label}
                      options={[
                        {
                          label: 'Existing User',
                          value: '1',
                        },
                        {
                          label: 'New User',
                          value: '0',
                        },
                      ]}
                      // required={true}
                    />
                  </Box>
                )}
                {isExistingUser ? (
                  <Box mb={2}>
                    <CustomSelectField
                      name={fields.user_id.name}
                      label={fields.user_id.label}
                      options={sysInfoUserOptions}
                      disabled={!isNew}
                    />
                  </Box>
                ) : null}
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.role_id.name}
                    label={fields.role_id.label}
                    options={roles}
                    // required={true}
                  />
                </Box>
                {isExistingUser ? null : isNew && Boolean(selectedNewAdmin) ? (
                  <>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.first_name.name}
                        label={fields.first_name.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.last_name.name}
                        label={fields.last_name.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.email.name}
                        label={fields.email.label}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomPhoneNumberField
                        label={fields.contact_number.label}
                        name={fields.contact_number.name}
                      />
                    </Box>
                    {/* <Box mb={2}>
                      <CustomRadioGroupField
                        name={fields.gender_id.name}
                        label={fields.gender_id.label}
                        options={genderList}
                        // required={true}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomTextField
                        name={fields.dob.name}
                        label={fields.dob.label}
                        type="date"
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomSelectField
                        name={fields.birth_country_id.name}
                        label={fields.birth_country_id.label}
                        options={countrylist}
                      />
                    </Box>
                    <Box mb={2}>
                      <CustomSelectField
                        name={fields.nationality_id.name}
                        label={fields.nationality_id.label}
                        options={countrylist}
                      />
                    </Box> */}
                  </>
                ) : null}

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && (
                      <Box ml={2}>
                        <Button variant="contained" color="danger" onClick={handleRemoveAdmin}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
};

export default SuperAdminTable;
