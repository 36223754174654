import React, { useState, Component, useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';
import ReactFileViewer from 'react-file-viewer';
import './styles.css';

const FilePreview = (props) => {
  const { src, onError, onReload, ...restProps } = props;

  const [state, setState] = useState({
    ...props,
    error: false,
  });
  const { url, mimeType, fileType, subType, error, isLocal = false } = state;

  useEffect(() => {
    (async () => {
      let error = false;
      let newURL = src;
      let mimeType;
      let subType;
      let fileType;
      let isLocal = false;
      if (src) {
        console.log('srcsrcsrc', src);
        try {
          if (src?.file) {
            newURL = URL.createObjectURL(src?.file);
            isLocal = true;
          }
          let res = await axios.get(newURL);
          let contentType = res?.headers?.['content-type'];
          mimeType = contentType;
          fileType = mimeType ? mimeType?.split('/')?.[0] : undefined;
          subType = mimeType ? mimeType?.split('/')?.[1] : undefined;
          if (!src?.file) {
            if (fileType == 'image') {
              newURL = src;
            }
          }
        } catch (err) {
          error = true;
        }
      } else {
        error = true;
      }

      setState((prev) => ({
        ...prev,
        url: newURL,
        mimeType: mimeType,
        fileType,
        subType,
        error: error,
        isLocal,
      }));
    })();
  }, [src]);

  const handleOnError = (e) => {
    if (onError) {
      onError(e);
    }
  };

  const handleOnReload = () => {
    if (onReload) {
      onReload();
    }
  };

  const handleDocRefError = (e) => {
    // console.log('handleDocRefError', e);
    setState((prev) => ({ ...prev, error: true }));
    handleOnError(e);
  };
  const handleDocRefOnLoad = (e) => {
    // console.log('handleDocRefOnLoad', e);
    setState((prev) => ({
      ...prev,
      error: false,
    }));
  };

  let style = {
    display: 'flex',
    minHeight: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  let docWrapperStyle = {
    height: '100%',
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
  };
  if (error) {
    style = {
      ...style,
      justifyContent: 'center',
    };
    docWrapperStyle = {
      ...docWrapperStyle,
      display: 'none',
    };
  }

  let objectProps = {};
  if (mimeType) {
    objectProps = {
      type: mimeType,
    };
  }

  return (
    <div
      className="file-preview"
      style={{
        ...style,
        width: '100%',
      }}
    >
      <div style={docWrapperStyle}>
        {fileType == 'image' ? (
          <img
            src={url}
            style={{
              width: '100%',
              height: 'auto',
              flexGrow: 1,
            }}
            onError={handleDocRefError}
            onLoad={handleDocRefOnLoad}
          />
        ) : null}
        {fileType == 'application' || fileType == 'text' ? (
          isLocal && subType == 'pdf' ? (
            <ReactFileViewer fileType={subType} filePath={url} />
          ) : (
            <iframe
              src={url}
              style={{
                width: '100%',
                height: 'auto',
                flexGrow: 1,
              }}
              onError={handleDocRefError}
              onLoad={handleDocRefOnLoad}
              {...objectProps}
            />
          )
        ) : null}
      </div>
      {error ? (
        <ErrorComponent src={url} onClickRefresh={onReload ? handleOnReload : undefined}>
          {src ? 'No Preview Available' : 'Source not found'}
        </ErrorComponent>
      ) : null}
    </div>
  );
};

export default FilePreview;

const ErrorComponent = (props) => {
  const { src, onClickRefresh, children } = props;
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          color="grey"
          viewBox="0 0 24 24"
          style={{
            position: 'relative',
            userSelect: 'none',
            display: 'inline-block',
            height: '42px',
            fill: 'grey',
          }}
        >
          <g>
            <path d="M2.25 24A2.252 2.252 0 0 1 0 21.75V2.25A2.252 2.252 0 0 1 2.25 0h19.5A2.252 2.252 0 0 1 24 2.25v19.5A2.252 2.252 0 0 1 21.75 24H2.25zm0-22.5a.75.75 0 0 0-.75.75v19.5c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75V2.25a.75.75 0 0 0-.75-.75H2.25z" />
            <path d="M16.5 11.25c-2.068 0-3.75-1.682-3.75-3.75s1.682-3.75 3.75-3.75 3.75 1.682 3.75 3.75-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25zM15.655 19.858a.746.746 0 0 1-.699-.478 7.507 7.507 0 0 0-1.132-1.96l-.021-.026a7.364 7.364 0 0 0-3.819-2.495 7.485 7.485 0 0 0-1.979-.268 7.442 7.442 0 0 0-3.674.98.754.754 0 0 1-1.023-.281.751.751 0 0 1 .28-1.023 8.936 8.936 0 0 1 4.415-1.177 8.97 8.97 0 0 1 2.376.322 8.892 8.892 0 0 1 4.1 2.441 5.243 5.243 0 0 1 2.902-.879c1.219 0 2.402.427 3.331 1.204a.743.743 0 0 1 .266.509.743.743 0 0 1-.172.548.747.747 0 0 1-1.056.094 3.699 3.699 0 0 0-2.369-.855c-.692 0-1.375.195-1.957.555a8.833 8.833 0 0 1 .928 1.769.747.747 0 0 1-.697 1.02z" />
          </g>
        </svg>

        <span
          style={{
            color: 'rgb(128, 144, 162)',
            fontSize: '12px',
            marginTop: '10px',
          }}
        >
          {children}
        </span>
        {onClickRefresh && src && (
          <IconButton onClick={onClickRefresh}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
