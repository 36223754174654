import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';

import CustomUploadButton from '../../../components/CustomFormFields/CustomUploadButton';
import CustomMenu from '../../../components/CustomMenu';
import commonApi from '../../../services/common.service';
import shareholderApi from '../../../services/shareholder.service';
import companyApi from '../../../services/company.service';
import userApi from '../../../services/user.service';
import documentApi from '../../../services/document.service';
import useCommonOptions from '../../../hooks/useCommonOptions';
import FileViewerButton2 from '../../../components/FileViewerButton2';
import FormFieldContainer from '../../../components/CustomFormFields/FormFieldContainer';

/*
DividendDeclarationApplication (dividend_declarations)

company_application_id: 462
dividend_amount: 12000
dividend_date: "2021-10-02T00:00:00.000Z"
dividend_declaration_application_id: 2
payment_date: "2021-10-02T00:00:00.000Z"
*/

/*

*/

/*

*/

/*

*/

/*

*/

export const OpenBankAccountApplication = ({ application = {} }) => {
  /*
bank_name: "open bank"
company_application_id: 438
is_sgd_account: 0
is_usd_account: 0
open_bank_account_application_id: 1

*/
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            label="Bank Name"
            value={application?.bank_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Is SGD Account"
            value={application?.is_sgd_account === 1 ? 'YES' : 'NO'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Is USD Account"
            value={application?.is_usd_account === 1 ? 'YES' : 'NO'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CloseBankAccountApplication = ({ application = {} }) => {
  /*
bank_account_number: "1234567890"
bank_name: "pb bank"
close_bank_account_application_id: 1
company_application_id: 454

*/
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            label="Bank Name"
            value={application?.bank_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Bank Account Number"
            value={application?.bank_account_number || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const BusinessActivityApplication = ({ application = {}, request }) => {
  /*
  business_activity_application_id: 1
change_effective_date: null
company_application_id: 431
delete_secondary_activity_flag: 0
primary_activity_description: "desc 1"
primary_activity_id: 1
secondary_activity_description: "desc 2"
secondary_activity_id: 2
*/
  const [businessActivities, setBusinessActivities] = useState([]);

  const businessActivitiesOptions = businessActivities.map((anItem) => {
    return {
      label: anItem.ssic_name,
      value: anItem.ssic_id,
    };
  });

  let foundPrimaryActivity = businessActivitiesOptions.find((anItem) => {
    return anItem.value == application?.primary_activity_id;
  });
  let foundSecondaryActivity = businessActivitiesOptions.find((anItem) => {
    return anItem.value == application?.secondary_activity_id;
  });

  useEffect(() => {
    commonApi
      // .getSsicCodeByCode("123")
      .getSsicCode()
      .then((result) => {
        // if (result?.data?.ssic_codes) {
        //   setBusinessActivities(result?.data?.ssic_codes);
        // }
        if (result?.data?.document) {
          setBusinessActivities(result?.data?.document);
        }
      })
      .catch((error) => {
        setBusinessActivities([]);
      });
  }, [JSON.stringify(request), JSON.stringify(application)]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Change Effective Date"
            value={
              application?.change_effective_date
                ? moment(application?.change_effective_date).format('DD/MM/yyyy')
                : '-'
            }
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Primary Activity"
            value={foundPrimaryActivity?.label || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Primary Activity Desc"
            value={application?.primary_activity_description || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Secondary Activity"
            value={foundSecondaryActivity?.label || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Secondary Activity Desc"
            value={application?.secondary_activity_description || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

// export const BusinessActivityApplication = ({ application = {} }) => {
//   /*
//   business_activity_application_id: 1
// change_effective_date: null
// company_application_id: 431
// delete_secondary_activity_flag: 0
// primary_activity_description: "desc 1"
// primary_activity_id: 1
// secondary_activity_description: "desc 2"
// secondary_activity_id: 2
// */
//   const [businessActivities, setBusinessActivities] = useState([]);

//   const businessActivitiesOptions = businessActivities.map((anItem) => {
//     return {
//       label: anItem.ssic_name,
//       value: anItem.ssic_id,
//     };
//   });
//   useEffect(() => {
//     commonApi
//       // .getSsicCodeByCode("123")
//       .getSsicCode()
//       .then((result) => {
//         // if (result?.data?.ssic_codes) {
//         //   setBusinessActivities(result?.data?.ssic_codes);
//         // }
//         if (result?.data?.document) {
//           setBusinessActivities(result?.data?.document);
//         }
//       })
//       .catch((error) => {
//         setBusinessActivities([]);
//       });
//   }, [JSON.stringify(application)]);

//   return (
//     <>
//       <Grid container spacing={3}>
//         <Grid item xs={12}>
//           <TextField
//             label="Change Effective Date"
//             value={application?.change_effective_date || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <CustomMenu
//             data={businessActivitiesOptions}
//             value={application?.primary_activity_id}
//             label="Primary Activity"
//             // onChange={handleModuleChange}
//             disabled={true}
//             variant="standard"
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             required
//             label="Primary Activity Desc"
//             value={application?.primary_activity_description || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//         {/* <Grid item xs={12}>
//           <TextField
//             label="Delete Secondary Activity Flag"
//             value={
//               application.delete_secondary_activity_flag === 1 ? "YES" : "NO"
//             }
//             fullWidth
//             disabled={true}
//           />
//         </Grid> */}
//         <Grid item xs={12}>
//           <CustomMenu
//             data={businessActivitiesOptions}
//             value={application?.secondary_activity_id}
//             label="Secondary Activity"
//             // onChange={handleModuleChange}
//             disabled={true}
//             variant="standard"
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             required
//             label="Secondary Activity Desc"
//             value={application?.secondary_activity_description || "-"}
//             fullWidth
//             disabled={true}
//           />
//         </Grid>
//       </Grid>
//     </>
//   );
// };

export const CessationApplication = ({ application = {}, request }) => {
  /*
cessation_application_id: 13
cessation_attachment_id: null
cessation_attachment_status_id: null
cessation_attachment_status_name: null
cessation_attachment_url: null

cessation_date: "2021-10-13T12:14:42.000Z"
cessation_reason: "123"
company_application_id: 68
company_application_status_id: 2
company_id: 3
company_name: "CHEO HUAY HOLDINGS PTE. LTD."
first_name: "YEOW SOON"
last_name: "TEO"
role_name: "Director"
status_name: "Pending"
user_company_role_id: 5

user_id: 4
username: "d"
*/

  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    if (application?.cessation_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.cessation_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setAttachments([]);
        });
    }
  }, [JSON.stringify(application), JSON.stringify(request)]);

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            id="firstname"
            name="firstname"
            label="Status"
            variant="standard"
            value={application.status_name}
            fullWidth
            disabled={true}
          />
        </Grid> */}
        <Grid item xs={6}>
          <TextField
            required
            id="cessation_date"
            name="cessation_date"
            label="Cessation Date"
            variant="standard"
            value={moment(application?.cessation_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="cessation_reason"
            name="cessation_reason"
            label="Cessation Reason"
            variant="standard"
            value={application?.cessation_reason || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="company_name"
            name="company_name"
            label="Company Name"
            variant="standard"
            value={application?.company_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="role_name"
            name="role_name"
            label="Role"
            variant="standard"
            value={application?.role_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstname"
            name="firstname"
            label="First Name"
            value={application?.first_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            value={application?.last_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>Attachments</Box>
          {attachments?.length > 0
            ? attachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
      </Grid>
    </>
  );
};

export const CompanyAddressChangeApplication = ({ application = {} }) => {
  /*
  address_line_1: "Tester Building"
address_line_2: "Deployment Street"
address_line_3: "Automation City"
company_address_application_id: 2
company_application_id: 302
company_application_status_id: 1
country_id: 190
country_name: "Singapore"
floor_unit_no: "11"
postal_code: "54321"
status_name: "In Progress"
  */

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Status"
            value={application.status_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}></Grid> */}

        <Grid item xs={12}>
          <TextField
            required
            label="Floor Unit No"
            value={application?.floor_unit_no || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Address Line 1"
            value={application?.address_line_1 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Address Line 2"
            value={application?.address_line_2 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="City / State"
            value={application?.address_line_3 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Poster Code"
            value={application?.postal_code || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Country"
            value={application?.country_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CompanyNameChangeApplication = ({ application = {} }) => {
  /*
company_application_id: 241
company_application_status_id: 1
company_name_application_id: 1
proposed_company_name_1: "ABC Inc"
proposed_company_name_2: "ABCD Inc"
proposed_company_name_3: "ABCDEF Inc"
status_name: "In Progress"
*/
  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Status"
            value={application.status_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}></Grid> */}
        <Grid item xs={12}>
          <TextField
            required
            label="Proposed Name 1"
            value={application?.proposed_company_name_1 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Proposed Name 2"
            value={application?.proposed_company_name_2 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Proposed Name 3"
            value={application?.proposed_company_name_3 || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const DividendDeclarationApplication = ({ application = {} }) => {
  /*
  company_application_id: 458
dividend_amount: 12000
dividend_date: "2021-10-02T00:00:00.000Z"
dividend_declaration_application_id: 1
payment_date: "2021-10-02T00:00:00.000Z"

  */
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            label="Amount"
            value={application?.dividend_amount || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Dividend Date"
            value={moment(application?.dividend_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Payment Date"
            value={moment(application?.payment_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const FinancialYearApplication = ({ application = {} }) => {
  /*
  company_application_id: 441
financial_year_end_application_id: 1
financial_year_end_date: "2021-10-02T00:00:00.000Z"
new_financial_year_end_date: "2021-10-02T00:00:00.000Z"
next_agm_due_date: "0000-00-00 00:00:00"
  */
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            label="Year End Date"
            value={moment(application?.financial_year_end_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="NEW Year End Date"
            value={moment(application?.new_financial_year_end_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Next AGM Due Date"
            value={moment(application?.next_agm_due_date).format('YYYY-MM-DD') || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const OfficerApplication = ({ application = {}, request }) => {
  /*
  address_attachment_id: null
address_attachment_status_id: null
address_attachment_status_name: null
address_attachment_url: null

appoinment_letter_attachment_id: null
appoinment_letter_attachment_status_id: null
appoinment_letter_attachment_url: null
appointment_letter_attachment_status_name: null

company_application_id: 5
company_application_status_id: 9
company_role_id: 3

employment_pass_attachment_id: null
employment_pass_attachment_status_id: null
employment_pass_attachment_status_name: null
employment_pass_attachment_url: null

first_name: "Max"
last_name: "Goh"

nric_attachment_id: null
nric_attachment_status_id: null
nric_attachment_status_name: null
nric_attachment_url: null

officer_application_id: 1
residence_type_id: null

role_name: "Beneficial Owner / Registerable Controller"
status_name: "Order Pending"
username: "f"

  */

  const [addressAttachments, setAddressAttachments] = useState([]);
  const [appoinmentLetterAttachments, setAppoinmentLetterAttachments] = useState([]);
  const [employmentPassAttachments, setEmploymentPassAttachments] = useState([]);
  const [nricAttachments, setNricAttachments] = useState([]);

  useEffect(() => {
    if (application?.address_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.address_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setAddressAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setAddressAttachments([]);
        });
    }
    if (application?.appoinment_letter_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.appoinment_letter_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setAppoinmentLetterAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setAppoinmentLetterAttachments([]);
        });
    }
    if (application?.employment_pass_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.employment_pass_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setEmploymentPassAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setEmploymentPassAttachments([]);
        });
    }
    if (application?.nric_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.nric_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setNricAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setNricAttachments([]);
        });
    }
  }, [JSON.stringify(application), JSON.stringify(request)]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            id="role_name"
            name="role_name"
            label="Role"
            variant="standard"
            value={application?.role_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            variant="standard"
            value={application?.email || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            value={application?.first_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            value={application?.last_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="nric_passport_no"
            name="nric_passport_no"
            label="NRIC/Passport"
            variant="standard"
            value={application?.nric_passport_no || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="mobile_number"
            name="mobile_number"
            label="Mobile Number"
            variant="standard"
            value={application?.mobile_number || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>Address Attachments</Box>
          {addressAttachments?.length > 0
            ? addressAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
        <Grid item xs={12}>
          <Box>Appointment Letter Attachments</Box>
          {appoinmentLetterAttachments?.length > 0
            ? appoinmentLetterAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
        <Grid item xs={12}>
          <Box>Employment Pass Attachments</Box>
          {employmentPassAttachments?.length > 0
            ? employmentPassAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
        <Grid item xs={12}>
          <Box>NRIC Attachments</Box>
          {nricAttachments?.length > 0
            ? nricAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
      </Grid>
    </>
  );
};

export const OtherRequestApplication = ({ application = {} }) => {
  /*
  company_application_id: 461
other_request_application_id: 1
other_request_type_id: 1
request_description: "2021-10-2"

  */
  const requestType = [
    { label: 'Capital & Shares Related', value: 1 },
    { label: 'General Enquires', value: 2 },
    { label: 'Officers Related', value: 3 },
    { label: 'Product & Subscription Related', value: 4 },
    { label: 'Shareholders Related', value: 5 },
  ];

  let foundRequestType = requestType?.find((anItem) => {
    return anItem.value == application?.other_request_type_id;
  });
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            label="Request Type"
            value={foundRequestType?.label || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Request Description"
            value={application?.request_description || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ShareAllotmentApplicationItem = ({ application = {}, request }) => {
  /*
  company_application_id: 2614
  company_application_status_id: 11
  shares_allotment_application_id: 104
  status_name: "In Cart"

  passport_attachment_id: null
  proof_capital_attachment_id: 1394
  proof_identity_attachment_id: null
  proof_residency_attachment_id: null

  is_new_shareholder: 0
  is_foreigner: null
  transfer_from_id: null
  transfer_to_id: 1
  allotment_shareholder_id: 0
  
  allotment_date: null
  issued_paid_up_amount: 2
  new_capital: 1
  new_shares: 1
  no_shares: 2
  currency: "SGD"

  email: null
  identity_id: null
  mobile_number: null
  shareholder_name: "Edison Tan"

  */

  const [transferFrom, setTransferFrom] = useState({});
  const [transferTo, setTransferTo] = useState({});

  let isTransferToCompany = application?.is_company == 1;
  let isNewShareholder = application?.is_new_shareholder == 1;
  let transferToData = isNewShareholder ? application : { ...application, ...transferTo };

  let data = application;

  const {
    username: transferTo_username = '',
    first_name: transferTo_firstname = '',
    last_name: transferTo_lastname = '',
  } = transferTo;

  const { rawData: passportAttachments = [], refetch: fetchPassportAttachments } = useCommonOptions(
    documentApi.getDocumentsByIdSTS,
    {
      respondKey: 'document',
      lazyLoad: true,
    }
  );
  const { rawData: capitalAttachments = [], refetch: fetchCapitalAttachments } = useCommonOptions(
    documentApi.getDocumentsByIdSTS,
    {
      respondKey: 'document',
      lazyLoad: true,
    }
  );
  const {
    rawData: proofIdentityAttachments = [],
    refetch: fetchProofIdentityAttachments,
  } = useCommonOptions(documentApi.getDocumentsByIdSTS, {
    respondKey: 'document',
    lazyLoad: true,
  });
  const {
    rawData: proofResidencyAttachments = [],
    refetch: fetchProofResidencyAttachments,
  } = useCommonOptions(documentApi.getDocumentsByIdSTS, {
    respondKey: 'document',
    lazyLoad: true,
  });

  useEffect(() => {
    if (!isNewShareholder) {
      let transferToId = application?.transfer_to_id;
      if (request?.company_id && (transferToId || transferToId == 0)) {
        shareholderApi
          .getShareholderById(request.company_id, application?.transfer_to_id)
          .then((result) => {
            if (result?.data?.shares) {
              setTransferTo(result?.data?.shares);
            }
          })
          .catch((error) => {
            setTransferTo({});
          });
      }
    }

    if (application?.passport_attachment_id) {
      fetchPassportAttachments(application?.passport_attachment_id);
    }
    if (application?.proof_capital_attachment_id) {
      fetchCapitalAttachments(application?.proof_capital_attachment_id);
    }
    if (application?.proof_identity_attachment_id) {
      fetchProofIdentityAttachments(application?.proof_identity_attachment_id);
    }
    if (application?.proof_residency_attachment_id) {
      fetchProofResidencyAttachments(application?.proof_residency_attachment_id);
    }
  }, [JSON.stringify(application), JSON.stringify(request)]);

  const attachments = [
    {
      label: 'Passport Attachments',
      data: passportAttachments || [],
    },
    {
      label: 'Capital Attachments',
      data: capitalAttachments || [],
    },
    {
      label: 'Proof Identity Attachments',
      data: proofIdentityAttachments || [],
    },
    {
      label: 'Proof Residency Attachments',
      data: proofResidencyAttachments || [],
    },
  ];
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            id="currency"
            name="currency"
            label="Currency"
            variant="standard"
            value={transferToData?.currency || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="issued_paid_up_amount"
            name="issued_paid_up_amount"
            label="Issued Paid Up Amount"
            value={transferToData?.issued_paid_up_amount || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="shares"
            name="shares"
            label="No Of Shares"
            value={transferToData?.no_shares || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="New capital"
            value={`${transferToData?.new_capital || '-'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="New share"
            value={`${transferToData?.new_shares || '-'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <b>Transfer To</b>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Is new shareholder"
            value={`${isNewShareholder ? 'YES' : 'NO'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Is Company"
            value={`${isTransferToCompany ? 'YES' : 'NO'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        {isTransferToCompany ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Company Name"
                value={transferToData?.company_name || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Company Registration No"
                value={transferToData?.company_registration_no || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Country Incorporation Name"
                value={transferToData?.country_incorporation_name || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Floor Unit No"
                value={transferToData?.floor_unit_no || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address Line 1"
                value={transferToData?.address_line_1 || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Address Line 2"
                value={transferToData?.address_line_2 || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Postal Code"
                value={transferToData?.postal_code || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Country Name"
                value={transferToData?.country_name || '-'}
                fullWidth
                disabled={true}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <TextField
                required
                label="Name"
                value={`${transferToData?.shareholder_name || '-'}`}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Email"
                value={`${transferToData?.email || transferToData?.username || '-'}`}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Identity Id"
                value={`${transferToData?.identity_id || '-'}`}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Mobile Number"
                value={`${transferToData?.mobile_number || '-'}`}
                fullWidth
                disabled={true}
              />
            </Grid>
          </>
        )}

        {attachments.map((anAttachment, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Box>{anAttachment.label}</Box>
              {anAttachment?.data?.length > 0
                ? anAttachment?.data.map((anItem, index) => {
                    return (
                      <Box key={index} mb={1}>
                        {/* <CustomUploadButton
                          previewLink={anItem?.signed_document_url || ''}
                          previewLabel={`View Attachment (${anItem?.document_type_name})`}
                          hideUpload={true}
                        /> */}
                        {/* <Button
                          variant="contained"
                          target="_blank"
                          color={'primary'}
                          href={anItem?.signed_document_url || ''}
                        >
                          View Attachment ({anItem?.document_type_name})
                        </Button> */}
                        <FileViewerButton2
                          srcType="url"
                          src={anItem?.signed_document_url || ''}
                          document={anItem}
                        >
                          View Attachment ({anItem?.document_type_name})
                        </FileViewerButton2>
                      </Box>
                    );
                  })
                : 'No Attachment Found'}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export const ShareAllotmentApplication = ({ application = [], request }) => {
  return (
    <>
      {application?.length > 0
        ? application.map((anItem, index) => {
            return (
              <Box key={index} mb={2}>
                {index == 0 ? null : (
                  <Box py={2}>
                    <hr />
                  </Box>
                )}
                <Box mb={1}>
                  <Typography variant="h5">
                    <b>Transfer {index + 1}</b>
                  </Typography>
                </Box>
                <ShareAllotmentApplicationItem application={anItem} request={request} />
              </Box>
            );
          })
        : 'No Data Found'}
    </>
  );
};

export const ShareApplication = ({ application = {} }) => {
  /*
  company_application_id: 262
company_application_status_id: 1
current_number_of_shares: 1
current_share_capital: 1
is_increase: 0
proposed_number_of_shares: 1
proposed_share_capital: 1
share_application_id: 1
status_name: "In Progress"

  */
  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Status"
            variant="standard"
            value={application.status_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} /> */}
        <Grid item xs={6}>
          <TextField
            required
            label="Number of shares"
            value={application?.current_number_of_shares || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Current share capital"
            value={application?.current_share_capital || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Propose number of shares"
            value={application?.proposed_number_of_shares || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Proposed share capital"
            value={application?.proposed_share_capital || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const ShareHolderApplication = ({ application = {}, request = {} }) => {
  /*
  company_application_id: 532
email: "a@b.c"
identity_id: "23333333"
is_foreigner: 0
mobile_number: "12345"
passport_attachment_id: null
proof_identity_attachment_id: null
proof_residency_attachment_id: null
shareholder_application_id: 1
shareholder_name: "s name"
status_name: "In Progress"

  */

  const [passportAttachments, setPassportAttachments] = useState([]);
  const [identityAttachments, setIdentityAttachments] = useState([]);
  const [residencyAttachments, setResidencyAttachments] = useState([]);
  useEffect(() => {
    if (application?.passport_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.passport_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setPassportAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setPassportAttachments([]);
        });
    }
    if (application?.proof_identity_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.proof_identity_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setIdentityAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setIdentityAttachments([]);
        });
    }
    if (application?.proof_residency_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.proof_residency_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setResidencyAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setResidencyAttachments([]);
        });
    }
  }, [JSON.stringify(application), JSON.stringify(request)]);
  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <TextField
            required
            label="Status"
            value={application.status_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} /> */}
        <Grid item xs={6}>
          <TextField
            required
            label="Shareholder ID"
            value={application?.identity_id || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Shareholder Name"
            value={application?.shareholder_name || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Shareholder Email"
            value={application?.email || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Shareholder Phone"
            value={application?.mobile_number || '-'}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            label="Is Foreigner"
            value={`${application?.is_foreigner === 1 ? 'YES' : 'NO'}`}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>Passport Attachments</Box>
          {passportAttachments?.length > 0
            ? passportAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
        <Grid item xs={12}>
          <Box>Proof Identity Attachments</Box>
          {identityAttachments?.length > 0
            ? identityAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
        <Grid item xs={12}>
          <Box>Proof Residency Attachments</Box>
          {residencyAttachments?.length > 0
            ? residencyAttachments.map((anItem, index) => {
                return (
                  <Box key={index} mb={1}>
                    {/* <Button
                      variant="contained"
                      target="_blank"
                      color={'primary'}
                      href={anItem?.signed_document_url || ''}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </Button> */}
                    <FileViewerButton2
                      srcType="url"
                      src={anItem?.signed_document_url || ''}
                      document={anItem}
                    >
                      View Attachment ({anItem?.document_type_name})
                    </FileViewerButton2>
                  </Box>
                );
              })
            : 'No Attachment Found'}
        </Grid>
      </Grid>
    </>
  );
};

const ShareTransferApplicationItem = ({ application = {}, request }) => {
  /*
  company_application_id: 513
company_application_status_id: 11
is_new_shareholder: 0
total_share_transfer: 111
share_transfer_application_id: 10
status_name: "In Cart"
transfer_date: "2021-11-11T16:01:09.000Z"
transfer_from_id: 29
transfer_to_id: 67

transfer from shareholder
first_name: "Way Siong (T)"
last_name: "Goh"
share_capital_id: 8
share_type_id: 1
share_type_name: "Ordinary"
shareholder_id: 29
shareholder_type_id: 1
shareholder_type_name: "Individual"
user_allotment: 200000
user_id: 67
username: "max.goh@meyzer.com"

transfer to user
alias: " "
country_of_birth: null
country_of_birth_id: null
country_of_birth_iso_2_code: null
date_of_birth: "0000-00-00"
email: "max.goh@meyzer.com"
first_name: "Way Siong (T)"
gender_code: "M"
gender_id: 1
gender_name: "Male"
last_name: "Goh"
nationality: "Singapore"
nationality_id: 190
nationality_iso_2_code: "SG"
nric: "S8270577E"
passport: null
user_id: 67
username: "max.goh@meyzer.com"



new respond
share_transfer_application_id: 108
company_application_id: 2592
company_application_status_id: 11
status_name: "In Cart"

is_new_shareholder: 1
total_share_transfer: 2
number_consideration: null
number_share_transfer: 1
transfer_date: "2022-04-26T14:37:37.000Z"
transfer_from_id: 142
transfer_to_id: 0

passport_attachment_id: null
proof_identity_attachment_id: 1372
proof_residency_attachment_id: null

identity_id: "222"
is_foreigner: 0
shareholder_name: "222"
mobile_number: "2"
email: "qwe@qwe.qwew"


company data
is_company: null
company_name: null
company_registration_no: null
country_incorporation_id: null
country_incorporation_name: null

floor_unit_no: null
address_line_1: null
address_line_2: null
postal_code: null
country_id: null
country_name: null

  */

  let isTransferToCompany = application?.is_company == 1;
  let isNewShareholder = application?.is_new_shareholder == 1;

  const [transferFrom, setTransferFrom] = useState({});
  const [transferTo, setTransferTo] = useState({});

  let isTransferFromCompany = transferFrom?.company_name ? true : false;
  let transferFromData = transferFrom;
  let transferToData = isNewShareholder ? application : { ...application, ...transferTo };

  const { rawData: passportAttachments = [], refetch: fetchPassportAttachments } = useCommonOptions(
    documentApi.getDocumentsByIdSTS,
    {
      respondKey: 'document',
      lazyLoad: true,
    }
  );
  // const { rawData: capitalAttachments = [], refetch: fetchCapitalAttachments } = useCommonOptions(
  //   documentApi.getDocumentsByIdSTS,
  //   {
  //     respondKey: 'document',
  //     lazyLoad: true,
  //   }
  // );
  const {
    rawData: proofIdentityAttachments = [],
    refetch: fetchProofIdentityAttachments,
  } = useCommonOptions(documentApi.getDocumentsByIdSTS, {
    respondKey: 'document',
    lazyLoad: true,
  });
  const {
    rawData: proofResidencyAttachments = [],
    refetch: fetchProofResidencyAttachments,
  } = useCommonOptions(documentApi.getDocumentsByIdSTS, {
    respondKey: 'document',
    lazyLoad: true,
  });

  useEffect(() => {
    if (request?.company_id && application?.transfer_from_id) {
      shareholderApi
        .getShareholderById(request.company_id, application?.transfer_from_id)
        .then((result) => {
          if (result?.data?.shares) {
            setTransferFrom(result?.data?.shares);
          }
        })
        .catch((error) => {
          setTransferFrom({});
        });
    }
    if (!isNewShareholder) {
      if (request?.company_id && application?.transfer_to_id) {
        shareholderApi
          .getShareholderById(request.company_id, application?.transfer_to_id)
          .then((result) => {
            if (result?.data?.shares) {
              setTransferTo(result?.data?.shares);
            }
          })
          .catch((error) => {
            setTransferTo({});
          });
      }
    }

    if (application?.passport_attachment_id) {
      fetchPassportAttachments(application?.passport_attachment_id);
    }
    if (application?.proof_identity_attachment_id) {
      fetchProofIdentityAttachments(application?.proof_identity_attachment_id);
    }
    if (application?.proof_residency_attachment_id) {
      fetchProofResidencyAttachments(application?.proof_residency_attachment_id);
    }
  }, [JSON.stringify(request), JSON.stringify(application)]);

  const attachments = [
    {
      label: 'Passport Attachments',
      data: passportAttachments || [],
    },
    {
      label: 'Proof Identity Attachments',
      data: proofIdentityAttachments || [],
    },
    {
      label: 'Proof Residency Attachments',
      data: proofResidencyAttachments || [],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          required
          label="Transfer Date"
          value={moment(application?.transfer_date).format('YYYY-MM-DD') || '-'}
          fullWidth
          disabled={true}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="Number of share transfer"
          value={application?.number_share_transfer || '-'}
          fullWidth
          disabled={true}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="Number Consideration"
          value={application?.number_consideration || '-'}
          fullWidth
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <b>Transfer From</b>
      </Grid>
      <Grid item xs={12}>
        <FormFieldContainer label="Is Company">
          {isTransferFromCompany ? 'YES' : 'NO'}
        </FormFieldContainer>
      </Grid>
      {isTransferFromCompany ? (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Company Name"
              value={transferFromData?.company_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Company Registration No"
              value={transferFromData?.company_registration_no || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Country Incorporation Name"
              value={transferFromData?.country_incorporation_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Floor Unit No"
              value={transferFromData?.floor_unit_no || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Address Line 1"
              value={transferFromData?.address_line_1 || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Address Line 2"
              value={transferFromData?.address_line_2 || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Postal Code"
              value={transferFromData?.postal_code || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Country Name"
              value={transferFromData?.country_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid> */}
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Name"
              value={`${transferFromData?.username || '-'} (${transferFromData?.first_name || ''} ${
                transferFromData?.last_name || ''
              })`}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Is Foreigner"
              value={transferFromData?.is_foreigner ? 'Yes' : 'No'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Identity Id"
              value={transferFromData?.identity_id || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Mobile Number"
              value={transferFromData?.mobile_number || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
        </>
      )}
      {/* <Grid item xs={12}>
        <hr />
      </Grid> */}
      <Grid item xs={12}>
        <b>Transfer To</b>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormFieldContainer label="Is new shareholder">
          {isNewShareholder ? 'YES' : 'NO'}
        </FormFieldContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormFieldContainer label="Is Company">
          {isTransferToCompany ? 'YES' : 'NO'}
        </FormFieldContainer>
      </Grid>
      {isTransferToCompany ? (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Company Name"
              value={transferToData?.company_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Company Registration No"
              value={transferToData?.company_registration_no || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Country Incorporation Name"
              value={transferToData?.country_incorporation_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Floor Unit No"
              value={transferToData?.floor_unit_no || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Address Line 1"
              value={transferToData?.address_line_1 || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Address Line 2"
              value={transferToData?.address_line_2 || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Postal Code"
              value={transferToData?.postal_code || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Country Name"
              value={transferToData?.country_name || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              label="Name"
              value={`${transferToData?.username || '-'} (${transferToData?.first_name || ''} ${
                transferToData?.last_name || ''
              })`}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Is Foreigner"
              value={transferToData?.is_foreigner ? 'Yes' : 'No'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Identity Id"
              value={transferToData?.identity_id || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Mobile Number"
              value={transferToData?.mobile_number || '-'}
              fullWidth
              disabled={true}
            />
          </Grid>
        </>
      )}

      {attachments.map((anAttachment, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Box>{anAttachment.label}</Box>
            {anAttachment?.data?.length > 0
              ? anAttachment?.data.map((anItem, index) => {
                  return (
                    <Box key={index} mb={1}>
                      {/* <CustomUploadButton
                          previewLink={anItem?.signed_document_url || ''}
                          previewLabel={`View Attachment (${anItem?.document_type_name})`}
                          hideUpload={true}
                        /> */}
                      {/* <Button
                          variant="contained"
                          target="_blank"
                          color={'primary'}
                          href={anItem?.signed_document_url || ''}
                        >
                          View Attachment ({anItem?.document_type_name})
                        </Button> */}
                      <FileViewerButton2
                        srcType="url"
                        src={anItem?.signed_document_url || ''}
                        document={anItem}
                      >
                        View Attachment ({anItem?.document_type_name})
                      </FileViewerButton2>
                    </Box>
                  );
                })
              : 'No Attachment Found'}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const ShareTransferApplication = ({ application = [], request }) => {
  return (
    <>
      {application?.length > 0
        ? application.map((anItem, index) => {
            return (
              <Box key={index} mb={2}>
                {index == 0 ? null : (
                  <Box py={2}>
                    <hr />
                  </Box>
                )}
                <Box mb={1}>
                  <Typography variant="h5">
                    <b>Transfer {index + 1}</b>
                  </Typography>
                </Box>
                <ShareTransferApplicationItem application={anItem} request={request} />
              </Box>
            );
          })
        : 'No Data Found'}
    </>
  );
};

// function camelize(str) {
//   return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
//     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
//     return index === 0 ? match.toLowerCase() : match.toUpperCase();
//   });
// }
function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

let ApplicationItem = (props) => {
  const { valueKey = '', value } = props;
  let lowerCaseKey = valueKey.toLocaleLowerCase();
  let keyTexts = lowerCaseKey.split('_');
  keyTexts = keyTexts.map((aText) => {
    if (aText?.length > 0) {
      return aText[0].toLocaleUpperCase() + aText.substring(1);
    }
    return '';
  });
  let label = keyTexts.join(' ');
  return (
    <Box>
      <Box>
        <small>{label}</small>
      </Box>
      <Box>{value || '-'}</Box>
    </Box>
  );
};
export const IndividualParticularApplication = (props) => {
  const { application = {}, request } = props;

  const individualTypes = {
    1: 'Director',
    2: 'Shareholder',
  };
  let generalKeys = [
    // "company_application_id",
    'individual_particular_application_id',
    'name_change_date',
  ];
  let userKeys = [
    // 'individual_type_id',
    'company_role_id',
    'user_id',
    'email',
    'first_name',
    'last_name',
    'mobile_number',
    'nationality_country_id',
    'identification_type_id',
    'identification_value',
    // "identity_attachment_id",
    // "identity_attachment_url",
  ];

  let addressKeys = [
    'floor_unit_no',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'postal_code',
    'state_id',
    'country_id',
    'residence_type_id',
    // "residency_attachment_id",
    // "residency_attachment_url",
  ];

  let otherKeys = [
    'deed_poll_date',
    // "deed_poll_attachment_id",
    // "deed_poll_attachment_url",
  ];

  let keyGroups = [
    {
      id: 1,
      label: '',
      keys: generalKeys,
    },
    {
      id: 2,
      label: 'User',
      keys: userKeys,
    },
    {
      id: 3,
      label: 'Address',
      keys: addressKeys,
    },
    {
      id: 4,
      label: 'Deed Poll',
      keys: otherKeys,
    },
  ];

  const [identityAttachments, setIdentityAttachments] = useState([]);
  const [residencyAttachments, setResidencyAttachments] = useState([]);
  const [deedPollAttachments, setDeedPollAttachments] = useState([]);
  useEffect(() => {
    if (application?.identity_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.identity_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setIdentityAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setIdentityAttachments([]);
        });
    }
    if (application?.residency_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.residency_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setResidencyAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setResidencyAttachments([]);
        });
    }
    if (application?.deed_poll_attachment_id) {
      documentApi
        .getDocumentsByIdSTS(application?.deed_poll_attachment_id)
        .then((result) => {
          if (result?.data?.document) {
            setDeedPollAttachments(result?.data?.document || []);
          }
        })
        .catch((error) => {
          setDeedPollAttachments([]);
        });
    }
  }, [JSON.stringify(application), JSON.stringify(request)]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* {individualTypes[application['individual_type_id']]} */}
          <ApplicationItem
            valueKey={'Individual Type'}
            value={individualTypes[application['individual_type_id']]}
          />
        </Grid>
        {keyGroups.map((aGroup, index) => {
          return (
            <Grid container item xs={12} key={index} spacing={1}>
              {aGroup.label && (
                <Grid item xs={12} key={index}>
                  <b>{aGroup.label}</b>
                </Grid>
              )}

              {aGroup.keys.map((aKey, index2) => {
                return (
                  <Grid item xs={12} sm={6} key={index2}>
                    <ApplicationItem valueKey={aKey} value={application[aKey]} />
                  </Grid>
                );
              })}

              {aGroup.id == 2 ? (
                <Grid item xs={12} key={'doc2'}>
                  <Box>
                    <small>Identity Attachments</small>
                  </Box>
                  <Box>
                    {identityAttachments?.length > 0
                      ? identityAttachments.map((anItem, index) => {
                          return (
                            <Box key={index} mb={1}>
                              {/* <Button
                                variant="contained"
                                target="_blank"
                                color={'primary'}
                                href={anItem?.signed_document_url || ''}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </Button> */}
                              <FileViewerButton2
                                srcType="url"
                                src={anItem?.signed_document_url || ''}
                                document={anItem}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </FileViewerButton2>
                            </Box>
                          );
                        })
                      : 'No Attachment Found'}
                  </Box>
                </Grid>
              ) : null}

              {aGroup.id == 3 ? (
                <Grid item xs={12} key={'doc2'}>
                  <Box>
                    <small>Residency Attachments</small>
                  </Box>
                  <Box>
                    {residencyAttachments?.length > 0
                      ? residencyAttachments.map((anItem, index) => {
                          return (
                            <Box key={index} mb={1}>
                              {/* <Button
                                variant="contained"
                                target="_blank"
                                color={'primary'}
                                href={anItem?.signed_document_url || ''}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </Button> */}
                              <FileViewerButton2
                                srcType="url"
                                src={anItem?.signed_document_url || ''}
                                document={anItem}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </FileViewerButton2>
                            </Box>
                          );
                        })
                      : 'No Attachment Found'}
                  </Box>
                </Grid>
              ) : null}

              {aGroup.id == 4 ? (
                <Grid item xs={12} key={'doc3'}>
                  <Box>
                    <small>Deed Poll Attachments</small>
                  </Box>
                  <Box>
                    {deedPollAttachments?.length > 0
                      ? deedPollAttachments.map((anItem, index) => {
                          return (
                            <Box key={index} mb={1}>
                              {/* <Button
                                variant="contained"
                                target="_blank"
                                color={'primary'}
                                href={anItem?.signed_document_url || ''}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </Button> */}
                              <FileViewerButton2
                                srcType="url"
                                src={anItem?.signed_document_url || ''}
                                document={anItem}
                              >
                                View Attachment ({anItem?.document_type_name})
                              </FileViewerButton2>
                            </Box>
                          );
                        })
                      : 'No Attachment Found'}
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const CompanyAuditorApplication = (props) => {
  const { application = {}, request } = props;

  let generalKeys = [
    // "company_application_id",
    'company_auditor_application_id',
  ];
  let companyKeys = [
    'company_name',
    'company_registration_no',
    // 'country_incorporation_id',
    'incorp_country',
  ];

  let addressKeys = [
    'floor_unit_no',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'postal_code',
    'state_id',
    'country_id',
    'residence_type_id',
    // "residency_attachment_id",
    // "residency_attachment_url",
  ];

  let keyGroups = [
    {
      id: 1,
      label: '',
      keys: generalKeys,
    },
    {
      id: 2,
      label: 'Company',
      keys: companyKeys,
    },
    {
      id: 3,
      label: 'Address',
      keys: addressKeys,
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        {keyGroups.map((aGroup, index) => {
          return (
            <Grid container item xs={12} key={index} spacing={1}>
              {aGroup.label && (
                <Grid item xs={12} key={index}>
                  <b>{aGroup.label}</b>
                </Grid>
              )}

              {aGroup.keys.map((aKey, index2) => {
                return (
                  <Grid item xs={12} sm={6} key={index2}>
                    <ApplicationItem valueKey={aKey} value={application[aKey]} />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export const AuditorCessationApplication = (props) => {
  const { application = {}, request } = props;
  console.log('AuditorCessationApplication', {
    application,
    request,
  });

  /*
  auditor_cessation_application_id: 5
  status_name: "Payment Complete"
  company_application_id: 2603
  company_application_status_id: 10

  client_company_id: 63
  client_company_name: "Pet's Shop "
  auditor_company_id: 121
  auditor_company_name: "Emily Lai 1 Auditing"
  appointment_date: "2022-04-27T00:00:00.000Z"
  company_auditor_id: 21

  cessation_type_id: null
  cessation_date: "0000-00-00"
  cessation_reason: "28 April 2022 Testing "
  cessation_attachment_id: null
  cessation_attachment_status_id: null
  cessation_attachment_status_name: null
  cessation_attachment_url: null
  */
  let generalKeys = [
    // "company_application_id",
    // 'company_auditor_application_id',
    'client_company_name',
    'appointment_date',
    'auditor_company_name',
  ];
  let auditorCompanyKeys = [
    'company_name',
    'company_registration_no',
    'company_type_name',
    'country_of_incorporation',
  ];
  let cessationKeys = ['cessation_date', 'cessation_reason'];

  const {
    rawData: cessationAttachments = [],
    refetch: fetchCessationAttachments,
  } = useCommonOptions(documentApi.getDocumentsByIdSTS, {
    respondKey: 'document',
    lazyLoad: true,
  });

  const [auditorCompany, setAuditorCompany] = useState();

  useEffect(() => {
    if (application?.auditor_company_id) {
      companyApi
        .getCompanyDetails(application?.auditor_company_id)
        .then((result) => {
          // console.log('result', result);
          if (result?.data?.company) {
            setAuditorCompany(result?.data?.company);
          } else {
            setAuditorCompany();
          }
        })
        .catch((error) => {
          setAuditorCompany();
        });
    }
    if (application?.cessation_attachment_url) {
      fetchCessationAttachments(application?.cessation_attachment_url);
    }
  }, [JSON.stringify(request), JSON.stringify(application)]);

  let keyGroups = [
    {
      id: 1,
      label: '',
      keys: generalKeys,
      data: application,
    },
    {
      id: 2,
      label: 'Auditor Company',
      keys: auditorCompanyKeys,
      data: auditorCompany,
    },
    {
      id: 2,
      label: 'Cessation',
      keys: cessationKeys,
      data: application,
    },
  ];

  let attachments = [
    {
      label: 'Cessation Attachments',
      data: cessationAttachments,
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        {keyGroups.map((aGroup, index) => {
          let source = aGroup?.data || application;
          return (
            <Grid container item xs={12} key={index} spacing={1}>
              {aGroup.label && (
                <Grid item xs={12} key={index}>
                  <b>{aGroup.label}</b>
                </Grid>
              )}

              {aGroup.keys.map((aKey, index2) => {
                return (
                  <Grid item xs={12} sm={6} key={index2}>
                    <ApplicationItem valueKey={aKey} value={source[aKey]} />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        {attachments.map((anAttachment, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Box>{anAttachment.label}</Box>
              {anAttachment?.data?.length > 0
                ? anAttachment?.data.map((anItem, index) => {
                    return (
                      <Box key={index} mb={1}>
                        {/* <CustomUploadButton
                          previewLink={anItem?.signed_document_url || ''}
                          previewLabel={`View Attachment (${anItem?.document_type_name})`}
                          hideUpload={true}
                        /> */}
                        {/* <Button
                          variant="contained"
                          target="_blank"
                          color={'primary'}
                          href={anItem?.signed_document_url || ''}
                        >
                          View Attachment ({anItem?.document_type_name})
                        </Button> */}
                        <FileViewerButton2
                          srcType="url"
                          src={anItem?.signed_document_url || ''}
                          document={anItem}
                        >
                          View Attachment ({anItem?.document_type_name})
                        </FileViewerButton2>
                      </Box>
                    );
                  })
                : 'No Attachment Found'}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
