import React, { Suspense, useState, useEffect } from 'react';
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
// components
import Layout from './Layout';
import CustomLoading from './CustomLoading';
// pages
import Error from '../pages/error';
import Login from '../pages/login';

// context
import { useUserState, useUserContext } from '../context/UserContext';
import useQueryParams from '../hooks/useQueryParams';
let isProd = process.env.REACT_APP_API_URL == 'https://api.meyzer360.com';

export default function App() {
  // global

  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
}

const AllRoutes = () => {
  const user = new Cookies().get('idToken');
  const [cookieIdToken, setCookieIdToken] = useCookies(['idToken']);
  const [accessToken, setAccessToken] = useCookies(['accessToken']);
  // const { setUserLoading } = useContext(ConfigContext);
  const [userLoading, setUserLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  // const queryParams = useQueryParams();
  const params = new URLSearchParams(location.search);

  const [{ isAuthenticated, userId }, userMethods] = useUserContext();

  useEffect(() => {
    setUserLoading(true);
    let fetching = true;

    if (user) {
      setUserLoading(false);
    }

    if (isProd) {
      setUserLoading(false);
    } else {
      (async () => {
        let code = params.get('code');
        // let user = new Cookies().get('idToken');

        // console.log('codecode', { code, user });
        if (!code && !user) {
          window.location.href = `${process.env.REACT_APP_ACCESS}?client_id=meyzeradmin&redirect_uri=${window.origin}`;
        }
        if (code && !user) {
          try {
            let response = await axios.post(process.env.REACT_APP_BACKEND, {
              code: code,
            });
            var idTokenData = jwt.decode(response.data.id_token);

            if (idTokenData) {
              setCookieIdToken('idToken', response.data.id_token, {
                expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
              });
              if (response.data.access_token) {
                setAccessToken('accessToken', response.data.access_token, {
                  expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
                });
              }
              // history.replace(ROUTES.DASHBOARD);
              // history.replace('/dashboard');
            }
            await userMethods.initialUser();
            setUserLoading(false);
          } catch (error) {
            setUserLoading(false);
            console.log('access error', error);
          }
          // axios
          //   .post(process.env.REACT_APP_BACKEND, {
          //     code: code,
          //   })
          //   .then(function (response) {
          //     setUserLoading(false);
          //     var idTokenData = jwt.decode(response.data.id_token);

          //     if (idTokenData && fetching) {
          //       setCookieIdToken('idToken', response.data.id_token, {
          //         expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
          //       });
          //       if (response.data.access_token) {
          //         setAccessToken('accessToken', response.data.access_token, {
          //           expires: idTokenData?.exp ? new Date(idTokenData?.exp * 1000) : null,
          //         });
          //       }
          //       // history.replace(ROUTES.DASHBOARD);
          //       history.replace('/dashboard');
          //     }
          //   })
          //   .catch(function (error) {
          //     setUserLoading(false);
          //     console.log(error);
          //   });
        }
        setUserLoading(false);
      })();
    }
    return () => {
      setUserLoading(false);
      fetching = false;
    };
  }, []);

  useEffect(() => {
    if (isProd) {
      (async () => {
        setUserLoading(true);
        await userMethods.initialUserProd();
        setUserLoading(false);
      })();
    }
  }, [userId]);

  // const globalState = useSelector((s) => s.global);
  // const [auth, setAuth] = useState({ isAuth: user, loading: false });

  // useEffect(() => {
  //   console.log(globalState?.auth);
  //   if (globalState?.auth?.timespan !== 0)
  //     setAuth({ isAuth: globalState.auth.isAuthenticated, loading: false });
  // }, [globalState]);

  if (userLoading) return <CustomLoading loading={true} />;
  // const mainPath = '/dashboard'
  const mainPath = '/company';
  return (
    <Suspense fallback={<h1>loading</h1>}>
      <Switch>
        {/* <Route
          exact
          path="/login"
          render={() => {
            if (!user) {
              // return <Login />;
              return (window.location.href = `${process.env.REACT_APP_ACCESS}?client_id=meyzeradmin&redirect_uri=${window.origin}`);
            }
            return <Redirect to={mainPath} />;
          }}
        />
        <Route path="/" render={(props) => <Layout {...props} />} />
        <Route
          exact
          path="/"
          render={(props) => <>{!user ? <Redirect to={'/login'} /> : <Redirect to={mainPath} />}</>}
        /> */}
        {/* <Route path="/" render={() => <Redirect to={mainPath} />} /> */}
        {/* <PrivateRoute path="/app" component={Layout} /> */}
        {/* <PublicRoute path="/login" component={Login} /> */}

        {/* <Route path="/" render={() => <Redirect to={mainPath} />} /> */}
        {/* <Route exact path="/app" render={() => <Redirect to="/dashboard" />} /> */}

        {/* <Route
          path="/"
          render={(props) => <>{!user ? <Redirect to={'/login'} /> : <Layout {...props} />}</>}
        /> */}
        {/* <Route
          path="/"
          render={(props) => <>{!user ? <Redirect to={'/login'} /> : <Redirect to={mainPath} />}</>}
        /> */}

        {/* {isAuthenticated ? (
          <>
            <Route path="/" render={(props) => <Layout {...props} />} />
            <Redirect from="/" to={mainPath} />
          </>
        ) : null} */}

        {/* <Route
          path="/"
          render={(props) => <>{!user ? <Redirect to={'/login'} /> : <Layout {...props} />}</>}
        /> */}

        {isProd ? (
          <>
            {/* <PrivateRoute path="/" component={Layout} /> */}

            <Route
              exact
              path="/login"
              render={() => {
                if (!user) {
                  return <Login />;
                }
                return <Redirect to={mainPath} />;
              }}
            />
            <Route
              path="/"
              render={() => {
                return (
                  <Switch>
                    <Route
                      path="/"
                      render={() => {
                        if (!user) {
                          return <Redirect to={'/login'} />;
                        }
                        return <Layout />;
                      }}
                    />
                  </Switch>
                );
              }}
            />
          </>
        ) : (
          <>
            {/* <Route
              exact
              path="/"
              render={() => {
                if (!user) {
                  return <Redirect to={'/login'} />;
                }
                return <Redirect to={mainPath} />;
              }}
            /> */}
            <Route
              exact
              path="/login"
              render={() => {
                if (!user) {
                  return (window.location.href = `${process.env.REACT_APP_ACCESS}?client_id=meyzeradmin&redirect_uri=${window.origin}`);
                }
                return <Redirect to={mainPath} />;
              }}
            />
            <Route
              path="/"
              render={() => {
                return (
                  <Switch>
                    <Route
                      path="/"
                      render={() => {
                        if (!user) {
                          return <Redirect to={'/login'} />;
                        }
                        return <Layout />;
                      }}
                    />
                  </Switch>
                );
              }}
            />
            {/* <Route path="/" component={Layout} /> */}
          </>
        )}

        {/* <Route path="/" component={Layout} /> */}
        <Route path="*" component={Error} />
      </Switch>
    </Suspense>
  );
};

// #######################################################################

function PrivateRoute({ component, ...rest }) {
  const { isAuthenticated } = useUserState();
  // console.log('isAuthenticated', isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, ...rest }) {
  const { isAuthenticated, idToken } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}
