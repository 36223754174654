import axios from "axios";

export default axios.create({
  // baseURL: "https://n5a74lgnv9.execute-api.ap-southeast-1.amazonaws.com/Prod",
  baseURL: `${process.env.REACT_APP_CUSTOMERDD_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
