import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';

const CustomPopper = function (props) {
  const styles = (theme) => ({
    popper: {
      // width: 'fit-content',
      width: '300px',
    },
  });
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 520,
    width: '100%',
  },
}));

const boldString = (str, substr) => {
  return str.replace(substr, '<b>' + substr + '</b>');
};

export default function CustomMenu({
  data: options = [],
  value,
  label,
  onChange,
  size = 'medium',
  variant = 'outlined',
  disabled = false,
  searchable = false,
  disablePortal = false,
  multiple = false,
  ...restProps
}) {
  const classes = useStyles();

  // let contentWrapperDom = document?.getElementById('content-wrapper');

  if (searchable) {
    let defaultValue = value === undefined ? null : options.find((v) => v.value === value);
    if (multiple) {
      defaultValue =
        value?.length > 0
          ? options.filter((anItem) => {
              return value.indexOf(anItem.value) >= 0;
            })
          : [];
    }
    return (
      <Autocomplete
        key={value}
        multiple={multiple}
        PopperComponent={CustomPopper}
        options={options}
        getOptionLabel={(option) => option.label}
        defaultValue={defaultValue}
        // id="auto-complete"
        // autoComplete=
        includeInputInList
        disabled={disabled}
        size={size}
        // onChange={handleChange}
        onChange={(event, newValue) => {
          if (multiple) {
            onChange({
              ...event,
              target: {
                // value: newValue?.length > 0 ? newValue : [],
                value: newValue?.length > 0 ? newValue?.map((anItem) => anItem.value) : [],
              },
            });
          } else {
            onChange({ ...event, target: { value: newValue?.value || '' } });
          }
        }}
        renderOption={(option, state) => {
          // console.log("option, state", { option, state });
          if (state.inputValue) {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: boldString(option.label, state.inputValue),
                }}
              />
            );
          }
          return option.label || '';
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // type="search"
            variant={variant}
            label={label}
            // margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            size={size}
            {...restProps}
          />
        )}
        // container={contentWrapperDom || document.body}
        disablePortal={disablePortal}
      />
    );
  }
  return (
    <FormControl variant={variant} className={classes.formControl} size={size}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
      >
        {options.map(({ value: optionValue, label, disabled = false }, index) => {
          return (
            <MenuItem key={index} value={optionValue} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
