import shareholderApi from "./shareholderapi";

const addShareholder = (companyId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareholderApi.post(`/companies/` + companyId + `/shareholders`, data);
  // .then((response) => {
  //   console.log("postShareholder", response);
  //   return response;
  // })
  // .catch((error) => {
  //   console.log("errpostshareholder", error);
  //   return error;
  // });

  // .then((response) => {
  //   return response.data;
  // })
  // .catch((error) => {
  //   return error;
  // });
};

const removeShareholder = (companyId, shareholderId) => {
  return shareholderApi.delete(
    `/companies/${companyId}/shareholders/${shareholderId}`
  );
};
const getShareholder = (companyId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareholderApi.get(`/companies/` + companyId + `/shareholders`);
  // .then((response) => {
  //   console.log("getShareholder", response);
  //   return response;
  // })
  // .catch((error) => {
  //   console.log("errshare", error);
  //   return error;
  // });
};

const getShareholderById = (companyId, shareholderId) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareholderApi.get(
    `/companies/` + companyId + `/shareholders/${shareholderId}`
  );
  // .then((response) => {
  //   console.log("getShareholder", response);
  //   return response;
  // })
  // .catch((error) => {
  //   console.log("errshare", error);
  //   return error;
  // });
};

const updateShareholder = (companyId, shareholderId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return shareholderApi.put(
    `/companies/` + companyId + `/shareholders/` + shareholderId,
    data
  );
  // .then((response) => {
  //   console.log("putShareholder", response);
  //   return response;
  // })
  // .catch((error) => {
  //   console.log("puterrshareholder", error);
  //   return error;
  // });
};

export default {
  addShareholder,
  getShareholder,
  updateShareholder,
  getShareholderById,
  removeShareholder,
};
