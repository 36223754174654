import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

// styles
import useStyles from './styles';

import PageTitle from '../../components/PageTitle/PageTitle';
import CustomTable from '../../components/CustomTable';
import CustomModal from '../../components/CustomModal';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
import userApi from '../../services/user.service';
import { useUserState } from '../../context/UserContext';
import CustomSelectField from '../../components/CustomFormFields/CustomSelectField';
import CustomLoading from '../../components/CustomLoading';

import AdminUserMenuForm from './AdminUserMenuForm';

const defaultModalForm = {
  show: false,
  data: null,
};
export default function AdminUsers(props) {
  var classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [newAdminUserForm, setNewAdminUserForm] = useState(defaultModalForm);
  const [changePasswordForm, setChangePasswordForm] = useState(defaultModalForm);
  const [adminUserMenuForm, setAdminUserMenuForm] = useState(defaultModalForm);

  const fetchAdminUsers = async () => {
    setLoading(true);
    try {
      let adminUsersRes = await userApi.getAdminUsers();
      setAdminUsers(adminUsersRes?.data?.users || []);
    } catch (error) {}
    setLoading(false);
  };

  const handleNewAdminUserFormOpen = () => {
    setNewAdminUserForm({ ...defaultModalForm, show: true });
  };
  const handleNewAdminUserFormClose = () => {
    setNewAdminUserForm(defaultModalForm);
  };

  const handleChangePasswordFormClose = () => {
    setChangePasswordForm(defaultModalForm);
  };

  const handleAdminUserMenuFormOpen = (value) => {
    setAdminUserMenuForm({
      show: true,
      data: value,
    });
  };
  const handleAdminUserMenuFormClose = () => {
    setAdminUserMenuForm(defaultModalForm);
  };

  useEffect(() => {
    (async () => {
      await fetchAdminUsers();
    })();
  }, []);

  const tableColumns = [
    {
      id: 'user_id',
      label: 'USER ID',
    },
    {
      id: 'username',
      label: 'USERNAME',
    },
    {
      id: 'email',
      label: 'EMAIL',
    },
    {
      id: 'last_login',
      label: 'LAST LOGIN',
      render: (text) => {
        return text ? moment(text).format('DD MMM YYYY') : '-';
      },
    },
    {
      id: 'action',
      label: '',
      render: (text, data) => {
        return (
          <Button
            // variant=""
            color="primary"
            size="small"
            onClick={() => {
              setChangePasswordForm({
                show: true,
                data: data,
              });
            }}
          >
            Change Password
          </Button>
        );
      },
    },
    {
      id: 'action1',
      label: '',
      render: (text, data) => {
        return (
          <Button
            // variant=""
            color="primary"
            size="small"
            onClick={() => {
              handleAdminUserMenuFormOpen(data);
            }}
          >
            User Menu
          </Button>
        );
      },
    },
    {
      id: 'action2',
      label: '',
      render: (text, data) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setNewAdminUserForm({
                show: true,
                data: data,
              });
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Admin Users"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleNewAdminUserFormOpen}
          >
            Add New Admin
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTable data={adminUsers} columns={tableColumns} />
        </Grid>
      </Grid>
      <CustomModal
        show={newAdminUserForm?.show}
        onHide={handleNewAdminUserFormClose}
        title={
          newAdminUserForm?.data?.user_id
            ? `Edit Admin User (ID: ${newAdminUserForm?.data?.user_id})`
            : 'New Admin User'
        }
        scrollable={false}
      >
        <AdminUserForm
          afterSubmit={() => {
            fetchAdminUsers();
            handleNewAdminUserFormClose();
          }}
          defaultValues={newAdminUserForm?.data}
          id={newAdminUserForm?.data?.user_id}
        />
      </CustomModal>
      <CustomModal
        show={changePasswordForm?.show}
        onHide={handleChangePasswordFormClose}
        title={`Change Password (ID: ${changePasswordForm?.data?.user_id})`}
        scrollable={false}
      >
        <AdminUserChangePasswordForm
          afterSubmit={() => {
            fetchAdminUsers();
            handleChangePasswordFormClose();
          }}
          defaultValues={changePasswordForm?.data}
          id={changePasswordForm?.data?.user_id}
        />
      </CustomModal>
      <CustomModal
        show={adminUserMenuForm?.show}
        onHide={handleAdminUserMenuFormClose}
        title={`Add User Menu (ID: ${adminUserMenuForm?.data?.user_id})`}
        scrollable={false}
      >
        <AdminUserMenuForm
          afterSubmit={() => {
            fetchAdminUsers();
            handleAdminUserMenuFormClose();
          }}
          defaultValues={adminUserMenuForm?.data}
          id={adminUserMenuForm?.data?.user_id}
        />
      </CustomModal>

      <CustomLoading loading={loading} />
    </>
  );
}

const fields = {
  username: {
    name: 'username',
    label: 'Username',
  },
  email: {
    name: 'email',
    label: 'Email',
  },
  password: {
    name: 'password',
    label: 'Password',
  },
  role: {
    name: 'role',
    label: 'Role',
  },
};

const validationSchemaObj = {
  // [fields.username.name]: Yup.string().required(`${fields.username.label} is required`).default(''),
  // Company Details
  [fields.email.name]: Yup.string().required(`${fields.email.label} is required`).default(''),
  [fields.password.name]: Yup.string().required(`${fields.password.label} is required`).default(''),
};

const AdminUserForm = (props) => {
  const { defaultValues = {}, id, afterSubmit } = props;
  const { userId: authUserId } = useUserState();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape(validationSchemaObj);
  let initialValues = { ...validationSchema.cast(), ...defaultValues };

  let isNew = id ? false : true;

  const handleRemove = async () => {
    setLoading(true);
    if (id) {
      if (window.confirm('Confirm Remove this Admin?')) {
        try {
          let adminUsersRes = await userApi.removeAdminUser(id);
          enqueueSnackbar('Admin User Removed', {
            variant: 'success',
          });
          if (afterSubmit) {
            afterSubmit();
          }
        } catch (error) {
          enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Remove Admin User`, {
            variant: 'error',
          });
        }
      }
    }
    setLoading(false);
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    if (isNew) {
      // create
      try {
        let adminUsersRes = await userApi.createAdminUser({
          ...values,
          [fields.username.name]: values?.[fields?.email?.name],
          created_by: authUserId,
        });
        enqueueSnackbar('Admin User Created', {
          variant: 'success',
        });
        if (afterSubmit) {
          afterSubmit();
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Create Admin User`, {
          variant: 'error',
        });
      }
    } else {
      // update
      try {
        let adminUsersRes = await userApi.updateAdminUser(id, values);
        enqueueSnackbar('Admin User Updated', {
          variant: 'success',
        });
        if (afterSubmit) {
          afterSubmit();
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Update Admin User`, {
          variant: 'error',
        });
      }
    }
    setLoading(false);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const { values, handleSubmit, validateForm, setTouched, touched } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                <CustomTextField
                  name={fields.username.name}
                  label={fields.username.label}
                  required={true}
                />
              </Grid> */}
                <Grid item xs={12}>
                  <CustomTextField
                    name={fields.email.name}
                    label={fields.email.label}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomSelectField
                    name={fields.role.name}
                    label={fields.role.label}
                    required={true}
                    options={[
                      {
                        label: 'Super Admin Panel',
                        value: '1',
                      },
                      {
                        label: 'System User',
                        value: '2',
                      },
                      {
                        label: 'Corpsec User',
                        value: '3',
                      },
                      {
                        label: 'Web User',
                        value: '4',
                      },
                      {
                        label: 'Finance User',
                        value: '5',
                      },
                      {
                        label: 'Meyzerswap User',
                        value: '6',
                      },
                      {
                        label: 'Office Supplies User',
                        value: '7',
                      },
                      {
                        label: 'Software Licenses User',
                        value: '8',
                      },
                    ]}
                  />
                </Grid>
                {isNew ? (
                  <Grid item xs={12}>
                    <CustomTextField
                      name={fields.password.name}
                      label={fields.password.label}
                      required={true}
                      type="password"
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Box ml="auto" width="100%" display="flex" justifyContent="space-between">
                    {isNew ? null : (
                      <Button variant="contained" color="secondary" onClick={handleRemove}>
                        Remove
                      </Button>
                    )}
                    <Box ml="auto">
                      <Button variant="contained" color="primary" type="submit">
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <CustomLoading loading={loading} />
    </>
  );
};

const fields_changePassword = {
  curr_password: {
    name: 'curr_password',
    label: 'Current Password',
  },
  new_password: {
    name: 'new_password',
    label: 'New Password',
  },
};

const validationSchemaObj_changePassword = {
  [fields_changePassword.curr_password.name]: Yup.string()
    .required(`${fields_changePassword.curr_password.label} is required`)
    .default(''),
  // Company Details
  [fields_changePassword.new_password.name]: Yup.string()
    .required(`${fields_changePassword.new_password.label} is required`)
    .default(''),
};

const AdminUserChangePasswordForm = (props) => {
  const { defaultValues = {}, id, afterSubmit } = props;
  const { userId: authUserId } = useUserState();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape(validationSchemaObj_changePassword);
  let initialValues = { ...validationSchema.cast(), ...defaultValues };

  const onSubmit = async (values, actions) => {
    console.log('values', values);

    try {
      let adminUsersRes = await userApi.changePasswordAdminUser(id, values);
      enqueueSnackbar('Admin User Password Changed', {
        variant: 'success',
      });
      if (afterSubmit) {
        afterSubmit();
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error?.message || `Failed to Change Password`, {
        variant: 'error',
      });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { values, handleSubmit, validateForm, setTouched, touched } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomTextField
                  name={fields_changePassword.curr_password.name}
                  label={fields_changePassword.curr_password.label}
                  required={true}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  name={fields_changePassword.new_password.name}
                  label={fields_changePassword.new_password.label}
                  required={true}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Box ml="auto" width="100%" display="flex" justifyContent="space-between">
                  <Box ml="auto">
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
