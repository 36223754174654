import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Grid, Box, CircularProgress, Chip } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import orderSvc from '../../services/order.service';
import CustomTable from '../../components/CustomTable';
// styles
import useStyles from './styles';

export default function OrdersByProductCategory(props) {
  const { productCategoryId, title } = props;
  var classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let ordersRes = await orderSvc.orders();
      let orders = ordersRes?.length > 0 ? ordersRes : [];
      if (productCategoryId != undefined) {
        orders = orders.filter((anItem) => {
          let found = false;
          let orderItems = anItem?.items || [];
          orderItems = orderItems.filter((anOrderItem) => {
            return anOrderItem?.product?.product_category_id == productCategoryId;
          });

          if (orderItems?.length > 0) {
            found = true;
          }
          return found;
        });
      }
      setOrderList(orders);
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  const tableColumns = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'Cart ID',
      id: 'cart_id',
    },
    {
      label: 'USER',
      id: 'first_name',
      render: (_, data) => {
        if (!data?.first_name && !data?.last_name) {
          return '-';
        }
        return `${data?.first_name} ${data?.last_name}`;
      },
    },
    {
      label: 'Company',
      id: 'company_name',
      render: (text, data) => {
        if (!text) {
          return '-';
        }
        return `${text} ${
          data?.company_registration_no ? `(${data?.company_registration_no})` : ''
        }`;
      },
    },
    {
      label: 'DATE',
      id: 'created_at',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy`);
      },
    },
    {
      label: 'ITEMS',
      id: 'items',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text.length}</Box>;
      },
    },
    {
      label: 'AMOUNT',
      id: 'totalPrice',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    {
      label: 'STATUS',
      id: 'status',
    },
    {
      label: 'REMARK',
      id: 'remark',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: '',
      id: 'action',
      render: (text, data) => {
        return (
          <Chip
            color="primary"
            label="DETAIL"
            onClick={() => {
              history.push(`/order?orderid=${data?.id}`);
            }}
          />
        );
      },
    },
  ];

  const orderItemColumns = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'DATE',
      id: 'created_at',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy`);
      },
    },
    {
      label: 'NAME',
      id: 'product',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text?.name || '-'}</Box>;
      },
    },
    {
      label: 'PRICE',
      id: 'price',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    {
      label: 'QUANTITY',
      id: 'quantity',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    {
      label: 'STATUS',
      id: 'status',
    },
  ];
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title={title || 'Orders'} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <CustomTable
                  rowKey="id"
                  columns={tableColumns}
                  data={orderList}
                  //   renderRow={(rowData) => {
                  //     return (
                  //       <CustomTable
                  //         rowKey="id"
                  //         columns={orderItemColumns}
                  //         data={rowData?.items || []}
                  //       />
                  //     );
                  //   }}
                />
              </>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
