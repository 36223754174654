import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import utilsSvc from '../../services/utils.service';
import CustomTable from '../../components/CustomTable';

function EmailLogs(props) {
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const emailLogs = await utilsSvc.getEmailLogs();
    setData(emailLogs?.data?.email_log || []);
    setLoading(false);
  };
  useEffect(() => {
    // fetchData().finally(() => setLoading(false));
    fetchData();
  }, []);

  const tableColumns = [
    {
      label: 'DATE',
      id: 'created_date',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy hh:mm:ssa`);
      },
    },
    {
      label: 'SUBJECT',
      id: 'subject',
    },
    {
      label: 'FROM',
      id: 'from_email',
    },
    {
      label: 'TO',
      id: 'to_email',
    },
    {
      label: 'MESSAGE',
      id: 'message_id',
    },
    {
      label: 'ERROR',
      id: 'error',
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Email Logs" />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title=""
            upperTitle
            noBodyPadding
            // bodyClass={classes.tableWidget}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <CustomTable data={data} columns={tableColumns} rowKey={'user_id'} />
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

export default EmailLogs;
