import userApi from './userapi';

// const createUser = (data) => {
//   // console.log("common", commonApi.get(`/identificationtype`));
//   return companyApi.post(`/companies/`, data);
// };

const getUsers = (filterData, pageData, pageLimitData) => {
  return userApi.get(
    `/users?filter=` + filterData + `&page=` + pageData + `&page_limit=` + pageLimitData
  );
};
const getUserById = (userId) => {
  return userApi.get(`/users/` + userId);
};
const createUser = (data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return userApi.post(`/users/`, data);
};

const updateUser = (userId, data) => {
  // console.log("common", commonApi.get(`/identificationtype`));
  return userApi.put(`/users/` + userId, data);
};

const getContacts = (userId) => {
  return userApi.get(`/users/` + userId + '/contacts/');
};

const updateContactById = (userId, contactId, data) => {
  return userApi.put(`/users/` + userId + '/contacts/' + contactId, data);
};

const createContacts = (userId, data) => {
  return userApi.post(`/users/` + userId + '/contacts', data);
};
// const respContact = await axios.post(
//     "https://l1968cbgy7.execute-api.ap-southeast-1.amazonaws.com/Prod/users/" +
//       respUser.data.users.user_id +
//       "/contacts/",
//     contactData,
//     { headers: header }
//   );

// const getUserDetails = (companyId, data) => {
//   return companyApi.get(`/companies/` + companyId);
// };

const getSysInfoUsers = () => {
  return userApi.get('/sysinfousers');
};
const getSysInfoUserById = (id) => {
  return userApi.get(`/sysinfousers/${id}`);
};
const getSysInfoUserByEmail = (email) => {
  return userApi.get(`/sysinfousers/email/${email}`);
};
const linkCompanyUserToSysInfoUser = (sysInfoUserId, data = {}) => {
  return userApi.put(`/sysinfousers/linkuser/${sysInfoUserId}`, data);
};
const updateSysInfoUser = (userId, body = {}) => {
  return userApi.put(`/sysinfousers/${userId}`, body);
};
const getSysInfoUserRoleByUserId = (id) => {
  return userApi.get(`sysinfouserrolescompanies/user/${id}`);
};
const getSysInfoUserRoleByCompanyId = (id) => {
  return userApi.get(`sysinfouserroles/company/${id}`);
};
const createSysInfoUser = (body = {}) => {
  return userApi.post(`/sysinfousers`, body);
};
const createSysInfoUserRole = (body = {}) => {
  return userApi.post(`/sysinfouserroles/add-user`, body);
  //   {
  //     "username": "a@a.com",
  //     "role_id": 1,   // 1 (ownerr), 2(admin), 3 (shareholder),  remove this comment before sending
  //     "company_id": 1,
  //     "sysinfo_user_id": 100,
  //     "user_id": 1,  // this is invitation user, in normal condition, user_id and created_by same value,  remove this before sending
  //     "created_by": 1
  // }
};

const removeSysInfoUserRoleByUsername = (email, companyId, deleted_by) => {
  return userApi.delete(`/sysinfouserroles/username/${email}/company/${companyId}`, {
    data: { deleted_by: deleted_by },
  });
};

const updateSysInfoUserRole = (email, companyId, role_id, body = {}) => {
  return userApi.put(`/sysinfouserroles/username/${email}/company/${companyId}`, {
    role_id,
    // ...body,
  });
};

const updateCompanySuperAdminOnPublish = (body = {}) => {
  return userApi.put(`/sysinfouserroles/update-superadmins`, body);
};

const getAdminUsers = () => {
  return userApi.get(`adminusers`);
};
const getAdminUserById = (id) => {
  return userApi.get(`adminusers/${id}`);
};
const createAdminUser = (body = {}) => {
  return userApi.post(`adminusers`, body);
};
const updateAdminUser = (id, body = {}) => {
  return userApi.put(`adminusers/${id}`, body);
};

const changePasswordAdminUser = (id, body = {}) => {
  return userApi.put(`adminusers/password/${id}`, {
    new_password: body?.new_password,
    curr_password: body?.curr_password,
  });
};
const removeAdminUser = (id) => {
  return userApi.delete(`adminusers/${id}`);
};

const sendSuperAdminEmail = (body) => {
  return userApi.post('/sysinfouserroles/send-superadmin-email', body);
};
export default {
  createContacts,
  createUser,
  getUsers,
  getUserById,
  getContacts,
  updateContactById,
  updateUser,
  getSysInfoUsers,
  getSysInfoUserById,
  getSysInfoUserByEmail,
  linkCompanyUserToSysInfoUser,
  updateSysInfoUser,
  getSysInfoUserRoleByUserId,
  getSysInfoUserRoleByCompanyId,
  createSysInfoUserRole,
  removeSysInfoUserRoleByUsername,
  createSysInfoUser,
  updateCompanySuperAdminOnPublish,
  updateSysInfoUserRole,
  getAdminUsers,
  getAdminUserById,
  createAdminUser,
  updateAdminUser,
  changePasswordAdminUser,
  removeAdminUser,
  sendSuperAdminEmail,
};
