import axios from './customAxios';

const orders = async (queryString = '') => {
  const { data } = await axios.get(`/order/orders${queryString}`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.orders;
};

const getOrder = async (id) => {
  const { data } = await axios.get(`/order/order/${id}`);

  if (data && !data.is_success) throw Error(data?.message);

  return data?.order;
};

const updateOrderStatus = async (orderId, status, userId) => {
  return await axios.put(`/order/order/update`, {
    id: orderId,
    status: status,
    user_id: userId,
  });
};

const updateOrderItemStatus = async (orderId, status, userId, remark = '') => {
  return axios.put(`/order/order/updateitem`, {
    id: orderId,
    status: status,
    user_id: userId,
    remark: remark,
  });
};

const orderSvc = {
  orders,
  getOrder,
  updateOrderStatus,
  updateOrderItemStatus,
};

export default orderSvc;
