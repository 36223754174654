import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import CustomMenu from '../../../components/CustomMenu';
import CustomModal from '../../../components/CustomModal';
import useCommonOptions from '../../../hooks/useCommonOptions';
import FormFieldContainer from '../../../components/CustomFormFields/FormFieldContainer';

import companyApi from '../../../services/company.service';
import requestsApi from '../../../services/requests.service';
import cartApi from '../../../services/cart.service';
import productApi from '../../../services/product.service';
import userApi from '../../../services/user.service';

const fields = {
  company_id: {
    name: 'company_id',
    label: 'Company',
  },
  user_id: {
    name: 'user_id',
    label: 'User',
  },
  request_description: {
    name: 'request_description',
    label: 'Description',
  },
  other_request_type_id: {
    name: 'other_request_type_id',
    label: 'Request Type',
  },
  price: {
    name: 'price',
    label: 'Price',
  },
};

let validationSchemaObj = {
  [fields.company_id.name]: Yup.string().required(`${fields.company_id.label} is required`),
  [fields.user_id.name]: Yup.string().required(`${fields.user_id.label} is required`),
  [fields.request_description.name]: Yup.string().required(
    `${fields.request_description.label} is required`
  ),
  [fields.other_request_type_id.name]: Yup.string().required(
    `${fields.other_request_type_id.label} is required`
  ),
  [fields.price.name]: Yup.number().required(`${fields.price.label} is required`),
};
const requestTypeOptions = [
  { label: 'Capital & Shares Related', value: 1 },
  { label: 'General Enquires', value: 2 },
  { label: 'Officers Related', value: 3 },
  { label: 'Product & Subscription Related', value: 4 },
  { label: 'Shareholders Related', value: 5 },
];

const fetchSuperAdmins = async (selectedCompanyId) => {
  let result = [];
  try {
    const resSysInfoUserRoles = await userApi.getSysInfoUserRoleByCompanyId(selectedCompanyId);
    if (resSysInfoUserRoles?.data?.is_success) {
      result = resSysInfoUserRoles?.data?.users || [];
      // setSelectedSuperAdmins(result);
    } else {
      result = [];
      // setSelectedSuperAdmins([]);
    }
  } catch (error) {}
  return result;
};

const RequestFormModal = (props) => {
  const { show, onHide, refetch } = props;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [companyAdmins, setCompanyAdmins] = useState([]);

  const {
    options: companyOptions,
    loading: loadingCompanies,
    rawData: companies = [],
    refetch: fetchCompanies,
  } = useCommonOptions(async () => companyApi.getCompanies('', 1, 10000), {
    respondKey: 'companies',
    labelKey: 'company_name',
    valueKey: 'company_id',
    render: (_, data) => {
      return `${data?.company_name} (REG NO: ${data?.company_registration_no})`;
    },
    lazyLoad: true,
  });

  const {
    // options: sysInfoUserOptions,
    loading: loadingSysInfoUsers,
    rawData: sysInfoUsers = [],
    refetch: fetchSysInfoUsers,
  } = useCommonOptions(userApi.getSysInfoUsers, {
    respondKey: 'users',
    labelKey: 'email',
    valueKey: 'user_id',
    render: (_, data) => {
      return `${data.email} (${data.first_name} ${data.last_name})`;
    },
    lazyLoad: true,
  });

  //   const {
  //     options: productsOptions,
  //     loading: loadingProducts,
  //     rawData: products = [],
  //     refetch: fetchProducts,
  //   } = useCommonOptions(productApi.getProducts, {
  //     respondKey: 'products',
  //     labelKey: 'name',
  //     valueKey: 'id',
  //     lazyLoad: true,
  //   });

  let companyAdminsEmails = companyAdmins.map((anItem) => anItem.sysinfo_role_username);
  let foundSysInfoUsers = sysInfoUsers.filter((anItem) => {
    return companyAdminsEmails.indexOf(anItem.email) >= 0;
  });
  let foundSysInfoUsersOptions = foundSysInfoUsers.map((anItem) => {
    return {
      label: anItem.email,
      value: anItem.user_id,
    };
  });

  const [formValues, setFormValues] = useState({
    company_id: null,
    user_id: null,
    request_description: '',
    other_request_type_id: null,
  });

  const handleSetFormValue = (key, value) => {
    setFormValues((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (show) {
      fetchCompanies();
      //   fetchProducts();
      fetchSysInfoUsers();
    }
  }, [show]);

  useEffect(() => {
    if (show && formValues?.company_id) {
      handleSetFormValue('user_id', null);
      (async () => {
        try {
          let foundCompanyAdmins = await fetchSuperAdmins(formValues?.company_id);
          setCompanyAdmins(foundCompanyAdmins);
        } catch (error) {
          setCompanyAdmins([]);
        }
      })();
    } else {
      setCompanyAdmins([]);
    }
  }, [formValues?.company_id]);

  const handleOnSubmit = async (values) => {
    let selectedCompany = companies.find((anItem) => anItem.company_id == values.company_id);
    // let foundProduct = products.find((anItem) => anItem.id == 19);
    let foundUser = foundSysInfoUsers.find((anItem) => anItem.user_id == values.user_id);
    if (selectedCompany && foundUser) {
      setLoadingSubmit(true);
      // create request
      let requestBody = {
        ...values,
        created_by: values.user_id,
        company_application_status_id: 11,
      };

      let requestRes;
      try {
        requestRes = await requestsApi.createRequest_other(requestBody);
      } catch (error) {
        setLoadingSubmit(false);
        return;
      }
      if (requestRes?.data?.is_success) {
        // create cart
        let cartBody = {
          is_invoice: true,
          price: values.price,
          product_id: 19,
          quantity: 1,
          user_id: values.user_id,
          product_detail_id: requestRes?.data?.applications.other_request_application_id,
          company_id: selectedCompany.company_id,
          company_name: selectedCompany.company_name,
          company_registration_no: selectedCompany.company_registration_no,
          company_application_id: requestRes?.data?.applications.company_application_id,
          last_name: foundUser?.last_name,
          first_name: foundUser?.first_name,
          email: foundUser?.email,
        };

        try {
          let cartRes = await cartApi.addCart(cartBody);
          if (refetch) {
            refetch();
          }
          setLoadingSubmit(false);
          if (onHide) {
            onHide();
          }
        } catch (error) {
          console.log('cartRes error', error);
        }
      }
      setLoadingSubmit(false);
    }
  };

  let validationSchema = Yup.object().shape(validationSchemaObj);
  let initialValues = validationSchema.cast();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema,
    validateOnChange: false,
  });

  let formikErrors = formik?.['errors'];
  return (
    <CustomModal
      show={show}
      onHide={onHide}
      scrollable={false}
      title="New Request"
      //   style={{ width: '70%' }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormFieldContainer
              error={formikErrors?.[fields.company_id.name]}
              helperText={formikErrors?.[fields.company_id.name] || ''}
            >
              <CustomMenu
                data={companyOptions}
                onChange={(e) => {
                  //   handleSetFormValue('company_id', e.target.value);
                  //   formik.handleChange(e);
                  //   formik.setTouched({ [fields.company_id.name]: false });
                  let selectedCompanyId = e.target.value;
                  formik.setFieldValue(fields.company_id.name, selectedCompanyId);

                  if (show && selectedCompanyId) {
                    formik.setFieldValue('user_id', '');
                    (async () => {
                      try {
                        let foundCompanyAdmins = await fetchSuperAdmins(selectedCompanyId);
                        setCompanyAdmins(foundCompanyAdmins);
                      } catch (error) {
                        setCompanyAdmins([]);
                      }
                    })();
                  } else {
                    setCompanyAdmins([]);
                  }
                }}
                label="Company"
              />
              <input
                type="text"
                hidden
                value={formik?.values?.[fields.company_id.name]}
                name={fields.company_id.name}
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FormFieldContainer
              error={formikErrors?.[fields.user_id.name]}
              helperText={formikErrors?.[fields.user_id.name] || ''}
            >
              <CustomMenu
                data={foundSysInfoUsersOptions}
                onChange={(e) => {
                  // handleSetFormValue('user_id', e.target.value);
                  //   formik.handleChange(e);
                  formik.setFieldValue(fields.user_id.name, e.target.value);
                }}
                label="User"
              />
              <input
                type="text"
                hidden
                value={formik?.values?.[fields.user_id.name]}
                name={fields.user_id.name}
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FormFieldContainer
              error={formikErrors?.[fields.other_request_type_id.name]}
              helperText={formikErrors?.[fields.other_request_type_id.name] || ''}
            >
              <CustomMenu
                data={requestTypeOptions}
                onChange={(e) => {
                  // handleSetFormValue('other_request_type_id', e.target.value);
                  //   formik.handleChange(e);
                  formik.setFieldValue(fields.other_request_type_id.name, e.target.value);
                }}
                label="Other Request Type"
              />
              <input
                type="text"
                hidden
                value={formik?.values?.[fields.other_request_type_id.name]}
                name={fields.other_request_type_id.name}
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FormFieldContainer
              error={formikErrors?.[fields.request_description.name]}
              helperText={formikErrors?.[fields.request_description.name] || ''}
            >
              <TextField
                name={fields.request_description.name}
                label={fields.request_description.label}
                variant="outlined"
                value={formik?.values?.[fields.request_description.name]}
                onChange={(e) => {
                  formik.handleChange(e);
                  // handleSetFormValue('request_description', e.target.value);
                }}
                multiline
                minRows={4}
                fullWidth
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FormFieldContainer
              error={formikErrors?.[fields.price.name]}
              helperText={formikErrors?.[fields.price.name] || ''}
            >
              <TextField
                name={fields.price.name}
                label={fields.price.label}
                variant="outlined"
                value={formik?.values?.[fields.price.name]}
                onChange={(e) => {
                  formik.handleChange(e);
                  // handleSetFormValue('request_description', e.target.value);
                }}
                type="number"
                fullWidth
              />
            </FormFieldContainer>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Backdrop open={loadingSubmit} style={{ zIndex: 5 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </CustomModal>
  );
};

export default RequestFormModal;
