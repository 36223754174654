import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Box, Chip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import CustomTable from '../../../../components/CustomTable';
import CustomModal from '../../../../components/CustomModal';
import CustomTextField from '../../../../components/CustomFormFields/CustomTextField';
import CustomRadioGroupField from '../../../../components/CustomFormFields/CustomRadioGroupField';
import CustomSelectField from '../../../../components/CustomFormFields/CustomSelectField';
import FormikDummyField from '../../../../components/CustomFormFields/FormikDummyField';
import useCommonOptions from '../../../../hooks/useCommonOptions';
import commonApi from '../../../../services/common.service';
import companyApi from '../../../../services/company.service';

export const ShareholderTable = ({
  data = [],
  onEdit,
  disabled = false,
  shareTypeOptions = [],
  identificationTypeOptions: defaultIdentificationTypeOptions = [],
  ...restProps
}) => {
  const identificationTypeOptions = [
    ...defaultIdentificationTypeOptions,
    {
      label: 'Company Registered Number',
      value: 3,
    },
  ];
  const handleOnEdit = (rowId) => {
    if (onEdit) {
      onEdit(rowId);
    }
  };

  const shareholderTableColumns = [
    {
      label: '',
      id: 'row_id',
      render: (text, data) => {
        return (
          <Chip
            color="secondary"
            onClick={() => handleOnEdit(data)}
            clickable
            label={'EDIT'}
            disabled={disabled}
          />
        );
      },
    },
    {
      label: 'NAME',
      id: 'user_firstname',
      render: (text, data) => {
        return text || '-';
      },
    },
    {
      label: 'IDENTIFICATION',
      id: 'user_idtype',
      render: (text) => {
        let result = '-';
        if (text) {
          result = identificationTypeOptions?.find((v) => v.value == text)?.label || '-';
        }
        return result;
      },
    },
    {
      label: 'IDENTIFICATION NUMBER',
      id: 'user_idvalue',
      render: (text) => {
        return text || '-';
      },
    },
    {
      label: 'SHARE TYPE',
      id: 'user_sharetype',
      render: (text) => {
        return shareTypeOptions.find((v) => v.value === text)?.label || '-';
      },
    },
    {
      label: 'NUMBER OF SHARE',
      id: 'user_sharenumber',
    },
  ];

  return (
    <CustomTable data={data} columns={shareholderTableColumns} rowKey={'user_id'} {...restProps} />
  );
};

const userTypeId_individual = '1';
const userTypeId_individual_passport = '1';
const userTypeId_company = '2';

const userTypeOptions = [
  {
    label: 'Individual',
    value: userTypeId_individual,
  },
  {
    label: 'Company',
    value: userTypeId_company,
  },
];

export const fields = {
  user_idtype: {
    name: 'user_idtype',
    label: 'Entity',
  },
  user_id: {
    name: 'user_id',
    label: '',
  },
  user_sharetype: {
    name: 'user_sharetype',
    label: 'Share Types Name',
  },
  user_sharenumber: {
    name: 'user_sharenumber',
    label: 'Number of Share',
  },
};

export const validationSchemaObj = {
  user_idtype: Yup.string()
    .required(`${fields.user_idtype.label} is required`)
    .default(userTypeId_individual),
  user_id: Yup.string().required('User is required').default(''),
  user_sharetype: Yup.string().required(`${fields.user_sharetype.label} is required`).default(''),
  user_sharenumber: Yup.number()
    .required(`${fields.user_sharenumber.label} is required`)
    .default(0),
};

export const ShareholderModalForm = ({
  data,
  show = false,
  onHide,
  title,
  disabled = false,
  onSubmit,
  onRemove,

  userData = [],
  shareTypes = [],
  shareholders = [],
}) => {
  let isNew = !Boolean(data);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState(userTypeId_individual);
  let isUserTypeCompany = `${selectedUserTypeId}` == `${userTypeId_company}`;

  let shareTypeOptions =
    shareTypes?.map((anItem) => {
      return {
        label: anItem.share_type_name,
        value: anItem.share_type_id,
      };
    }) || [];
  const validationSchema = useMemo(() => {
    if (isUserTypeCompany) {
      return Yup.object().shape({
        ...validationSchemaObj,
        [fields.user_id.name]: Yup.string().required(`Company is required`),
      });
    }
    return Yup.object().shape(validationSchemaObj);
  }, [selectedUserTypeId]);

  let defaultValues = isNew ? validationSchema.cast() : data;

  const {
    options: companyOptions = [],
    loading: loadingCompanies,
    rawData: companies = [],
    refetch: fetchCompanies,
  } = useCommonOptions(async () => companyApi.getCompanies('', 1, 10000), {
    respondKey: 'companies',
    labelKey: 'company_name',
    valueKey: 'company_id',
    render: (_, anItem) => {
      return `${anItem.company_name} (${anItem.company_registration_no})`;
    },
    lazyLoad: true,
  });

  useEffect(() => {
    (async () => {
      if (show) {
        try {
          setLoading(true);
          if (fetchCompanies) {
            await fetchCompanies();
          }
          let defaultIdType = data?.[fields.user_idtype.name];
          if (defaultIdType) {
            setSelectedUserTypeId(defaultIdType);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [show]);

  const onChangeUserTypeId = useCallback((value, formik) => {
    if (isNew) {
      setSelectedUserTypeId(value);
      formik.setFieldValue(fields.user_id.name, '');
    }
  }, []);

  const getTotalShareholderShares = (shareType) => {
    let totalHoldingShares = 0;
    shareholders.forEach((aShareholder) => {
      if (!aShareholder?.isDeleted) {
        if (aShareholder.user_sharetype == shareType) {
          let aShareNum = parseInt(aShareholder[fields.user_sharenumber.name]);
          totalHoldingShares += aShareNum;
        }
      }
    });
    if (data && data?.[fields.user_sharenumber.name]) {
      totalHoldingShares = totalHoldingShares - parseInt(data?.[fields.user_sharenumber.name] || 0);
    }
    return totalHoldingShares;
  };

  const checkShareNumber = (shareTypeId, shareNumber = 0) => {
    let passShareNumber = false;
    let selectedShareTypeId = shareTypeId;
    let foundShareType = shareTypes.find((anItem) => anItem?.share_type_id == selectedShareTypeId);
    if (foundShareType) {
      let totalHoldingShares = 0;
      totalHoldingShares = getTotalShareholderShares(selectedShareTypeId);

      let newTotalShare = parseFloat(totalHoldingShares) + parseFloat(shareNumber);

      if (newTotalShare <= foundShareType.number_of_shares) {
        passShareNumber = true;
      }
    }
    return passShareNumber;
  };

  const handleOnSubmit = (values) => {
    const { [fields.user_id.name]: selectedUserId } = values;

    let newBody = {
      ...values,
    };
    if (isUserTypeCompany) {
      let foundCompany = companies.find((anItem) => anItem.company_id == selectedUserId);
      if (foundCompany) {
        newBody = {
          ...newBody,
          user_idvalue: foundCompany.company_registration_no,
          user_firstname: foundCompany.company_name,
        };
      }
    } else {
      let foundUser = userData.find((anItem) => anItem.user_id == selectedUserId);
      if (foundUser) {
        if (foundUser?.nric) {
          newBody = {
            ...newBody,
            user_idvalue: foundUser.nric,
            user_firstname: foundUser?.first_name,
          };
        } else {
          newBody = {
            ...newBody,
            // user_idtype: userTypeId_individual_passport,
            user_idvalue: foundUser.passport,
            user_firstname: foundUser?.first_name,
          };
        }
      }
    }
    if (onSubmit) {
      onSubmit(newBody);
    }
  };

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(data);
    }
  };
  const handleOnHideForm = () => {
    if (onHide) {
      onHide();
    }
  };

  const userOptions =
    userData?.map((anItem) => {
      return {
        label: `${anItem?.first_name || ''} ${anItem?.last_name || ''} (${
          anItem?.nric ? `NRIC - ${anItem?.nric}` : `Passport - ${anItem?.passport || ''}`
        })`,
        value: anItem.user_id,
      };
    }) || [];

  return (
    <CustomModal
      show={show}
      onHide={handleOnHideForm}
      scrollable={false}
      title={title || isNew ? 'New Shareholder' : 'Edit Shareholder'}
    >
      {loading ? (
        'Loading'
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          initialValues={defaultValues}
        >
          {(props) => {
            const {
              values,
              handleSubmit,
              validateForm,
              setTouched,
              touched,
              setFieldError,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                {isNew && (
                  <>
                    <FormikDummyField
                      field={fields.user_idtype.name}
                      onChange={onChangeUserTypeId}
                    />
                    <Box mb={2}>
                      <CustomRadioGroupField
                        name={fields.user_idtype.name}
                        label={fields.user_idtype.label}
                        options={userTypeOptions}
                      />
                    </Box>
                  </>
                )}
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.user_id.name}
                    label={isUserTypeCompany ? 'Company' : 'User'}
                    options={isUserTypeCompany ? companyOptions : userOptions}
                  />
                </Box>
                {/* {isUserTypeCompany ? (
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.user_id.name}
                    label={"Company"}
                    options={companyOptions}
                  />
                </Box>
              ) : (
                <Box mb={2}>
                  <CustomSelectField
                    name={fields.user_id.name}
                    label={"User"}
                    options={userOptions}
                  />
                </Box>
              )} */}

                <Box mb={2}>
                  <CustomSelectField
                    name={fields.user_sharetype.name}
                    label={fields.user_sharetype.label}
                    options={shareTypeOptions}
                  />
                </Box>
                <Box mb={2}>
                  <CustomTextField
                    name={fields.user_sharenumber.name}
                    label={fields.user_sharenumber.label}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box display="flex">
                    <Button variant="outlined" onClick={handleOnHideForm}>
                      Cancel
                    </Button>
                    {!isNew && (
                      <Box ml={2}>
                        <Button variant="contained" onClick={handleOnRemove}>
                          Remove
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      validateForm()
                        .then((result) => {
                          let canSubmit = false;

                          if (result && Object.keys(result).length == 0) {
                            canSubmit = true;
                          }

                          if (canSubmit) {
                            let passShareNumber = checkShareNumber(
                              values?.[fields.user_sharetype.name],
                              values?.[fields.user_sharenumber.name]
                            );

                            if (passShareNumber) {
                              handleOnSubmit(values);
                            } else {
                              setFieldError(
                                fields.user_sharenumber.name,
                                "Current shareholder's number of share exceeds share limit."
                              );
                            }
                          } else {
                            enqueueSnackbar(`Please fill up all required fields`, {
                              variant: 'error',
                            });
                            setTouched({ ...touched, ...result });
                          }
                        })
                        .catch((errors) => {
                          enqueueSnackbar(`Please fill up all required fields`, {
                            variant: 'error',
                          });
                          setTouched({ ...touched, ...errors });
                        });
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );
};
