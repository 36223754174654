import { Button, Grid, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import cartSvc from '../../services/cart.service';
import Table from './components/Table/Table';
import CustomTable from '../../components/CustomTable';

// styles
import useStyles from './styles';

export default function Carts(props) {
  var classes = useStyles();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  var [cartList, setCartList] = useState();

  const tableColumns = [
    {
      label: 'ID',
      id: 'id',
    },
    {
      label: 'USER',
      id: 'first_name',
      render: (_, data) => {
        if (!data?.first_name && !data?.last_name) {
          return '-';
        }
        return `${data?.first_name} ${data?.last_name}`;
      },
    },
    {
      label: 'Company',
      id: 'company_name',
      render: (text, data) => {
        if (!text) {
          return '-';
        }
        return `${text} ${
          data?.company_registration_no ? `(${data?.company_registration_no})` : ''
        }`;
      },
    },
    {
      label: 'DATE',
      id: 'created_at',
      render: (text) => {
        return moment(text).format(`DD/MM/yyyy`);
      },
    },
    {
      label: 'ITEMS',
      id: 'items',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text?.length}</Box>;
      },
    },
    {
      label: 'AMOUNT',
      id: 'totalPrice',
      numeric: true,
      render: (text) => {
        return <Box textAlign="right">{text}</Box>;
      },
    },
    // {
    //   label: "STATUS",
    //   id: "status",
    // },
    {
      label: '',
      id: 'id',
      render: (text) => {
        return (
          <Chip
            color="primary"
            label="DETAIL"
            onClick={() => {
              history.push(`/cart?cartid=${text}`);
            }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const carts = await cartSvc.getCarts();
      console.log(carts);
      setCartList(carts || []);
    };

    fetchData().finally(() => setLoading(false));
  }, []);
  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle title="Carts" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <CustomTable rowKey="id" columns={tableColumns} data={cartList} />
                {/* <Table data={cartList} /> */}
              </>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
