import axios from "axios";

export default axios.create({
  // baseURL: "https://ix7tsjr1r0.execute-api.ap-southeast-1.amazonaws.com/Prod",
  baseURL: `${process.env.REACT_APP_COMMON_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});
