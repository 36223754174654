import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  InputBase,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomTextField from '../../components/CustomFormFields/CustomTextField';
// styles
import useStyles from './styles';

// components
import PageTitle from '../../components/PageTitle/PageTitle';

import companyApi from '../../services/company.service';
import documentApi from '../../services/document.service';
import productSvc from '../../services/product.service';
import FileViewerButton2 from '../../components/FileViewerButton2';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const actionss = {
  1: 'In Progress',
  2: 'Pending',
  3: 'Complete',
  4: 'Rejected',
  5: 'Draft',
  6: 'Pending Third Party Approval',
  7: 'Approved',
  8: 'Order Checkout',
  9: 'Order Pending',
  10: 'Payment Complete',
  11: 'In Cart',
  12: 'Active',
  13: 'Suspend',
  14: 'Trash',
};

const actionOptions = [
  {
    label: 'Active',
    value: 12,
  },
  {
    label: 'Suspend',
    value: 13,
  },
  {
    label: 'Move to Pending',
    value: 2,
  },
  {
    label: 'Move to Trash',
    value: 14,
    color: 'dangerText',
  },
  {
    label: 'Approved',
    value: 7,
  },
  {
    label: 'Rejected',
    value: 4,
  },
];

const fieldNames = {
  account_type_id: 'Account Type ID',
  company_name: 'Business Legal Name',
  company_country_name: 'Business Registered Country',
  entity_category: 'Entity Category',
  entity_type: 'Entity Type',
  bus_registration_no: 'Business Reg. number (UEN)',
  industry_nature: 'Nature of Industry',
  sub_industry_nature: 'Nature of Sub-Industry',
  bus_description: 'Product/Service Description',
  company_website: 'Company website',
  number_employees: 'Number of Employee',
  annual_turnover: 'Annual Turnover',

  floor_unit_no: 'Floor/Unit No',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  state_name: 'City / State',
  postal_code: 'Postcode',
  country_name: 'Country',

  status_name: 'Status',

  fieldName_businessActivity: 'businessActivity',
  fieldName_businessFiles: 'bus_document_attachment_id',
};

const fieldsDisplay = [
  {
    label: 'Business Structure',
    fields: {
      account_type_id: 'Account Type ID',
      company_name: 'Business Legal Name',
      company_country_name: 'Business Registered Country',
      entity_category: 'Entity Category',
      entity_type: 'Entity Type',
      bus_registration_no: 'Business Reg. number (UEN)',
      industry_nature: 'Nature of Industry',
      sub_industry_nature: 'Nature of Sub-Industry',
      bus_description: 'Product/Service Description',
      company_website: 'Company website',
      number_employees: 'Number of Employee',
      annual_turnover: 'Annual Turnover',
    },
  },
  {
    label: 'Business Detail',
    fields: {
      floor_unit_no: 'Floor/Unit No',
      address_line_1: 'Address Line 1',
      address_line_2: 'Address Line 2',
      state_name: 'City / State',
      postal_code: 'Postcode',
      country_name: 'Country',
    },
  },
  {
    label: 'Company Director',
    fields: {
      director_name: 'Name',
      director_passport_ic: 'Passport / IC',
      director_email: 'Email',
      director_contact_number: 'Contact Number',
    },
  },
];

function OnboardProfileDetail(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  const routeParams = useParams();
  const onboardProfileId = routeParams?.id || null;
  // local
  const [selectedAction, setSelectedAction] = useState();
  const [isLoading, setLoading] = useState(true);
  const [onboardProfile, setOnboardProfile] = useState({});
  const [onboardProfileDocuments, setOnboardProfileDocuments] = useState([]);
  const [onboardProfileRemarks, setOnboardProfileRemarks] = useState('');
  const [onboardProfileProduct, setOnboardProfileProduct] = useState();
  const [onboardProfileExtra, setOnboardProfileExtra] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const respOnboardProfile = await companyApi.getOnboardProfilesPublicById(onboardProfileId);

    if (respOnboardProfile?.data?.is_success) {
      let onboardProfile = respOnboardProfile?.data?.onboard_profile || {};
      setOnboardProfile(onboardProfile);
      setOnboardProfileRemarks(onboardProfile?.remarks);

      let product;
      if (onboardProfile?.product_id) {
        let resProduct = await productSvc.getProduct(onboardProfile?.product_id);

        if (resProduct?.data?.product) {
          product = resProduct?.data?.product;
        }
      }
      setOnboardProfileProduct(product);
      // const getRemarksObj = () => {
      //   let result = onboardProfile?.remarks;
      //   if (result) {
      //     let matches = result.match(/\[(.*?)\]/);

      //     let objString = matches?.[1];
      //     if (objString) {
      //       try {
      //         let obj = JSON.parse(objString);
      //         setOnboardProfileExtra(obj || {});
      //       } catch (error) {}
      //     }

      //     let foundIndex = result.indexOf(']');
      //     let actualRemarks = result.substring(foundIndex + 1);
      //     setOnboardProfileRemarks(actualRemarks);
      //   }
      // };

      // let remarksObj = getRemarksObj();
    }

    const resDocuments = await documentApi.getDocumentsSTS(167, 29, onboardProfileId);

    if (resDocuments?.data?.document) {
      setOnboardProfileDocuments(resDocuments?.data?.document || []);
    }
  };

  useEffect(() => {
    if (onboardProfileId) {
      fetchData().finally(() => setLoading(false));
    }
  }, [onboardProfileId]);

  const handleAction = async () => {
    const updateRes = await companyApi.updateOnboardProfilesPublicById(onboardProfileId, {
      onboard_status_id: selectedAction,
      to_email: onboardProfile?.email,
      from_email: 'support@meyzer360.com',
    });
    setSelectedAction();

    fetchData(true).finally(() => setLoading(false));
  };

  const handleUpdateRemarks = async () => {
    const updateRes = await companyApi.updateOnboardProfilesPublicById(onboardProfileId, {
      // remarks: `[${JSON.stringify(onboardProfileExtra)}]${onboardProfileRemarks}`,
      remarks: onboardProfileRemarks,
    });

    fetchData(true).finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        // onClick={() => history.goBack()}
        onClick={() => history.push({ pathname: '/company-account-request-public' })}
      >
        BACK TO ONBOARD PROFILES
      </Button>
      <PageTitle
        title={`Onboarding Company Public Request Detail (ID: ${onboardProfileId || '-'})`}
      />
      <Paper>
        {isLoading ? (
          <CircularProgress />
        ) : onboardProfile ? (
          <Box p={4}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.fontWeightBold, classes.marginBottom)}>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item xs={12}>
                    <Box textAlign="right">Status: {onboardProfile?.status_name || '-'}</Box>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedAction}
                        onChange={(e) => {
                          setSelectedAction(e.target.value);
                        }}
                        input={<BootstrapInput />}
                        displayEmpty={true}
                        renderValue={(value) => {
                          let options = actionOptions;
                          let result = (
                            <span style={{ color: theme.palette.grey['500'] }}>Select Action</span>
                          );
                          let foundValue = options.find((anOption) => {
                            return anOption.value == value;
                          });
                          if (foundValue) {
                            result = foundValue.label;
                          }
                          return result;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>Select Action</em>
                        </MenuItem>
                        {actionOptions.map((anAction, index) => {
                          let menuItemProps = {};
                          if (anAction.color) {
                            menuItemProps['className'] = classes[anAction.color];
                          }
                          return (
                            <MenuItem key={index} value={anAction.value} {...menuItemProps}>
                              {anAction.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation={true}
                      className={classes.button}
                      onClick={handleAction}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}>{onboardProfileProduct.name}</Box>
              </Grid>
              {fieldsDisplay.map((aGroup, index) => {
                let fieldKeys = Object.keys(aGroup.fields);
                return (
                  <Grid item container key={index} className={classes.marginBottom}>
                    <Grid item xs={12} className={classes.fontWeightBold}>
                      {aGroup.label}
                    </Grid>
                    {fieldKeys.map((aKey, index2) => {
                      return (
                        <Grid item container xs={12} key={index2}>
                          <Grid item xs={6}>
                            {`${aGroup.fields[aKey] || '-'}`}
                          </Grid>
                          <Grid item xs={6}>
                            {`${onboardProfile[aKey] || '-'}`}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
              {/* <Grid item container className={classes.marginBottom} xs={12}>
                <Grid item xs={12} className={classes.fontWeightBold}>
                  Company Director
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    Name
                  </Grid>
                  <Grid item xs={6}>
                    {onboardProfileExtra?.[fieldName_directorName] || '-'}
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    Passport / IC
                  </Grid>
                  <Grid item xs={6}>
                    {onboardProfileExtra?.[fieldName_directorPassportIC] || '-'}
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    Email
                  </Grid>
                  <Grid item xs={6}>
                    {onboardProfileExtra?.[fieldName_directorEmail] || '-'}
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    Contact Number
                  </Grid>
                  <Grid item xs={6}>
                    {onboardProfileExtra?.[fieldName_directorContactNumber] || '-'}
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12}>
                <Box mb={1} className={classes.fontWeightBold}>
                  Business Document
                </Box>
                {onboardProfileDocuments && onboardProfileDocuments.length > 0 ? (
                  <Box display="flex" flexDirection="column">
                    {onboardProfileDocuments.map((aDoc, index) => {
                      return (
                        <Box mb={1} key={aDoc?.document_id}>
                          <FileViewerButton2
                            srcType="url"
                            src={aDoc?.signed_document_url}
                            size="small"
                            filePreviewProps={{ style: { minHeight: '80vh' } }}
                            modalProps={{ style: { minHeight: '400px' }, maxWidth: 'lg' }}
                            document={aDoc}
                          >
                            View Document {aDoc?.file_name || '-'} (ID:
                            {aDoc?.document_id})
                          </FileViewerButton2>
                        </Box>
                      );
                      return (
                        <div key={index} style={{ marginBottom: '5px' }}>
                          <Button
                            variant="contained"
                            target="_blank"
                            color={'primary'}
                            href={aDoc?.signed_document_url}
                          >
                            View Document {aDoc?.document_type_name || '-'} (ID:
                            {aDoc?.document_id})
                          </Button>
                        </div>
                      );
                    })}
                  </Box>
                ) : (
                  'No Document found'
                )}
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>Remarks</Box>
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    minRows={4}
                    multiline={true}
                    value={onboardProfileRemarks}
                    onChange={(e) => {
                      setOnboardProfileRemarks(e.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <Button variant="contained" color="primary" onClick={handleUpdateRemarks}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No Data'
        )}
      </Paper>
    </>
  );
}

export default OnboardProfileDetail;
