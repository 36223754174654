import { Redirect } from 'react-router-dom';
import {
  ArrowBack as ArrowBackIcon,
  Business as BusinessIcon,
  CloudUpload as FileUploadIcon,
  Home as HomeIcon,
  People as PeopleIcon,
  PostAdd as PostAddIcon,
  List as OrderIcon,
  AddShoppingCart as CartIcon,
  Apps as ProductIcon,
  Web as WebIcon,
  Settings as SettingsIcon,
  AttachMoney as AttachMoneyIcon,
  Extension as ExtensionIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
} from '@material-ui/icons';

import Carts from '../pages/cart/Carts';
import CartDetail from '../pages/cart/Detail';
import Company from '../pages/company';
import OnboardProfiles from '../pages/onboardProfiles';
import OnboardProfileDetail from '../pages/onboardProfileDetail';
import OnboardProfilesPublic from '../pages/onboardProfilesPublic';
import OnboardProfilePublicDetail from '../pages/onboardProfilePublicDetail';
import SysInfoUsers from '../pages/sysInfoUsers';
import Companydetails from '../pages/companydetails';
import Companydocuments from '../pages/companydocuments';
import Companyform from '../pages/companyform';
import Tasks from '../pages/tasks/Tasks';
import TaskDetail from '../pages/tasks/Detail';
import LogTimeline from '../pages/logtimeline/LogTimeline';
import Dashboard from '../pages/dashboard';
import Form from '../pages/form';
import Individual from '../pages/individual';
import Individualdetails from '../pages/individualdetails';
import Individualdocuments from '../pages/individualdocuments';
import Notifications from '../pages/notifications';
import OrderDetail from '../pages/orders/Detail';
import Orders from '../pages/orders/Orders';
import OrdersByProductCategory from '../pages/orders/OrdersByProductCategory';
import OrderItemsByProductCategory from '../pages/orders/OrderItemsByProductCategory';
import Products from '../pages/product/Products';
import Requests from '../pages/requests';
import RequestDetail from '../pages/requests/Detail';
import Upload from '../pages/upload/Upload';
import WebDevDetail from '../pages/webdev/Detail';
import WebDevs from '../pages/webdev/WebDevs';
import ContactSupports from '../pages/contactsupports/ContactSupports';
import CompanyApplicationLogs from '../pages/companyApplicationLogs/CompanyApplicationLogs';
import EmailLogs from '../pages/emailLogs/EmailLogs';
import SendEmail from '../pages/sendEmail/SendEmail';
import EmailTemplates from '../pages/emailTemplates/EmailTemplates';
import AdminUsers from '../pages/adminUsers/AdminUsers';
import Charts from '../pages/charts';
import Tables from '../pages/tables';
import Maps from '../pages/maps';
import Icons from '../pages/icons';
import Typography from '../pages/typography';

export const nav = [
  { label: 'Dashboard', link: '/dashboard', icon: <HomeIcon /> },
  {
    label: 'Corpsec',
    icon: <BusinessIcon />,
    children: [
      {
        label: 'Individual',
        link: '/individual',
        icon: <PeopleIcon />,
        menu_id: 1,
      },
      {
        label: 'Company',
        link: '/company',
        icon: <BusinessIcon />,
        menu_id: 2,
      },
      {
        label: 'Onboarding Company Requests',
        link: '/company-account-request',
        icon: <BusinessIcon />,
        menu_id: 3,
      },
      {
        label: 'Onboarding Company Public Requests',
        link: '/company-account-request-public',
        icon: <BusinessIcon />,
        menu_id: 3,
      },
      {
        label: 'Requests',
        link: '/requests',
        icon: <PostAddIcon />,
        menu_id: 4,
      },
      {
        label: 'Upload',
        link: '/upload',
        icon: <FileUploadIcon />,
        menu_id: 5,
      },
      {
        label: 'Tasks',
        link: '/tasks',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 6,
      },
      {
        label: 'Company Compliance Timeline',
        link: '/logtimeline',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 7,
      },
      {
        label: 'Company Application Logs',
        link: '/companyapplicationlogs',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 8,
      },
      {
        label: 'Products',
        link: '/corpsec-products',
        icon: <ProductIcon />,
      },
      {
        label: 'Orders',
        link: '/corpsec-orders',
        icon: <OrderIcon />,
      },
    ],
  },
  {
    label: 'System',
    icon: <SettingsIcon />,
    children: [
      {
        label: 'SysInfo Users',
        link: '/sysinfo-users',
        icon: <BusinessIcon />,
        menu_id: 9,
      },
      {
        label: 'Admin Users',
        link: '/admin-users',
        icon: <BusinessIcon />,
        // menu_id: 10,
      },
      {
        label: 'Contact Supports',
        link: '/contactsupports',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 11,
      },
      {
        label: 'Email Templates',
        link: '/emailtemplates',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 12,
      },
      {
        label: 'Send Email',
        link: '/sendemail',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 18,
      },
      {
        label: 'Email Logs',
        link: '/emaillogs',
        icon: <PlaylistAddCheckIcon />,
        menu_id: 13,
      },
    ],
  },
  {
    label: 'Finance',
    icon: <AttachMoneyIcon />,
    children: [
      {
        label: 'Carts',
        link: '/carts',
        icon: <CartIcon />,
        menu_id: 14,
      },
      {
        label: 'Orders',
        link: '/orders',
        icon: <OrderIcon />,
        menu_id: 15,
      },
      {
        label: 'Products',
        link: '/products',
        icon: <ProductIcon />,
        menu_id: 16,
      },
    ],
  },
  {
    label: 'Web Services',
    icon: <ExtensionIcon />,
    children: [
      {
        label: 'Web Devs',
        link: '/webdevs',
        icon: <WebIcon />,
        menu_id: 17,
      },
    ],
  },
  {
    label: 'Office Supplies',
    icon: <ExtensionIcon />,
    children: [
      {
        label: 'Products',
        link: '/officesupplies-products',
        icon: <ProductIcon />,
      },
      {
        label: 'Orders',
        link: '/officesupplies-orders',
        icon: <OrderIcon />,
      },
      {
        label: 'Delivery',
        link: '/officesupplies-delivery',
        icon: <ProductIcon />,
      },
    ],
  },
  {
    label: 'Software Licenses',
    icon: <ExtensionIcon />,
    children: [
      {
        label: 'Products',
        link: '/softwarelicenses-products',
        icon: <ProductIcon />,
      },
      {
        label: 'Orders',
        link: '/softwarelicenses-orders',
        icon: <OrderIcon />,
      },
      {
        label: 'Delivery',
        link: '/softwarelicenses-delivery',
        icon: <ProductIcon />,
      },
    ],
  },

  //   { label: "Tables", link: "/tables", icon: <TableIcon /> },
  //   {
  //     label: "Notifications",
  //     link: "/notifications",
  //     icon: <NotificationsIcon />,
  //   },
  //   {
  //     label: "UI Elements",
  //     link: "/ui",
  //     icon: <UIElementsIcon />,
  //     children: [
  //       { label: "Icons", link: "/ui/icons" },
  //       { label: "Charts", link: "/ui/charts" },
  //       { label: "Maps", link: "/ui/maps" },
  //     ],
  //   },
  //   {  type: "divider" },
  //   {  type: "title", label: "HELP" },
  //   {
  //     label: "Library",
  //     link: "https://meyzer.com/",
  //     icon: <LibraryIcon />,
  //   },
  //   {
  //     label: "Support",
  //     link: "https://meyzer.com/",
  //     icon: <SupportIcon />,
  //   },
  //   { label: "FAQ", link: "https://meyzer.com/", icon: <FAQIcon /> },
  //   {  type: "divider" },
  //   {  type: "title", label: "PROJECTS" },
  //   {
  //     label: "My recent",
  //     link: "",
  //     icon: <Dot size="small" color="warning" />,
  //   },
  //   {
  //     label: "Starred",
  //     link: "",
  //     icon: <Dot size="small" color="primary" />,
  //   },
  //   {
  //     label: "Background",
  //     link: "",
  //     icon: <Dot size="small" color="secondary" />,
  //   },
];

export const routes = [
  {
    name: '',
    path: '/dashboard',
    component: Dashboard,
  },
  // Corpsec
  {
    name: '',
    path: '/form',
    component: Form,
    menu_id: 1,
  },
  {
    name: '',
    path: '/individual',
    component: Individual,
    menu_id: 1,
  },
  {
    name: '',
    path: '/individualdocuments',
    component: Individualdocuments,
    menu_id: 1,
  },
  {
    name: '',
    path: '/individualdetails',
    component: Individualdetails,
    menu_id: 1,
  },
  {
    name: '',
    path: '/companyform',
    component: Companyform,
    menu_id: 2,
  },
  {
    name: '',
    path: '/company',
    component: Company,
    menu_id: 2,
  },
  {
    name: '',
    path: '/companydetails',
    component: Companydetails,
    menu_id: 2,
  },
  {
    name: '',
    path: '/companydocuments',
    component: Companydocuments,
    menu_id: 2,
  },
  {
    name: '',
    path: '/company-account-request',
    component: OnboardProfiles,
    exact: true,
    menu_id: 3,
  },
  {
    name: '',
    path: '/company-account-request/:id',
    component: OnboardProfileDetail,
    exact: true,
    menu_id: 3,
  },
  {
    name: '',
    path: '/company-account-request-public',
    component: OnboardProfilesPublic,
    exact: true,
    menu_id: 3,
  },
  {
    name: '',
    path: '/company-account-request-public/:id',
    component: OnboardProfilePublicDetail,
    exact: true,
    menu_id: 3,
  },
  {
    name: '',
    path: '/requests',
    component: Requests,
    menu_id: 4,
  },
  {
    name: '',
    path: '/request',
    component: RequestDetail,
    menu_id: 4,
  },
  {
    name: '',
    path: '/upload',
    component: Upload,
    menu_id: 5,
  },
  {
    name: '',
    path: '/tasks',
    component: Tasks,
    menu_id: 6,
  },
  {
    name: '',
    path: '/task',
    component: TaskDetail,
    menu_id: 6,
  },
  {
    name: '',
    path: '/logtimeline',
    component: LogTimeline,
    menu_id: 7,
  },
  {
    name: '',
    path: '/companyapplicationlogs',
    component: CompanyApplicationLogs,
    menu_id: 8,
  },
  // System
  {
    name: '',
    path: '/sysinfo-users',
    component: SysInfoUsers,
    menu_id: 9,
  },

  {
    name: '',
    path: '/admin-users',
    component: AdminUsers,
    // menu_id: 10,
  },

  {
    name: '',
    path: '/contactsupports',
    component: ContactSupports,
    menu_id: 11,
  },
  {
    name: '',
    path: '/emailtemplates',
    component: EmailTemplates,
    menu_id: 12,
  },
  {
    name: '',
    path: '/sendemail',
    component: SendEmail,
    menu_id: 18,
  },
  {
    name: '',
    path: '/emaillogs',
    component: EmailLogs,
    menu_id: 13,
  },

  // Finance
  {
    name: '',
    path: '/carts',
    component: Carts,
    menu_id: 14,
  },
  {
    name: '',
    path: '/cart',
    component: CartDetail,
    menu_id: 14,
  },
  {
    name: '',
    path: '/orders',
    component: Orders,
    menu_id: 15,
  },
  {
    name: '',
    path: '/order',
    component: OrderDetail,
    menu_id: 15,
  },
  {
    name: '',
    path: '/products',
    component: Products,
    menu_id: 16,
  },
  // Web Services

  {
    name: '',
    path: '/webdevs',
    component: WebDevs,
    menu_id: 17,
  },
  {
    name: '',
    path: '/webdev',
    component: WebDevDetail,
    menu_id: 17,
  },
  // Office Supplies
  {
    name: '',
    path: '/corpsec-products',
    render: () => <Products title="Corpsec Products" productCategoryId={1} />,
  },
  {
    name: '',
    path: '/corpsec-orders',
    render: () => <OrdersByProductCategory title="Corpsec Orders" productCategoryId={1} />,
  },
  {
    name: '',
    path: '/officesupplies-products',
    render: () => <Products title="Office Supplies Products" productCategoryId={2} />,
  },
  {
    name: '',
    path: '/officesupplies-orders',
    render: () => <OrdersByProductCategory title="Office Supplies Orders" productCategoryId={2} />,
  },
  {
    name: '',
    path: '/officesupplies-delivery',
    render: () => (
      <OrderItemsByProductCategory title="Office Supplies Delivery" productCategoryId={2} />
    ),
  },
  {
    name: '',
    path: '/softwarelicenses-products',
    render: () => <Products title="Software Licenses Products" productCategoryId={3} />,
  },
  {
    name: '',
    path: '/softwarelicenses-orders',
    render: () => (
      <OrdersByProductCategory title="Software Licenses Orders" productCategoryId={3} />
    ),
  },
  {
    name: '',
    path: '/softwarelicenses-delivery',
    render: () => (
      <OrderItemsByProductCategory title="Software Licenses Delivery" productCategoryId={3} />
    ),
  },

  // others
  {
    name: '',
    path: '/ui',
    render: () => <Redirect to="/ui/icons" />,
    exact: true,
  },
  {
    name: '',
    path: '/typography',
    component: Typography,
  },
  {
    name: '',
    path: '/tables',
    component: Tables,
  },
  {
    name: '',
    path: '/notifications',
    component: Notifications,
  },
  {
    name: '',
    path: '/ui/maps',
    component: Maps,
  },
  {
    name: '',
    path: '/ui/icons',
    component: Icons,
  },
  {
    name: '',
    path: '/ui/charts',
    component: Charts,
  },
];
