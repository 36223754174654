import React from 'react';
import { Typography, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { Field } from 'formik';
import FormFieldContainer from '../FormFieldContainer';

const CustomCheckboxGroupField = (props) => {
  const {
    label,
    name,
    options = [],
    placeholder,
    containerProps: defaultContainerProps = {},
    helperText,
    variant = 'default',
    labelPlacement = 'end',
    disabled,
    required = false,
    children,
    ...restProps
  } = props;

  let fieldProps = {
    name: name,
    row: true,
    disabled,
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        const { setFieldValue } = form;
        const { value = [], onChange } = field;

        let fieldValue = value?.length > 0 ? value : [];
        let error = meta.touched && !!meta.error;

        const handleChange = (event) => {
          let isChecked = event.target.checked;
          let selectedValue = event.target.value;
          let result = [];

          if (isChecked) {
            result = [...fieldValue, selectedValue];
          } else {
            result = result.filter((anItem) => {
              return anItem != selectedValue;
            });
          }
          setFieldValue(name, result);
        };

        return (
          <FormFieldContainer
            label={label}
            error={error}
            required={required}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            <FormGroup>
              {options && options.length > 0 ? (
                options.map((anOption, index) => {
                  let isChecked = fieldValue.find((anItem) => anItem == anOption.value);

                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={Boolean(isChecked)}
                          onChange={handleChange}
                          name={name}
                          color="primary"
                          value={anOption.value}
                        />
                      }
                      label={anOption.label}
                    />
                  );
                })
              ) : (
                <div>No Options</div>
              )}
              {children}
            </FormGroup>
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomCheckboxGroupField;
